import React, { useState, useEffect, Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaLayerGroup, FaStar, FaUser, FaClock, FaBookReader, FaCommentAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Book2 from "../images/304301_1706363750816.jpg"
import Book1 from "../images/860295_1704701727805.jpg"
import { callingAPI } from '../service';
import { useNavigate } from 'react-router-dom';
import noDataImg from "../images/empty.png"
function Content(props) {
    const navigate = useNavigate();
    const [list, setList] = useState([])
    const [listFlag, setListFlag] = useState()

    useEffect(() => {
        const PostData = {
            "userId": props.userID,
            "max": "8",
            "offset": "0",
            "cmbSearchType": "",
            "searchInput": ""
        }
        callingAPI('book/getbooks', PostData).then((response) => {
            if (response.data.success == 1) {
                setList(response.data.result[0].publishedBookLists)
                if (response.data.result[0].publishedBookLists.length === 0) {
                    setListFlag(true)
                }
                else {
                    setListFlag(false)
                }
            }
            else {
                setList([])
            }
        })
    }, [props.userID])

    const displayBookDuration = (decimalMinutes) => {
        const totalSeconds = Math.round(decimalMinutes * 60);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        let result = '';
        if (hours > 0) {
            result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
        }
        if (minutes > 0) {
            result += `${result.length > 0 ? ' and ' : ''}${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        }
        if (seconds > 0 && hours <= 0 && minutes <= 0) {
            result += `${result.length > 0 ? ' and ' : ''}${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
        }
        if (result.length === 0) {
            result += 'Less than a minute';
        }
        return result;
    }
    const goToBookDetails = (userID) => {
        navigate('/book-detail', { state: { userID: userID } });
        window.scrollTo({top:0,behavior:'smooth'});
    };
    return (
        <div className="row starBooksNodata">
            {list &&
                <>
                    {list.map((data) =>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 zoom-effect-container">
                            <span onClick={() => goToBookDetails(data.bookId)} >
                                <div className="booklist-sec shine-overlay">
                                    <a href=""><img src={data.bookImageWithPath} alt="" /></a>
                                    <div className="image-overlay"></div>
                                    <div class="shine"></div>
                                    {data.avgRating > 0 &&
                                        <div className="rating-div"><FaStar /><span className='rating-div-text'>{parseFloat(data.avgRating.toFixed(1))}</span></div>}
                                    {data.noOfEpisodes > 0 &&
                                        <div className="episode-partText">
                                            <FaLayerGroup /> {data.noOfEpisodes} Parts
                                        </div>}
                                </div>
                                <div className="booklist-content">
                                    <h3 title={data.title}>{data.title}</h3>

                                    <div className="d-flex justify-content-between">
                                        <div className="authortext" title="Duration"><FaClock /><span className='bookDuration'>{displayBookDuration(data.duration)}</span></div>
                                        <div className="authortext" title='Views'><FaBookReader /><span>{data.no_of_views} </span></div>
                                        {/* <div className="authortext" title='Reviews'><FaCommentAlt /><span>{data.no_of_reviews} Reviews</span></div> */}
                                    </div>
                                </div>
                            </span>
                        </div>)}

                    {list.length > 8 &&
                        <div className="btn-viewmore-outer">
                            <div className='btn-boolist-viewmore'>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                View More
                            </div>
                        </div>}
                </>}
            {listFlag &&
                <div class="color-text-follow noDataTxts">
                    <span><img src={noDataImg} className='noDataImg' /></span>
                    <p>  No Data Found</p>
                </div>
            }
        </div>
    )

}
export default Content