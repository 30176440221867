import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Document, Page, pdfjs } from 'react-pdf';

// Configure PDFJS worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfContent, setPdfContent] = useState('');
    const [error, setError] = useState(null);

    const onFileChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async function (event) {
            try {
                const buffer = event.target.result;
                const typedArray = new Uint8Array(buffer);
                const pdf = await pdfjs.getDocument(typedArray).promise;
                setNumPages(pdf.numPages);
                let content = '';
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const textContent = await page.getTextContent();
                    content += textContent.items.map((item) => item.str).join(' ');
                }
                setPdfContent(content);
            } catch (error) {
                console.error('Error during PDF processing:', error);
                setError('Error occurred while processing PDF.');
            }
        };

        reader.onerror = function (event) {
            console.error('Error occurred while reading the file:', event.target.error);
            setError('Error occurred while reading the file.');
        };

        reader.readAsArrayBuffer(file);
    };

    const onPageLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const onTextChange = (content) => {
        setPdfContent(content);
    };

    return (
        <div>
            <input type="file" onChange={onFileChange} />
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <ReactQuill
                        value={pdfContent}
                        onChange={onTextChange}
                        readOnly={false}
                        theme="snow"
                    />
            {pdfContent && (
                <div>
                   
                    <Document
                        file={URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))}
                        onLoadSuccess={onPageLoadSuccess}
                    >
                        {[...Array(numPages)].map((_, index) => (
                            <Page key={index + 1} pageNumber={index + 1} />
                        ))}
                    </Document>
                    <p>
                        Page {pageNumber} of {numPages}
                    </p>
                </div>
            )}
        </div>
    );
};

export default PdfViewer;
