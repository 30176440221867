import React, { useEffect, useState } from "react";
import { FaBookReader, FaClock, FaStar, FaUser,FaCommentAlt } from "react-icons/fa";
import { callingAPI } from '../../service'
export default function LatestReleases(props) {
    const [latRelease, setLatRelease] = useState([]);
    const [top20, setTop20] = useState([]);
    useEffect(() => {
        latestRelease();

        // Smooth Hscroll
        const scroll = document.querySelector(".scroll");
        var isDown = false;
        var scrollX;
        var scrollLeft;
        // Mouse Up Function
        scroll.addEventListener("mouseup", () => {
            isDown = false;
            scroll.classList.remove("active");
        });

        // Mouse Leave Function
        scroll.addEventListener("mouseleave", () => {
            isDown = false;
            scroll.classList.remove("active");
        });

        // Mouse Down Function
        scroll.addEventListener("mousedown", (e) => {
            e.preventDefault();
            isDown = true;
            scroll.classList.add("active");
            scrollX = e.pageX - scroll.offsetLeft;
            scrollLeft = scroll.scrollLeft;
        });

        // Mouse Move Function
        scroll.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            var element = e.pageX - scroll.offsetLeft;
            var scrolling = (element - scrollX) * 2;
            scroll.scrollLeft = scrollLeft - scrolling;
        });
    }, []);
    const latestRelease = () => {
        const PostData = {
            userId: "",
            // userId: window.localStorage.getItem("userId"),
        }
        callingAPI('home/getHomeData', PostData).then((res) => {
            setLatRelease(res.data.result[0]["Latest Releases"]);
            setTop20(res.data.result[0]["Top 20 This Week"]);

        })

    }
    return (
        <>
            <h3 className="topHead">Latest Releases </h3>
            <div className="row">
                <div className="col-md-12">
                    <main class="main">
                        <div class="scroll">
                            {latRelease.map((obj, i) => (
                                <div class="card dashCardsBooks"
                                    onClick={() => props.handleDetailClick({ bookId: obj.bookId, user_id: obj.user_id })}>
                                    <div class="card-image">
                                        <img src={obj.bookImageWithPath} loading="lazy" class="responsive" alt="Krithy" />
                                        {obj.episodeCount > 0 &&
                                            <p className="episodesCount"><FaStar style={{ fontSize: "12px" }} />&nbsp;{obj.episodeCount} Episode(s)</p>
                                        }
                                    </div>
                                    <div class="card-inner">
                                        {obj.avgRating !== "" &&obj.avgRating>0 &&
                                            <p className="starRatingCount"><FaStar style={{ fontSize: "12px" }} />&nbsp;{obj.avgRating.toFixed(1)}</p>
                                        }
                                        <h3 class="text text-title latestRTitle" title={obj.title}>{obj.title}</h3>
                                        <h4 className="  latestRTitle" title={obj.authorName}><FaUser style={{ fontSize: "15px" }} /> {obj.authorName}</h4>
                                        <h4><FaClock style={{ fontSize: "15px" }} /> {obj.duration}</h4>
                                        <h4><FaBookReader style={{ fontSize: "15px" }} /> {obj.no_of_views} Views</h4>
                                        {obj.no_of_reviews>0 ?
                                         <h4><FaCommentAlt style={{ fontSize: "15px" }} /> {obj.no_of_reviews} Reviews</h4>
                                       :
                                       <h4>&nbsp;</h4>
                                        }
                                    </div>

                                </div>
                            ))}

                        </div>
                    </main>
                </div>
            </div>


            <h3 className="topHead">Top 20 This Week</h3>
            <div className="row">
                <div className="col-md-12">
                    <main class="main">
                        <div class="scroll">                      
                            {top20.map((obj, i) => (
                                <div class="card dashCardsBooks" onClick={() => props.handleDetailClick({ bookId: obj.bookId, user_id: obj.user_id })}>

                                    <div class="card-image">

                                        <img src={obj.bookImageWithPath} loading="lazy" class="responsive" alt="Krithy" />
                                        {obj.episodeCount > 0 &&
                                            <p className="episodesCount"><FaStar style={{ fontSize: "12px" }} />&nbsp;{obj.episodeCount} Episode(s)</p>
                                        }

                                    </div>
                                    <div class="card-inner">
                                        {obj.avgRating !== "" && obj.avgRating>0 &&
                                            <p className="starRatingCount"><FaStar style={{ fontSize: "12px" }} />&nbsp;{obj.avgRating.toFixed(1)}</p>
                                        }
                                        <h3 class="text text-title latestRTitle" title={obj.title}>{obj.title}</h3>
                                        <h4 className="latestRTitle" title={obj.authorName}><FaUser style={{ fontSize: "15px" }} /> {obj.authorName}</h4>
                                        <h4><FaClock style={{ fontSize: "15px" }} /> {obj.duration}</h4>
                                        <h4><FaBookReader style={{ fontSize: "15px" }} /> {obj.no_of_views} Views</h4>
                                        {obj.no_of_reviews>0 && <h4><FaCommentAlt style={{ fontSize: "15px" }} /> {obj.no_of_reviews} Reviews</h4>}
                                    </div>

                                </div>
                            ))}

                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}