import React, { useState, Component ,useEffect} from 'react';
import ReactApexChart from "react-apexcharts";
import { callingAPI } from '../../service';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
export default function TaskDistribution() {
    const [loginData, setloginData] = useState([]);
    useEffect(() => {
      
        const json = {

        };
        callingAPI('/appDetails/appDeviceCount', json).then((response) => {
            if (response.data.success === '1') {
                setloginData(response.data.result)
                const { androidDeviceUsersCount, iosDeviceUsersCount } = response.data.result;
                setChartData(prevState => ({
                    ...prevState,
                    series: [iosDeviceUsersCount, androidDeviceUsersCount],
                }));
            }
            else {
                setloginData([])
            }
        })
            .catch();
    }, []);
    const [chartData, setChartData] = useState({
        series: [0, 9],
        options: {
            chart: {
                type: 'polarArea',
            },
            // colors: ["#b6c9dd", "#a1acb9","#81a0c5","#77a0ce"],
            colors: ["#b6c9dd", "#77a0ce"],
            stroke: {
                colors: ['#fff'],
            },
            fill: {
                opacity: 0.8,
            },
            labels: ["IOS", "Android",],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 100,
                        },
                        legend: {
                            position: 'top',
                        },
                    },
                },
            ],
        },
    });

    return (
        <>
        
            <div className="issueSummWrap">
                <h2>Device Login Count</h2>
                <div className="summaryTabs">
                    <Tabs>
                        <TabList>
                            {/* <Tab>Last Week</Tab> */}
                            {/* <Tab>This Week</Tab> */}
                        </TabList>

                        <TabPanel>

                            <div id="chart">
                                <ReactApexChart
                                    options={chartData.options}
                                    series={chartData.series}
                                    type="polarArea" height={320}
                                />
                            </div>
                            <div className="teamContact">
                                <div className="teamConn1">
                                    <span> <h2>{loginData.iosDeviceUsersCount}</h2>
                                        <p>IOS</p></span>
                                    <span>
                                        <h2>{loginData.androidDeviceUsersCount}</h2>
                                        <p>Android</p>
                                    </span>
                                </div>
                            </div>

                        </TabPanel>
                        {/* <TabPanel>
                            <h2>Any content 2</h2>
                        </TabPanel> */}
                    </Tabs>
                </div>

            </div>
        </>
    )

}