import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header";
import { FaEdit, FaFileDownload, FaPlus, FaSearch, FaTrashAlt } from "react-icons/fa";
import { FaCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import Chats from "../dashboard/chats";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { callingAPI } from '../../service'
import moment from 'moment';
import Pagination from "react-js-pagination";
import Select from "react-select";
import BooksTableList from "./booksList";

const colourStyles = {
    menuList: styles => ({
        ...styles,
        background: '#fff',
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        background: isFocused
            ? '#1e293b80'
            : isSelected
                ? '#1e293bbf'
                : undefined,
        zIndex: 1,
    }),
    menu: base => ({
        ...base,
        zIndex: 100,
    }),
}


export default function TableList() {

    const [bookList, setBookList] = useState([]);
    const [bookStatus, setBookStatus] = useState([]);
    const [selectFilter, setSelectFilter] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [activePage, setActivePage] = useState(1)
    const [listingCount, setListingCount] = useState("")
    const [newArray] = useState([
        {
            value: "AutherName",
            label: "Auther Name",
        },
        {
            value: "bookName",
            label: "Book Name",
        }
    ])
    useEffect(() => {
        bookListing();
    }, [])
    const bookListing = () => {
        const PostData = {
            "offset": "0",
            "max": "10",
            "searchCmb": selectFilter, //bookName
            "searchInput": searchValue,
        };
        callingAPI('book/publishedBookList', PostData).then((res) => {

            setBookList(res.data.result.bookList)
            setListingCount(res.data.result.totalCount)

        })
    }
    const selectFilterFunction = (selectedOption) => {
        if (selectedOption) {
            setSelectFilter(selectedOption.value);
        } else {
            setSelectFilter(null);
        }
    };
    const searchFunction = (selectedOption) => {
        var flag = true
        if (selectFilter === "") {
            alert("Please select filter")
            flag = false
        }
        if (flag === true) {
            const PostData = {
                "offset": "0",
                "max": "10",
                "searchCmb": selectFilter, //bookName
                "searchInput": searchValue
            };
            callingAPI('book/publishedBookList', PostData).then((res) => {
                setBookList(res.data.result.bookList)
                setListingCount(res.data.result.totalCount)
            })
        }
    };
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = 10;
        } else {
            offset =
                parseInt(pageNumber - 1) * 10;
            max = 10;
        }
        setActivePage(pageNumber);
        const PostData = {
            "offset": offset,
            "max": max,
            "searchCmb": selectFilter, //bookName
            "searchInput": searchValue
        };
        callingAPI('book/publishedBookList', PostData).then((res) => {
            setBookList(res.data.result.bookList)
            setListingCount(res.data.result.totalCount)
        })
    };
    const exportFunction = async () => {
        const csvRow = [];
        var booksListInfo = [['Image', 'Title', 'AuthorName', 'Duration', 'Published%20On']];

        const PostData = {
            "offset": "0",
            "max": "",
            "searchCmb": selectFilter, //bookName
            "searchInput": searchValue
        };
        callingAPI('book/publishedBookList', PostData).then((res) => {
            if (res.data.success == "1") {
                for (var item = 0; item < res.data.result.bookList.length; item++) {
                    var dataSet = res.data.result.bookList
                    booksListInfo.push([
                        dataSet[item].image,
                        dataSet[item].title,
                        dataSet[item].authorName,
                        dataSet[item].duration,
                        moment(dataSet[item].created_on).format("DD-MM-YYYY"),

                    ]);
                }
            }
            for (var i = 0; i < booksListInfo.length; ++i) {
                csvRow.push(booksListInfo[i].join(","));
            }
            var csvString = csvRow.join("%0A");
            var creat = document.createElement("a");
            creat.href = "data:attachment/csv," + csvString;
            creat.target = "_Blank";
            creat.download = new Date() + ".csv";
            document.body.appendChild(creat);
            creat.click();
        })
    };

    const publishBooks = (info, status) => {

        var x = 3;
        if (status === 3) {
            x = window.confirm('Do you want to Approve this?');
        }
        else {
            x = window.confirm('Do you want to Unapprove this?');
        }

        if (x === true) {
            const PostData = {
                user_id: info.user_id,
                // status_id : info.status_id, // for approve - 3 / unaprrove - 4
                status_id: status == 4 ? 4 : 3,
                // createdOn: info.created_on,
                createdOn: new Date().getTime(),
                book_id: info.id,
                status_comment: info.status_comment
            };
            callingAPI('book/approveAndUnapproveBook', PostData).then((res) => {
                bookListing();
            })
        }

    }
    return (
        <>
            <div className="WrapLeft">
                <Header />
                <div className="dashboardContentInner">
                    <div className="mainHead">
                        <div className="row">
                            <div className="col-md-8">
                                <h2>Reports</h2>
                            </div>
                            <div className="col-md-4 dashClmRight">
                                <div className="dashAction">
                                    <a href="/reports/form" className="btnNavy"  ><FaPlus /> Add New</a>
                                    <button className="btnBlue" onClick={exportFunction}><FaFileDownload />  Export</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="tabContentsWrap">
                    <div className="issueSummWrap heightAuto">

                        <Tabs>
                            <TabList>
                                <Tab> <h2>BOOKS</h2></Tab>
                                <Tab><h2>Table 2</h2></Tab>
                                <Tab><h2>Table 3</h2></Tab>
                            </TabList>

                            <TabPanel>
                                <div className="fiterSection">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={10}
                                        totalItemsCount={listingCount}
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange}
                                    />
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    {/* <div className="col-md-7">
                                            <div className="row justfyEnd"> */}
                                                    <Select
                                                        // className="form-control "
                                                        id="selectFilter"
                                                        placeholder="Filter By"
                                                        options={newArray.map(item => ({
                                                            value: item.value,
                                                            label: item.label,
                                                        }))}
                                                        onChange={selectFilterFunction}
                                                        styles={colourStyles}
                                                    />
                                                    {/* </div>
                                                    </div> */}
                                                </div>
                                                <div className="col-md-7">
                                                    <div class="search-local">

                                                        <div class="icon">
                                                            <FaSearch />
                                                        </div>

                                                        <input type="text" placeholder="Search a product..." onChange={(e) => { setSearchValue(e.target.value) }} />
                                                        <button onClick={() => { searchFunction() }}>
                                                            <a>Search</a>
                                                            <ion-icon name="search-outline" class="search-icon"></ion-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-7">
                                            <div className="row justfyEnd">
                                               
                                                <div className="col-md-3">
                                                    <select className="form-control classic">
                                                        <option value="">Sort By</option>
                                                        <option value="">Sort By</option>
                                                        <option value="">Sort By</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-1">
                                                    <div className="form-control">
                                                        <FaFileDownload />
                                                    </div>

                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>

                                {/*  */}

                                <BooksTableList />
                                <div className="summaryTabs budgetTable">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Title </th>
                                                <th>AuthorName</th>
                                                <th>Duration</th>

                                                <th>Published on</th>
                                                <th style={{ textAlign: "right" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookList.map((data) =>
                                                <tr>
                                                    {/* <td data-column="Name"><span className="charName">JV</span>Jeny Varghese</td> */}
                                                    <td data-column="Image"><img src={data.image} className="categoryImg" /></td>
                                                    <td data-column="Title">{data.title}</td>
                                                    <td data-column="AuthorName">{data.authorName}</td>
                                                    <td data-column="Duration">{data.duration}</td>
                                                    {/* <td  data-column="Status">{data.is_active}</td> */}

                                                    <td data-column="created on">{moment(data.created_on).format('DD-MM-YYYY')}</td>
                                                    <td data-column="Action " style={{ textAlign: "right" }}>

                                                        <div className="tableAction">
                                                            {data.status_id === 3 ? <span><FaCheckCircle title="Click to Unapprove Book" color={"green"} className="cursorPointer"
                                                                onClick={() => publishBooks(data, 4)}
                                                            /> </span>
                                                                :
                                                                <span><FaRegTimesCircle title="Click to Approve Book" color={"#db0000"} className="cursorPointer"

                                                                    onClick={() => publishBooks(data, 3)}
                                                                /> </span>
                                                            }


                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {/*  */}
                            </TabPanel>
                            <TabPanel>
                                <div className="fiterSection">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div class="search-local">
                                                        <div class="icon">
                                                            <FaSearch />
                                                        </div>

                                                        <input type="text" placeholder="Search a product..." />
                                                        <button>
                                                            <a href="#">Search</a>
                                                            <ion-icon name="search-outline" class="search-icon"></ion-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="row justfyEnd">
                                                <div className="col-md-3">
                                                    <select className="form-control classic">
                                                        <option value="">Filter</option>
                                                        <option value="">Filter</option>
                                                        <option value="">Filter</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-3">
                                                    <select className="form-control classic">
                                                        <option value="">Sort By</option>
                                                        <option value="">Sort By</option>
                                                        <option value="">Sort By</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-1">
                                                    <div className="form-control">
                                                        <FaFileDownload />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="summaryTabs budgetTable">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Reference Number </th>
                                                <th>Reference Details </th>
                                                <th>Customer Since</th>
                                                <th>No.of Active Portfolio</th>
                                                <th style={{ textAlign: "right" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-column="Name">John Doe</td>
                                                <td data-column="Reference Number">XXXXXXX123</td>
                                                <td data-column="Reference Details">testData</td>
                                                <td data-column="Customer Since">21/08/2023</td>
                                                <td data-column="No.of Active Portfolio">10</td>
                                                <td data-column="Action" style={{ textAlign: "right" }} >
                                                    <div className=" dashClmRight">
                                                        <div className="dashAction">
                                                            <button className="btnNavy"><FaEdit /> </button>
                                                            <button className="btnRed"><FaTrashAlt />  </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-column="Name">John Doe</td>
                                                <td data-column="Reference Number">XXXXXXX123</td>
                                                <td data-column="Reference Details">testData</td>
                                                <td data-column="Customer Since">21/08/2023</td>
                                                <td data-column="No.of Active Portfolio">10</td>
                                                <td data-column="Action" style={{ textAlign: "right" }} >
                                                    <div className=" dashClmRight">
                                                        <div className="dashAction">
                                                            <button className="btnNavy"><FaEdit /> </button>
                                                            <button className="btnRed"><FaTrashAlt />  </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-column="Name">John Doe</td>
                                                <td data-column="Reference Number">XXXXXXX123</td>
                                                <td data-column="Reference Details">testData</td>
                                                <td data-column="Customer Since">21/08/2023</td>
                                                <td data-column="No.of Active Portfolio">10</td>
                                                <td data-column="Action" style={{ textAlign: "right" }} >
                                                    <div className=" dashClmRight">
                                                        <div className="dashAction">
                                                            <button className="btnNavy"><FaEdit /> </button>
                                                            <button className="btnRed"><FaTrashAlt />  </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row">
                                    <div className="col-md-2 dcenter">
                                        <div className="iconsTxt">
                                            <FaSearch />
                                        </div>
                                    </div>
                                    <div className="col-md-4  iconContBg ">
                                        <div className="iconCont">
                                            <h4>30 Day Plan</h4>
                                            <p>Lorem ipsum may be used as a placeholder before final copy is available</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5 paddingleft"  >
                                        <div class="item item1"  ></div>
                                        <div className="summaryTabs1 budgetTable">
                                            <table className="tableList" >
                                                <thead>
                                                    <tr>
                                                        <th> </th>
                                                        <th>Due Date </th>
                                                        <th>Amount</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-column="Name">Sample Text</td>
                                                        <td data-column="Due Date">12/12/2023</td>
                                                        <td data-column="Amount">$10</td>

                                                    </tr>
                                                    <tr>
                                                        <td data-column="Name">Sample Text</td>
                                                        <td data-column="Due Date">12/12/2023</td>
                                                        <td data-column="Amount">$10</td>

                                                    </tr>
                                                    <tr>
                                                        <td data-column="Name">Sample Text</td>
                                                        <td data-column="Due Date">12/12/2023</td>
                                                        <td data-column="Amount">$10</td>

                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row greyBg">
                                    <div className="col-md-2 dcenter">
                                        <div className="iconsTxt">
                                            <FaSearch />
                                        </div>
                                    </div>
                                    <div className="col-md-4  iconContBg ">
                                        <div className="iconCont">
                                            <h4>30 Day Plan</h4>
                                            <p>Lorem ipsum may be used as a placeholder before final copy is available</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5 paddingleft"  >
                                        <div class="item item1 greyClr"  ></div>
                                        <div className="summaryTabs1 budgetTable">
                                            <table className="tableList" >
                                                <thead>
                                                    <tr>
                                                        <th> </th>
                                                        <th>Due Date </th>
                                                        <th>Amount</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-column="Name">Sample Text</td>
                                                        <td data-column="Due Date">12/12/2023</td>
                                                        <td data-column="Amount">$10</td>

                                                    </tr>
                                                    <tr>
                                                        <td data-column="Name">Sample Text</td>
                                                        <td data-column="Due Date">12/12/2023</td>
                                                        <td data-column="Amount">$10</td>

                                                    </tr>
                                                    <tr>
                                                        <td data-column="Name">Sample Text</td>
                                                        <td data-column="Due Date">12/12/2023</td>
                                                        <td data-column="Amount">$10</td>

                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row brownBg">
                                    <div className="col-md-2 dcenter">
                                        <div className="iconsTxt">
                                            <FaSearch />
                                        </div>
                                    </div>
                                    <div className="col-md-4  iconContBg ">
                                        <div className="iconCont">
                                            <h4>30 Day Plan</h4>
                                            <p>Lorem ipsum may be used as a placeholder before final copy is available</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5 paddingleft"  >
                                        <div class="item item1 "  ></div>
                                        <div className="summaryTabs1 budgetTable">
                                            <table className="tableList" >
                                                <thead>
                                                    <tr>
                                                        <th> </th>
                                                        <th>Due Date </th>
                                                        <th>Amount</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-column="Name">Sample Text</td>
                                                        <td data-column="Due Date">12/12/2023</td>
                                                        <td data-column="Amount">$10</td>

                                                    </tr>
                                                    <tr>
                                                        <td data-column="Name">Sample Text</td>
                                                        <td data-column="Due Date">12/12/2023</td>
                                                        <td data-column="Amount">$10</td>

                                                    </tr>
                                                    <tr>
                                                        <td data-column="Name">Sample Text</td>
                                                        <td data-column="Due Date">12/12/2023</td>
                                                        <td data-column="Amount">$10</td>

                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>

                    </div>
                </div>
            </div>
            <Chats />
        </>
    )
}
