import React, { useState, useEffect, Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import bookImg from "../../src/images/category-filter-img-01.jpg"
import bookImg2 from "../../src/images/category-filter-img-02.jpg"
import bookImg3 from "../../src/images/category-filter-img-03.jpg"
import bookImg4 from "../../src/images/category-filter-img-04.jpg"
import { FaArrowRight, FaLayerGroup, FaStar, FaRegTimesCircle } from "react-icons/fa";
// import { FaEdit, FaSearch, FaPlus, FaTrashAlt, FaCheckCircle, FaRegTimesCircle, FaSave } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";

function Navarasa(props) {
    const navigate = useNavigate();
    const [list, setList] = useState([])
    const [redirectData, setRedirectData] = useState([])
    const [popupView, setPopupView] = useState(false);

    useEffect(() => {
        setList(props.navarasaData)
    }, [])
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000
    };
    const termsHandle = (data) => {
        setPopupView(true);
        setRedirectData(data)
    }
    const closePopup = () => {
        setPopupView(false);

    }
    const handleRedirect = () => {
        navigate('/book-detail', { state: { userID: redirectData.bookId } });
        window.scrollTo({top:0,behavior:'smooth'});
    }
    const goToComponentB = (userID) => {
        navigate('/books', { state: { categoryId: userID } });
    };
    return (
        <div className="section-navarasa">
            <div className="section-topbBook">
                <div className="container">
                    <div className="headdiv-sec">
                        <h3 className="mainHeadText mainHeadText-white">Navarasa</h3>
                        <span className="underline center"></span>
                    </div>

                    <div className="text-center">
                        {list.slice(0, 5).map((data) =>
                            <div className="booknavarsa-card" onClick={() => termsHandle(data)}>
                                <div className="card shine-overlay position-relative">
                                    <div className="card-img image-ratio">
                                        <div className="ratio-inner">
                                            <img src={data.bookImageWithPath} alt="" className='img-fluid' />
                                        </div>
                                        <div class="shine"></div>
                                    </div>
                                    {data.avgRating > 0 &&
                                        <div className="rating-div"><FaStar /><span className='rating-div-text' title="Rating">{parseFloat(data.avgRating.toFixed(1))}</span></div>}
                                    {data.episodeCount > 0 &&
                                        <div className="episode-partText" title="Episodes">
                                            <FaLayerGroup /> {data.episodeCount}
                                        </div>}

                                </div>
                                <div className="card-content content ">
                                    <div className="card-content content">
                                        <h3 className="title">{data.title}</h3>
                                    </div>
                                </div>
                            </div>
                        )}


                        {list.length > 5 &&

                            <div className="booknavarsa-card">
                                <div className="card shine-overlay card-more">
                                    <div className="read-more" onClick={() => goToComponentB("navarsa")}>Read More <FaArrowRight /></div>
                                </div>
                            </div>
                        }

                    </div>

                    <div id="demo-modal" className="modal navarasa-temrscondtns" style={{ display: popupView === true ? "flex" : "none" }} >

                        <div className="modal__content">
                            <h3 id="popHeading">Terms and Conditions </h3>
                            <div className="modalFields">
                                <div className="form-group">
                                    കൃതി അംഗങ്ങൾക്കായുള്ള നിർദ്ദേശങ്ങൾ.
                                    കൃതിയിൽ പ്രസിദ്ധീകരിക്കപ്പെടുന്ന ചെറുകഥകൾ, തുടർക്കഥകൾ, കവിതകൾ, ലേഖനങ്ങൾ, നിരൂപണം, തുടങ്ങിയ സാഹിത്യ സൃഷ്ടികളോടും, ആ സൃഷ്ടികളുടെ രചയിതാക്കളോടും പ്രതികരിക്കുന്ന വായനക്കാർ, മറ്റു എഴുത്തുകാർ എന്നിവർ സഭ്യമായ ഭാഷയിൽ അഭിപ്രായ പ്രകടനങ്ങൾ പ്രകടിപ്പിക്കുക.{'\n\n'}
                                    അശ്ലീല ചുവയുള്ളതും അസഭ്യവും, അപകീർത്തികരമായി പരസ്പ്പരം സ്പർധ വളർത്തുന്നതുമായ പരാമർശങ്ങൾ ഉൾകൊള്ളുന്ന അഭിപ്രായങ്ങൾ,വ്യക്തിഹത്യക്ക് വഴി തെളിക്കുന്ന വിമർശനങ്ങൾ, നിരൂപണം എന്ന പേരിൽ പ്രസിദ്ധീകരിക്കുന്ന ആക്ഷേപ സാഹിത്യ സൃഷ്ടികൾ, നിയമവിരുദ്ധമായ ഉള്ളടക്കങ്ങൾ നിറഞ്ഞ രചനകൾ മുതലായവ പൂർണ്ണമായും ഒഴിവാക്കാൻ എല്ലാവരും ശ്രദ്ധിക്കുക.
                                    ആവിഷ്കാര സ്വാതന്ത്ര്യം എല്ലാവർക്കും സമമാണ് .പൂർണ്ണമായും തങ്ങളുടെ സ്വതസിദ്ധമായ ആശയങ്ങൾക്ക് അനുയോജ്യമായ രീതിയിലുള്ള കഥാ സന്ദർഭങ്ങൾ കോർത്തിണക്കി ഓരോ രചനയെയും മികവുറ്റതാക്കാൻ ഓരോ എഴുത്തുകാരനും /എഴുത്തുകാരിയും ആവിഷ്കാര സ്വാതന്ത്ര്യം അർഹിക്കുന്നവരാണ്.അതേ പോലെ വിമർശനവും ആവിഷ്കാര സ്വാതന്ത്ര്യം അർഹിക്കുന്നതാണ്. എന്നാൽ ഈ ആവിഷ്കാര സ്വാതന്ത്ര്യത്തിന്റെ മറപിടിച്ചു നടത്തുന്ന വ്യക്തിപരമായ അധിക്ഷേപങ്ങൾ,കൃതിയിൽ അനുവദനീയമല്ല . ഇത്തരം അഭിപ്രായപ്രകടനങ്ങൾ, സൈബർ നിയമപ്രകാരം ശിക്ഷാർഹമാണ്. വായനക്കാരുടെ വിമർശനങ്ങളും അഭിപ്രായങ്ങളും രേഖപ്പെടുത്താനുള്ള മാർഗ്ഗങ്ങൾ ഞങ്ങൾ ഈ മൊബൈൽ ആപ്പിൽ ഉൾക്കൊള്ളിച്ചിട്ടുള്ളതാണ് ദയവായി ആ മാർഗ്ഗങ്ങൾ ഉപയോഗിച്ച് നിങ്ങളുടെ അഭിപ്രായപ്രകടനങ്ങൾ സഭ്യമായ രീതിയിൽ നടത്തുക.
                                    കൃതിയിൽ പ്രസിദ്ധീകരിക്കുന്ന ഉള്ളടക്കങ്ങളുടെ നേർക്കുള്ള വായനക്കാരുടെ അഭിപ്രായങ്ങൾ നിങ്ങളുടേത് മാത്രമായിരിക്കും , അവ ഒരിക്കലും കൃതി ടീമിന്റേത് അല്ല. ദയവായി സഭ്യമായ രീതിയിൽ മലയാളത്തിലോ ഇംഗ്ലീഷിലോ അഭിപ്രായങ്ങൾ എഴുതുക.
                                    അശ്ലീലം, മതം, ജാതി, വ്യക്തിഹത്യ, മാനുഷിക മൂല്യങ്ങളെ ചോദ്യം ചെയ്യുന്ന തരത്തിൽ ഉള്ളതും കക്ഷി രാഷ്ട്രീയം നിറഞ്ഞതുമായ ഉള്ളടക്കങ്ങൾ ഒന്നും തന്നെ കൃതിയിൽ പങ്കിടാൻ ഞങ്ങൾ അനുവദിക്കില്ല.മറ്റു സോഷ്യൽ മീഡിയ പേജുകൾ, വെബ്പേജുകൾ, ഇതര മൊബൈൽ ആപ്പുകൾ മുതലായവയുടെ ലിങ്കുകൾ ഉള്ള പോസ്റ്റുകൾ, കൃതിയിൽ കർശനമായി നിയന്ത്രിക്കപ്പെടുന്നതാണ്.മറ്റു വെബ്പേജുകളിൽ നിന്നും,ഇതര മീഡിയകളിൽനിന്നുമുള്ള കടപ്പാട് പോസ്റ്റുകളും, ലിങ്കുകളും കൃതിയിൽ അനുവദിക്കുന്നതല്ല.
                                    കൃതിയിൽ പ്രസിദ്ധീകരിക്കുന്ന രചനകളിലും പ്രതികരണങ്ങളിലും മതം, രാഷ്ട്രീയം,അവഹേളനം,അശ്ലീലം, ലൈംഗിക അതിപ്രസരം, വ്യക്തിഹത്യ, ആക്ഷേപ ഹാസ്യത്തിന്റെ അതിപ്രസരം കലർന്ന വിമർശനങ്ങൾ ( നിരൂപണകൃതികൾ) പോലുള്ള ഉള്ളടക്കങ്ങൾ, പ്രതികരണങ്ങൾ ഉണ്ടെങ്കിൽ ഞങ്ങളുടെ ടീം ആ രചന, പ്രതികരണം രചയിതാവിന്റെ അനുമതി കൂടാതെ പിൻവലിക്കുന്നതാണ്.{'\n\n'}
                                    കൃതിയിൽ സാഹിത്യ സൃഷ്ടികൾ പ്രസിദ്ധീകരിക്കുന്ന എഴുത്തുകാരേയും അവരുടെ രചനകളെയോ, ഈ കുടുംബത്തിലെ മറ്റു അംഗങ്ങളെയോ,ഞങ്ങളുടെ കമ്പിനിയുടെ ലക്ഷ്യങ്ങളെയോ അവഹേളിക്കുന്നതും,അപമാനിക്കുന്നതുമായ ആശയങ്ങൾ ഉൾകൊള്ളുന്ന പോസ്റ്റുകളോ പ്രതികരണങ്ങളോ, രചനകളോ കൃതിയിൽ പ്രസിദ്ധീകരിക്കാൻ ഞങ്ങൾ അനുവദിക്കില്ല.
                                    കൃതിയിൽ സാഹിത്യസൃഷ്ടികൾ പ്രസിദ്ധീകരിക്കുന്ന രചയിതാക്കൾ ശ്രദ്ധിക്കുക. നിങ്ങളുടെ സ്വന്തം ആശയങ്ങളിൽ നിന്നും ഉരുത്തിരിയുന്ന ഭാവനകൾക്ക് നിറം പകരുന്ന രചനകൾ മാത്രം കൃതിയിൽ പ്രസിദ്ധീകരിക്കാൻ ശ്രമിക്കുക. ആശയ മോഷണം, എഴുത്തുകാരുടെ അനുവാദം ഇല്ലാതെ അവരുടെ സാഹിത്യസൃഷ്ടികൾ , പകർത്തിയെഴുത്ത്,-ഇവ ഞങ്ങൾ പ്രോത്സാഹിപ്പിക്കുന്നില്ല.{'\n\n'}
                                    കൃതിയിൽ നിങ്ങൾ ഓരോരുത്തരും പ്രസിദ്ധീകരിക്കുന്ന സാഹിത്യസൃഷ്ടികൾ നിങ്ങളുടെ അനുവാദം കൂടാതെ മറ്റു ഇടങ്ങളിൽ ഏതെങ്കിലും വ്യക്തികൾ, പേജുകൾ,ആപ്പുകൾ പ്രസിദ്ധീകരിക്കുന്നു എങ്കിൽ ഞങ്ങളുടെ ടീം ആയിട്ട് ബന്ധപെടുക. അനുചിതമായ മേൽ നടപടികൾക്ക് ആയി ഞങ്ങളുടെ ടീം നിങ്ങളെ സഹായിക്കുന്നതായിരിക്കും.
                                    രചയിതാക്കളുടെയും വായനക്കാരുടെയും ശ്രദ്ധയ്ക്ക് നിങ്ങൾക്ക് കൃതി ൽ ഏതെങ്കിലും തരത്തിലുള്ള ബുദ്ധിമുട്ടുകൾ അനുഭവപ്പെട്ടാൽ ഞങ്ങളുടെ പ്രതിനിധിയുമായി ഉടനെ ബന്ധപെടുക. പരാതികൾക്ക് അതിവേഗം തീരുമാനം ലഭിക്കുന്നതാണ്.24 മണിക്കൂറിനുള്ളിൽ ഞങ്ങളുടെ പ്രതിനിധി നിങ്ങളുടെ പരാതികൾക്ക് മറുപടി തരുന്നത് ആയിരിക്കും.
                                    കൃതിയിൽ നിങ്ങൾ ഓരോരുത്തരും എഴുതി പ്രസിദ്ധീകരിക്കുന്ന സാഹിത്യ സൃഷ്ടികൾ, അഭിപ്രായങ്ങൾ മുതലായവയുടെ പൂർണ്ണ ഉത്തരവാദിത്യം നിങ്ങൾ ഓരോരുത്തരുടെയും മാത്രം ആയിരിക്കും. കേന്ദ്ര സർക്കാരിന്റെ ഐ ടി നയപ്രകാരം വ്യക്തി, സമുദായം, മതം, രാജ്യം, ആവിഷ്ക്കാര സ്വാതന്ത്ര്യം എന്നിവയ്ക്കെതിരായി ഉയരുന്ന രചനകളും , വ്യക്തിപരമായ അധിക്ഷേപങ്ങളും, അശ്ലീല പദപ്രയോഗങ്ങളും, അഭിപ്രായങ്ങളും ശിക്ഷാർഹമായ കുറ്റമാണ്.ഇത്തരം അഭിപ്രായ പ്രകടനങ്ങൾ , രചനകൾ മുതലായവയ്ക്ക് എതിരായി ഞങ്ങളുടെ ടീം നിയമനടപടികൾ കൈക്കൊള്ളുന്നതാണ്. വായനക്കാരുടെയും എഴുത്തുകാരുടെയും അഭിപ്രായങ്ങൾ അവരുടേത് മാത്രമാണ് അത് ഒരിക്കലും കൃതി ടീമി- ന്റേത് അല്ല.
                                    വരൂ... നമുക്കൊന്നിച്ച് നല്ല സൃഷ്ടികൾ രൂപപ്പെടുത്താം... പുതിയ വായന സംസ്കാരം തുടങ്ങി വയ്ക്കാം...
                                    സ്നേഹപൂർവ്വം
                                    Team കൃതി
                                </div>
                            </div>
                            <div className="modal__footer">
                                <div className="">
                                    <button className="acpt-btn" onClick={() => handleRedirect()} >Accept Terms and Conditions</button>
                                    {/* <button className="save-btn" onClick={() => savePopup()}>Save</button>
                                <button className="cancel-btn" onClick={() => closePopup()}>Cancel</button> */}
                                </div>
                            </div>
                            <a onClick={() => closePopup()} class="modal__close"> <FaRegTimesCircle /></a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}
export default Navarasa