import React, { useEffect } from 'react';
// import Header from "../components/layouts/header";
// import BuyerHeader from '../components/layouts/buyerHeader';
import Header from "../Home/layout/header";
import Footer from "../Home/layout/footer"
const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    return (
        <>
            <div className="WrapLeft loginWrap termsPolicyWrap">
                <Header />
                <div className="container   pb-5 termsHead">
                    <h3 className='text-center pb-3'><b>Privacy policy</b></h3>
                    <p>At (“Fantasico” “we” or “us”), we are committed to protecting your privacy and keeping your information safe. Krithy processes information about you in a few different ways. This Privacy Policy explains how we collect, use, disclose and keep information that can identify you (“Personal Information”) when you create a Krithy  account and use krithy.com (the “Site”), or our mobile application, (the “App” )to post content to the Krithy community, including stories you create, comments and messages (the “Services”).</p>
                    <p>Note that our Terms of Use apply to this Privacy Policy. By creating an account with Krithy , using the Services, or submitting any Personal Information via the Services, you are consenting to the collection, use and disclosure of your Personal Information as described below in this Privacy Policy. If you do not consent to the use of your Personal Information in these ways, please stop using the Services.</p>
                    <p>This is important; we hope you will take time to read it carefully ;</p>
                    <p>Personal Information</p>
                    <p>In order to provide you with a better service, the system will automatically store the information you fill out or submit when you register for Krithy account and when you choose the services provided by Krithy Stories , including your gender, date of birth, mail address and phone number. Submitting false information will result in potential problems for which you shall bear the corresponding consequences. Please do not give or lend your account or its password to other people.</p>
                    <p>What we collect:</p>
                    <p>Information that is necessary for the use of our Services, such as your username, email address, date of birth, and password.</p>
                    <p>If you register for Krithy Stories via third parties like Facebook and Google, they send us information that is necessary for your use of our Services, such as your name, email address, gender and birthdate.</p>
                    <p>We use this Personal Information to:</p>
                    <ul>
                        <li>Create your Krithy account and provide you with our Services</li>
                        <li>Identify you across the Services (e.g. when you sign in, post a story, or make a comment)</li>
                        <li>Make age appropriate content available to you.</li>
                        <li>Provide you with technical support services.</li>
                        <li>Communicate with you (e.g. letting you know about upcoming changes or improvements).</li>
                        <li>Send you marketing emails, unless you opt-out.</li>
                        <li>Display the information as part of your Krithy profile.</li>
                        <li>Calculate anonymous, aggregate statistics to better understand our users, improve our Services, and develop new features.</li>
                    </ul>
                    <p>What we collect:</p>
                    <p>Information you choose to give to us, such as your real name, gender, and location.</p>
                    <p>We use this Personal Information to:</p>
                    <ul>
                        <li>Display the information as part of your Krithy profile</li>
                        <li> Calculate anonymous, aggregate statistics to better understand our users, improve our Services, and develop new features.</li>
                    </ul>
                    <p>What we collect:</p>
                    <p>Information we automatically collect from your use of our Services, including:</p>
                    <p>Information we automatically collect from your use of our Services, including:</p>
                    <ul>
                        <li>IP address</li>
                        <li>Cookie data</li>
                        <li>Device information</li>
                        <li>Browser type</li>
                        <li>Operating system</li>
                        <li>Pages visited</li>
                        <li>Actions performed e.g. voting.</li>
                    </ul>
                    <p>We use this Personal Information to:</p>
                    <ul>
                        <li>Personalize and customize your story discovery experience.</li>
                        <li>Measure your use and overall satisfaction with our Services.</li>
                        <li>Calculate anonymous, aggregate statistics to better understand our users, improve our Services, and develop new features.</li>
                        <li>Investigate and prevent reported issues, illegal activity, and breaches of our terms of service.</li>
                        <li>Help prevent spam, fraud, abuse, and other prohibited activity.</li>
                        <li>For security purposes.</li>
                    </ul>
                    <p>We treat the personal information above as private by default, so it won’t be displayed on the Site (unless you choose to publicly display applicable information via your account settings), revealed to other Krithy users, or rented or sold to anyone unless the data has been anonymized</p>
                    <p>We will collect your payment information if you are a paying customer. This information is required to complete a commercial transaction on our App or our Site. We use this information to enable and fulfill your transaction. If you choose to use any third party’s service to finalize and pay for your order, you will provide your credit/debit card/ bank account information directly to the third party. The privacy policy of the third party will apply to the information you provide on the website.</p>
                    <p>Automatically Collected Information.</p>
                    <p>When you use the Service, we may send one or more cookies – small text files containing a string of alphanumeric characters – to your device. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the Service. We may also automatically record certain information from your device by using various types of technology. In this context, we may collect certain information about you, such as your IP address or other device address or ID, web browser and/or device type, the web pages or sites that you visit just before or just after you use the Service, the pages or other content you view or otherwise interact with on the Service, and the dates and times that you visit, access, or use the Service. We also may use these technologies to collect information regarding your interaction with email messages, such as whether you opened, clicked on, or forwarded a message. This information is gathered from all users, and may be connected with other information about you.</p>
                    <p>Please review your web browser “Help” file to learn the proper way to modify your cookie settings. Please note that if you delete, or choose not to accept, cookies from the Service, you may not be able to utilize the features of the Service to their fullest potential. We may use third party cookies on our Service as well. For instance, we use Google Analytics to collect and process certain analytics data. Google provides some additional privacy options described at www.google.com/policies/privacy/partners/ regarding Google Analytics cookies.</p>
                    <p>Third-Party Services</p>
                    <p>The Service may contain features or links to websites and services provided by third parties. Any information you provide on third-party sites or services is provided directly to the operators of such services and is subject to those operators’ policies, if any, governing privacy and security, even if accessed through the Service. We are not responsible for the content or privacy and security practices and policies of third-party sites or services to which links or access are provided through the Service. We encourage you to learn about third parties’ privacy and security policies before providing them with information.</p>
                    <p>How do we share your Personal Information?</p>
                    <p>We may share your Personal Information with third-party service providers, but only where it’s necessary to provide any of the Services to you (e.g., email providers, server providers, etc.). When we share data with third-party services that support our delivery of the Services, we require that they protect your Personal Information to the same standards we do.</p>
                    <p>We do not and will not release, rent or sell your Personal Information, except in the following very limited situations:</p>
                    <ul>
                        <li>To comply with an applicable law (including investigations of any crime), regulation, legal process or enforceable governmental request;</li>
                        <li>To enforce or apply our Terms of Use and other policies, including investigation of potential violations;</li>
                        <li>To protect the rights, property, or safety of Krithy, our employees, our users, or the public;</li>
                        <li>If Krithy involved in a merger, acquisition or asset sale, in which case we will continue to ensure the confidentiality of any Personal Information and give affected users notice before Personal Information is transferred or becomes subject to a different privacy policy.</li>
                        <li>We never post anything to your Facebook or other third-party accounts without your permission.</li>
                    </ul>
                    <p>Information Security</p>
                    <p>We to use a variety of security technologies and measures to prevent the leak, destruction or loss of your information. For example, in some services, we will use encryption technology to protect the personal information you provide to us. However, please understand that due to technical limitations and various malicious means that may exist, the information will not always be 100% secure even if we do everything we can to strengthen security measures. You need to understand that the systems and communication networks that you use to access our services may potentially be compromised due to factors beyond our control.</p>
                    <p>Children’s Privacy</p>
                    <p>Protecting the privacy of young children is especially important. Our Site is a general audience site not directed to children under the age of 18, and we do not knowingly collect Personal Information from children under the age of 18 without obtaining parental consent. If we learn that Personal Information has been collected on the Services from persons under 18 years of age and without verifiable parental consent, then we will take the appropriate steps to delete this Personal Information. If you are a parent or guardian and discover that your child under 18 years of age has obtained an account on the Services or that we may have collected information from that child through our Services, then you may alert us (mail id)and request that we delete that child’s Personal Information from our systems.</p>
                    <p>Data Deletion</p>
                    <p>No matter what operating system you use (e.g. IOS, Android, etc.), you can contact us at (contact mail id),and request that we delete your data. We will evaluate such requests on a case by case basic. We may keep some of your data for as long as reasonably necessary for our legitimate business interests, including fraud detection and prevention and to comply with our legal obligations including tax, legal reporting, and auditing obligations. Sometimes, your request will only be satisfied by closing your account.</p>
                    <p>You have the ability to delete your Account, and you should understand that upon deletion of your Account, you will lose the right to access or use all or part of the Platform.</p>
                    <p>Scope of This Privacy Policy</p>
                    <p>This Privacy Policy applies to all services provided by Krithy and its related companies. However, it shall not apply to those services not listed here which have Privacy Policies of their own.</p>
                    <p>Please note that this Privacy Policy does not apply to the following situations.</p>
                    <p>Information collected through our services by any third party (including any third party website);</p>
                    <p>Changes and Updates to this Policy</p>
                    <p>Please revisit this page periodically to stay aware of any changes to this Policy, which we may update from time to time. If we modify this Policy, we will make it available through the Service, and indicate the date of the latest revision. In the event that the modifications materially alter your rights or obligations hereunder, we will make reasonable efforts to notify you of the change. For example, we may send a message to your email address, if we have one on file, or generate a pop-up or similar notification when you access the Service for the first time after such material changes are made. Your continued use of the Service after the revised Policy has become effective indicates that you have read, understood and agreed to the current version of this Policy.</p>
                    <p>For any grievance related to this Privacy Policy, or any other grievance in relation to the collection, usage and processing of your personal information, you may contact Mrs/Mr Grievance Officer at</p>
                    <p>In case of any conflict arising in the interpretation of the Privacy Policy in English and any other language as it may be made available on the Website/Application, the terms of the English version shall prevail.</p>
                    <p>Thank you for being our reliable technology partner Xilligence.</p>
                </div>
                <Footer/>
            </div>
        </>
    );
};

export default PrivacyPolicy;