


import React, { useState, useEffect, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from "react-router-dom";
import noDataImg from "../images/empty.png"
import { callingAPI } from '../service';
import { FaArrowRight, FaClock, FaEye, FaLayerGroup, FaStar, FaTimesCircle, FaTrashAlt } from "react-icons/fa";
function LibraryListProfile(props) {
    const navigate = useNavigate();
    const [library, setLibrary] = useState([]);
    const [arrayLibLength, setArrayLibLength] = useState([]);
    const [listFlag, setListFlag] = useState()
    useEffect(() => {
        libraryList();
        // setLibrary(props.library)

    }, [])
    const libraryList = () => {
        // debugger
        const PostData = {
            // "userId": "4"
            "userId": window.localStorage.getItem("userId")
        };
        callingAPI('library/myLibraryDetails', PostData).then((response) => {
            console.log("LIBRARYLIST RESPONSE ===========> ", response)
            if (response.data.success === '1') {
                setLibrary(response.data.result.myLibrary);
                setArrayLibLength(response.data.result.myLibrary.length)
                console.log("LIBRARYLIST RESULTS ===========> ", response.data.result.myLibrary)
                if(response.data.result.myLibrary.length === 0){
                    setListFlag(true)
                }
                else{
                    setListFlag(false)
                }
            }
        })
    }


    const goToBookDetails = (userID) => {
        navigate('/book-detail', { state: { userID: userID } });
        window.scrollTo({ top: 0, behavior: 'smooth' });

    };
    const removeLibrary = (libId) => {
        debugger
        const PostData = {
            "library_id": libId
        };
        callingAPI('library/deleteLibrary', PostData).then((response) => {
            console.log("REMOVE LIBRARY RESPONSE ===========> ", response)
            if (response.data.success === '1') {
                libraryList()
                toast.success("Removed from Library.", { containerId: "C" })
            }
        })
    }
    const displayBookDuration = (decimalMinutes) => {
        const totalSeconds = Math.round(decimalMinutes * 60);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        let result = '';
        if (hours > 0) {
            result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
        }
        if (minutes > 0) {
            result += `${result.length > 0 ? ' and ' : ''}${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        }
        if (seconds > 0 && hours <= 0 && minutes <= 0) {
            result += `${result.length > 0 ? ' and ' : ''}${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
        }
        if (result.length === 0) {
            result += 'Less than a minute';
        }
        return result;
    }
    return (
        <>
            {arrayLibLength > 0 &&
                <div className="section-topbBook section-latestRelease libraryWrap lilistPro" style={{ background: "transparent", paddingBottom: "0px" }}>
                    <ToastContainer
                        enableMultiContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        containerId={"C"}
                        theme="colored" />
                    <div className="container">

                        <ul className='row'>
                            {library.map((data) =>
                                <div className="col-lg-3 col-md-4">
                                    <div className="position-relative li"   >
                                        <img src={data.bookImageWithPath} style={{ cursor: "pointer" }} alt="" className='img-fluid' onClick={() => goToBookDetails(data.bookId)} />

                                        <div className="libRemove libRemovePro" onClick={() => removeLibrary(data.library_id)}>
                                            <FaTrashAlt />
                                            <span className='rating-div-text'>
                                                {/* Remove */}
                                            </span>
                                        </div>

                                        {data.avgRating > 0 &&
                                            <div className="rating-div" title='Rating'>
                                                <FaStar /><span className='rating-div-text'>{parseFloat(data.avgRating.toFixed(1))}</span>
                                            </div>
                                        }

                                        {data.episodeCount > 0 &&
                                            <div className="episode-partText" title='Episodes'>
                                                <FaLayerGroup /> {data.episodeCount}
                                            </div>}
                                        <span className='bookNameReleased' title={data.title}>{data.title}</span>
                                        <p><FaClock style={{ fontSize: "15px" }} /> 
                                        &nbsp;{displayBookDuration(data.duration)}
                                        </p>
                                        <p><FaEye /> {data.no_of_views} </p>
                                    </div>
                                </div>
                            )}
                        </ul>
                    </div>
                </div>
                // :
                // <div className='noDataFound'>
                //     <p>No Data Found</p>
                // </div> 
               
            }
             {listFlag &&
                <div class="noDataFound color-text-follow noDataTxts">
                <span><img src={noDataImg} className='noDataImg' /></span>
                <p>  No Data Found</p>
            </div>
                }
        </>
    )

}
export default LibraryListProfile