import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyB3xXEzzWSYS35CroanEnBLP6IyhVmP4jk",
    authDomain: "awesomepen-74701.firebaseapp.com",
    // authDomain: "awesomepen-74701.firebaseapp.com",
    // databaseURL: "https://awesomepen-74701-default-rtdb.firebaseio.com",
    projectId: "awesomepen-74701",
    storageBucket: "awesomepen-74701.appspot.com",
    messagingSenderId: "1034874799416",
    appId: "1:1034874799416:web:5b1acdf898f6554a3a2dcd",
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;