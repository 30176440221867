import React, { useState, useEffect, Component } from 'react';
import Header from '../Home/layout/header';
import Footer from '../Home/layout/footer';
import Book1 from "../images/860295_1704701727805.jpg"
import Book2 from "../images/304301_1706363750816.jpg"
import { FaLayerGroup, FaStar, FaUser, FaClock, FaBookReader, FaCommentAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { callingAPI } from '../service';
import Write from "../images/write.png"
import { useNavigate } from 'react-router-dom';
import NoData from '../pages/nodatafound/noDataFound';
import noDataImg from "../images/empty.png"
function BookListHome(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [list, setList] = useState([])
    const [showAll, setShowAll] = useState(false);
    const visibleData = showAll ? list : list.slice(0, 20);
    const [searchValue, setSearchValue] = useState((location.state && location.state.categoryId) ? location.state.categoryId : [])
    const categoryId = (location.state && location.state.categoryId) ? location.state.categoryId : [];
    const categoryName = (location.state && location.state.categoryName) ? location.state.categoryName : [];
    const reloadPath = (location.state && location.state.reloadPath) ? location.state.reloadPath : "";
    const [listFlag, setListFlag] = useState()
    const [categoryValue, setCategoryValue] = useState(location.state && location.state.categoryValueData ? location.state.categoryValueData : categoryName)
    useEffect(() => {
        window.scrollTo(0,0);
        handlebooksList();

    }, [])
    // useEffect(() => {
    //     debugger
    //   if(reloadPath === "yes")
    //     handlebooksList();
       
    // }, [reloadPath,categoryName])

    const handlebooksList = () => {
        debugger
        const postData = {
            "searchText": categoryValue,
            "offset": "0",
            "max": "21"
        }
        callingAPI('book/bookSearch', postData).then((response) => {
            if (response.data.success === '1') {
                console.log("..............................,,", response);
                setList(response.data.result[0].contentDetails)
                if (response.data.result[0].contentDetails.length === 0) {
                    setListFlag(true)
                }
                else {
                    setListFlag(false)
                }
            }
            else {
                setList([])
                setListFlag(true)
            }

        })
        handlebooksListSearch()
    }
    const handlebooksListSearch = () => {
        if(categoryValue === ""){
           document.getElementById("categoryValue").focus();
            return false
        }
        else{
        const postData = {
            "searchText": categoryValue,
            "offset": "0",
            "max": "21"
        }
        callingAPI('book/bookSearch', postData).then((response) => {
            if (response.data.success === '1') {
                console.log("..............................,,", response);
                setList(response.data.result[0].contentDetails)
                if (response.data.result[0].contentDetails.length === 0) {
                    setListFlag(true)
                }
                else {
                    setListFlag(false)
                }
            }
            else {
                setList([])
                setListFlag(true)
            }

        })
    }
    }
    const handleClear = () => {
        setSearchValue("");
        // handlebooksList()
        setList([])
        setCategoryValue("");
        setListFlag(true)
    }
    const handleViewMore = () => {
        setShowAll(true);
    };
    const goToBookDetails = (userID) => {
        debugger
        navigate('/book-detail', { state: { userID: userID, pageName: "booksList", categoryValue: categoryValue } });
    };

    return (
        <div className='section-booklist position-relative'>
            <Header />
            <div className="books-list-wrapper">



                <div className="container">
                    <div className="booklist-searchSec">
                        <div className="row searchbar-section">
                            {/* <div className="col-md-2">
                                <select name="" id="" className='form-control'>
                                    <option value="">Language</option>
                                </select>
                            </div> */}
                            <div className="col-md-5">
                                <input type="text" className='form-control' id='categoryValue' placeholder='Search by Book/Author/Category' value={categoryValue} onChange={(e) => setCategoryValue(e.target.value)} />
                            </div>
                            {/* <div className="col-md-3">
                                <select name="" id="" className='form-control'>
                                    <option value="">Category</option>
                                    <option value="">Category1</option>
                                </select>
                            </div> */}
                            <div className="col-md-2 d-flex header-topsearchbtns">
                                <button className='btn-search' onClick={() => handlebooksListSearch()}>Search</button>
                                <button className='btn-clear' onClick={() => handleClear()}>Clear</button>
                            </div>
                        </div>
                    </div>

                    <div className="booklist-mainhead">
                        {/* <h3>{categoryName}</h3> */}
                    </div>
                    {/* <div className="row"> */}
                    {/* {list && list.map((data) =>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 zoom-effect-container">
                                <Link to="/book-detail">
                                    <div className="booklist-sec shine-overlay">
                                        <a href=""><img src={data.bookImage} alt="" /></a>
                                        <div className="image-overlay"></div>
                                        <div class="shine"></div>
                                        <div className="rating-div"><FaStar /><span className='rating-div-text'>4.0</span></div>
                                        <div className="episode-partText">
                                            <FaLayerGroup /> 2 Parts
                                        </div>
                                    </div>
                                    <div className="booklist-content">
                                        <h3>{data.title}</h3>
                                        <div className="authortext"><FaUser /><span>{data.autherName}</span></div>
                                        <div className="d-flex justify-content-between">
                                            <div className="authortext" title='Duration'><FaClock /><span>0.00</span></div>
                                            <div className="authortext" title='Views'><FaBookReader /><span>10 Views</span></div>
                                            <div className="authortext" title='Reviews'><FaCommentAlt /><span>20 Reviews</span></div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )} */}


                    {/* {list.length > 0 ? */}
                    <>
                        <ul className='text-center books-list-wrapper-ul'>
                            {visibleData && visibleData.map((data) =>
                                <>
                                    <li onClick={() => goToBookDetails(data.bookId)} className='list-top list-books-list' style={{ cursor: 'pointer' }}>
                                        <div className="position-relative shine-overlay">
                                            <img src={data.bookImage} alt="" className='img-fluid' />
                                            <div class="shine"></div>
                                        </div>
                                        <div className="booklist-content-top" title={data.title}>
                                            <h3>{data.title}</h3>
                                            <div className="authortext"><FaUser /><span>{data.autherName}</span></div>
                                        </div>
                                    </li>
                                </>
                            )}
                        </ul>


                        {list.length > 21 && !showAll && (
                            <div className="btn-viewmore-outer">
                                <div className='btn-boolist-viewmore' onClick={handleViewMore}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    View More
                                </div>
                            </div>
                        )}
                    </>
                    {/* : */}
                    {listFlag &&
                        <div className="books-list-wrapper">
                            <div className="container">
                                <div class="color-text-follow noDataTxts">
                                    <span><img src={noDataImg} className='noDataImg' /></span>
                                    <p>  No Data Found</p>
                                </div>
                            </div>
                        </div>
                    }
                    {/* } */}

                    {/* </div> */}

                </div>


            </div>




            <Footer />

        </div>
    )

}
export default BookListHome