import React, { useEffect } from 'react';
// import BuyerHeader from '../components/layouts/buyerHeader';
// import Header from "../components/layouts/header";
import Header from "../Home/layout/header";
import Footer from "../Home/layout/footer"
const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0,0);
}, [])
  return (
    <>
      <div className="WrapLeft loginWrap termsPolicyWrap">
        <Header />
        <div className="container   pb-5 termsHead">
          <h3 className="text-center pb-3">Terms and condition</h3>
          <div className=""> കൃതി അംഗങ്ങൾക്കായുള്ള നിർദ്ദേശങ്ങൾ.</div>
          <ul>
            <li> കൃതിയിൽ പ്രസിദ്ധീകരിക്കപ്പെടുന്ന ചെറുകഥകൾ, തുടർക്കഥകൾ, കവിതകൾ, ലേഖനങ്ങൾ, നിരൂപണം, തുടങ്ങിയ സാഹിത്യ സൃഷ്ടികളോടും, ആ സൃഷ്ടികളുടെ രചയിതാക്കളോടും പ്രതികരിക്കുന്ന വായനക്കാർ, മറ്റു എഴുത്തുകാർ എന്നിവർ സഭ്യമായ ഭാഷയിൽ അഭിപ്രായ പ്രകടനങ്ങൾ പ്രകടിപ്പിക്കുക.</li>
            <li>അശ്ലീല ചുവയുള്ളതും അസഭ്യവും, അപകീർത്തികരമായി പരസ്പ്പരം സ്പർധ വളർത്തുന്നതുമായ പരാമർശങ്ങൾ ഉൾകൊള്ളുന്ന അഭിപ്രായങ്ങൾ,വ്യക്തിഹത്യക്ക് വഴി തെളിക്കുന്ന വിമർശനങ്ങൾ, നിരൂപണം എന്ന പേരിൽ പ്രസിദ്ധീകരിക്കുന്ന ആക്ഷേപ സാഹിത്യ സൃഷ്ടികൾ, നിയമവിരുദ്ധമായ ഉള്ളടക്കങ്ങൾ നിറഞ്ഞ രചനകൾ മുതലായവ പൂർണ്ണമായും ഒഴിവാക്കാൻ എല്ലാവരും ശ്രദ്ധിക്കുക.</li>
            <li>ആവിഷ്കാര സ്വാതന്ത്ര്യം എല്ലാവർക്കും സമമാണ് .പൂർണ്ണമായും തങ്ങളുടെ സ്വതസിദ്ധമായ ആശയങ്ങൾക്ക് അനുയോജ്യമായ രീതിയിലുള്ള കഥാ സന്ദർഭങ്ങൾ കോർത്തിണക്കി ഓരോ രചനയെയും മികവുറ്റതാക്കാൻ ഓരോ എഴുത്തുകാരനും /എഴുത്തുകാരിയും ആവിഷ്കാര സ്വാതന്ത്ര്യം അർഹിക്കുന്നവരാണ്.അതേ പോലെ വിമർശനവും ആവിഷ്കാര സ്വാതന്ത്ര്യം അർഹിക്കുന്നതാണ്. എന്നാൽ ഈ ആവിഷ്കാര സ്വാതന്ത്ര്യത്തിന്റെ മറപിടിച്ചു നടത്തുന്ന വ്യക്തിപരമായ അധിക്ഷേപങ്ങൾ,കൃതിയിൽ അനുവദനീയമല്ല . ഇത്തരം അഭിപ്രായപ്രകടനങ്ങൾ, സൈബർ നിയമപ്രകാരം ശിക്ഷാർഹമാണ്. വായനക്കാരുടെ വിമർശനങ്ങളും അഭിപ്രായങ്ങളും രേഖപ്പെടുത്താനുള്ള മാർഗ്ഗങ്ങൾ ഞങ്ങൾ ഈ മൊബൈൽ ആപ്പിൽ ഉൾക്കൊള്ളിച്ചിട്ടുള്ളതാണ് ദയവായി ആ മാർഗ്ഗങ്ങൾ ഉപയോഗിച്ച് നിങ്ങളുടെ അഭിപ്രായപ്രകടനങ്ങൾ സഭ്യമായ രീതിയിൽ നടത്തുക.</li>
            <li>കൃതിയിൽ പ്രസിദ്ധീകരിക്കുന്ന ഉള്ളടക്കങ്ങളുടെ നേർക്കുള്ള വായനക്കാരുടെ അഭിപ്രായങ്ങൾ നിങ്ങളുടേത് മാത്രമായിരിക്കും , അവ ഒരിക്കലും കൃതി ടീമിന്റേത് അല്ല. ദയവായി സഭ്യമായ രീതിയിൽ മലയാളത്തിലോ ഇംഗ്ലീഷിലോ അഭിപ്രായങ്ങൾ എഴുതുക.</li>
            <li>അശ്ലീലം, മതം, ജാതി, വ്യക്തിഹത്യ, മാനുഷിക മൂല്യങ്ങളെ ചോദ്യം ചെയ്യുന്ന തരത്തിൽ ഉള്ളതും കക്ഷി രാഷ്ട്രീയം നിറഞ്ഞതുമായ ഉള്ളടക്കങ്ങൾ ഒന്നും തന്നെ കൃതിയിൽ പങ്കിടാൻ ഞങ്ങൾ അനുവദിക്കില്ല.മറ്റു സോഷ്യൽ മീഡിയ പേജുകൾ, വെബ്പേജുകൾ, ഇതര മൊബൈൽ ആപ്പുകൾ മുതലായവയുടെ ലിങ്കുകൾ ഉള്ള പോസ്റ്റുകൾ, കൃതിയിൽ കർശനമായി നിയന്ത്രിക്കപ്പെടുന്നതാണ്.മറ്റു വെബ്പേജുകളിൽ നിന്നും,ഇതര മീഡിയകളിൽനിന്നുമുള്ള കടപ്പാട് പോസ്റ്റുകളും, ലിങ്കുകളും കൃതിയിൽ അനുവദിക്കുന്നതല്ല.</li>
            <li>കൃതിയിൽ പ്രസിദ്ധീകരിക്കുന്ന രചനകളിലും പ്രതികരണങ്ങളിലും മതം, രാഷ്ട്രീയം,അവഹേളനം,അശ്ലീലം, ലൈംഗിക അതിപ്രസരം, വ്യക്തിഹത്യ, ആക്ഷേപ ഹാസ്യത്തിന്റെ അതിപ്രസരം കലർന്ന വിമർശനങ്ങൾ ( നിരൂപണകൃതികൾ) പോലുള്ള ഉള്ളടക്കങ്ങൾ, പ്രതികരണങ്ങൾ ഉണ്ടെങ്കിൽ ഞങ്ങളുടെ ടീം ആ രചന, പ്രതികരണം രചയിതാവിന്റെ അനുമതി കൂടാതെ പിൻവലിക്കുന്നതാണ്.</li>
            <li>കൃതിയിൽ സാഹിത്യ സൃഷ്ടികൾ പ്രസിദ്ധീകരിക്കുന്ന എഴുത്തുകാരേയും അവരുടെ രചനകളെയോ, ഈ കുടുംബത്തിലെ മറ്റു അംഗങ്ങളെയോ,ഞങ്ങളുടെ കമ്പിനിയുടെ ലക്ഷ്യങ്ങളെയോ അവഹേളിക്കുന്നതും,അപമാനിക്കുന്നതുമായ ആശയങ്ങൾ ഉൾകൊള്ളുന്ന പോസ്റ്റുകളോ പ്രതികരണങ്ങളോ, രചനകളോ കൃതിയിൽ പ്രസിദ്ധീകരിക്കാൻ ഞങ്ങൾ അനുവദിക്കില്ല.</li>
            <li>കൃതിയിൽ സാഹിത്യസൃഷ്ടികൾ പ്രസിദ്ധീകരിക്കുന്ന രചയിതാക്കൾ ശ്രദ്ധിക്കുക. നിങ്ങളുടെ സ്വന്തം ആശയങ്ങളിൽ നിന്നും ഉരുത്തിരിയുന്ന ഭാവനകൾക്ക് നിറം പകരുന്ന രചനകൾ മാത്രം കൃതിയിൽ പ്രസിദ്ധീകരിക്കാൻ ശ്രമിക്കുക. ആശയ മോഷണം, എഴുത്തുകാരുടെ അനുവാദം ഇല്ലാതെ അവരുടെ സാഹിത്യസൃഷ്ടികൾ , പകർത്തിയെഴുത്ത്,-ഇവ ഞങ്ങൾ പ്രോത്സാഹിപ്പിക്കുന്നില്ല.</li>
            <li>കൃതിയിൽ നിങ്ങൾ ഓരോരുത്തരും പ്രസിദ്ധീകരിക്കുന്ന സാഹിത്യസൃഷ്ടികൾ നിങ്ങളുടെ അനുവാദം കൂടാതെ മറ്റു ഇടങ്ങളിൽ ഏതെങ്കിലും വ്യക്തികൾ, പേജുകൾ,ആപ്പുകൾ പ്രസിദ്ധീകരിക്കുന്നു എങ്കിൽ ഞങ്ങളുടെ ടീം ആയിട്ട് ബന്ധപെടുക. അനുചിതമായ മേൽ നടപടികൾക്ക് ആയി ഞങ്ങളുടെ ടീം നിങ്ങളെ സഹായിക്കുന്നതായിരിക്കും.</li>
            <li>രചയിതാക്കളുടെയും വായനക്കാരുടെയും ശ്രദ്ധയ്ക്ക് നിങ്ങൾക്ക് കൃതി ൽ ഏതെങ്കിലും തരത്തിലുള്ള ബുദ്ധിമുട്ടുകൾ അനുഭവപ്പെട്ടാൽ ഞങ്ങളുടെ പ്രതിനിധിയുമായി ഉടനെ ബന്ധപെടുക. പരാതികൾക്ക് അതിവേഗം തീരുമാനം ലഭിക്കുന്നതാണ്.24 മണിക്കൂറിനുള്ളിൽ ഞങ്ങളുടെ പ്രതിനിധി നിങ്ങളുടെ പരാതികൾക്ക് മറുപടി തരുന്നത് ആയിരിക്കും.</li>
            <li>കൃതിയിൽ നിങ്ങൾ ഓരോരുത്തരും എഴുതി പ്രസിദ്ധീകരിക്കുന്ന സാഹിത്യ സൃഷ്ടികൾ, അഭിപ്രായങ്ങൾ മുതലായവയുടെ പൂർണ്ണ ഉത്തരവാദിത്യം നിങ്ങൾ ഓരോരുത്തരുടെയും മാത്രം ആയിരിക്കും. കേന്ദ്ര സർക്കാരിന്റെ ഐ ടി നയപ്രകാരം വ്യക്തി, സമുദായം, മതം, രാജ്യം, ആവിഷ്ക്കാര സ്വാതന്ത്ര്യം എന്നിവയ്ക്കെതിരായി ഉയരുന്ന രചനകളും , വ്യക്തിപരമായ അധിക്ഷേപങ്ങളും, അശ്ലീല പദപ്രയോഗങ്ങളും, അഭിപ്രായങ്ങളും ശിക്ഷാർഹമായ കുറ്റമാണ്.ഇത്തരം അഭിപ്രായ പ്രകടനങ്ങൾ , രചനകൾ മുതലായവയ്ക്ക് എതിരായി ഞങ്ങളുടെ ടീം നിയമനടപടികൾ കൈക്കൊള്ളുന്നതാണ്. വായനക്കാരുടെയും എഴുത്തുകാരുടെയും അഭിപ്രായങ്ങൾ അവരുടേത് മാത്രമാണ് അത് ഒരിക്കലും കൃതി ടീമി- ന്റേത് അല്ല.</li>
            <p>വരൂ... നമുക്കൊന്നിച്ച് നല്ല സൃഷ്ടികൾ രൂപപ്പെടുത്താം... പുതിയ വായന സംസ്കാരം തുടങ്ങി വയ്ക്കാം...</p>
            <div>സ്നേഹപൂർവ്വം</div>
            <div className="">team കൃതി</div>
          </ul>
        </div>
        <Footer/>
      </div>
    </>
  );
};

export default TermsAndConditions;