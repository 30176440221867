import React, { useEffect } from "react";
// import logo from "../../images/awesomepenpng-light.png"
import logo from "../../images/krithyBlack.png"
import userImg from "../../images/userImg.jpg"
import { FaHome, FaSearch, FaEnvelope, FaCashRegister, FaTeamspeak, FaPersonBooth, FaPeopleCarry, FaShare, FaBell, FaUserAlt, FaPowerOff, FaAddressBook, FaTable, FaLanguage, FaSitemap, FaTypo3, FaAlignJustify, FaRegEdit, FaStar, FaBook } from "react-icons/fa";
import $ from "jquery"
import { useNavigate } from 'react-router-dom';
export default function Header() {
    const navigate = useNavigate();
    useEffect(() => {
        if (!window.localStorage.getItem("userId") || window.localStorage.getItem('role') !== "Admin") {
            navigate('/')
        }
        $('.toggle').click(function () {
            $('#target').toggle('slow');
        });
    }, [])

    return (
        <>
            <div className="header">
                <div className="logo">
                    <a href="/">
                        <img className="img-fluid" style={{ width: "250px" }} src={logo} alt="" /></a>
                </div>
                <div className="headerMenus">
                    <ul>
                        {/* <li><a href="/dashboard/dashboard"><FaHome /> Dashboard</a></li> 
                        <li> <a href="/reports/tableList"><FaTable/> Reports</a></li>
                        <li><a href="/languages/languageList"><FaLanguage/> Languages</a></li>
                        <li><a href="/category/categoryList"><FaSitemap/> Category</a></li>
                        <li><a href="/type/typeList"> <FaAlignJustify/> Type</a></li>
                        <li><a href="/authors/authorsList"><FaRegEdit/> Authors</a></li>
                        <li><a href="/authors/startWriterList"><FaStar/> Star Writers</a></li>
                        <li><a href="/books/booksList"><FaBook/> Books</a></li> */}
                        <li>
                            {/* <a href=""> */}
                            Welcome back, &nbsp;<b> Admin</b>
                            {/* </a> */}
                        </li>

                    </ul>
                </div>
                {/* <div className="headerRight">
                    <ul> 
                        <li> <FaSearch /></li>
                        <li> <FaEnvelope /></li>
                        <li><FaBell /></li>

                    </ul>
                    <div className="spanUser">
                <button class="toggle">
                    <div className="userInfo">
                        <img src={userImg} className="userImg" /> <span className="online"></span>
                    </div>
                    </button>
                    <div id="target">
                        <ul>
                            <li><FaUserAlt/> Profile</li>
                            
                            <li><FaPowerOff/> Logout</li>
                        </ul>
                    </div>
                </div>
                    
                </div>
                */}
            </div>
        </>
    )
}