import React, { useEffect, useState } from "react";
import { FaCheckCircle, FaClock, FaRegTimesCircle, FaUser, FaStar, FaBookOpen, FaBookReader } from "react-icons/fa";
import { callingAPI } from '../../service';
import Select from "react-select";
import moment from 'moment';

export default function BooksTableList(props) {
  const [popupDesc, setPopupDesc] = useState(false);
  const [popupView, setPopupView] = useState(false);
  const [bookList, setBookList] = useState([]);
  const [reason, setReason] = useState("");
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState([]);  // Use an array for multiple Select components
  const [popUpBookList, setPopUpBookList] = useState([]);
  const [popUpBookListIndex, setPopUpBookListIndex] = useState([]);
  const [newArray, setNewArray] = useState([
    { value: "3", label: "Approve" },
    { value: "4", label: "Un-Approve" }
  ]);

  useEffect(() => {
    bookListing();
    setPopupView(false);
  }, []);

  const bookListing = () => {
    // const postData = {
    //   "offset": "0",
    //   "max": "10",
    //   "searchCmb": "",
    //   "searchInput": "",
    // };

    // callingAPI('book/publishedBookList', postData).then((res) => {
    //   setBookList(res.data.result.bookList);
    // });
    const postData = {
      "offset": "0",
      "max": "10",
      "searchCmb": "", //bookName
      "searchInput": ""
    };

    callingAPI('book/draftStatusBookList', postData).then((res) => {
      setBookList(res.data.result.bookList);

    });
  };

  const publishBooks = (info, status) => {
    const confirmationMessage = status === 3
      ? 'Do you want to Approve this?'
      : 'Do you want to Unapprove this?';

    const confirmed = window.confirm(confirmationMessage);

    if (confirmed) {
      const postData = {
        user_id: info.user_id,
        status_id: status === 4 ? 4 : 3,
        // createdOn: info.created_on,
        createdOn: new Date().getTime(),
        book_id: info.id,
        status_comment: info.status_comment
      };

      callingAPI('book/approveAndUnapproveBook', postData).then(() => {
        bookListing();
      });
    }
  };

  const selectedValue = (selectedValue, dataItem, index) => {
    setSelectedSiteIds((prevSelectedSiteIds) => {
      const updatedSelectedSiteIds = [...prevSelectedSiteIds];
      updatedSelectedSiteIds[index] = selectedValue;
      return updatedSelectedSiteIds;
    });
    setPopUpBookListIndex(index)
    setPopUpBookList(dataItem);

    if (selectedValue.value === "3") {
      const answer = window.confirm("Do you really want to Approve?");

      if (answer) {
        const postData = {
          "book_id": dataItem.id,
          "status_id": selectedValue.value,
          // "createdOn": dataItem.created_on,
          createdOn: new Date().getTime(),
          "user_id": dataItem.user_id,
          "status_comment": ""
        };
        setPopupView(false);
        callingAPI('book/approveAndUnapproveBook', postData).then(() => {
          bookListing();
          setSelectedSiteIds((prevSelectedSiteIds) => {
            const updatedSelectedSiteIds = [...prevSelectedSiteIds];
            updatedSelectedSiteIds[index] = null;
            return updatedSelectedSiteIds;
          });
        });
      } else {
        setSelectedSiteIds((prevSelectedSiteIds) => {
          const updatedSelectedSiteIds = [...prevSelectedSiteIds];
          updatedSelectedSiteIds[index] = null;
          return updatedSelectedSiteIds;
        });
      }
    } else {
      setPopupView(true);
    }
  };

  const closePopup = () => {
    setPopupView(false);
    setSelectedSiteIds((prevSelectedSiteIds) => {
      const updatedSelectedSiteIds = [...prevSelectedSiteIds];
      updatedSelectedSiteIds[popUpBookListIndex] = null;
      return updatedSelectedSiteIds;
    });

  };

  const popUpFlagHandle = () => {
    const postData = {
      "book_id": popUpBookList.id,
      "status_id": selectedSiteIds[popUpBookListIndex]?.value,
      "createdOn": popUpBookList.created_on,
      "user_id": popUpBookList.user_id,
      "status_comment": reason
    };
    setPopupView(false);
    callingAPI('book/approveAndUnapproveBook', postData).then(() => {
      bookListing();
    });
  };
  const closePopupbooks = (dataIndex) => {
    setPopupDesc(false);
    document.getElementById('demo-modal' + dataIndex).style.display = 'none'
  }
  const descriptionData = (dataIndex) => {
    document.getElementById('demo-modal' + dataIndex).style.display = 'flex'
  }
  return (
    <>
      <div className="summaryTabs budgetTable dashBooksListTbl">
        <div class="scheduleListNew">
          <table className="requestTable">
            <thead>
              <tr className="row">
                <th className="col-md-8">Book Details</th>
                {/* <th>Title</th> */}
                {/* <th>Author Name</th> */}
                {/* <th>Duration</th> */}
                {/* <th>Description</th> */}
                <th className="col-md-2"  >Published on</th>
                {/* <th style={{ textAlign: "right", width: "180px", display: "flex", justifyContent: "end" }}>Action</th> */}
                <th className="col-md-2" style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody id="style-1">
              {bookList.map((data, index) => (
                <tr key={index} onClick={() => props.handleDetailClick({ bookId: data.id, user_id: data.user_id })}>
                  <td data-column="Image" className="col-md-8">
                    <div className="draftCon">
                      <span style={{ position: "relative" }}>
                        <img src={data.bookImageWithPath} className="categoryImg" onClick={() => props.handleDetailClick({ bookId: data.id, user_id: data.user_id })} />
                        {data.rating !== "" || data.rating !== null || data.rating !== "null" &&
                          <span className="starRatingCountTbl">
                            <FaStar style={{ fontSize: "12px" }} />&nbsp;
                            {data.rating}
                          </span>
                        }
                        {data.episodeDetailsCount > 0 &&
                          <span className="episodesCount"> <FaBookOpen style={{ fontSize: "15px", color: "#fff" }} /> {data.episodeDetailsCount} episodes</span>
                        }
                      </span>
                      <span>
                        <p><b>{data.title}</b></p>
                        <p><FaUser style={{ fontSize: "15px", color: "#363636" }} /> {data.authorName}</p>
                        <p><FaClock style={{ fontSize: "15px", color: "#363636" }} /> {data.duration}</p>
                        <p><FaBookReader style={{ fontSize: "15px", color: "#363636" }} /> {data.no_of_views} Views</p>
                      </span>
                    </div>
                  </td>
                  {/* <td data-column="Title" title={data.title}>{data.title}</td> */}
                  {/* <td data-column="AuthorName">{data.authorName}</td> */}
                  {/* <td data-column="Duration">{data.duration}</td> */}
                  {/* <td data-column="Description">
                    <span className="descriptionData" title="Click here to view more..." onClick={() => descriptionData(index)}>{data.description_without_html}</span>
                     
                    <div id={"demo-modal" + index} class="modal" style={{ display: "none" }} >
                      <div class="modal__content">
                        <h3 >Description</h3>
                        <>
                          <div className="modalFieldss">
                            <p className="descrptn" id="style-1">{data.description_without_html}</p>
                          </div>

                          <a onClick={() => closePopupbooks(index)} class="modal__close"> <FaRegTimesCircle /></a>
                        </>

                      </div>
                    </div>
                    
                  </td> */}
                  <td className="col-md-2 dflexAlignCenter" data-column="created on" >
                    {moment(data.created_on).format('DD-MM-YYYY')}
                  </td>
                  <td className="col-md-2 dflexAlignCenterEnd" style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                    <button className="btn-review" variant="primary" onClick={() => props.handleDetailClick({ bookId: data.id, user_id: data.user_id })}  >View Details</button>

                  </td>
                  {/* <td data-column="Action" style={{ textAlign: "right" }}>
                    <div className="tableAction actionTbls111" id={index}>
                      <Select
                        id={`select-${index}`} className="selectControl"
                        value={selectedSiteIds[index] || null}
                        placeholder="Change Status"
                        options={newArray.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        onChange={(e) => selectedValue(e, data, index)}
                      />
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div id="demo-modal" class="modal" style={{ display: popupView === true ? "flex" : "none" }} >
        <div class="modal__content">
          <h3 >Reason For Un-Approve</h3>
          <div className="modalFields">
            <div className="form-group">
              <label htmlFor="">Reason <span>:</span> </label>
              <textarea
                className="form-control"
                id='statusComment'
                onChange={(e) => setReason(e.target.value)}
                value={reason}
              />
            </div>
          </div>
          <div class="modal__footer">
            <div className="dashAction">
              <a
                onClick={() => popUpFlagHandle()}
                id="saveBtn" className="btnNavy"  >  Ok</a>
            </div>
          </div>
          <a onClick={() => closePopup()} class="modal__close"> <FaRegTimesCircle /></a>
        </div>
      </div>
    </>
  );
}
