import React, { useState, useEffect, Component } from 'react';
import { FaArrowLeft, FaEdit, FaTrashAlt } from "react-icons/fa";
import { callingAPI } from '../../service';
function EpisodeReview({ episodeID }) {
    const [replyVisible, setReplyVisible] = useState(false);
    const [replySubVisible, setReplySubVisible] = useState(false);
    const [editInputVisible, setEditInputVisible] = useState(false);
    const [editSubInputVisible, setSubEditInputVisible] = useState(false);
    const [editSubLastInputVisible, setSubEditLastInputVisible] = useState(false);
    const [listingData, setListingData] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [replyInputValue, setReplyInputValue] = useState('')
    const [subinputValue, setSubInputValue] = useState('')
    const [subinputLastValue, setSubInputLastValue] = useState('')
    const [subReplyValue, setSubReplyValue] = useState('')
    const [feedbackType, setFeedbackType] = useState('true');
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editingReplyCommenttId, setEditingReplyCommentId] = useState(null);
    const [editingSubCommentId, setEditingSubCommentId] = useState(null);
    const [editingSubReplyCommentId, setEditingSubReplyCommentId] = useState(null);
    const [editingSubLastCommentId, setEditingSubLastCommentId] = useState(null);

    useEffect(() => {

        if (typeof episodeID !== "undefined") {
            reviewListing()
        }

    }, []);
    const reviewListing = () => {
        const postData = {
            "episodeId": episodeID,
            "userId": window.localStorage.getItem("userId")
        }

        callingAPI('episodeData/episodeReviewDetails', postData).then((res) => {
            if (res.data.success === "1") {
                setListingData(res.data.result)

            } else {
                setListingData([])
            }
        });
    };
    const handleReplyShow = (revId) => {
        setReplyVisible(true);
        setEditingReplyCommentId(revId)
    };

    const handleReplyHide = (episodeReviewId, type) => {
        if (type === "submit") {
            const postData = {
                "episodeReviewsCommentId": "",
                "userId": sessionStorage.getItem('userId'),
                "currentDateTime": new Date().getTime(),
                "episodeReviewId": episodeReviewId,
                "comments": replyInputValue,
                "parentCommentId": ""
            }

            callingAPI('reading/saveEpisodeReviewCommentDetails', postData).then((res) => {
                // setListingData(res.data.result)
                if (res.data.success === "1") {
                    reviewListing()
                    setReplyInputValue("")
                    setReplyVisible(false);
                    setEditingReplyCommentId(null)

                }
            });
        } else {
            setReplyVisible(false);
            setEditingReplyCommentId(null)
        }

    };

    const handleEditShow = (reviewId, bookReview) => {
        setInputValue(bookReview)
        setEditInputVisible(true);
        setEditingCommentId(reviewId);
    };
    const handleCheckboxChange = () => {

        setFeedbackType((prevType) => (prevType === 'true' ? 'false' : 'true'));
    };
    const handleEditHide = (episodereviewId, episodeId, type) => {
        
        if (type === "submit") {
            const postData = {
                "bookId": episodeID,
                "episodeId": episodeId,
                "episodeReviewsId": episodereviewId,
                "userId": sessionStorage.getItem('userId'),
                "currentDateTime": new Date().getTime(),
                "review": inputValue,
                "is_positive": feedbackType
            }

            callingAPI('reading/saveEpisodeReviewDetails', postData).then((res) => {
                // setListingData(res.data.result)
                if (res.data.success === "1") {
                    reviewListing()
                    setInputValue("")
                    setEditInputVisible(false);
                    setEditingCommentId(null);

                }
            });
        } else {
            setEditInputVisible(false);
            setEditingCommentId(null);
        }
    };
    const handleSubEditShow = (comment, commentId) => {

        setSubInputValue(comment)
        setSubEditInputVisible(true);
        setEditingSubCommentId(commentId)
    };
    const handleSubEditHide = (episodereviewId, episodereviewcommentId, type) => {
        if (type === "submit") {
            const postData = {
                "episodeReviewsCommentId": episodereviewcommentId,
                "userId": sessionStorage.getItem('userId'),
                "currentDateTime": new Date().getTime(),
                "episodeReviewId": episodereviewId,
                "comments": subinputValue,
                "parentCommentId": ""
            }

            callingAPI('reading/saveEpisodeReviewCommentDetails', postData).then((res) => {
                // setListingData(res.data.result)
                if (res.data.success === "1") {
                    reviewListing()
                    setSubInputValue("")
                    setSubEditInputVisible(false);
                    setEditingSubCommentId(null)

                }
            });
        } else {
            setSubEditInputVisible(false);
            setEditingSubCommentId(null)
        }
    };
    const handleSubReplyShow = (commentId) => {
        setReplySubVisible(true);
        setEditingSubReplyCommentId(commentId)
    };

    const handleSubReplyHide = (episodeReviewId, episodereviewCommentId, type) => {
        if (type === "submit") {
            const postData = {
                "episodeReviewsCommentId": "",
                "userId": sessionStorage.getItem('userId'),
                "currentDateTime": new Date().getTime(),
                "episodeReviewId": episodeReviewId,
                "comments": subReplyValue,
                "parentCommentId": episodereviewCommentId
            }

            callingAPI('reading/saveEpisodeReviewCommentDetails', postData).then((res) => {
                // setListingData(res.data.result)
                if (res.data.success === "1") {
                    reviewListing()
                    setSubReplyValue("")
                    setReplySubVisible(false);
                    setEditingSubReplyCommentId(null)

                }
            });
        } else {
            setReplySubVisible(false);
            setEditingSubReplyCommentId(null)
        }
    };
    const handleSubLastEditShow = (comment, commentId) => {
        setSubInputLastValue(comment)
        setSubEditLastInputVisible(true);
        setEditingSubLastCommentId(commentId)
    };
    const handleSubLastEditHide = (episodereviewId, episodereviewcommentId, parentId, type) => {
        if (type === "submit") {
            const postData = {
                "episodeReviewsCommentId": episodereviewcommentId,
                "userId": sessionStorage.getItem('userId'),
                "currentDateTime": new Date().getTime(),
                "episodeReviewId": episodereviewId,
                "comments": subinputLastValue,
                "parentCommentId": parentId
            }

            callingAPI('reading/saveEpisodeReviewCommentDetails', postData).then((res) => {
                // setListingData(res.data.result)
                if (res.data.success === "1") {
                    reviewListing()
                    setSubInputLastValue("")
                    setSubEditLastInputVisible(false);
                    setEditingSubLastCommentId(null)

                }
            });
        } else {
            setSubEditLastInputVisible(false);
            setEditingSubLastCommentId(null)
        }
    };
    const handleDelete = (episodeReviewId, type) => {
        var x = false;
        x = window.confirm('Do you want to Delete this Review?');
        if (x === true) {
            let postData = {}
            if (type === "subReview") {
                postData = {
                    "bookOrEpisodeReviewCommentsId": episodeReviewId,
                    "deleteType": "Episode"   //For Episodes Comments Delete-"Episode",For Books Comments-"Book"
                }
                callingAPI('reading/deleteBookEpisodeReviewCommentDetails', postData).then((res) => {
                    // setListingData(res.data.result)
                    if (res.data.success === "1") {
                        reviewListing()

                    }
                });
            }
            else {
                postData = {
                    "bookOrEpisodeReviewsId": episodeReviewId,
                    "deleteType": "Episode"   //For Episodes Comments Delete-"Episode",For Books Comments-"Book"
                }
                callingAPI('reading/deleteBookEpisodeReviewDetails', postData).then((res) => {
                    // setListingData(res.data.result)
                    if (res.data.success === "1") {
                        reviewListing()

                    }
                });
            }

        }

    };
    const formatTimestampToDate = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        }).format(date);
        return formattedDate;
    }
    return (
        <>

            <div className="review-wrapper review-wrapper111111">
                {listingData.length > 0 ?
                    <div class="card">
                        <h1>Episode Reviews</h1>
                        <div className="bkBg">
                            {listingData.map((data, index) => (
                                <div class="comment">
                                    <div class="comment_header">
                                        <div class="comment_header-pic"></div>
                                        <h2>{data.userDisplayName}</h2>
                                    </div>
                                    <div class="comment_content">
                                        <p className="d-flex edit-revewwrap">
                                            <div className="edit-revewwrap-div">{data.episodeReview}</div>
                                            <div className='d-flex edit-revewwrapIcon'>
                                                <div><FaEdit className="edit-svg" onClick={() => handleEditShow(data.episodeReviewId, data.episodeReview)} title='Edit' /></div>
                                                <div><FaTrashAlt className='delete-svg' title='Delete' onClick={() => handleDelete(data.episodeReviewId, "mainReview")} /></div>
                                            </div>
                                        </p>
                                        {editInputVisible && editingCommentId === data.episodeReviewId && (
                                            <div className="reply-inputSec" id="replyDiv">
                                                <input type="text" placeholder="Reply" className="form-control" value={inputValue}
                                                    onChange={(e) => setInputValue(e.target.value)}></input>
                                                <div className="revwsubmt-bottm-sec">
                                                    <div className="d-flex mt-2 comment-toggle commntRply">
                                                        <span className='comment-toggle-text' style={{ marginRight: "16px" }}>comments</span>
                                                        <input type="checkbox" id="switch" checked={feedbackType === 'false'}
                                                            onChange={handleCheckboxChange} /><label for="switch"></label>
                                                        <span className='comment-toggle-text' style={{ marginLeft: "16px" }}>criticism</span>
                                                    </div>
                                                    <div className="replySubmtBtn">
                                                        <button className="reply-btn" onClick={() => handleEditHide(data.episodeReviewId, data.episodeId, "submit")}>
                                                            Submit
                                                        </button>
                                                        <button className="reply-btn cancel-btn-reply" onClick={() => handleEditHide(data.episodeReviewId, data.episodeId, "cancel")}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div class="comment_footer">
                                        <span>{new Date(data.modifiedDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</span>
                                        <span className="replybtn" onClick={() => handleReplyShow(data.episodeReviewId)}>Reply</span>
                                    </div>
                                    {replyVisible && editingReplyCommenttId === data.episodeReviewId && (
                                        <div className="reply-inputSec" id="replyDiv">
                                            <input type="text" placeholder="Reply" className="form-control" value={replyInputValue}
                                                onChange={(e) => setReplyInputValue(e.target.value)} />
                                            <div className="revwsubmt-bottm-sec justify-content-end">
                                                <div className="replySubmtBtn">
                                                    <button className="reply-btn" onClick={() => handleReplyHide(data.episodeReviewId, "submit")}>
                                                        Submit
                                                    </button>
                                                    <button className="reply-btn cancel-btn-reply" onClick={() => handleReplyHide(data.episodeReviewId, "cancel")}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {data.episodeReviewComments.map((obj, index) => (
                                        <div className="sub-commentSection">
                                            <div class="comment">
                                                <div class="comment_header">
                                                    <div class="comment_header-pic"></div>
                                                    <h2>{obj.userDisplayName}</h2>
                                                </div>
                                                <div class="comment_content">
                                                    <p className="d-flex">
                                                        <div className="">{obj.episodeReviewsComment}</div>
                                                        <div className='d-flex edit-revewwrapIcon'>
                                                            <div><FaEdit className="edit-svg" title='Edit' onClick={() => handleSubEditShow(obj.episodeReviewsComment, obj.episodeReviewsCommentsId)} /></div>
                                                            <div><FaTrashAlt className='delete-svg' title='Delete' onClick={() => handleDelete(obj.episodeReviewsCommentsId, "subReview")} /></div>
                                                        </div>


                                                    </p>
                                                    {(editSubInputVisible && editingSubCommentId == obj.episodeReviewsCommentsId) &&
                                                        <div className="reply-inputSec" id="replyDiv">
                                                            <input type="text" placeholder="Reply" className="form-control" value={subinputValue}
                                                                onChange={(e) => setSubInputValue(e.target.value)} />
                                                            <div className="revwsubmt-bottm-sec justify-content-end">
                                                                <div className="replySubmtBtn">
                                                                    <button className="reply-btn" onClick={() => handleSubEditHide(data.episodeReviewId, obj.episodeReviewsCommentsId, "submit")}>
                                                                        Submit
                                                                    </button>
                                                                    <button className="reply-btn cancel-btn-reply" onClick={() => handleSubEditHide(data.episodeReviewId, obj.episodeReviewsCommentsId, "cancel")}>
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div class="comment_footer">
                                                    <span>{formatTimestampToDate(obj.modifiedDate)}</span>
                                                    <span className="replybtn" onClick={() => handleSubReplyShow(obj.episodeReviewsCommentsId)}>Reply</span>
                                                </div>
                                                {(replySubVisible && editingSubReplyCommentId == obj.episodeReviewsCommentsId) && (
                                                    <div className="reply-inputSec" id="replyDiv">
                                                        <input type="text" placeholder="Reply" className="form-control" value={subReplyValue}
                                                            onChange={(e) => setSubReplyValue(e.target.value)} />
                                                        <div className="revwsubmt-bottm-sec justify-content-end">

                                                            <div className="replySubmtBtn">
                                                                <button className="reply-btn" onClick={() => handleSubReplyHide(data.episodeReviewId, obj.episodeReviewsCommentsId, "submit")}>
                                                                    Submit
                                                                </button>
                                                                <button className="reply-btn cancel-btn-reply" onClick={() => handleSubReplyHide(data.episodeReviewId, obj.episodeReviewsCommentsId, "cancel")}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {obj.episodeReviewCommentsReplays.map((obj1, index) => (
                                                <div className="sub-commentSection pl-5">
                                                    <div class="comment">
                                                        <div class="comment_header">
                                                            <div class="comment_header-pic"></div>
                                                            <h2>{obj1.userDisplayName}</h2>
                                                        </div>
                                                        <div class="comment_content">
                                                            <p className="d-flex">
                                                                <div className="">{obj1.episodeReviewsComment}</div>
                                                                <div className='d-flex edit-revewwrapIcon'>
                                                                    <div><FaEdit className="edit-svg" title='Edit' onClick={() => handleSubLastEditShow(obj1.episodeReviewsComment, obj1.episodeReviewsCommentsId)} /></div>
                                                                    <div><FaTrashAlt className='delete-svg' title='Delete' onClick={() => handleDelete(obj1.episodeReviewsCommentsId, "subReview")} /></div>
                                                                </div>


                                                            </p>
                                                            {(editSubLastInputVisible && editingSubLastCommentId == obj1.episodeReviewsCommentsId) &&
                                                                <div className="reply-inputSec" id="replyDiv">
                                                                    <input type="text" placeholder="Reply" className="form-control" value={subinputLastValue}
                                                                        onChange={(e) => setSubInputLastValue(e.target.value)} />
                                                                    <div className="revwsubmt-bottm-sec justify-content-end">

                                                                        <div className="replySubmtBtn">
                                                                            <button className="reply-btn" onClick={() => handleSubLastEditHide(data.episodeReviewId, obj1.episodeReviewsCommentsId, obj1.parentCommentId, "submit")}>
                                                                                Submit
                                                                            </button>
                                                                            <button className="reply-btn cancel-btn-reply" onClick={() => handleSubLastEditHide(data.episodeReviewId, obj1.episodeReviewsCommentsId, obj1.parentCommentId, "cancel")}>
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div class="comment_footer">
                                                            <span>{formatTimestampToDate(obj1.modifiedDate)}</span>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    : ""}
            </div>
        </>

    )

}
export default EpisodeReview