import React, { useState, useEffect, Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { callingAPI } from '../../service';
import VisitorStatics from './VisitorStatics';
import { FaAngleRight, FaArrowCircleRight, FaArrowRight, FaBell, FaClock, FaEnvelope, FaExpandArrowsAlt, FaLocationArrow, FaLongArrowAltRight, FaRegArrowAltCircleRight } from "react-icons/fa";

// export default function ScheduleList() {

//     return (
//         <>
//             <div className="issueSummWrap">
//                 <h2>Schedule  </h2>
//                 <div className="summaryTabs">
//                     <Tabs>
//                         <TabList>
//                             <Tab>Today</Tab>
//                             <Tab>Tomorrow</Tab>
//                         </TabList>
//                         <TabPanel>
//                             <div className="scheduleList" id="style-1">
//                                 <ul >
//                                     <li>
//                                         <h5>
//                                             <h3>Marketing Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li>
//                                     <li>
//                                         <h5>
//                                             <h3>Marketing Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li>
//                                     <li>
//                                         <h5>
//                                             <h3>Marketing Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li>
//                                     <li>
//                                         <h5>
//                                             <h3>Marketing Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li>
//                                     <li>
//                                         <h5>
//                                             <h3>Marketing Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li> <li>
//                                         <h5>
//                                             <h3>Marketing Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </TabPanel>
//                         <TabPanel>
//                         <div className="scheduleList " id="style-1">
//                                 <ul>
//                                     <li>
//                                         <h5>
//                                             <h3>Group Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li>
//                                     <li>
//                                         <h5>
//                                             <h3>Group Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li>
//                                     <li>
//                                         <h5>
//                                             <h3>Group Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li>
//                                     <li>
//                                         <h5>
//                                             <h3>Group Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li>
//                                     <li>
//                                         <h5>
//                                             <h3>Group Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li> <li>
//                                         <h5>
//                                             <h3>Marketing Meeting </h3>
//                                             <p><FaClock /> <span>09.00 AM</span>  <FaLocationArrow /> <span>Conference room 1A </span></p>

//                                         </h5>
//                                         <h1>
//                                             <FaAngleRight />
//                                         </h1>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </TabPanel>
//                     </Tabs>
//                 </div>

//             </div>
//         </>
//     )

// }


function ScheduleList() {
    const [yearAndMonth, setYearMonth] = useState([]);
    const [menCount, setMenCount] = useState([]);
    const [totalAppointmentResult, setTotalAppointmentResult] = useState([]);
    const [listFlag, setListFlag] = useState()
    useEffect(() => {

        const PostData = {

        };


        callingAPI('userData/userRegistrationAnalytics', PostData).then((response) => {
            if (response.data.success === '1') {

                setTotalAppointmentResult(response.data.result.monthWiseData)
                // console.log("monthWiseData", response.data.result.monthWiseData)
            }
            else {

                setTotalAppointmentResult([])

            }
            if (response.data.result.monthWiseData.length === 0) {
                setListFlag(true)

            }
            else {
                setListFlag(false)
            }
        })
            .catch();
    }, []);
    return (
        <>
            <div className="issueSummWrap">
                <h2>User Registration (Yearly)</h2>
                <div className="summaryTabs">
                    {/* <Tabs>
                        <TabList>
                            <Tab>Last Week</Tab>
                            <Tab>This Week</Tab>
                        </TabList>

                        <TabPanel> */}
                    <div className="row">
                        <div className="col-md-12">
                            {/* <h2>News vs Closed</h2> */}
                            {/* <ReactApexChart
                                        options={chartData.options}
                                        series={chartData.series}
                                        type="line"
                                        height={350}
                                    /> */}


                            {totalAppointmentResult.length > 0 &&

                                <VisitorStatics key={totalAppointmentResult} yearMonthResult={totalAppointmentResult} />


                            }
                            {listFlag &&
                                <div className='todayAppScroll nodataFnd nodata-found_wrpr graphDataNotfound' >
                                    <p>No Data Found </p>
                                </div>
                            }
                        </div>
                        {/* <div className="col-md-6">
                                    <h2>Overview</h2>
                                    <div className="row issueTopTxt">
                                        <div className="col-md-6">
                                            <div className="dashCards" style={{ background: "#eef2ff" }}>
                                                <div className="dashCount darkBlue">
                                                    <h1>197</h1>
                                                    <p>New Issues</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="dashCards " style={{ background: "#f0fdf4" }}>
                                                <div className="dashCount darkGreen">
                                                    <h1>38</h1>
                                                    <p>Closed</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row issueBtmTxt">
                                        <div className="col-md-3">
                                            <div className="dashCards" style={{ background: "#f1f5f9" }}>
                                                <div className="dashCount">
                                                    <h1>6</h1>
                                                    <p>Fixed</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dashCards" style={{ background: "#f1f5f9" }}>
                                                <div className="dashCount">
                                                    <h1>11</h1>
                                                    <p>Won't Fix</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dashCards" style={{ background: "#f1f5f9" }}>
                                                <div className="dashCount">
                                                    <h1>6</h1>
                                                    <p>Re-opened</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dashCards" style={{ background: "#f1f5f9" }}>
                                                <div className="dashCount">
                                                    <h1>6</h1>
                                                    <p>Needs Triage</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                    </div>
                    {/* </TabPanel>
                        <TabPanel>
                            <h2>Any content 2</h2>
                        </TabPanel>
                    </Tabs> */}
                </div>

            </div>
        </>
    )

}
export default ScheduleList