
import React, { useState, useEffect, Component } from 'react';
import { callingAPI } from '../service';
import Header from "../Home/layout/header";
import Footer from "../Home/layout/footer"
import bannerImg from "../images/bannerImg.jpg"
import aboutIcon from "../images/aboutIcon.png"
import contactus from "../images/contactus.png"
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCommentAlt, FaEnvelope, FaGlobe, FaPhoneAlt, FaRegAddressCard, FaSnapchat, FaUserAlt, FaVoicemail, FaWhatsapp } from "react-icons/fa";

const ContactUs = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [comments, setComments] = useState("")
    const [singleClick, setSingleClick] = useState(true)
    const [isPopupVisible, setPopupVisibility] = useState(false);

  
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    const handleContactSave = () =>{
        setSingleClick(false)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!name){
            toast.error("Name Required", { containerId: "C" })
            document.getElementById("name").focus();
            setSingleClick(true)
        }
        else if(!email){
            toast.error("Email Required", { containerId: "C" })
            document.getElementById("email").focus();
            setSingleClick(true)
        }
        else if (!emailRegex.test(email)) {
            toast.error("Invalid Email", { containerId: "C" });
            document.getElementById("email").focus();
            setSingleClick(true)
        }
        else if(!comments){
            toast.error("Comments Required", { containerId: "C" })
            document.getElementById("comments").focus();
            setSingleClick(true)
        }
        else{
        const postData = {
            "name": name,
            "email": email,
            "mobile_number": mobile,
            "description": comments,
            "created_on": new Date().getTime(),
            "created_by": null
        }
        console.log("🚀 ~ handleContactSave ~ postData:", postData)
        
        callingAPI('contactUs/saveContactUs', postData).then((response) => {
            console.log("response", response);
            if (response.data.success == "1") {
                toast.success("Saved successfully", { containerId: "C" })
                setName("");
                setEmail("");
                setMobile("");
                setComments("");
                setSingleClick(true)
            }
            else{
                toast.error("Failed to save data", { containerId: "C" });
                setSingleClick(true)
            }
        })
    }
    }
    const showPopup = () => {
        setPopupVisibility(true);
    };
    const hidePopup = () => {
        setPopupVisibility(false);
    };


    return (
        <>
            <div className="WrapLeft loginWrap termsPolicyWrap">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"C"}
                theme="colored" />
                <Header isPopupVisible={isPopupVisible} showPopup={showPopup} hidePopup={hidePopup}/>
                <div className="innerImgs">
                    <img src={bannerImg} />
                </div>

                <div className="container divOverlap">

                    <h3 className='languageSelect-mainHead'>
                        <span> Contact Us</span>
                        <span className="underline center"></span>
                    </h3>

                    <p style={{ textAlign: "center" }}><span className='aboutTxt'>W</span>elcome to Krithy, where stories come to life and imagination knows no bounds!</p>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="aweContact">
                                <img src={contactus} />
                                <div className="aweAddress">
                                    <span><FaRegAddressCard /> </span>
                                    <span>
                                        <p>Xilligence</p>
                                        <p>6th floor, Technopark Trivandrum, Nila Building, Technopark Campus, Kazhakkoottam, Thiruvananthapuram, Kerala 695581</p>
                                    </span>
                                </div>
                                <div className="aweAddress">
                                    <span><FaGlobe /> </span>
                                    <span>
                                        <p>https://www.krithy.com/</p>
                                    </span>
                                </div>
                                <div className="aweAddress">
                                    <span><FaEnvelope /> </span>
                                    <span>
                                        <p>support@awesomepen.in</p>
                                    </span>
                                </div>
                                {/* <div className="aweAddress">
                                    <span><FaWhatsapp /> </span>
                                    <span>
                                        <p>9999 999 999</p>
                                    </span>
                                </div> */}
                                <div className="aweAddress">
                                    <span><FaPhoneAlt /> </span>
                                    <span>
                                        <p>+916238190530</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="aweEnquiry">
                                <section className='login-wrapper contactus-formsec'>
                                    <div>
                                        <h1 className='login-headtext' style={{ textAlign: "center" }}>Enquiry</h1>
                                        <span className="underline-login center"></span>

                                        <div className="inputbox faEnvelope">
                                            <FaUserAlt />
                                            <input type="text" id='name'  className='email-input' value={name} onChange={(e) => setName(e.target.value)}/>
                                            <label htmlFor="" >Name <sup className='star-red'>*</sup></label>
                                        </div>
                                        <div className="inputbox faEnvelope contactus-formsec">
                                            <FaEnvelope />
                                            <input type="text" id="email" className='email-input' value={window.localStorage.getItem("emailId") ? window.localStorage.getItem("emailId") : email} onChange={(e) => setEmail(e.target.value)}/>
                                            <label htmlFor="" >Email <sup className='star-red'>*</sup></label>
                                        </div>
                                        <div className="inputbox faEnvelope contactus-formsec">
                                            <FaPhoneAlt />
                                            <input type="text" id="mobile" className='email-input' maxLength={10} value={mobile} onChange={(e) => setMobile(e.target.value)}/>
                                            <label htmlFor="" >Mobile</label>
                                        </div>
                                        <div className="inputbox faEnvelope contactus-formsec"  style={{border:"none"}}>
                                            <FaCommentAlt />
                                            <label htmlFor="" className='commtsLabel' >Comments <sup className='star-red'>*</sup></label>
                                            <textarea  id="comments" className='email-input search-autocomplete commentsScroll'   value={comments} onChange={(e) => setComments(e.target.value)} style={{width:"100%"}} />
                                          
                                        </div>
                                        <span className='enquirySubmit' onClick={ singleClick ? () => handleContactSave() : null}>Submit</span>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default ContactUs;