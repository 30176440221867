import React, { useState, useEffect, Component, useRef } from 'react';
import { callingAPI } from '../service';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./layout/header"
import Footer from "./layout/footer"
import { Editor } from 'react-draft-wysiwyg';
import { Link, useNavigate } from "react-router-dom";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import Select from "react-select";
import { convertToHTML } from 'draft-convert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAudioDescription, FaCommentDots, FaMicrophone, FaMicrophoneAltSlash, FaMicrophoneSlash, FaSpinner, FaStop } from "react-icons/fa";
import AWS from 'aws-sdk';
var s3

function WriteHere() {
    const navigate = useNavigate()
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [title, setTitle] = useState("");
    const [file, setFile] = useState('');
    const [imageName, setImageName] = useState('');
    const [imgObject, setImgObject] = useState('');
    const [htmlcontent, setHtmlContent] = useState("");
    const [content, setContent] = useState("");
    const [languageList, setLanguageList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [language, setLanguage] = useState([]);
    // const [languageSpeech, setLanguageSpeech] = useState([]);
    const [status, setStatus] = useState([]);
    const [types, setTypes] = useState([]);
    const [category, setCategory] = useState([]);
    const [environmentType, setEnvironmentType] = useState('');
    const [cofigDetails, setConfigDetails] = useState('')
    const [categoryImagePath, setCategoryImagePath] = useState('');
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML("")
            )
        ),
    );
    const [transcript, setTranscript] = useState('');
    const [isListening, setIsListening] = useState(false);
    const recognitionRef = useRef(null);
    const timeoutRef = useRef(null);
    const [statusList, setStatusList] = useState([
        {
            id: "3",
            name: "Publish",
        },
        {
            id: "1",
            name: "Draft",
        }, {
            id: "4",
            name: "Un-Publish",
        }]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.localStorage.getItem("userId") == null) {
            showPopup()
        }
        getLanguageList()
        getConfiguration();
    }, []);



    const handleEditorState = (value) => {
        const rawData = convertToRaw(value.getCurrentContent());
        setEditorState(value);
        const htmlContent = convertToHTML(value.getCurrentContent());
        setHtmlContent(htmlContent);
        setContent(rawData.blocks.map(block => block.text).join("\n"));
    }


    const handleImageUploadOnChange = (e) => {
        if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
            alert("Please attach jpg,jpeg or png files");
            return false;
        }
        setFile(e.target.files[0]);
        setImageName(e.target.files[0].name);
        const obj = URL.createObjectURL(e.target.files[0])
        setImgObject(obj);
    };


    const handleWriteToFile = async () => {
        if (!title) {
            toast.error("Title Required", { containerId: "W" })
            document.getElementById("title").focus()
            return false
        }
        else if (!imageName) {
            toast.error("Cover Photo Required", { containerId: "W" })
            return false
        }
        
        else if (!language.value) {
            toast.error("Language Required", { containerId: "W" })
            return false
        }
        else if (!content) {
            toast.error("Description Required", { containerId: "W" })
            return false
        }
        else if (!status.value) {
            toast.error("Status Required", { containerId: "W" })
            return false
        }
        else if (!types.value) {
            toast.error("Type Required", { containerId: "W" })
            return false
        }
        else if (category.length == 0) {
            toast.error("Category Required", { containerId: "W" })
            return false
        }
        else {
            const textContent = content;
            const blob = new Blob([textContent], { type: 'text/plain' });
            textFileS3Save(`book_${window.localStorage.getItem("userId")}_${new Date().getTime()}.txt`, blob);
        }
    };


    const handleSave = async (textFileData) => {
        console.log("category", category);

        try {
            debugger
            var postData = {
                "bookCategories": category.map(category => category.value),
                "bookContentFileS3Url": textFileData.Location,
                "bookId": "",
                "bookTypes": types.value,
                "createdOn": new Date().getTime(),
                "description": "",
                "description_without_html": "",
                "image": imageName,
                "isSeriesCompleted": false,
                "languageId": language.value,
                "price": "",
                "statusId": status.value,
                "title": title,
                "userId": window.localStorage.getItem("userId"),
            }
            console.log("🚀 ~ handleSave ~ postData:", postData)
            const response = await callingAPI('book/bookCategorySave', postData);

            if (response.data.success == 1) {
                await toast.success("Added successfully", { containerId: "W" })
                if (file.length !== 0) {
                    let isUploaded = await imageS3Save(imageName, file);
                    if (isUploaded === true) {
                        setTimeout(() => {
                            handleCancel();
                        }, 2000);
                    }
                }
            }
        }
        catch (error) {
            console.error("Error:", error);
        }

    }


    const textFileS3Save = (imageName, blob) => {
        return new Promise((resolve, reject) => {
            try {

                var S3BucketMainFolder = environmentType + '/' + categoryImagePath;
                var fullFilePath = S3BucketMainFolder + '/' + imageName;

                s3.upload(
                    {
                        Key: fullFilePath,
                        Body: blob, // Upload the Blob object
                        ACL: 'public-read', // Optional: Set access control (consider security)
                    },
                    (err, data) => {
                        if (err) {
                            alert('There was an error uploading your photo: ' + err.message);
                            reject(err);
                        } else {
                            console.log("File uploaded to S3 successfully!", data);
                            resolve(true);
                            handleSave(data);
                        }
                    }
                );
            } catch (error) {
                console.error("Error uploading file to S3:", error);
                reject(error); // Propagate errors for handling in calling code
            }
        });
    };



    const imageS3Save = (imageName, file) => {
        return new Promise((resolve, reject) => {

            var S3BucketMainFolder = environmentType + '/' + categoryImagePath;
            var fullFilePath = S3BucketMainFolder + '/' + imageName;
            s3.upload(
                {
                    Key: fullFilePath,
                    Body: file,
                    ACL: 'public-read',
                },
                (err, data) => {
                    if (err) {
                        alert('There was an error uploading your photo: ' + err.message);
                        reject(err);
                    } else {
                        //  alert("File Uploaded Successfully")
                        resolve(true);
                        // document.getElementById('uploadButton').style.display = 'block';

                    }
                }
            );
        })
    };

    const getConfiguration = () => {
        return new Promise((resolve, reject) => {
            try {
                const PostData = {
                };
                callingAPI('configurationData', PostData).then((res) => {
                    setConfigDetails(res.data.result[0]);
                    const getConfigValue = (configKey) => {
                        return res.data.result[0].configDetails
                            ? res.data.result[0].configDetails.find((config) => config.config_key === configKey)?.config_value
                            : null;
                    };
                    setEnvironmentType(getConfigValue("S3BucketMainFolder"));
                    setCategoryImagePath(getConfigValue("bookImagePath"));
                    AWS.config.update({
                        accessKeyId: getConfigValue("AWSAccessKeyId"),
                        secretAccessKey: getConfigValue("AWSSecretKey"),
                        region: getConfigValue("s3Region"),
                    });
                    var bucketParams = {
                        Bucket: getConfigValue("s3bucket"),
                    };
                    s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
                    getLanguageList()
                    resolve(s3)
                })

            }
            catch (e) {
                console.error("Error in getConfiguration", e)
                reject(e)
            }
        })

    }

    const getLanguageList = () => {
        const PostData = {

        };
        callingAPI('languageListing', PostData).then((res) => {
            setLanguageList(res.data.result);

        })
        const jsondata = {

        };
        callingAPI('categoryListing', jsondata).then((res) => {
            setCategoryList(res.data.result);

        })
        const postValue = {

        };
        callingAPI('typeListing', postValue).then((res) => {
            setTypesList(res.data.result);
        })
    }

    const handleCancel = () => {
        setTitle("");
        setStatus("");
        setCategory("");
        setLanguage("");
        setTypes("");
        setImgObject("");
        setEditorState("")
        navigate("/")
    }

    const showPopup = () => {
        setPopupVisibility(true);
    };
    const hidePopup = () => {
        // setPopupVisibility(false);
    };
    const startListening = (lang) => {
        if (language.length === 0) {
            toast.error("Please Select Language", { containerId: "W" })
            return false
        }
        setIsListening(true);
        const recognition = new window.webkitSpeechRecognition(); // Initialize speech recognition
        recognition.lang = lang; // Specify the language to recognize
        recognition.continuous = true; // Continuously listen for speech
        recognition.interimResults = true; // Provide interim results

        recognition.onstart = () => console.log('Speech recognition started');
        recognition.onend = () => {
            console.log('Speech recognition stopped');
            setIsListening(false);
        };

        recognition.onresult = (event) => {
            let concatenatedTranscript = editorState.getCurrentContent().getPlainText('\n'); // Get current editor content
            for (let i = event.resultIndex; i < event.results.length; ++i) {
                if (event.results[i].isFinal) { // Concatenate only final results
                    concatenatedTranscript += ' ' + event.results[i][0].transcript;
                }
            }

            // Update the editor state with the concatenated content
            const newEditorState = EditorState.createWithContent(
                ContentState.createFromText(concatenatedTranscript)
            );
            setEditorState(newEditorState);
        };

        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            console.log('No input detected for 5 seconds. Stopping recognition.');
            stopListening();
        }, 5000);

        recognitionRef.current = recognition; // Store the recognition object in the ref
        recognition.start();
    };
    const stopListening = () => {
        setIsListening(false);
        recognitionRef.current.stop(); // Access the recognition object from the ref and stop it
        clearTimeout(timeoutRef.current);
    };
    const languageSpeechHandle = (e) => {
        setLanguage(e)
        if (e.label === "Bengali") {
            setTranscript("bn-IN");
        }
        else if (e.label === "Telungu") {
            setTranscript("te-IN");
        }
        else if (e.label === "Gujarati") {
            setTranscript("gu-IN");
        }
        else if (e.label === "Hindi") {
            setTranscript("hi-IN");
        }
        else if (e.label === "Kannada") {
            setTranscript("kn-IN");
        }
        else if (e.label === "Malayalam") {
            setTranscript("ml-IN");
        }
        else if (e.label === "Marathi") {
            setTranscript("mr-IN");
        }
        else if (e.label === "Odia") {
            setTranscript("or-IN");
        }
        else if (e.label === "Punjabi") {
            setTranscript("pa-IN");
        }
        else if (e.label === "Tamil") {
            setTranscript("ta-IN");
        }
        else if (e.label === "Urdu") {
            setTranscript("ur-IN");
        }
        else if (e.label === "English  ") {
            setTranscript("en-US");
        }
    }

    return (
        <div className='write-here-sec write-here-sectionform'>
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"W"}
                theme="colored" />
            <Header isPopupVisible={isPopupVisible} showPopup={showPopup} hidePopup={hidePopup} />
            <div className="container write-here-wrapper">
                <div className="form-group">
                    <label>Title<sup className='star-red'>*</sup></label>
                    <input type="text" className="form-control" id="title" placeholder="Enter Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className="form-group">
                    <span id="uploadButton" className="">
                        <input
                            type="file"
                            id="file1"
                            title="Upload"
                            onChange={(e) => handleImageUploadOnChange(e)}
                            hidden
                        />
                        <label for="file1" className="img-upload">
                            upload cover photo<sup className='star-red'>*</sup>
                        </label>
                        {imgObject &&
                            <img src={imgObject} alt="" width={40} height={40} style={{ marginLeft: "10px" }} />}

                    </span>
                </div>
                <div className="row">
                    <div className="col-lg-10 col-md-9 col-8">
                        <div className="form-group">
                            <label>Language<sup className='star-red'>*</sup></label>

                            <Select
                                value={language}
                                placeholder="Select"
                                name="colors"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'grey' : '#403c3c',
                                        backgroundColor: state.isSelected ? 'grey' : 'transparent',
                                    }),

                                }}
                                className="select-language basic-multi-select"
                                classNamePrefix="select"
                                options={languageList.map(item => ({
                                    value: item.id,
                                    label: item.name,
                                }))}
                                onChange={(e) => { languageSpeechHandle(e) }}
                            />

                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-4 microPhone">
                        <div>
                            {!isListening ? (
                                <FaMicrophone onClick={() => startListening(transcript)} disabled={isListening} title='Click to Start Listening' />

                            ) : null}

                            {isListening ? (
                                // Show the recording symbol when listening
                                <FaSpinner title='Recording' />
                            ) : null}
                            {isListening ? (
                                <FaStop onClick={() => stopListening(transcript)} disabled={isListening} title='Click to Stop Listening' />) : null}


                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Description<sup className='star-red'>*</sup></label>
                    <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorState={editorState}
                        onEditorStateChange={handleEditorState}
                        placeholder='Please enter your text'
                    />
                </div>
                <div className="row">
                    {/* <div className="col-md-6">   <div className="form-group">
                        <label>Language<sup className='star-red'>*</sup></label>

                        <Select
                            value={language}
                            placeholder="Select"
                            name="colors"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : '#403c3c',
                                    backgroundColor: state.isSelected ? 'grey' : 'transparent',
                                }),

                            }}
                            className="select-language basic-multi-select"
                            classNamePrefix="select"
                            options={languageList.map(item => ({
                                value: item.id,
                                label: item.name,
                            }))}
                            onChange={(e) => {
                                setLanguage(e)
                            }}
                        />

                    </div>
                    </div> */}
                    <div className="col-md-6">  <div className="form-group">
                        <label>Status<sup className='star-red'>*</sup></label>
                        <Select
                            value={status}
                            placeholder="Select"
                            name="colors"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : '#403c3c',
                                    backgroundColor: state.isSelected ? 'grey' : 'transparent',
                                }),

                            }}
                            className="select-language basic-multi-select"
                            classNamePrefix="select"
                            options={statusList.map(item => ({
                                value: item.id,
                                label: item.name,
                            }))}
                            onChange={(e) => {
                                setStatus(e)
                            }}
                        />
                    </div></div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Type<sup className='star-red'>*</sup></label>
                            <Select
                                value={types}
                                placeholder="Select"
                                name="colors"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'grey' : '#403c3c',
                                        backgroundColor: state.isSelected ? 'grey' : 'transparent',
                                    }),

                                }}
                                className="select-language basic-multi-select"
                                classNamePrefix="select"
                                options={typesList.map(item => ({
                                    value: item.id,
                                    label: item.name,
                                }))}
                                onChange={(e) => {
                                    setTypes(e)
                                }}
                            />
                        </div></div>
                    <div className="col-md-6"><div className="form-group">
                        <label>Categories<sup className='star-red'>*</sup></label>

                        <Select
                            isMulti
                            value={category}
                            placeholder="Select"
                            name="colors"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : '#403c3c',
                                    backgroundColor: state.isSelected ? 'grey' : 'transparent',
                                }),

                            }}
                            className="select-language basic-multi-select"
                            classNamePrefix="select"
                            options={categoryList.map(item => ({
                                value: item.id,
                                label: item.name,
                            }))}
                            onChange={(e) => {
                                setCategory(e)
                            }}
                        />

                    </div></div>
                </div>
                <div className=' actionsBtn'>
                    <span onClick={() => handleWriteToFile()}>Save</span>
                    <span className='btnCanceled' onClick={() => handleCancel()}>Cancel</span>
                </div>
            </div>

            <Footer />
        </div>
    )

}
export default WriteHere