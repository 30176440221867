import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header";
import { FaBell, FaEnvelope } from "react-icons/fa";
import userImg from "../../images/userImg.jpg"
import DashboardTabs from "./tabs";
import Chats from "./chats";
export default function Dashboard() {
    const [navigateBookList, setNavigateBookList] = useState("")
    const [userIdBookList, setUserIdBookList] = useState("")
    useEffect(() => {
       
        const handlePopstate = () => {
            // Reset the state to the current URL to prevent navigating back
            window.history.pushState(null, null, document.URL);
        };

        // Set the initial state in the browser history
        window.history.pushState(null, null, document.URL);

        // Add an event listener for the popstate event (triggered when navigating back or forward)
        window.addEventListener("popstate", handlePopstate);

        // Clean up event listener when the component unmounts
        return () => {
            window.removeEventListener("popstate", handlePopstate);
        };
    }, [])
    return (
        <>
            <div className="WrapLeft">
                <Header />
                {/* <div className="dashboardContent">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="dashWelcome">
                                <span className="dashWelcTxt">
                                    <div className="uImg">
                                        <img src={userImg} className="userImgs" />
                                    </div>
                                    <span><h2>Welcome back, Brian!</h2>
                                        <p> <FaBell /> You have 2 new messages and 15 new tasks</p></span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4 dashClmRight">
                            <div className="dashAction">
                                <button className="btnNavy"><FaEnvelope /> Messages</button>
                                <button className="btnBlue"><FaEnvelope />  Settings</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="bkBgColor">
                    <div className="tabContent">
                        <DashboardTabs navigateBookList={navigateBookList} setNavigateBookList={setNavigateBookList} userIdBookList={userIdBookList} />
                    </div>
                </div>
            </div>
            <Chats setNavigateBookList={setNavigateBookList} setUserIdBookList={setUserIdBookList} />
        </>
    )
}