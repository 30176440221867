import React, { useState } from 'react';
import Logo from "../../images/awesomepen-white.png"
import { Link } from 'react-router-dom';
function ResetHeader() {
    const showHome = () => {
        window.location.href="/"
    };
    return (
        <header className="header-home">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3">
                        <img src={Logo} alt="Logo" className='img-fluid logoimg' onClick={showHome} />
                    </div>
                </div>
            </div>
        </header>
    )

}
export default ResetHeader