import React, { useState, useEffect, Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import bookImg from "../../src/images/category-filter-img-01.jpg"
import bookImg2 from "../../src/images/category-filter-img-02.jpg"
import bookImg3 from "../../src/images/category-filter-img-03.jpg"
import bookImg4 from "../../src/images/category-filter-img-04.jpg"
import { FaArrowRight } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
function LatestRelease(props) {
    const navigate = useNavigate();
    const [list, setList] = useState([])

    useEffect(() => {
        setList(props.latestReleaseData)
    }, [])
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };
    const goToComponentB = (userID) => {
        navigate('/star-writer', { state: { userID: userID } });
        window.scrollTo({top:0,behavior:'smooth'});
    };
    const goToBookDetails = (userID) => {
        navigate('/book-detail', { state: { userID: userID } });
        window.scrollTo({top:0,behavior:'smooth'});
    };
    return (
        <div className="section-topbBook section-latestRelease">
            <div className="container">
                <div className="headdiv-sec">
                    <h3 className="mainHeadText mainHeadText-white">Latest Release</h3>
                    <span className="underline center"></span>
                </div>
                <ul>
                    <Slider {...settings}>
                        {list.map((data) =>
                            <div className="position-relative li">
                                <img src={data.bookImageWithPath} alt="" className='img-fluid' />
                                <span className='bookNameReleased'>{data.title}</span>
                                <div className="bg-latest-release">
                                    <div className="info-block">
                                        <h4 className='texthead'>{data.title}</h4>
                                        <span className="author">
                                            <span className='strong-text'>Author :  </span>
                                            {/* <Link to={{ pathname: "/star-writer", state: { authorName: data.authorName } }} onClick={() => goToComponentB(data)}className='readmore'>
            {data.authorName}
          </Link> */}
                                            <span onClick={() => goToComponentB(data.authorId)} className='readmore'>{data.authorName} </span>
                                            {/* <span className='strong-text'>Author:</span> {data.authorName} */}
                                        </span>
                                        {data.episodeCount>0 &&
                                        <span className="author">
                                            <span className='strong-text'>Epsisodes :</span> {data.episodeCount}
                                        </span>}
                                        <div className="rating">
                                        </div>
                                        <span onClick={() => goToBookDetails(data.bookId)} className='readmore'>Read More <FaArrowRight /></span>
                                    </div>
                                </div>
                               
                            </div>)}
                    </Slider>
                </ul>
            </div>
        </div>
    )

}
export default LatestRelease