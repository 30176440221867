import React, { useState, useEffect, Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import bookImg from "../../src/images/category-filter-img-01.jpg"
import bookImg2 from "../../src/images/category-filter-img-02.jpg"
import bookImg3 from "../../src/images/category-filter-img-03.jpg"
import bookImg4 from "../../src/images/category-filter-img-04.jpg"
import { FaArrowRight, FaLayerGroup, FaStar } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
function TopBookList(props) {
    const navigate = useNavigate();
    const [list, setList] = useState([])

    useEffect(() => {
        setList(props.topTenData)
    }, [])
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1000
    };
    const goToBookDetails = (userID) => {
        // if(window.localStorage.getItem("userId") !=null){
        navigate('/book-detail', { state: { userID: userID } });
        window.scrollTo({top:0,behavior:'smooth'});
    };
    return (
        <div className="section-topbBook">
            <div className="container">
                <div className="headdiv-sec">
                    <h3 className="mainHeadText">Top <span className='color-text'>10</span> This Week</h3>
                    <span className="underline center"></span>
                </div>

                <ul className='text-center'>
                    {list.slice(0, 10).map((data) =>
                        <>
                            <li onClick={() => goToBookDetails(data.bookId)} className='list-top' style={{ cursor: 'pointer' }}>
                                <div className="position-relative shine-overlay">
                                    <img src={data.bookImageWithPath} alt="" className='img-fluid' />
                                    <div class="shine"></div>
                                    {data.avgRating > 0 &&
                                        <div className="rating-div" title='Rating'><FaStar /><span className='rating-div-text' title='Rating'>{parseFloat(data.avgRating.toFixed(1))}</span></div>}
                                    {/* <div className="bg-topBook">
                                        <span className="info-block"  >
                                            <h4 className=''>{data.title}</h4>
                                        </span>
                                    </div> */}
                                    {data.episodeCount > 0 &&
                                        <div className="episode-partText" title='Episodes'>
                                            <FaLayerGroup /> {data.episodeCount}
                                        </div>}
                                </div>
                                <div className="booklist-content-top" title={data.title}>
                                    <h3>{data.title}</h3>
                                </div>

                            </li>

                        </>
                    )}


                </ul>



            </div>
        </div>
    )

}
export default TopBookList