import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import Book1 from "../images/860295_1704701727805.jpg"
import yourVideo from '../images/istockphoto-1395058135-640_adpp_is.mp4';
import { callingAPI } from '../service';
import { useNavigate } from 'react-router-dom';
function Language() {
    const [list, setList] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const PostData = {


        }
        callingAPI('book/topCategories', PostData).then((response) => {
            console.log(("...............................ststus", response));
            if (response.data.success == 1) {
                setList(response.data.topCategories)
            }
            else {
                setList([])
            }


        })
    }, [])
    const handleBookList = (categoryName) =>{
        navigate('/books', { state: { categoryName: categoryName } });
    }
    return (
        <div className="language-section">
            <div className="container">
                <div className="languageSection-box">
                    <video autoPlay loop muted>
                        <source src={yourVideo} type="video/mp4" />
                    </video>
                    <div class="overlay">
                    </div>
                    <div className="lang-border">
                        <h3 className='languageSelect-mainHead'>
                            <span> Top Categories</span>
                            <span className="underline center"></span>
                        </h3>
                        <div className="d-flex justify-content-center lang-wraper-scroll">
                            {list && list.slice(0, 5).map((data) =>
                                <span onClick={() => handleBookList(data.categoryname)}>
                                    <div className="lang-box shine-overlay">
                                        <img src={data.categoryImage} alt="" className='img-fluid' />
                                        <div class="shine"></div>
                                    </div>
                                    <div className="lang-category-text">{data.categoryname}</div>

                                </span>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Language