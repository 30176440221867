import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PublishedBooksAnalytics = (props) => {
  const [series] = useState([
    {
      name: 'Book Count',
      type: 'column',
      data: props.yearMonthResult.map((obj) => obj.bookCount)
    },

  ]);

  const [options] = useState({
    chart: {
      height: 350,
      type: 'line',
      colors: ['#a4b1c3'],
      stroke: {
        width: [0, 2],
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#a4b1c3', 'grey'],
    stroke: {
      width: [0, 2],
    },
    xaxis: {
      categories: props.yearMonthResult.map((obj) => obj.YearAndMonth),
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    yaxis: {
      min: 0,
      max: Math.max(...props.yearMonthResult.map((obj) => obj.bookCount)) + 5,
    }
  });

  return (
    <div id="chart">
      <ReactApexChart key={props} options={options} series={series}
        //  type="bar"
        type="line"
        height={350} />
    </div>
  );
};

export default PublishedBooksAnalytics;