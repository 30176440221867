import React, { useEffect, useState } from "react";
import { callingAPI } from '../../service';
import { FaClock, FaBookReader, FaRegTimesCircle, FaStar, FaUser, FaBookOpen, FaCommentAlt, FaLanguage } from "react-icons/fa";
import moment from 'moment';
import Pagination from "react-js-pagination";
import Select from "react-select";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NoData from "../nodatafound/noDataFound";
export default function ApprovedBookList(props) {
    const [newArray, setNewArray] = useState([
        // {
        //     value: "",
        //     label: "Select",
        // },
        {
            value: "4",
            label: "Un-Approve",
        }]);
    const [popupDesc, setPopupDesc] = useState(false);
    const [bookList, setBookList] = useState([]);
    const [selectedSiteId, setSelectedSiteId] = useState(null);
    const [popupView, setPopupView] = useState(false);
    const [selectedSiteIds, setSelectedSiteIds] = useState([]);  // Use an array for multiple Select components
    const [popUpBookList, setPopUpBookList] = useState([]);
    const [popUpBookListIndex, setPopUpBookListIndex] = useState([])
    const [reason, setReason] = useState("");
    const [searchValue, setSearchValue] = useState("")
    const [searchInput, setSearchInput] = useState("")
    const [searchStatus, setSearchStatus] = useState("")
    const [totalCount, setTotalCount] = useState("")
    const [activePage, setActivePage] = useState(1)

    // const [show, setShow] = useState(false);
    const [replyVisible, setReplyVisible] = useState(false);
    const [replySubVisible, setReplySubVisible] = useState(false);
    const [listFlag, setListFlag] = useState()
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    useEffect(() => {
        bookListing();
        setPopupView(false)
    }, [])
    const bookListing = () => {
        const PostData = {
            "offset": "0",
            "max": "10",
            "searchCmb": "", //bookName
            "searchInput": "",
            "statusId": "",

        };

        callingAPI('book/bookListsStatusWise', PostData).then((res) => {
            console.log("🚀 ~ file: approvedBookList.jsx:52 ~ callingAPI ~ res:", res)
            setBookList(res.data.result.bookLists);
            setTotalCount(res.data.result.totalBooksCount)


        })
    }
    const selectedValue = (selectedValue, dataItem, index) => {
        setSelectedSiteIds((prevSelectedSiteIds) => {
            const updatedSelectedSiteIds = [...prevSelectedSiteIds];
            updatedSelectedSiteIds[index] = selectedValue;
            return updatedSelectedSiteIds;
        });
        setPopUpBookListIndex(index)
        setPopUpBookList(dataItem);
        setPopupView(true);


    }
    const closePopup = () => {
        setPopupView(false);
        setSelectedSiteIds((prevSelectedSiteIds) => {
            const updatedSelectedSiteIds = [...prevSelectedSiteIds];
            updatedSelectedSiteIds[popUpBookListIndex] = null;
            return updatedSelectedSiteIds;
        });
    }
    const popUpFlagHandle = () => {
        const postData = {
            "book_id": popUpBookList.id,
            "status_id": selectedSiteIds[popUpBookListIndex]?.value,
            // "createdOn": popUpBookList.created_on,
            "createdOn": new Date().getTime(),
            "user_id": popUpBookList.user_id,
            "status_comment": reason
        };
        setPopupView(false);
        callingAPI('book/approveAndUnapproveBook', postData).then(() => {
            bookListing();
            setSelectedSiteIds((prevSelectedSiteIds) => {
                const updatedSelectedSiteIds = [...prevSelectedSiteIds];
                updatedSelectedSiteIds[popUpBookListIndex] = null;
                return updatedSelectedSiteIds;
            });
        });
    };

    const closePopupbooks = (dataIndex) => {
        setPopupDesc(false);
        document.getElementById('demo-modal' + dataIndex).style.display = 'none'
    }
    const descriptionData = (dataIndex) => {
        document.getElementById('demo-modal' + dataIndex).style.display = 'flex'
    }

    const handleReplyShow = () => {
        setReplyVisible(true);
    };

    const handleReplyHide = () => {
        setReplyVisible(false);
    };
    const handleSubReplyShow = () => {
        setReplySubVisible(true);
    };

    const handleSubReplyHide = () => {
        setReplySubVisible(false);
    };
    const searchHandleClick = () => {
        const PostData = {

            "searchCmb": searchValue,
            "searchInput": searchInput,
            "statusId": searchStatus,
            "offset": "0",
            "max": ""
        }
        console.log("🚀 ~ file: approvedBookList.jsx:145 ~ searchHandleClick ~ PostData:", PostData)
        callingAPI('book/bookListsStatusWise', PostData).then((res) => {
            setBookList(res.data.result.bookLists);
            setTotalCount(res.data.result.totalBooksCount)
        })
    }
    const clearHandleClick = () => {
        setSearchValue("")
        setSearchInput("");
        setSearchStatus("");
        bookListing();
        setActivePage("1")
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = 10;
        } else {
            offset =
                parseInt(pageNumber - 1) * 10;
            max = 10;
        }
        setActivePage(pageNumber);
        const PostData = {
            "offset": offset,
            "max": max,
            "searchCmb": searchValue, //bookName
            "searchInput": searchInput,
            "statusId": searchStatus,

        };

        callingAPI('book/bookListsStatusWise', PostData).then((res) => {
            console.log("🚀 ~ file: approvedBookList.jsx:52 ~ callingAPI ~ res:", res)
            setBookList(res.data.result.bookLists);
            setTotalCount(res.data.result.totalBooksCount)
            if (res.data.result.bookLists.length === 0) {
                setListFlag(true)
            }
            else {
                setListFlag(false)
            }

        })
    };
    return (
        <>
            <div className="">
                {/* <div class="scheduleListNew" id="style-1">
                    <div className="row">
                        <div className="col-md-3">
                            <select name="" id="" className="form-control" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}>
                                <option value="">Select</option>
                                <option value="bookName">Book Name</option>
                                <option value="authorName">Author Name</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                        </div>
                        <div className="col-md-2">
                            <select name="" id="" className="form-control" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                <option value="">Status</option>
                                <option value="1">Draft</option>
                                <option value="2">Approved</option>
                                <option value="3">UnApproved</option>
                            </select>
                        </div>
                        <div className="col-md-2 d-flex">
                            <button className="btn-review" style={{ marginRight: "10px" }} onClick={() => searchHandleClick()}>Search</button>
                            <button className="btn-review searchbtn-cancel" onClick={() => clearHandleClick()}>Cancel</button>
                        </div>
                    </div>
                    <table className="requestTable">
                        <thead>
                            <tr className="row">
                                <th className="col-md-6">Book Details</th>
                                <th className="col-md-2">Published on</th>
                                <th
                                    className="col-md-4"
                                    style={{ textAlign: "right", display: "flex", justifyContent: "center" }}
                                >Action</th>
                            </tr>
                        </thead>
                        <tbody id="style-1">
                            {bookList.map((data, index) =>
                                <tr

                                >
                                    <td data-column="Image" className="col-md-6">
                                        <div className="draftCon">
                                            <div style={{ position: "relative" }}>
                                                <img src={data.bookImageWithPath} className="categoryImg" onClick={() => props.handleDetailClick({ bookId: data.bookId })} />


                                                {data.rating !== "" || data.rating !== null || data.rating !== "null" &&
                                                    <span className="starRatingCountTbl">
                                                        <FaStar style={{ fontSize: "12px" }} />&nbsp;
                                                        {data.rating}
                                                    </span>
                                                }
                                                {data.totalEpisodeCount > 0 &&
                                                    <span className="episodesCount"> <FaBookOpen style={{ fontSize: "15px", color: "#fff" }} /> {data.totalEpisodeCount} episodes</span>
                                                }
                                            </div>
                                            <span>
                                                <p><b>{data.title}</b></p>
                                                <p><FaUser style={{ fontSize: "15px", color: "#363636" }} /> {data.authorName}</p>
                                                <p><FaClock style={{ fontSize: "15px", color: "#363636" }} /> {data.duration}</p>
                                                <p><FaBookReader style={{ fontSize: "15px", color: "#363636" }} /> {data.no_of_views} Views</p>
                                                <p><FaCommentAlt style={{ fontSize: "15px", color: "#363636" }} /> {data.no_of_reviews} Reviews</p>
                                                <p><FaLanguage style={{ fontSize: "20px", color: "#363636" }} /> {data.languageName}</p>
                                            </span>
                                        </div>
                                    </td>


                                    <td data-column="created on" className="col-md-2 dflexAlignCenter">{moment(data.created_on).format('DD-MM-YYYY')}</td>
                                    <td data-column="Action" className="col-md-4 dflexAlignCenterEnd" style={{ justifyContent: "center" }}>


                                        <div className="">
                                            {data.totalDraftEpisodeCount > 0 ? <div className="status-btn status-draftbtn" onClick={() => props.handleDetailClick({ bookId: data.bookId, statusId: "1" })}>Draft Episode : {data.totalDraftEpisodeCount}</div> : ""}
                                            {data.totalApprovedEpisodeCount > 0 ? <div className="status-btn status-approvedbtn" onClick={() => props.handleDetailClick({ bookId: data.bookId, statusId: "3" })}>Approved Episode : {data.totalApprovedEpisodeCount}</div> : ""}
                                            {data.totalUnApprovedEpisodeCount > 0 ? <div className="status-btn status-unapprovedbtn" onClick={() => props.handleDetailClick({ bookId: data.bookId, statusId: "4" })}>UnApproved Episode : {data.totalUnApprovedEpisodeCount}</div> : ""}
                                        </div>
                                        <button className="btn-review" variant="primary" onClick={() => props.handleDetailClick({ bookId: data.bookId })}  >View Details</button>
                                    </td>


                                </tr>
                            )}
                        </tbody>
                    </table>

                    {totalCount > 0 ?
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                        : ""}
                </div> */}
                {bookList && bookList.length > 0 &&
                    <div className="book-list-outer-bg">

                        <>
                            <div className="row booklist-headersec">
                                <div className="col-md-3">
                                    <select name="" id="" className="form-control" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="bookName">Book Name</option>
                                        <option value="authorName">Author Name</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                </div>
                                <div className="col-md-2">
                                    <select name="" id="" className="form-control" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                        <option value="">Status</option>
                                        <option value="1">Draft</option>
                                        <option value="3">Approved</option>
                                        <option value="4">UnApproved</option>
                                    </select>
                                </div>
                                <div className="col-md-2 d-flex">
                                    <button className="btn-review" style={{ marginRight: "10px" }} onClick={() => searchHandleClick()}>Search</button>
                                    <button className="btn-review searchbtn-cancel" onClick={() => clearHandleClick()}>Clear</button>
                                </div>
                            </div>


                            {bookList.map((data, index) =>
                                <div className="booklist-wrapper">
                                    <div className="booklist-leftSec">
                                        <div className="booklist-img-div">
                                            <div style={{ position: "relative" }}>
                                                <img src={data.bookImageWithPath} className="categoryImg" onClick={() => props.handleDetailClick({ bookId: data.bookId })} />


                                                {data.rating !== "" || data.rating !== null || data.rating !== "null" &&
                                                    <span className="starRatingCountTbl">
                                                        <FaStar style={{ fontSize: "12px" }} />&nbsp;
                                                        {data.rating}
                                                    </span>
                                                }
                                                {data.totalEpisodeCount > 0 &&
                                                    <span className="episodesCount"> <FaBookOpen style={{ fontSize: "15px", color: "#fff" }} /> {data.totalEpisodeCount} episodes</span>
                                                }
                                            </div>
                                        </div>
                                        <span className="book-list-detailsec">
                                            <p className="book-list-headText" title={data.title} onClick={() => props.handleDetailClick({ bookId: data.bookId })}><b>{data.title}</b></p>
                                            <div className="">
                                                <p><FaUser style={{ fontSize: "15px", color: "#363636" }} /> {data.authorName}</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p><FaClock style={{ fontSize: "15px", color: "#363636" }} /> {data.duration}</p>
                                                <p><FaBookReader style={{ fontSize: "15px", color: "#363636" }} title="Views" /> {data.no_of_views} Views</p>
                                                <p><FaCommentAlt style={{ fontSize: "15px", color: "#363636" }} title="Reviews" /> {data.no_of_reviews} Reviews</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p><FaLanguage style={{ fontSize: "20px", color: "#363636" }} /> {data.languageName}</p>
                                                <p>{moment(data.created_on).format('DD-MM-YYYY')}</p>
                                            </div>

                                        </span>
                                    </div>
                                    <div className="book-episode-sections">
                                        {data.totalDraftEpisodeCount > 0 ? <div className="status-btn status-draftbtn" onClick={() => props.handleDetailClick({ bookId: data.bookId, statusId: "1" })}>Draft Episode : {data.totalDraftEpisodeCount}</div> : ""}
                                        {data.totalApprovedEpisodeCount > 0 ? <div className="status-btn status-approvedbtn" onClick={() => props.handleDetailClick({ bookId: data.bookId, statusId: "3" })}>Approved Episode : {data.totalApprovedEpisodeCount}</div> : ""}
                                        {data.totalUnApprovedEpisodeCount > 0 ? <div className="status-btn status-unapprovedbtn" onClick={() => props.handleDetailClick({ bookId: data.bookId, statusId: "4" })}>UnApproved Episode : {data.totalUnApprovedEpisodeCount}</div> : ""}
                                    </div>
                                    {data.status_id === 3 ? (
                                        <div className="tag approved-tag">
                                            Approved
                                        </div>
                                    ) : data.status_id === 1 ? (
                                        <div className="tag draft-tag">
                                            Draft
                                        </div>
                                    ) : (
                                        <div className="tag unapproved-tag">
                                            Unapproved
                                        </div>
                                    )}

                                </div>
                            )}
                        </>
                    </div>
                }


                {listFlag &&
                    <NoData />
                }


                {totalCount > 0 ?
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                    : ""}


            </div>
            {/* =============== MODAL BEGINS HERE =============== */}
            <div id="demo-modal" class="modal" style={{ display: popupView === true ? "flex" : "none" }} >
                <div class="modal__content">
                    <h3 >Reason For Un-Approve</h3>
                    <div className="modalFields">

                        <div className="form-group">
                            <label htmlFor="">Reason <span>:</span> </label>
                            <textarea
                                className="form-control"
                                id='statusComment'
                                onChange={(e) => setReason(e.target.value)}
                                value={reason}
                            />
                        </div>

                    </div>
                    <div class="modal__footer">
                        <div className="dashAction">
                            <a
                                onClick={() => popUpFlagHandle()}
                                id="saveBtn" className="btnNavy"  >  Ok</a>
                        </div>
                    </div>
                    <a onClick={() => closePopup()} class="modal__close"> <FaRegTimesCircle /></a>
                </div>
            </div>
        </>
    )
}
