import React from "react";
import Header from "../../components/layouts/header";
import { FaEdit, FaEnvelope, FaHome, FaPhoneAlt, FaUser, FaUserCircle } from "react-icons/fa";
import Chats from "../dashboard/chats";
export default function Form() {
    return (
        <>
            <div className="WrapLeft">
                <Header />
                <div className="dashboardContentInner">
                    <div className="mainHead txtcenter">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Form</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tabContentsWrap">
                    <div className="issueSummWrap heightAuto">
                        <div className="fromWrap">
                            <div className="form-group dflexcenter">
                                <span><FaUser /> </span>
                                <input type="text" className="form-control" placeholder="Username" />
                            </div>
                            <div className="form-group dflexcenter">
                                <span><FaUserCircle /> </span>
                                <input type="text" className="form-control" placeholder="First Name" />
                                <span style={{ visibility: "hidden" }}> </span>
                                <input type="text" className="form-control" placeholder="Last Name" />
                            </div>
                            <div className="form-group dflexcenter">
                                <span><FaEnvelope /> </span>
                                <input type="text" className="form-control" placeholder="Email" />
                            </div>
                            <div className="form-group dflexcenter">
                                <span><FaPhoneAlt /> </span>
                                <input type="text" className="form-control" placeholder="Cell Phone Number" />
                                <span style={{ visibility: "hidden" }}> </span>
                                <input type="text" className="form-control" placeholder="Home Phone Number" />
                            </div>
                            <div className="form-group dflexcenter">
                                <span><FaHome /> </span>
                                <input type="text" className="form-control" placeholder="Address" />
                            </div>
                            <div className="  dashClmRight">
                                <div className="dashAction">
                                    <a className="btnNavy"  >  Submit</a>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Chats />
        </>
    )
}
