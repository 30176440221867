
import React, { useEffect } from 'react';
import Header from "../Home/layout/header";
import Footer from "../Home/layout/footer"
import bannerImg from "../images/bannerImg.jpg"
import ceo from "../images/ceo.jpeg"
import userMen from "../images/userMen.jpg"
import userWomen from "../images/userWomen.png"

const OurTeam = () => {
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <>
            <div className="WrapLeft loginWrap termsPolicyWrap">
                <Header />
                <div className="innerImgs">
                    <img src={bannerImg} />
                </div>
                <div className="container divOverlap">
                    <h3 className='languageSelect-mainHead'>
                        <span> Our Team</span>
                        <span className="underline center"></span>
                    </h3>
                    <br className="brTag" />
                    <p><span className='aboutTxt'>K</span>rithy’s technology partner is Xilligence Technology Private Limited,  that has built and supports Krithy’s apps and software. Xilligence was founded in 2000 in Toronto, Canada and has an impressive two-decade track record of successfully designing, developing, delivering, and supporting software and technology solutions around the world: from the US and Canada to Europe & Africa, to Japan & Southeast Asia to India and South Asia. Xilligence has offices in Canada, India, UAE and Singapore with its global delivery centre headquartered in Thiruvananthapuram, Kerala. XIlligence will ensure that Krithy will have a world-class technology platform for its readers, writers, clients and partners.</p>
                    <br />
                    <h3 className='languageSelect-mainHead'>
                        <span> Krithy Team</span>
                        <span className="underline center"></span>
                    </h3>
                    <br className="brTag1" />
                    {/* =========================== */}
                    <div class="services-content services-carousel tp-pl30 tp-pl-zm0">

                        <div className="row">
                            <div className="col-lg-2 col-md-6  ">
                                <div class="item">
                                    <div class="service-box">
                                        <div class="img-thumb">
                                            <img src={ceo} alt="" />
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-10 col-md-6  ">
                                <div class="overlay">
                                    <p>The content team is managed by Sabitha Riyas. She is a content writer with 8 year's  experience  in related sector. She supervise the content creating & marketing team. and also get involved in coming up with new ideas and cooperate with more junior members of the team to create more valuable contents that people love and want to share.</p>
                                    <div class="content1">
                                        <h4 class="title"> Sabitha Riyas
                                        </h4>
                                        <span className='aweDesignation'>CEO</span>
                                    </div>

                                </div>
                            </div>

                            
                        </div>

                        <div className="row">
                            <div className="col-lg-10 col-md-6 order1">
                                <div class="overlay">
                                    <p>Vikram Nair is a Canadian entrepreneur and Executive Director. He has 20+ years of experience working with technology companies in leadership, strategy and product design roles in North America and India. In addition, as an entrepreneur, he has helped found multiple start-ups. He believes in the ability of technology to empower people, bring them closer together and change the world for the better. Vikram believes that Krithy will be one of the world’s leading creative fiction platforms and is excited to help Krithy achieve it’s great potential.</p>
                                    <div class="content1 text-right">
                                        <h4 class="title"> Vikram Nair
                                        </h4>
                                        <span className='aweDesignation text-right'>Executive Director</span>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 order2">
                                <div class="item">
                                    <div class="service-box">
                                        <div class="img-thumb">
                                            <img src={userMen} alt="" />
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row">
                        <div className="col-lg-2 col-md-6  ">
                                <div class="item">
                                    <div class="service-box">
                                        <div class="img-thumb">
                                            <img src={userMen} alt="" />
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-10 col-md-6  ">
                                <div class="overlay">

                                    <p>
                                        Prakash Sathyapalan is Krithy’s CTO. He has worked for leading technology companies like SAS Institute before moving to Silicon Valley for 15 years where he worked in senior roles for blue-chip tech companies like Ebay (StubHub), IBM (Cognos) and others, before moving back to India and setting up Ernst & Young’s business intelligence unit. He has over 20 plus years experience in software development, design, Data Science and Business Intelligence. Prakash holds a Master‘s degree in Computer Science. Prakash brings world class technology experience and leadership to Krithy.</p>                                    
                                        <div class="content1  ">
                                        <h4 class="title"> Prakash Sathyapalan
                                        </h4>
                                        <span className='aweDesignation '>CTO</span>
                                    </div>

                                </div>
                            </div>
                       
                        </div>
                        <div className="row">
                            <div className="col-lg-10 col-md-6 order1">
                                <div class="overlay">
                                    <p>Mini Balaraman is Krithy’s COO.  Mini returned to India after 14 years in the US in Silicon Valley, having worked at Blue Shield of California and Sun Microsystems (now Oracle) in various technical roles, from software development to Business Intelligence. She combines a unique blend of client-facing experience and strong technical knowledge. Mini holds a Master‘s degree in Computer Applications. Mini brings her unique combination of technology and operational skills to help Krithy operate at peak performance.
                                    </p>

                                    <div class="content1 text-right">
                                        <h4 class="title">Mini Balaraman
                                        </h4>
                                        <span className='aweDesignation text-right'>COO</span>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 order2">
                                <div class="item">
                                    <div class="service-box">
                                        <div class="img-thumb">
                                            <img src={userWomen} alt="" />
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                           
                            <div className="col-lg-2 col-md-6  ">
                                <div class="item">
                                    <div class="service-box">
                                        <div class="img-thumb">
                                            <img src={userWomen} alt="" />
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-10 col-md-6  ">
                                <div class="overlay">
                                    <p>
                                        The technology team behind Krithy is led by Divya Jose who is the Director of technology. She has nearly 20 years of high level technology experience working on software projects that span multiple technologies and companies of all types: from enterprise businesses to start-ups and across many industry verticals. She and her team are the key players behind Krithy’s technology engine.</p>


                                    <div class="content1  ">
                                        <h4 class="title">Divya Jose
                                        </h4>
                                        <span className='aweDesignation  '>Director of Technology
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default OurTeam;