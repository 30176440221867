import React, { useEffect, useState } from "react";
import { FaComments, FaPowerOff } from "react-icons/fa";
import userImg from "../../images/userImg.jpg"
import send from "../../images/send.png"
import $ from "jquery"
import { callingAPI } from '../../service'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate, useParams } from 'react-router-dom';
export default function Chats(props) {
    const navigate = useNavigate()
    const [writers, setWriters] = useState([]);
    const [writerChat, setWritersChat] = useState([]);
    useEffect(() => {
        writersListing();
        // Chat box sidebar 
        // $('.close-sidebar').on('click', function () {
        //     $(this).toggleClass('open');
        //     $('.sidebar').toggleClass('closed');
        // })
        // $('ul li').on('click', function () {
        //     $('li').removeClass('active');
        //     $(this).addClass('active');
        // });

    }, []);

    const writersListing = () => {
        const PostData = {

        };
        callingAPI('getStarWriters', PostData).then((res) => {
            setWriters(res.data.result.starWriters);
            // setWritersChat(res.data.result.starWriters[0]);
            setWritersChat(res.data.result.starWriters)
        })

    }

    // ******* Time Out Function start **********

    const inactivityTimeout = 6 * 60 * 60 * 1000;
    let timeoutId;

    const resetTimer = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(logOut, inactivityTimeout);
    };

    useEffect(() => {
        const activityEvents = ['mousemove', 'keypress', 'click'];

        const handleUserActivity = () => {
            resetTimer();
        };

        activityEvents.forEach(event => {
            document.addEventListener(event, handleUserActivity);
        });

        resetTimer();

        return () => {
            activityEvents.forEach(event => {
                document.removeEventListener(event, handleUserActivity);
            });

            clearTimeout(timeoutId);
        };
    }, [timeoutId]);

    // ******* Time Out Function End **********

    const logOut = () => {
        window.localStorage.clear();
        window.sessionStorage.removeItem('userId');
        window.sessionStorage.removeItem('role');
        navigate('/')
        window.location.href = '/';
    }
    const starwriterDetails = (starWriterId) => {

        const postData = {
            "userId": starWriterId,
            "max": "10",
            "offset": "0",
            "cmbSearchType": "",
            "searchInput": ""
        };
        callingAPI('book/getbooks', postData).then((res) => {

        }).finally(() => {
            props.setNavigateBookList("bookList")
            props.setUserIdBookList(starWriterId)
        });
    };
    return (
        <>
            <div className="WrapRight">
                <div class="sidebar closed">
                    <a class="close-sidebar"  >
                        <span></span><span></span><span></span>
                        {/* <FaComments className="svgComments" /> */}
                        <FaPowerOff className="svglogout" style={{ color: "red" }} title="Logout" onClick={() => logOut()} />
                    </a>

                    <h3 className="sideHead">
                        <span>
                            {/* <img src={writerChat.profilePic} alt="" className="userImgsRightHead" /> */}
                        </span>
                        {/* <span className="online"></span>{writerChat.display_name} */}
                    </h3>

                    <nav class="sidebar-navigation">
                        <ul id="style-1">
                            <>
                                {writers.map((obj, i) => (
                                    <li class="active">
                                        <div onClick={() => starwriterDetails(obj.id)}>

                                            <span class="tooltip"> {obj.display_name} </span>
                                            <span> <img src={obj.profilePic} alt="" className="userImgsRight" />
                                                <span className="online"></span>
                                            </span>
                                        </div>
                                    </li>
                                ))}</>
                        </ul>
                    </nav>

                    <div className="chatSec">
                        <div class="separator">
                            <div class="line"></div>
                            <h2>November 10, 2023</h2>
                            <div class="line"></div>
                        </div>
                        <div class="app-wrapper">

                            <div class="app-message-body">
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>I woke up like 6am and fell back asleep until 9am which is the latest I've woken this week</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">SB</div>
                                        <span class="timestamp">7:32 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>What do you want? A medal?</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>I want two</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">SB</div>
                                        <span class="timestamp">7:32 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                                <div class="message-wrapper">
                                    <div class="message">
                                        <span>Ok I'll get you two. One says 9am and another says latest</span>
                                    </div>
                                    <div class="message-meta">
                                        <div class="sender-avatar">DH</div>
                                        <span class="timestamp">7:34 PM</span>
                                    </div>
                                </div>
                            </div>

                            <div class="app-input-area">
                                <input type="text" placeholder="Your message here..." />
                                <button type="submit">
                                    <img src={send} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}