import React, { useState, useEffect, Component } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../../service';
import $ from "jquery"
import Header from "../../components/layouts/header";
import { FaArrowLeft, FaEdit, FaTrashAlt, FaStar, FaCalendar, FaBookReader, FaClock, FaBookOpen, FaEye, FaUser, FaPlus, FaMicrophone } from "react-icons/fa";
import Chats from "../dashboard/chats";
import Edit from "../../images/edit.png"
import Delete from "../../images/deleteicon.png"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BookReview from './bookReview';
import EditBook from "../books/books-edit"
import BookEpisodes from './bookEpisodes';
import EpisodeReview from './episodeReview';
import Parser from 'html-react-parser';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { ToastContainer, toast } from 'react-toastify';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Select from "react-select";
import { convertToHTML } from 'draft-convert';

import AWS from 'aws-sdk';
// import { Rating } from 'react-simple-star-rating'
var s3

function BookDetail(props) {

    const [bookDetail, setBookDetail] = useState([]);
    const [replyVisible, setReplyVisible] = useState(false);
    const [replySubVisible, setReplySubVisible] = useState(false);
    const [editInputVisible, setEditInputVisible] = useState(false);
    const [data, setData] = useState([]);
    const [selectedBookEdit, setSelectedBookEdit] = useState(false);
    const [episodeClickParent, setepisodeClickParent] = useState(false);
    const [selectedBook, setSelectedBook] = useState(false);
    const [statusId, setStatusId] = useState("false");
    const [episodeID, setEpisodeID] = useState("");
    const [statusValue, setstatusValue] = useState("")
    const [bookStatus, setbookStatus] = useState([
        {
            value: "3",
            label: "Publish",
        },
        {
            value: "1",
            label: "Draft",
        }, {
            value: "4",
            label: "Un-Publish",
        }]);
    const [title, setTitle] = useState("");
    const [imgObject, setImgObject] = useState('');
    const [file, setFile] = useState('');
    const [imageName, setImageName] = useState('');
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML("")
            )
        ),
    );
    const [htmlcontent, setHtmlContent] = useState("");
    const [content, setContent] = useState("");
    const [status, setStatus] = useState([]);
    const [bookIdClick, setBookIdClick] = useState([]);
    const [environmentType, setEnvironmentType] = useState('');
    const [cofigDetails, setConfigDetails] = useState('')
    const [categoryImagePath, setCategoryImagePath] = useState('');
    useEffect(() => {
        console.log("Props.....................,", props.data.statusId);
        bookDetailInfo();
        getConfiguration();
        setStatusId(props.data.statusId)

        // =============================

        if (window.localStorage.getItem('role') !== "Admin") {
            $(window).scroll(function () {
                if ($(window).scrollTop() > 300) {
                    $('#buttonArrow').addClass('show');
                } else {
                    $('#buttonArrow').removeClass('show');
                }
            });

            $('#buttonArrow').on('click', function (e) {
                e.preventDefault();
                $('html, body').animate({ scrollTop: 0 }, '300');
            });
            $('#buttonArrowAdmin').on('click', function (e) {
                e.preventDefault();
                $('html, body').animate({ scrollTop: 0 }, '0');
            });
            
        }







        // ===============================
    }, [props.data.bookId]);

    const bookDetailInfo = () => {
        const PostData = {
            "bookId": props.data.bookId,
            "userId": window.localStorage.getItem("userId")
            // "userId": props.data.user_id
        };
        callingAPI('book/getBookDetails', PostData).then((response) => {
            if (response.data.success === '1') {
                setBookDetail(response.data.result);
            }

        })

    }

    const handleReplyShow = () => {
        setReplyVisible(true);
    };

    const handleReplyHide = () => {
        setReplyVisible(false);
    };
    const handleSubReplyShow = () => {
        setReplySubVisible(true);
    };

    const handleSubReplyHide = () => {
        setReplySubVisible(false);
    };
    const handleEditShow = () => {
        setEditInputVisible(true);
    };
    const handleEditHide = () => {
        setEditInputVisible(false);
    };
    const handleEditClick = (editData) => {
        setData(editData)
        setSelectedBookEdit(true);
    };
    const backToList = () => {
        setSelectedBook(false);
        setSelectedBookEdit(false);
        bookDetailInfo();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const changeStatus = (event, bookId) => {
        setstatusValue(event.value);
        const PostData = {
            "book_id": bookId,
            "status_id": event.value,// for publish - 3, unpublish - 1
            "createdOn": new Date().getTime(),
            "userId": window.localStorage.getItem("userId")
        };
        callingAPI('book/publishAndUnpublishBook', PostData).then((response) => {
            if (response.data.success === '1') {
                toast.success("Status changed successfully", { containerId: "M" })
                bookDetailInfo();
            } else {

            }

        })
    }
    const handleButtonAdd = (showFlag, id) => {
        document.getElementById("exampleModal").style.display = showFlag
        if (id) {
            setBookIdClick(id)
            setTitle("")
            setContent("")
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML("")
                )
            ),)
        }
        else {
            setBookIdClick('')
        }
    }
    const handleImageUploadOnChange = (e) => {
        if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
            toast.error("Please attach jpg,jpeg or png files", { containerId: "K" })
            return false;
        }
        setFile(e.target.files[0]);
        setImageName(e.target.files[0].name);
        const obj = URL.createObjectURL(e.target.files[0])
        setImgObject(obj);
    };
    const handleEditorState = (value) => {
        const rawData = convertToRaw(value.getCurrentContent());
        setEditorState(value);
        const htmlContent = convertToHTML(value.getCurrentContent());
        setHtmlContent(htmlContent);
        setContent(rawData.blocks.map(block => block.text).join("\n"));
    }


    const handleWriteToFile = async () => {
        if (!title) {
            toast.error("Please enter title", { containerId: "K" })
            return false
        }
        else if (!content) {
            toast.error("Please enter description", { containerId: "K" })
            return false
        }
        else if (status.length == 0) {
            toast.error("Please select status", { containerId: "K" })
            return false
        }
        else {
            const textContent = content;
            const blob = new Blob([textContent], { type: 'text/plain' });
            textFileS3Save(`book_${window.localStorage.getItem("userId")}_${new Date().getTime()}.txt`, blob);
        }
    };



    const handleSave = async (textFileData) => {

        try {
            var postData = {
                
                "episodeId": "",
                "title": title,
                "description": content,
                "description_without_html": content,
                "image": imageName,
                "createdOn": new Date().getTime(),
                "bookId": bookIdClick,
                "createdBy": window.localStorage.getItem("userId"),
                "statusId": status.value,
                "bookContentFileS3Url": textFileData.Location,
                
            }
            console.log("🚀 ~ handleSave ~ postData1:", postData)
            const response = await callingAPI('episodeData/episodeSave', postData);

            if (response.data.success == 1) {
                if (file.length !== 0) {
                    imageS3Save(imageName, file);
                }
                toast.success("Added Successfully", { containerId: "K" })
                handleButtonAdd("none")
                bookDetailInfo();
            }
        }
        catch (error) {
            console.error("Error:", error);
        }
    }


    const textFileS3Save = (imageName, blob) => {
        return new Promise((resolve, reject) => {
            try {

                var S3BucketMainFolder = environmentType + '/' + categoryImagePath;
                var fullFilePath = S3BucketMainFolder + '/' + imageName;

                s3.upload(
                    {
                        Key: fullFilePath,
                        Body: blob, // Upload the Blob object
                        ACL: 'public-read', // Optional: Set access control (consider security)
                    },
                    (err, data) => {
                        if (err) {
                            alert('There was an error uploading your photo: ' + err.message);
                            reject(err);
                        } else {
                            console.log("File uploaded to S3 successfully!", data);
                            resolve(true);
                            handleSave(data);
                        }
                    }
                );
            } catch (error) {
                console.error("Error uploading file to S3:", error);
                reject(error); // Propagate errors for handling in calling code
            }
        });
    };




    const imageS3Save = (imageName, file) => {
        return new Promise((resolve, reject) => {

            var S3BucketMainFolder = environmentType + '/' + categoryImagePath;
            var fullFilePath = S3BucketMainFolder + '/' + imageName;
            s3.upload(
                {
                    Key: fullFilePath,
                    Body: file,
                    ACL: 'public-read',
                },
                (err, data) => {
                    if (err) {
                        alert('There was an error uploading your photo: ' + err.message);
                        reject(err);
                    } else {
                        //  alert("File Uploaded Successfully")
                        resolve(data);
                        document.getElementById('uploadButton').style.display = 'block';

                    }
                }
            );
        })
    };
    const getConfiguration = () => {
        return new Promise((resolve, reject) => {
            try {
                const PostData = {
                };
                callingAPI('configurationData', PostData).then((res) => {
                    setConfigDetails(res.data.result[0]);
                    const getConfigValue = (configKey) => {
                        return res.data.result[0].configDetails
                            ? res.data.result[0].configDetails.find((config) => config.config_key === configKey)?.config_value
                            : null;
                    };
                    setEnvironmentType(getConfigValue("S3BucketMainFolder"));
                    setCategoryImagePath(getConfigValue("bookImagePath"));
                    AWS.config.update({
                        accessKeyId: getConfigValue("AWSAccessKeyId"),
                        secretAccessKey: getConfigValue("AWSSecretKey"),
                        region: getConfigValue("s3Region"),
                    });
                    var bucketParams = {
                        Bucket: getConfigValue("s3bucket"),
                    };
                    s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
                    resolve(s3)
                })

            }
            catch (e) {
                console.error("Error in getConfiguration", e)
                reject(e)
            }
        })


    }
    return (
        <>
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"M"}
                theme="colored" />
            {!selectedBookEdit &&
                <>
                    <div className=" booksList-Wrapper bookDetOLDDD myProfileBkDet bookDescrProf ">
                        {console.log(bookDetail)}
                        {bookDetail && bookDetail.map((obj, i) => (
                            <>
                                {console.log("ggggggggggggg", bookDetail)}
                                {!episodeClickParent && (
                                    <div className="bkBg">
                                        {console.log("......................ewrwerw", obj)}

                                        <div className="row">
                                            <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                                {window.localStorage.getItem('role') !== "Admin" ?
                                                    <div className="text-left backToLink">
                                                        <span className='' id="buttonArrow" onClick={props.backToList}><FaArrowLeft title='Back To List' /></span>
                                                    </div>
                                                    :
                                                   <div className="text-left backToLink">
                                                         <span className='' id="buttonArrowAdmin" onClick={props.backToList}><FaArrowLeft title='Back To List' /></span>
                                                    </div>
                                               }

                                                <div>
                                                    {/* <span title='Micro Phone'  >
                                                        <FaMicrophone className='editiconBlog' />
                                                    </span> */}
                                                    <span title='Edit'
                                                        onClick={() => handleEditClick(obj)}
                                                    >
                                                        <FaEdit className='editiconBlog' />
                                                    </span>
                                                    <span title='Add Episode' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleButtonAdd("block", obj.bookId)}>
                                                        <FaPlus className='editiconBlog' />
                                                    </span>
                                                </div>
                                            </span>
                                            <div className="col-md-3">
                                                <span style={{ position: "relative" }}>
                                                    <img src={obj.bookImageWithPath} alt="" className='img-fluid ' />
                                                </span>
                                            </div>
                                            <div className="col-md-9">
                                                <h3 className='main-bookHead text-left'>{obj.title}</h3>
                                                <div className="text-left dCenterBk"><FaUser style={{ fontSize: "17px", marginRight: "8px" }} /><b> {obj.authorName}</b><span className='vLine'>|</span><FaCalendar style={{ fontSize: "16px", marginRight: "8px" }} /> {new Date(obj.lastUpdatedDate).toLocaleString()}
                                                    <span className='select-status-sec'>
                                                        <Select
                                                            id="select-status" className="selectControl-status"
                                                            // value={statusValue}
                                                            placeholder="Change Status"
                                                            options={bookStatus.filter((status) => status.value !== obj.status_id.toString()).map((item) => ({
                                                                value: item.value,
                                                                label: item.label,
                                                            }))}
                                                            onChange={(e) => changeStatus(e, obj.bookId)}
                                                        />
                                                    </span>
                                                </div>
                                                {/* <div className=" text-left"><FaCalendar style={{ fontSize: "17px" }} /> {new Date(obj.lastUpdatedDate).toLocaleString()}</div> */}
                                                <div className="book-description text-left">{Parser(obj.description)}</div>
                                                {/* <div className="col-md-4">
                                                    {console.log("......................................ss",obj)}
                                                    <Select
                                                        id="select-status" className="selectControl-status"
                                                        // value={statusValue}
                                                        placeholder="Change Status"
                                                        options={bookStatus.filter((status) => status.value !== obj.status_id.toString()).map((item) => ({
                                                        value: item.value,
                                                        label: item.label,
                                                         }))}
                                                        onChange={(e) => changeStatus(e,obj.bookId)}
                                                   />
                                                   
                                                    </div> */}
                                                <p></p>
                                                <div className="row">
                                                    {obj.avgBookRating !== "" &&
                                                        <div className="col-md-2  ">
                                                            <span className="starRatingCountBkDetl111"  >
                                                                <FaStar style={{ fontSize: "17px", color: "#498507" }} />&nbsp;
                                                                <span style={{ color: "#498507" }}>{obj.avgBookRating.toFixed(1)}</span>
                                                                <p style={{ margin: "0px" }}>Rating</p>
                                                            </span>
                                                        </div>
                                                    }
                                                    {obj.noOfEpisodes > 0 &&
                                                        <div className="col-md-2">
                                                            <span className="episodesCountBkDet111">
                                                                <FaBookOpen style={{ fontSize: "17px", color: "#910303" }} /> &nbsp;
                                                                <span  >{obj.noOfEpisodes} </span>
                                                            </span>
                                                            <p style={{ margin: "0px" }}>Episodes</p>
                                                        </div>
                                                    }
                                                    <div className="col-md-3">
                                                        <div className=" text-left">
                                                            <FaClock style={{ fontSize: "17px", color: "#04ad68" }} />  &nbsp;
                                                            <span  >{obj.duration}</span>
                                                            <p style={{ margin: "0px" }}>Reading Time</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="pb-2 text-left">
                                                            <FaBookReader style={{ fontSize: "17px", color: "#03878f" }} /> &nbsp;
                                                            <span  >{obj.no_of_views} </span>
                                                            <p style={{ margin: "0px" }}>Read Count</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p></p>
                                    </div>
                                )}
                                <p></p>
                                {console.log(".....................................", obj)}
                                {obj.noOfEpisodes > 0 ?
                                    <BookEpisodes id={obj.bookId} statusId={statusId} setepisodeClickParent={setepisodeClickParent} setEpisodeID={setEpisodeID} backToList={props.backToList} /> :
                                    ""
                                }
                                {episodeClickParent == true && (
                                    <EpisodeReview episodeID={episodeID} />
                                )}
                                {!episodeClickParent && (
                                    <BookReview id={obj.bookId} />
                                )}
                            </>
                        ))}
                    </div>
                </>
            }
            {selectedBookEdit && (
                <EditBook key={selectedBookEdit} data={data} backToList={backToList} />
            )}
            <div class="modal " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "none" }}>
                <ToastContainer
                    enableMultiContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    containerId={"K"}
                    theme="colored" />
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Chapters</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleButtonAdd("none")}></button>
                        </div>
                        <div class="modal-body">
                            <div className="container">
                                <div className="form-group row mb-2">
                                    <label for="title" className="col-sm-2 col-form-label">Title<sup style={{ color: "red" }}>*</sup></label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                    </div>
                                </div>
                                {/* <div className="form-group row mb-2">
                                    <label for="author" className="col-sm-2 col-form-label">Image</label>
                                    <div className="col-sm-10" style={{ textAlign: "left" }}>
                                        <span id="uploadButton" className="uploadButton uploadButtonSmall">
                                            <input
                                                type="file"
                                                id="file1"
                                                title="Upload"
                                                onChange={(e) => handleImageUploadOnChange(e)}
                                                hidden
                                            />
                                            <label for="file1" className="btn-upload padd-upload">
                                                upload
                                            </label>
                                            {imgObject &&
                                                <img src={imgObject} alt="" width={40} height={40} />}

                                        </span>
                                    </div>
                                </div> */}

                                <div className="form-group row mb-2 description-editor">
                                    <label for="description" className="col-sm-2 col-form-label">Description<sup style={{ color: "red" }}>*</sup></label>
                                    <div className="col-sm-10">
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            placeholder='Please enter your text'
                                            editorState={editorState}
                                            onEditorStateChange={handleEditorState}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row mb-2">
                                    <label for="language" className="col-sm-2 col-form-label">Status<sup style={{ color: "red" }}>*</sup></label>
                                    <div className="col-sm-4">
                                        {/* <input type="text" className="form-control" placeholder="Language" defaultValue={obj.languageName} onChange={(e)=>setLanguage(e.target.value)}/> */}
                                        <Select
                                            value={status}
                                            placeholder="Select" className="select-language"
                                            options={bookStatus.map(item => ({
                                                value: item.value,
                                                label: item.label,
                                            }))}
                                            onChange={(e) => {
                                                setStatus(e)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="add-modal-footer">
                                    <button className="btnNavy btn-saveBook" onClick={() => handleWriteToFile()} >Save</button>
                                    <button className="btnNavy btn-saveBook btnCancel" onClick={() => handleButtonAdd("none")}>Cancel</button>
                                </div>
                            </div>
                        </div>
                        {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        
                      </div> */}
                    </div>
                </div>
            </div>
        </>
    )

}
export default BookDetail