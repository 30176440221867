
import React, { useState, useEffect, Component, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../service';
import { FaArrowLeft, FaEdit, FaUser, FaCalendar, FaStar, FaBookOpen, FaClock, FaBookReader, FaHamburger, FaBars, FaCog, FaPlus, FaMinus, FaArrowRight, FaCaretRight, FaAngleRight } from "react-icons/fa";
import Parser from 'html-react-parser';
import FontSizeChanger from 'react-font-size-changer';
import { DarkLightModeContext } from "react-light-dark-mode";
import theme from "../images/theme.png"
function EpisodeDetail(props) {
    const { setLargestScrollPercentage = () => { } } = props;
    const [selectedEpisode, setSelectedEpisode] = useState([]);
    const [data, setData] = useState([]);
    const [selectedBookEdit, setSelectedBookEdit] = useState(false);
    const [selectedBook, setSelectedBook] = useState(false);
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [onScrollPercentage, setOnScrollPercentage] = useState("")
    const scrollRef = useRef(null);
    const [lastCompletedEpisode, setLastCompletedEpisode] = useState(null);
    const [nextEpisode, setNextEpisode] = useState(null);
    const { lightMode, toggleLightDarkMode } = useContext(DarkLightModeContext);
    const [currentEpisodeId, setCurrentEpisodeId] = useState(props.episodeID)
    const [currentBookId, setCurrentBookId] = useState(props.bookObj.book_id)
    const { dataChanged, setdataChanged } = useState(new Date());
    

    const toggleMode = () => {
        toggleLightDarkMode();
    };
    useEffect(() => {
      //  alert(props.episodeID)
        console.log("*************props******************", props);
        // findLastCompletedEpisode(props.episodeLists);
        if(props.episodeID === ""){
            fetchDataNextEpisode();
        }
        else{
            fetchData();
        }
        

    }, []);


    const scrollToTarget = (readingPercentage) => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.body.scrollHeight;
        const scrollToY = (documentHeight - windowHeight) * (readingPercentage / 100);
        window.scrollTo(0, scrollToY);
    }

    const fetchDataNextEpisode = () => {
        const PostData = {
            "bookId": props.bookObj.book_id,
            "userId": window.localStorage.getItem("userId")
        };

        callingAPI('reading/bookReadMore', PostData).then((response) => {
            if (response.data.success === '1') {
                setSelectedEpisode(response.data.bookReadMoreData);
                setCurrentEpisodeId(response.data.bookReadMoreData[0].episodeId)
                const readingPercentage = response.data.bookReadMoreData[0].readingPercentage;
                setTimeout(() => {
                    scrollToTarget(readingPercentage);
                }, 1000);
            }
            console.log("🚀 ~ callingAPI ~ response.data.bookReadMoreData:", response.data.bookReadMoreData[0].episodeId)
        }).catch(error => {
            console.error("Error fetching data:", error);
        });

    };

    const fetchData = () => {
        const PostData = {
            "bookId": props.bookObj.book_id,
            "currentEpisodeId": props.bookObj.id,
            "userId": window.localStorage.getItem("userId")
        };

        callingAPI('reading/episodeRead', PostData).then((response) => {
            if (response.data.success === '1') {
                setSelectedEpisode(response.data.bookReadNextData);
                setCurrentEpisodeId(response.data.bookReadNextData[0].episodeId)
                const readingPercentage = response.data.bookReadNextData[0].readingPercentage;
                setTimeout(() => {
                    scrollToTarget(readingPercentage);
                }, 1000);
            }
            console.log("🚀 ~ callingAPI ~ response.data.bookReadNextData:", response.data.bookReadNextData[0].episodeId)
        }).catch(error => {
            console.error("Error fetching data:", error);
        });

    };



    async function backToEpisodeList() {
        window.scrollTo({ top: 0, behavior: 'smooth' });    
       

        try {
            console.log("@@@@@@@ 1111111111");
            await handleScrollPercentage();
            console.log("@@@@@@@ 33333333333");
           // setTimeout(() => {
            await props.episodeListfunction();
            console.log("@@@@@@@ 66666666666");
            await  props.findLastCompletedEpisode()
            props.setepisodeClick(false);
            props.setepisodeClickParent(false);
           // }, 1000)
        } catch (error) {
            console.error("Error saving scroll percentage:", error);
        }
    }


    const backToList = () => {
        setSelectedBook(false);
        setSelectedBookEdit(false);
        fetchData();
        props.episodeListfunction()
    };
    const handleEditClick = (editData) => {
        setData(editData)
        setSelectedBookEdit(true);
    };



    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.scrollHeight;
            const percentage = (scrollPosition / (documentHeight - windowHeight)) * 100;
            // setScrollPercentage(percentage.toFixed(2));
            console.log("test", percentage.toFixed(2))
            setOnScrollPercentage(percentage.toFixed(2))
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleScrollPercentage = () => {
        console.log("@@@@@@@ 2222222222222.1");
        return new Promise((resolve, reject) => {
            console.log("@@@@@@@ 2222222222222.2");
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.scrollHeight;

            
            const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;
            const scrollPercentageValue = scrollPercentage.toFixed(2);
            console.log("Scroll Percentage:", props.bookObj.id, scrollPercentageValue);
            console.log("@@@@@@@ 2222222222222.3");
            console.log("@@@@@@@ 2222222222222.1.....",scrollPosition,windowHeight,documentHeight,scrollPercentage);
            const postData = {
                userId: window.localStorage.getItem("userId"),
                bookId: props.bookObj.book_id,
                episodeId: currentEpisodeId,
                currentDateTime: new Date().getTime(),
                percentage: (scrollPercentageValue > 92 ? 100.00 : scrollPercentageValue)
            };

            console.log("🚀 ~ returnnewPromise ~ largestScrollPercentage.current > scrollPercentageValue:", typeof props.setLargestScrollPercentage)
            console.log("@@@@@@@ 2222222222222.4");
            // if (typeof setLargestScrollPercentage != 'function' || props.largestScrollPercentage > scrollPercentageValue) {
            //     resolve(true)
            //     return
            // }
            console.log("@@@@@@@ 2222222222222.5");
            setLargestScrollPercentage(scrollPercentageValue);
            console.log("@@@@@@@ 2222222222222.6");
            props.setProgressPercentages(preVal => ({ ...preVal, [props.bookObj.id]: Number(scrollPercentageValue) }))
            console.log("@@@@@@@ 2222222222222.7");
            callingAPI('reading/saveReadings', postData).then((data) => {
                if (data.data.success === '1') {
                    console.log("@@@@@@@ 2222222222222");
                    resolve(true)

                    // props.episodeListfunction()
                }
                else {
                    console.log("@@@@@@@ 2222222222222.8");
                    reject(data)
                }
            })
        })
    };




    async function readNextEpisode() {
        await handleScrollPercentage();
        const PostData = {
            "bookId": props.bookObj.book_id,
            "currentEpisodeId": currentEpisodeId,
        };

        await callingAPI('reading/bookReadNext', PostData).then((response) => {
            if (response.data.success === '1') {
                console.log("props.bookObj=", props.bookObj, props.episodeID);
                console.log("props.bookObj=", response.data);
              //  props.setEpisodeID(response.data.bookReadNextData[0].episodeId)
            //   setCurrentBookId()
                setSelectedEpisode(response.data.bookReadNextData);
               
                setCurrentEpisodeId(response.data.bookReadNextData[0].episodeId)
                console.log("🚀 ~ callingAPI ~ response.data.bookReadNextData:", response.data.bookReadNextData)
                const readingPercentage = response.data.bookReadNextData[0].readingPercentage;
                // setTimeout(() => {
                //     scrollToTarget(readingPercentage);
                // }, 1000);

                // handleScrollPercentage();
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setdataChanged(new Date())
                
            }
        }).catch(error => {
            console.error("Error fetching data:", error);
        });

    };



    return (
        <>
            {/* {!selectedBookEdit && */}
            <>
                <div className="epiosde-detail-wrapper 111" key={dataChanged}>
                    <>
                        {selectedEpisode.map((obj, i) => (
                            <div className="bkBg">
                                <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                    <div className="text-left"><span className='backToLink' id="button"
                                        onClick={backToEpisodeList}
                                    //  onClick={props.backToList}

                                    ><FaArrowLeft title='Back' /></span></div>
                                    {/* <span title='Edit'
                                            onClick={() => handleEditClick(obj)}
                                        >
                                            <FaEdit className='editiconBlog' />
                                        </span> */}
                                    {/* <span>
                                        <FaBars />

                                    </span>
                                    <div className='generalSettings'>
                                        <p>Font Size</p> 
                                       
                                    </div> */}
                                    <div className='awe'>
                                        <FontSizeChanger
                                            targets={['#target-one .Awesomecontent']}
                                            customButtons={{
                                                up: <span className='font' title="Increase Font Size">A+</span>,
                                                down: <span className='font' title="Decrease Font Size">A-</span>,
                                                style: {
                                                    color: 'white',
                                                    WebkitBoxSizing: 'border-box',
                                                    WebkitBorderRadius: '5px',
                                                    width: '60px'
                                                },
                                                buttonsMargin: 10
                                            }}
                                        />
                                        <button onClick={toggleMode} style={{ background: "transparent", border: "none", marginLeft: "5px" }}>
                                            <img src={theme} title="Dark Mode/Light Mode" style={{ width: "30px" }} />
                                        </button>
                                    </div>

                                </span>

                                <div className="row">
                                    <div className="col-md-12" id="target-one" >
                                        <h3 className='main-bookHead text-center page-text Awesomecontent'

                                        >
                                            {obj.title}
                                        </h3>
                                        <span className="underline center"></span>
                                        <div className="book-description text-left page-text Awesomecontent"  >
                                            {Parser(obj.description)}
                                        </div>
                                        {/* {console.log("222222222222222221111", onScrollPercentage)} */}
                                        {console.log("222222222222222221111", props.lastEpisodeOfList.id)}
                                        {console.log("222222222222222221111", currentEpisodeId)}
                                        {onScrollPercentage >= 90.97 && props.lastEpisodeOfList.id != currentEpisodeId ?
                                            <div className="detail-readmore readNextEpi bdnextepi"
                                                onClick={() => readNextEpisode()}
                                            ><FaBookReader /> &nbsp; Read Next Episode &nbsp; <FaAngleRight /> </div>
                                            : ""}
                                    </div>
                                </div>
                                <p></p>
                            </div>
                        ))}
                        <p></p>
                    </>

                </div>

            </>
            {/* } */}
            {/* {selectedBookEdit && (
                <EpisodeEdit key={selectedBookEdit} data={data} book_id={props.bookObj.book_id} userId={props.bookObj.created_by} backToList={backToList} />
            )} */}
        </>
    )

}
export default EpisodeDetail