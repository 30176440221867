
import React, { useEffect } from 'react';
import Header from "../Home/layout/header";
import Footer from "../Home/layout/footer"
import bannerImg from "../images/bannerImg.jpg"
import aboutIcon from "../images/aboutIcon.png"

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    return (
        <>
            <div className="WrapLeft loginWrap termsPolicyWrap">
                <Header />
                <div className="innerImgs">
                    <img src={bannerImg} />
                </div>

                <div className="container divOverlap">
                    {/* <div className="abtIcon">
          <img src={aboutIcon} alt="" />
          </div> */}

                    <h3 className='languageSelect-mainHead'>
                        <span> About Us</span>
                        <span className="underline center"></span>
                    </h3>
                    <br />
                    <p><span className='aboutTxt'>W</span>elcome to Krithy, where stories come to life and imagination knows no bounds!</p>
                    <p >At Krithy, we believe in the transformative power of storytelling. Our platform is dedicated to curating a diverse collection of stories from talented writers around the globe, spanning genres from fantasy and science fiction to romance, mystery, and beyond. Whether you're seeking a thrilling adventure, a heart-warming tale, or a thought-provoking journey, you'll find it here. Dive into captivating narratives that will keep you turning pages late into the night, or explore bite-sized tales perfect for a quick escape during your busy day.  </p> <br />
                    <p>Krithy isn't just about reading — it's about community. Join our vibrant community of readers and writers, where you can discuss your favourite stories, share your own creations, and connect with fellow enthusiasts who share your passion for storytelling.
                        With a user-friendly interface and constantly updated content, Krithy is your go-to destination for discovering new worlds and experiencing the magic of storytelling in all its forms.</p>
                    <p>At Krithy we're committed to celebrating the power of storytelling in all its forms. From short stories to serialized epics, from poetry to prose, we embrace the diversity of human expression and the endless possibilities of the written word.</p>
                    <p>So come on in, grab a cup of tea, and let your imagination soar. Your next adventure awaits at Krithy</p>

                </div>
                <Footer />
            </div>
        </>
    );
};

export default AboutUs;