import React, { useState } from 'react';
// import Logo from "../../images/awesomepen-white.png"
 import Logo from "../../images/krithyWhite.png"

import LoginPopup from '../loginPopUp';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { callingAPI } from '../../service';
function Header({ isPopupVisible, showPopup, hidePopup }) {
     // const [isPopupVisible, setPopupVisibility] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [bookDetails, setBookDetails] = useState([]);
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [authorDetails, setAuthorDetails] = useState([]);
    const navigate = useNavigate();
    const showHome = () => {
        window.location.href = "/"
    };
    const logoutClick = () => {
        window.localStorage.clear();
        window.location.href = "/"
    };
    const showProfile = () => {
        navigate("/profile")
    }
    const showDashboard = () => {
        navigate("/dashboard/dashboard")
    }
    // const showPopup = () => {
    //     setPopupVisibility(true);
    // };
    // const hidePopup = () => {
    //     setPopupVisibility(false);
    // };
    const searchToggle = (evt) => {
        // debugger
        const container = evt.target.closest('.search-wrapper');

        if (!container.classList.contains('active')) {
            container.classList.add('active');
            evt.preventDefault();
        } else if (container.classList.contains('active') && !evt.target.closest('.input-holder')) {
            container.classList.remove('active');
            // Clear input
            const searchInput = container.querySelector('.search-input');
            if (searchInput) {
                searchInput.value = '';
            }
        }
    };
    const handleInputChange = (event) => {
        const { value } = event.target;
        searchByCategory(value)
        searchByBook(value)
        searchByAuthor(value)
        setSearchTerm(value);
        setShowAutocomplete(value.length >= 1); // Show autocomplete when at least two letters are typed
    };
    const searchByBook = (value) => {
        const postData = {
            "searchText": value,
            "offset": "",
            "max": "",
            "searchType": "Book" //Category,Author,Book
        }
        callingAPI('book/bookSearch', postData).then((response) => {
            console.log("bookk", response)
            if (response.data.result.length > 0) {
                setBookDetails(response.data.result[0].contentDetails)
            }
            else {
                setBookDetails([])
            }
        })
    }
    const searchByAuthor = (value) => {
        const postData = {
            "searchText": value,
            "offset": "",
            "max": "",
            "searchType": "Author" //Category,Author,Book
        }
        callingAPI('book/bookSearch', postData).then((response) => {
            console.log("author", response)
            if (response.data.result.length > 0) {
                setAuthorDetails(response.data.result[0].authorDetails)
            }
            else {
                setAuthorDetails([])
            }
        })
    }
    const searchByCategory = (value) => {
        const postData = {
            "searchText": value,
            "offset": "",
            "max": "",
            "searchType": "Category" //Category,Author,Book
        }
        callingAPI('book/bookSearch', postData).then((response) => {
            console.log("categor", response)
            if (response.data.result.length > 0) {
                setCategoryDetails(response.data.result[0].categoryDetails)
            }
            else {
                setCategoryDetails([])
            }
        })
    }
    const goToBookDetails = (bookId) => {
        navigate('/book-detail', { state: { userID: bookId } });
        setShowAutocomplete(false)
    };
    const goToComponentB = (userID) => {
        navigate('/star-writer', { state: { userID: userID } });
        setShowAutocomplete(false)
    };
    const handleBookList = (categoryName) => {
        navigate('/books', { state: { categoryName: categoryName } });
        setShowAutocomplete(false)
    }
    const handleAutocompleteItemClick = () => {
        // Hide autocomplete when clicking on an item
        setShowAutocomplete(false);
    };
    return (
        <header className="header-home">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3 col-5">
                        <img src={Logo} alt="Logo" className='img-fluid logoimg' onClick={showHome} />
                    </div>


                    <div className="col-md-9 col-7 header-right-sec">
                        <div className={`search-wrapper ${isActive ? 'active' : ''}`}  onClick={searchToggle}>
                            <div className="input-holder">
                                <input type="text" className="search-input" placeholder="Type to search"
                                    value={searchTerm}
                                    autoComplete='off'
                                    onChange={handleInputChange}
                                />
                                <button className="search-icon"><span></span></button>
                            </div>
                            {/* <span className="close"></span> */}
                            {showAutocomplete && ((bookDetails.length > 0) || (authorDetails.length > 0) || (categoryDetails.length > 0)) && (
                                <div className="search-autocomplete">
                                    <ul>
                                        {bookDetails.map((data) =>
                                            <li onClick={() => { goToBookDetails(data.bookId); handleAutocompleteItemClick(); }}>{data.title}<span className='bold-searchlst'>{" " + data.autherName}</span></li>
                                        )}

                                        {authorDetails.map((data) =>
                                            <li onClick={() => goToComponentB(data.userId)}><span className='bold-searchlst'>{data.pen_name}</span>{" books"}</li>
                                        )}



                                        {categoryDetails.map((data) =>
                                            <>
                                                {data.categoryname !== "" &&
                                                    <li onClick={() => handleBookList(data.categoryname)}>{"books available in "}<span className='bold-searchlst'>{data.categoryname}</span></li>}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>

                        {(window.localStorage.getItem("userId") != null) ?
                            <>
                                {window.localStorage.getItem('role') !== "Admin" ?
                                    <button className='login-btn' onClick={showProfile}>
                                        My Profile
                                    </button>
                                    :
                                    <button className='login-btn' onClick={showDashboard}>
                                        Dashboard
                                    </button>}
                                <span className='v-line'></span>
                                <button className='login-btn' onClick={logoutClick} >
                                    Logout
                                </button>
                            </>
                            :
                            <>
                                <button className='login-btn' onClick={showPopup}>
                                    Login
                                </button>
                                <span className='v-line'></span>
                                <Link to="/register" className='login-btn'>Register</Link>
                            </>
                        }
                    </div>
                </div>
            </div>
            <LoginPopup show={isPopupVisible} onClose={hidePopup} />
        </header>
    )

}
export default Header