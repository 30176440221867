import React, { useState, useEffect } from "react";
import { FaEdit, FaSearch, FaPlus, FaTrashAlt, FaCheckCircle, FaRegTimesCircle, FaSave } from "react-icons/fa";
import { callingAPI } from '../../service'
import $ from "jquery"
import Header from "../../components/layouts/header";
import Chats from "../dashboard/chats";

import AWS from 'aws-sdk';
var s3;

export default function CategoryList() {
    const [file, setFile] = useState('');
    const [imageName, setImageName] = useState('');

    const [categoryList, setCategoryList] = useState([]);
    const [categoryName, setCategoryName] = useState('');

    const [popupView, setPopupView] = useState(false);
    const [ids, setIds] = useState('')

    const [cofigDetails, setConfigDetails] = useState('')
    const [environmentType, setEnvironmentType] = useState('');
    const [categoryImagePath, setCategoryImagePath] = useState('');

    const [imgObject, setImgObject] = useState('');
    const [imagePath, setImagePath] = useState('');
    useEffect(() => {
        categoryListing();

        const PostData = {
        };
        callingAPI('configurationData', PostData).then((res) => {

            setConfigDetails(res.data.result[0]);

            const getConfigValue = (configKey) => {
                return res.data.result[0].configDetails
                    ? res.data.result[0].configDetails.find((config) => config.config_key === configKey)?.config_value
                    : null;
            };
            setEnvironmentType(getConfigValue("S3BucketMainFolder"));
            setCategoryImagePath(getConfigValue("categoryImagePath"));
            AWS.config.update({
                accessKeyId: getConfigValue("AWSAccessKeyId"),
                secretAccessKey: getConfigValue("AWSSecretKey"),
                region: getConfigValue("s3Region"),
            });
            var bucketParams = {
                Bucket: getConfigValue("s3bucket"),
            };
            s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
        })
    }, [])



    const categoryListing = () => {
        const PostData = {

        };
        callingAPI('categoryListing', PostData).then((res) => {
            setCategoryList(res.data.result);

        })
    }
    const deleteCategory = (delId) => {
        var x = false;
        x = window.confirm('Do you want to Delete this Category?');
        if (x === true) {
            const PostData = {
                category_id: delId,
            };
            callingAPI('deleteCategory', PostData).then((res) => {
                if (res.data.success === "1") {
                    setPopupView(false)
                    alert("Category Deleted Successfully.");
                    categoryListing();
                }
                else {
                    alert("Invalid Data");
                }
            })
        }
    }
    const addCategory = (type, info) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        setPopupView(true)
        if (type === "Add Category") {
            document.getElementById("popHeading").innerHTML = type;
            setCategoryName("");
            setImageName("")
            setFile("")
            setIds("")
            $("#updateBtn").css("display", "none")
            $("#saveBtn").css("display", "inline-flex")
        }
        else {
            setImgObject("");
            setImageName("")
            setFile("")
            document.getElementById("popHeading").innerHTML = type;
            setCategoryName(info.name);
            const pathname = new URL(info.image_name).pathname;
            const parts = pathname.split("/");
            const filename = parts[parts.length - 1];
            setImageName(filename)
            setIds(info.id)
            setImgObject(info.image_name);
            setFile(info.image_name)
            $("#updateBtn").css("display", "inline-flex")
            $("#saveBtn").css("display", "none")
        }

    }
    const handleImageUploadOnChange = (e) => {

        if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
            alert("Please attach jpg,jpeg or png files");
            return false;
        }
        setFile(e.target.files[0]);
        setImageName(e.target.files[0].name);
        const obj = URL.createObjectURL(e.target.files[0])
        setImgObject(obj);
        document.getElementById('uploadButton').style.display = 'block';


    };
    const saveCategory = (imageName, fulFile) => {
        if (!categoryName) {
            alert("Please Enter the Category Name.");
            document.getElementById("categoryName").focus();
            return
        }
        else {

            const PostData = {
                categoryName: categoryName,
                image_name: imageName,
                id: ""
            };
            callingAPI('saveOrEditCategory', PostData).then(async (res) => {
                if (res.data.success === "1") {
                    const promises = [];
                    if (fulFile.length !== 0) {
                        await imageS3Save(imageName, fulFile);
                    }
                    categoryListing();
                    setPopupView(false);
                    setImgObject("");
                    setImageName("")
                    setFile("")
                    alert("Category Added Successfully.");

                }
                else {
                    alert("Invalid Data");
                }
            })
        }
    }
    const updateCategory = (imageName, fulFile) => {

        if (!categoryName) {
            alert("Please Enter the Category Name.");
            document.getElementById("categoryName").focus();
            return
        }
        else {

            const PostData = {
                categoryName: categoryName,
                image_name: imageName,
                id: ids
            };
            callingAPI('saveOrEditCategory', PostData).then(async (res) => {
                if (res.data.success === "1") {
                    const promises = [];
                    if (fulFile.length !== 0) {
                        await imageS3Save(imageName, fulFile);
                    }
                    categoryListing();
                    setPopupView(false)
                    setImgObject("");
                    setImageName("")
                    setFile("")
                    // setImgObject("");
                    alert("Category Updated Successfully.");

                }
                else {
                    alert("Invalid Data");
                }
            })
        }
    }


    const imageS3Save = (imageName, file) => {
        return new Promise((resolve, reject) => {

            var S3BucketMainFolder = environmentType + '/' + categoryImagePath;
            var fullFilePath = S3BucketMainFolder + '/' + imageName;
            s3.upload(
                {
                    Key: fullFilePath,
                    Body: file,
                    ACL: 'public-read',
                },
                (err, data) => {
                    if (err) {
                        alert('There was an error uploading your photo: ' + err.message);
                        reject(err);
                    } else {
                        // alert("File Uploaded Successfully")
                        resolve(data);
                        document.getElementById('uploadButton').style.display = 'block';

                        categoryListing();
                    }
                }
            );
        })
    };
    const closePopup = () => {
        setPopupView(false);
        setImgObject("");
        setImageName("")
        setFile("")
        // $('body').addClass('overflowAuto');
        // $('body').removeClass('overflowHidden');
    }

    return (
        <>
            {/* <div className="WrapLeft">
                <Header /> */}
            <div className="dashboardContentInner">
                <div className="mainHead ">
                    <div className="row">
                        <div className="col-md-8">
                            <h2>Category List</h2>
                        </div>
                        <div className="col-md-4 dashClmRight">
                            <div className="dashAction">
                                <a
                                    onClick={(e) =>
                                        addCategory("Add Category", "")
                                    }
                                    className="btnNavy"  ><FaPlus /> Add New</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabContentsWrap">
                <div className="issueSummWrap heightAuto">
                    <div className="summaryTabs budgetTable">
                        <table >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{ width: "250px" }}>Image </th>
                                    <th style={{ width: "150px" }}>Status</th>
                                    <th style={{ textAlign: "right", width: "150px" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categoryList.map((obj, i) =>
                                    <tr>
                                        <td data-column="Name">{obj.name}</td>
                                        <td data-column="Image ">
                                            <img src={obj.image_name} className="categoryImg" alt="" />
                                        </td>
                                        <td data-column="Status">{obj.is_active === true ?
                                            <FaCheckCircle color={"green"} />
                                            : <FaRegTimesCircle color={"red"} />}</td>
                                        <td data-column="Action " style={{ textAlign: "right" }}>
                                            <div className="tableAction">
                                                <span><FaEdit title="Edit" color={"#6a5b5b"} className="cursorPointer"
                                                    onClick={(e) => addCategory("Edit Category", obj)}
                                                /> </span>
                                                <span><FaTrashAlt title="Delete" color={"#db0000"} className="cursorPointer"
                                                    onClick={() => deleteCategory(obj.id)}
                                                /> </span>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* </div>
            <Chats /> */}

            {/* =============== MODAL BEGINS HERE =============== */}
            <div id="demo-modal" class="modal" style={{ display: popupView === true ? "flex" : "none" }} >
                <div class="modal__content">
                    <h3 id="popHeading"> </h3>

                    <div className="modalFields">
                        <div className="form-group">
                            <label htmlFor=""> Name <span>:</span> </label>
                            <input type="text" className="form-control" id='categoryName'
                                onChange={(e) => setCategoryName(e.target.value)}
                                value={categoryName}
                            />
                        </div>


                        {/* Image Upload Begins */}
                        <div className="form-group">
                            <label htmlFor=""> Image  <span>:</span> </label>
                            <span id="uploadButton" className="uploadButton uploadButtonSmall">
                                <input
                                    type="file"
                                    id="file1"
                                    title="Upload"
                                    onChange={(e) => handleImageUploadOnChange(e)}
                                    hidden
                                />
                                  <label for="file1" className="btn-upload">
                                    upload
                                </label>
                                {imgObject !== "" && <img src={imgObject !== "" ? imgObject : imageName}
                                    alt="" width={40} height={40} style={{ margin: "5px" }} />}

                            </span>
                        </div>


                        {/* Image Upload Ends */}



                    </div>
                    <div class="modal__footer">
                        <div className="dashAction">

                            <a onClick={() => saveCategory(imageName, file)} id="saveBtn" className="btnNavy"  >  Save</a>
                            <a onClick={() => updateCategory(imageName, file)} id="updateBtn" className="btnNavy"  >  Update</a>
                            <a onClick={() => closePopup()} className="btnCancel"> Cancel</a>
                        </div>
                    </div>
                    <a onClick={() => closePopup()} class="modal__close"> <FaRegTimesCircle /></a>
                </div>
            </div>
        </>
    )
}
