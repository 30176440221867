import React, { useState, useEffect } from "react";
import { FaEdit, FaSearch, FaPlus, FaTrashAlt, FaCheckCircle, FaRegTimesCircle, FaSave } from "react-icons/fa";
import { callingAPI } from '../../service'
import $ from "jquery"
export default function TypeList() {
    const [types, setTypes] = useState([]);
    const [typeName, setTypename] = useState('');
    const [popupView, setPopupView] = useState(false);
    const [popHead, setPopHead] = useState("")
    const [idss, setIdss] = useState('')
    useEffect(() => {
        typeListing();
        setPopupView(false);
    }, [])
    const typeListing = () => {
        const PostData = {

        };
        callingAPI('typeListing', PostData).then((res) => {
            setTypes(res.data.result);
        })

    }
    const deleteType = (delId) => {
        var x = false;
        x = window.confirm('Do you want to Delete this Type?');
        if (x === true) {
            const PostData = {
                type_id: delId,
            };
            callingAPI('deleteType', PostData).then((res) => {
                if (res.data.success === "1") {
                    setPopupView(false)
                    alert("Type Deleted Successfully.");
                    typeListing();
                }
                else {
                    alert("Invalid Data");
                }
            })
        }
    }
    const addType = (type, info) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        setPopupView(true)
        if (type === "Add Type") {
            document.getElementById("popHeading").innerHTML = type;
            setTypename("");
            setIdss("")
            $("#updateBtn").css("display", "none")
            $("#saveBtn").css("display", "inline-flex")

        }
        else {
            document.getElementById("popHeading").innerHTML = type;
            setTypename(info.name);
            setIdss(info.id)
            $("#updateBtn").css("display", "inline-flex")
            $("#saveBtn").css("display", "none")
        }
    }


    const saveType = () => {
        if (!typeName) {
            alert("Please Enter the Type Name.");
            document.getElementById("typeName").focus();
            return
        }

        else {
            const PostData = {
                TypeName: typeName,
                typeId: "",

            };
            callingAPI('saveType', PostData).then((res) => {
                if (res.data.success === "1") {
                    setPopupView(false)
                    alert("Type Added Successfully.");
                    typeListing();

                }
                else {
                    alert("Invalid Data");
                }
            })
        }
    }
    const updateType = () => {
        if (!typeName) {
            alert("Please Enter the Type Name.");
            document.getElementById("typeName").focus();
            return
        }

        else {
            const PostData = {
                TypeName: $("#typeName").val(),
                typeId: idss,
            };
            callingAPI('saveType', PostData).then((res) => {
                if (res.data.success === "1") {
                    setPopupView(false)
                    alert("Type Updated Successfully.");
                    typeListing();

                }
                else {
                    alert("Invalid Data");
                }
            })
        }
    }
    const closePopup = () => {
        setPopupView(false);
    }
    return (
        <>

            <div className="dashboardContentInner">
                <div className="mainHead ">
                    <div className="row">
                        <div className="col-md-8">
                            <h2>Type List</h2>
                        </div>
                        <div className="col-md-4 dashClmRight">
                            <div className="dashAction">
                                <a
                                    onClick={(e) =>
                                        addType("Add Type", "")
                                    }
                                    className="btnNavy"  ><FaPlus /> Add New</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabContentsWrap">
                <div className="issueSummWrap heightAuto">

                    <div className="summaryTabs budgetTable">
                        <table >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{ width: "100px" }}>Status</th>
                                    <th style={{ textAlign: "right", width: "150px" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {types.map((obj, i) =>
                                    <tr>
                                        <td data-column="Name">{obj.name}</td>
                                        <td data-column="Status">{obj.is_active === true ?
                                            <FaCheckCircle color={"green"} />
                                            : <FaRegTimesCircle color={"red"} />}</td>
                                        <td data-column="Action " style={{ textAlign: "right" }}>
                                            <div className="tableAction">
                                                <span><FaEdit title="Edit" color={"#6a5b5b"} className="cursorPointer"
                                                    onClick={(e) => addType("Edit Type", obj)}
                                                />
                                                </span>
                                                <span><FaTrashAlt title="Delete" color={"#db0000"} className="cursorPointer"
                                                    onClick={() => deleteType(obj.id)}
                                                /> </span>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {/* =============== MODAL BEGINS HERE =============== */}
            <div id="demo-modal" class="modal" style={{ display: popupView === true ? "flex" : "none" }} >
                <div class="modal__content">
                    <h3 id="popHeading"></h3>
                    <div className="modalFields">
                        <div className="form-group">
                            <label htmlFor=""> Name <span>:</span> </label>
                            <input type="text" className="form-control" id='typeName'
                                onChange={(e) => setTypename(e.target.value)}
                                value={typeName}
                            />
                        </div>


                    </div>
                    <div class="modal__footer">
                        <div className="dashAction">

                            <a onClick={() => saveType()} id="saveBtn" className="btnNavy"  >  Save</a>
                            <a onClick={() => updateType()} id="updateBtn" className="btnNavy"  >  Update</a>
                            <a onClick={() => closePopup()} className="btnCancel"> Cancel</a>
                        </div>
                    </div>
                    <a onClick={() => closePopup()} class="modal__close"> <FaRegTimesCircle /></a>
                </div>
            </div>
        </>
    )
}
