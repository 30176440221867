import React, { useState, useEffect } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import Write from "../../images/writeBlk.png"
import { callingAPI } from '../../service'
import $ from "jquery"
import moment from 'moment';
import Pagination from "react-js-pagination";
export default function Support() {
    const [issueList, setIssueList] = useState([])
    const [description, setDescription] = useState([])
    const [status, setStatus] = useState("0")
    const [authorData, setAuthorData] = useState("0")
    const [searchInput, setSearchInput] = useState("")
    const [dataSection, setDataSection] = useState()
    const [statusName, setStatusName] = useState('0');
    const [issueCount, setIssueCount] = useState("")
    const [activePage, setActivePage] = useState(1)
    useEffect(() => {
        issueListing()
        $("#IssuesPopup").hide();
    }, [])
    const issueListing = () => {
        const PostData = {
            "status": "",//OPEN,INPROGRESS,CLOSED
            "searchInput": "",
            "cmbSearchType": "",//autherName,mobileNumber
            "offset": "0",
            "max": "10"
        };
        callingAPI('common/issueList', PostData).then((response) => {
            if (response.data.success === '1') {
                setIssueList(response.data.result.issueLists)
                setIssueCount(response.data.result.issueCount)
                console.log("------------------ issueCount Listing", response.data.result.issueCount)
                console.log("===================== Issue List", response.data.result.issueLists)
                // setMobileNum(response.data.result.issueLists[0].mobile_number)
                setStatus("")
                setSearchInput("")
                setAuthorData("")
                setActivePage(1); 
            } else {
                console.log(response.data.errorMessage)
                setActivePage(0);
            }
            if (response.data.result.issueLists.length === 0) {
                setDataSection(true)
            }
            else {
                setDataSection(false)
            }
        })

    }
    const issueviewMore = (des) => {
        $("#IssuesPopup").show();
        setDescription(des)
    }
    const closePopup = () => {
        $("#IssuesPopup").hide();
        setDescription("");
    }
    const clearSearch = () => {
        setStatus("")
        setSearchInput("")
        setAuthorData("")
        issueListing()

    }
    const searchValue = () => {
        if (authorData === "0" && searchInput === "") {
            alert("Please enter fields");
            return;
        }
        else if (status === "0") {
            alert("Please enter fields");
            return;
        }
        const PostData = {
            "status": status,
            "searchInput": searchInput,
            "cmbSearchType": authorData,
            "offset": "0",
            "max": "10"
        };
        callingAPI('common/issueList', PostData).then((response) => {
            if (response.data.success === '1') {
                setIssueList(response.data.result.issueLists);
                setIssueCount(response.data.result.issueCount);
                setActivePage(1); 
                console.log("------------------ issueCount Search value", response.data.result.issueCount)
            }

            else if (response.data.success === "0") {
                setIssueList([]);
                setActivePage(0);
            }

            setDataSection(response.data.result.issueLists.length === 0);
        });
    };

    const handleStatusChange = (event, items) => {
        setStatusName(event.target.value);
        const PostData = {
            "status": event.target.value,
            "userId": window.localStorage.getItem("userId"),
            "issueId": items.id
        };

        // Show confirmation dialog
        if (window.confirm("Are you sure you want to change the status?")) {
            callingAPI('common/issueStatusChange', PostData).then((response) => {
                if (response.data.success === '1') {
                    alert("Status Changed Successfully.");
                    issueListing();
                } else {
                    alert("Error");
                }
            });
        }
    };


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = 10;
        } else {
            offset =
                parseInt(pageNumber - 1) * 10;
            max = 10;
        }
        setActivePage(pageNumber);
        const PostData = {
            "status": status,
            "searchInput": searchInput,
            "cmbSearchType": authorData,
            "offset": offset,
            "max": max,
        };
        callingAPI('common/issueList', PostData).then((response) => {
            setIssueList(response.data.result.issueLists)
            setIssueCount(response.data.result.issueCount)
            console.log("------------------ issueCount pagination", response.data.result.issueCount)
        })
    };
    return (
        <>

            <div className="dashboardContentInner">
                <div className="mainHead ">
                    <div className="row">
                        <div className="col-md-8">
                            <h2>Issue List</h2>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-3">

                            <select name="" id="select1" className='search-bookinput'
                                onChange={(e) => setAuthorData(e.target.value)} value={authorData}>
                                <option value="0">Select</option>
                                <option value="autherName">Auther Name</option>
                                <option value="mobileNumber">Mobile Number </option>
                                <option value="penName">Pen Name</option>
                                <option value="email">Email Id </option>
                            </select>

                        </div>
                        <div className="col-md-3">
                            <input type="text" placeholder="Search Text" id="authorNameId" className="search-bookinput"
                                onChange={(e) => setSearchInput(e.target.value)} value={searchInput} />
                        </div>
                        <div className="col-md-3">

                            <select name="" id="select2" className='search-bookinput'
                                onChange={(e) => setStatus(e.target.value)} value={status}
                            >
                                <option value="0">Select</option>
                                <option value="Open">Open </option>
                                <option value="Inprogress">Inprogress </option>
                                <option value="Closed">Closed </option>
                            </select>

                        </div>
                        <div className="col-md-3">
                            <div className="dashAction">
                                <a
                                    onClick={() => searchValue()}
                                    className="btnNavy"  > Search</a>
                                <a
                                    onClick={() => clearSearch()}
                                    className="btnNavy btnGrey"  > Clear</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="tabContentsWrap AuthrsListWrap">
                <div className="issueSummWrap heightAuto">

                    <div className="summaryTabs budgetTable">
                        <table >
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "200px" }} >Author Info</th>
                                    <th style={{ minWidth: "150px" }} >Issue Start Date </th>
                                    <th style={{ minWidth: "200px" }}>Issue Details</th>
                                    <th style={{ minWidth: "200px" }}>Status Updated</th>
                                    <th style={{ minWidth: "155px" }} >Status</th>
                                </tr>
                            </thead>
                            {issueList.map((obj, i) =>
                                <tbody className="aweSupprtCard">
                                    <tr>
                                        <td className="authorInfos">
                                            {obj.authorName && <p>  {obj.authorName}</p>}
                                            {obj.pen_name && <p><span style={{ fontSize: "13px" }}><img src={Write} style={{ width: "17px" }} />  {obj.pen_name}</span></p>}
                                            {obj.email_id && <p>  {obj.email_id}</p>}
                                            {obj.mobile_number && <p>{obj.mobile_number}</p>}
                                        </td>
                                        <td data-column="Issue Start Date" > {moment(obj.issue_created_date).format('DD-MM-YYYY')}</td>
                                        <td data-column="Issue details">
                                            <div className="issueCont">
                                                <div class="block-wrap">
                                                    <div class="block">
                                                        {obj.description.length > 45 ? (
                                                            <>
                                                                <div className="icons" onClick={() => issueviewMore(obj.description)} >View More</div>
                                                                <div className="texts">{obj.description.slice(0, 45)}...</div>
                                                            </>
                                                        ) : (
                                                            <div className="texts">{obj.description}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-column="Status Updated" >
                                            {obj.inprogress_date && <span><span style={{ fontSize: "12px", color: "grey", fontWeight: "600" }}>Inprogress  </span><br />{moment(obj.inprogress_date).format('DD-MM-YYYY')}  </span>}<br />
                                            {obj.issue_closed_date && <span><span style={{ fontSize: "12px", color: "green", fontWeight: "600" }}>Closed </span><br /> {moment(obj.issue_closed_date).format('DD-MM-YYYY')}  </span>}
                                        </td>
                                        <td data-column="Status" >
                                            <select name="" id={i} className='search-bookinput'
                                                onChange={(e) => handleStatusChange(e, obj)}>
                                                <option value="0">Select</option>
                                                <option value="OPEN" selected={obj.issue_status === "OPEN"}>Open</option>
                                                <option value="INPROGRESS" selected={obj.issue_status === "INPROGRESS"}>Inprogress</option>
                                                <option value="CLOSED" selected={obj.issue_status === "CLOSED"}>Closed</option>
                                            </select>

                                        </td>

                                    </tr>

                                </tbody>
                            )}
                            <tbody>
                                <tr>
                                    <td colspan="5">
                                        {dataSection &&
                                            <p className="noDataSec">No Data Found</p>
                                        }
                                    </td>
                                </tr>
                            </tbody>

                        </table>

                        <div class="modal" id="IssuesPopup"  >
                            <div class="modal__content">
                                <h3 id="popHeading">Issue Details  </h3>
                                <div className="modalFields">
                                    {description}
                                </div>
                                <div class="modal__footer">
                                    <div className="dashAction">
                                        <a
                                            onClick={() => closePopup()}
                                            className="btnCancel"> Cancel</a>
                                    </div>
                                </div>
                                <a onClick={() => closePopup()} class="modal__close"> <FaRegTimesCircle /></a>
                            </div>
                        </div>

                    </div>
                </div>

                {issueCount > 10 ?
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={issueCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                    : ""}


            </div>
        </>
    )
}
