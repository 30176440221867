import React from 'react';
import { DarkLightModeProvider } from "react-light-dark-mode";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/adminStyles.css';
import './css/style.css';
import './css/responsive.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Dashboard from './pages/dashboard/dashboard';
import TableList from './pages/reports/tableList';
import Form from './pages/reports/form';
import Login from './authentication/login';
import LanguageList from './pages/languages/languageList';
import CategoryList from './pages/category/categoryList';
import TypeList from './pages/type/typeList';
import AuthorsList from './pages/authors/authorsList';
import UsersList from './pages/authors/usersList.jsx';
import StartWriterList from './pages/authors/startWriterList';
import BookList from './pages/books/booksList';
import BookDetail from './pages/books/bookDetail';
import EditBook from './pages/books/books-edit';
import ForgotPassword from './authentication/forgotPassword'
import TermsAndConditions from "./common/termsAndConditions"
import PrivacyPolicy from "./common/privacyPolicy"
import EpisodeDetails from "./pages/books/episodeDetails"
import EpisodeEdit from "./pages/books/episodeEdit"
 import EpisodeReview from './pages/books/episodeReview.jsx';
 import Notification from './pages/notification/notification';

 import Home from './Home/home.jsx'
 import BookListHome from './Home/booklist.jsx'
 import BookDetailHome from './Home/bookDetail.jsx'
 import StarWriterDetail from './Home/star-writers-detail.jsx'
import Reviews from './Home/reviews';
import Registration from './Home/registration.jsx' 
import WriteHere from './Home/writehere.jsx' 
import Profile from './Home/profile.jsx' 

import PdfViewer from './pdf/pdfviewer.jsx';
import AboutUs from './common/aboutUs';
import ContactUs from './common/contactUs';
import OurTeam from './common/ourTeam';
import Support from './pages/support/support.jsx';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DarkLightModeProvider>
  <BrowserRouter>
    <Routes>
   
      <Route path="/" element={<Home />} />
      <Route path="/dashboard/dashboard" element={<Dashboard />} />
      <Route path='/reports/tableList' element={<TableList />} />
      <Route path="/reports/form" element={<Form />} />
      <Route path="/languages/languageList" element={<LanguageList />} />
      <Route path="/category/categoryList" element={<CategoryList />} />
      <Route path="/type/typeList" element={<TypeList />} />
      <Route path="/authors/authorsList" element={<AuthorsList />} />
      <Route path="/authors/usersList" element={<UsersList />} />
      <Route path="/authors/startWriterList" element={<StartWriterList />} />
      <Route path="/books/booksList" element={<BookList />} />
      <Route path="/books/bookDetail/:bookId/:user_id" element={<BookDetail />} />
      <Route path="/books/editBooks/:bookId/:user_id" element={<EditBook />} />
      <Route path="/books/episodeEdit" element={<EpisodeEdit />} /> 
      <Route path="/reset-password/:tokenId" element={<ForgotPassword />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/episodeDetails" element={<EpisodeDetails />} />
      <Route path="/episodeReview" element={<EpisodeReview />} />
      <Route path="/notification/notification" element={<Notification />} />

      <Route path="/support/support" element={<Support />} />
      {/* <Route path="/home" element={<Home />} /> */}
      <Route path="/books" element={<BookListHome />} />
      <Route path="/book-detail" element={<BookDetailHome />} />
      <Route path="/star-writer" element={<StarWriterDetail />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/write-here" element={<WriteHere />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/ourTeam" element={<OurTeam />} />
      
      

      <Route path="/pdfReader" element={<PdfViewer />} />
      
    </Routes>
  </BrowserRouter>
  </DarkLightModeProvider>
);
reportWebVitals();
