import React, { useState, useEffect, Component } from 'react';
import { FaFacebook, FaInstagram, FaAngleDown, FaTimes, FaImages, FaRegTimesCircle } from "react-icons/fa";
import GooglePlay from "../../images/google-Play.png"
import newPostIMg from "../../images/22.gif"
import Write from "../../images/write.png"
import userIcImg from "../../images/userIcImg.png"
import loginBg from "../../images/loginBg.jpg"
import images from "../../images/images.jpg"
import { callingAPI } from '../../service';
import { useNavigate, Link } from 'react-router-dom';
import $ from "jquery"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Footer() {
    const navigate = useNavigate();
    const [footerDetails, setFooterDetails] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const visibleData = showAll ? footerDetails : footerDetails.slice(0, 12);
    const [popupView, setPopupView] = useState(false);
    const [list, setList] = useState([])
    const [username, setUsername] = useState("")
    const [userImage, setUserImage] = useState("")
    const [storiesLength, setStoriesLength] = useState([])
    const handleViewMore = () => {
        setShowAll(true);
    };
    useEffect(() => {
        setPopupView(false);
        listing();
        handlePostList()
        $('#supportPopup').hide();

    }, []);
    const listing = () => {
        const PostData = {};
        callingAPI('book/booksByCategories', PostData).then((response) => {
            console.log("dataaaaaaaaaaaaaaaaaaaaaafooteraaa", response)
            if (response.data.success === '1') {
                setFooterDetails(response.data.bookListCategoryArray);
            }

        })

    }
    const handleWritehere = () => {
        navigate('/write-here');
    }
    const handleBookList = (categoryName) => {
        // debugger
        navigate('/books', { state: { categoryName: categoryName } });
    }
    const newPostPopup = () => {
        debugger
        setPopupView(true)
        $("#newPostMdl").show()
        handlePostList()

    }
    const newPostPopupCancel = () => {
        setPopupView(false)

    }
    const handlePostList = () => {
        const PostData = {
            "user_id": window.localStorage.getItem("userId"),

        }
        callingAPI('post/postList', PostData).then((response) => {
            console.log(("............................... POSTLIST", response));
            if (response.data.result.length > 0 && response.data.result[0].hasOwnProperty('stories')) {
                setList(response.data.result[0].stories)
                console.log(response.data.result[0].stories)
                setUsername(response.data.result[0].userName)
                setUserImage(response.data.result[0].userImage)
                setStoriesLength(response.data.result.length)
            }
            else {
                setList([])
            }
        })
    }
    const supportPopup = () => {
        $('#supportPopup').show();
    }
    const supportPopupClose = () => {
        $('#supportPopup').hide();
        $('#IssueContent').val("")
    }

    const issueSave = () => {
        const PostData = {
            "description": $('#IssueContent').val(),
            "userId": window.localStorage.getItem("userId"),
            "image": ""
        };

        console.log("IssueSave PostData: ", PostData);
        callingAPI('common/issueSave', PostData).then((response) => {
            if (response.data.success === "1") {
                toast.success("Supporting team will contact you soon...", {
                    containerId: "C",
                    autoClose: 3000, // Auto close the toast after 3000 milliseconds (3 seconds)
                    onClose: () => {
                        $('#supportPopup').hide();
                        $('#IssueContent').val("")
                    }
                });
            } else {
                toast.error("Failed to save data", {
                    containerId: "C",
                    autoClose: 3000, // Auto close the toast after 3000 milliseconds (3 seconds)
                    onClose: () => {
                        $('#supportPopup').hide();
                    }
                });
            }
        });
    };
    return (
        <>
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"C"}
                theme="colored" />
            <footer className="footer-wrapper">
                <div className="container">
                    <div className="row footer-outer">
                        <div className="col-lg-6 col-md-5 footer-rightSec">
                            <div className="footer-text-follow">CATEGORIES</div>
                            <div className="row">
                                {visibleData.map((obj, i) => (
                                    <div key={i} className="col-lg-4 col-md-6 col-5">
                                        <span className='category-name' onClick={() => handleBookList(obj.categoryname)}>{obj.categoryname}</span>
                                    </div>
                                ))}

                            </div>
                            {footerDetails.length > 12 && !showAll && (
                                <div className="viewmoreLink" onClick={handleViewMore}>
                                    View More <FaAngleDown />
                                </div>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-7">

                            <div className="d-flex footer-right-sectionIcon">
                                <div className="">
                                    <div className="footer-text-follow">FOLLOW US ON</div>
                                    <ul className='social-icons'>
                                        <li>
                                            <FaFacebook />
                                        </li>
                                        <li>
                                            <FaInstagram />
                                        </li>
                                    </ul>
                                </div>
                                <div className="application-left">
                                    <div className="footer-text-follow">APPLICATION</div>
                                    <img src={GooglePlay} alt="" className='img-fluid google-payImg' />
                                </div>
                            </div>
                            <ul className='footer-list'>
                                <li><Link to="/aboutUs">About Us</Link></li>
                                <li><Link to="/contactUs" >Contact</Link></li>
                                <li><Link to="/ourTeam">Our Team</Link></li>
                                <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            </ul>
                            {window.localStorage.getItem("userId") &&
                                <ul className='footer-list'>
                                    <li onClick={() => supportPopup()}><a>Support</a></li>

                                </ul>
                            }
                        </div>

                    </div>

                </div>
                <div className="sub-footer">
                    <div className="container">
                        <div className="sub-footer-text">© {new Date().getFullYear()} KRITHY. All rights reserved.</div>
                    </div>
                </div>

                {window.localStorage.getItem("userId") &&
                    <>
                        {storiesLength > 0 &&
                            <div className="newPost"
                                onClick={() => newPostPopup()} >
                                <img src={newPostIMg} alt="" className='img-fluid' />
                            </div>
                        }
                    </>
                }




                <div className='writehere-btn'>
                    <div className="theme-btn" onClick={() => handleWritehere()}>
                        <span> <img src={Write} alt="" className='img-fluid' width={25} height={25} />
                            <h3 className="write-text"> Write here</h3>
                        </span>
                    </div>

                </div>

                {/*  */}

                <div id="newPostMdl" class="modal" style={{ display: popupView === true ? "flex" : "none" }} >
                    <div class="modal__content ">
                        <section className='popup1 createStatusPopup'>
                            <span className='postHeadings'>

                                <div className="">
                                </div>
                                <div className="close-popup" style={{ margin: "0px" }} onClick={() => newPostPopupCancel()}>
                                    <FaRegTimesCircle />
                                </div>
                            </span>

                            <div className="create-status-sec newPostScrollauto">
                                <div className="postCards">
                                    <div className="row">
                                        <div className="col-md-9 postCardsAthr">
                                            <h5>{username}</h5>
                                        </div>
                                        <div className="col-md-3 postCardsAthr">
                                            {userImage ? <img src={userImage} alt="" /> :
                                                <img src={userIcImg} alt="" />
                                            }

                                        </div>
                                    </div>
                                </div>
                                {list && list.map((data) =>
                                    <>
                                        <div className="postCards postResults">

                                            {data.title && <h6>{data.title}</h6>}
                                            {data.description && <p>{data.description}</p>}
                                            {data.image && <img className="postCardsImg" src={data.image} alt="" />}

                                        </div>
                                    </>

                                )}

                            </div>
                        </section>
                    </div>
                </div>
                {/*  */}
            </footer>
            <>
               

                <div id="supportPopup" class="modal writeHereModal ChangePaswd"   >
                    <div class="modal__content">
                        <h3 id="popHeading">How Can I Help You?</h3>
                        <div className="modalFields">
                            <div className='write-here-sec ' style={{ background: "transparent" }}>

                                <div className="container write-here-wrapper writeinnerSec">
                                    <div className="add-chaptersq">
                                        <div class="form-group"> 
                                            <textarea className='form-control' name="" id="IssueContent" placeholder='Text your Query?'></textarea>
                                        </div>

                                    </div>
                                    <div className=' actionsBtn'>
                                        <span onClick={() => issueSave()}>Submit</span>

                                    </div>
                                </div>


                            </div>
                        </div>

                        <a onClick={() => supportPopupClose()} class="modal__close"> <FaTimes style={{ color: "#de4200", cursor: "pointer" }} /></a>
                    </div>
                </div>
            </>
        </>

    )

}
export default Footer