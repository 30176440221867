import React, { useState, useEffect, Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { callingAPI } from '../../service';
import $, { data } from "jquery"
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';

import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Select from "react-select";
import { convertToHTML } from 'draft-convert';
import { ToastContainer, toast } from 'react-toastify';
import AWS from 'aws-sdk';
var s3;

function EditBook(props) {

    const { bookId, user_id } = useParams();
    const navigate = useNavigate()
    const [bookDetail, setBookDetail] = useState([]);
    const [content, setContent] = useState("");
    const [htmlcontent, setHtmlContent] = useState("");
    const [title, setTitle] = useState("");
    const [language, setLanguage] = useState("");
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML("<p>Please enter your text</p>")
            )
        ),
    );



    const [file, setFile] = useState('');
    const [imageName, setImageName] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [imagePathName, setImagePathName] = useState('');


    const [cofigDetails, setConfigDetails] = useState('')
    const [environmentType, setEnvironmentType] = useState('');
    const [categoryImagePath, setCategoryImagePath] = useState('');
    const [imgObject, setImgObject] = useState('');
    const [languageList, setLanguageList] = useState([]);


    const handleEditorState = (value) => {
        const rawData = convertToRaw(value.getCurrentContent());
        setEditorState(value);
        const htmlContent = convertToHTML(value.getCurrentContent());
        setHtmlContent(htmlContent);
        setContent(rawData.blocks.map(block => block.text).join("\n"));
    }

    useEffect(() => {
        getConfiguration()

    }, [props.data.bookId]);

    const booksEdits = () => {
        const PostData = {
            "bookId": props.data.bookId,
            "userId": window.localStorage.getItem("userId")
            // "userId": props.data.user_id
        };
        callingAPI('book/getBookDetails', PostData).then((response) => {
            if (response.data.success === '1') {
                setBookDetail(response.data.result)
                setTitle(response.data.result[0].title)
                setImagePath(response.data.result[0].bookImageWithPath)
                setImagePathName(response.data.result[0].image)
                setLanguage({
                    value: response.data.result[0].languageId,
                    label: response.data.result[0].languageName
                })
                const [data] = response.data.result
                setHtmlContent(data.description)
                setContent(data.description_without_html)
                setEditorState(EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(data.description)
                    )
                ),)
            }

        })

    }


    const handleWriteToFile = async () => {
        if (!title) {
            toast.error("Please enter title", { containerId: "M" })
            return false
        }
        else if (!file && !imagePathName) {
            toast.error("Please upload image", { containerId: "M" })
            return false
        }
        else if (!htmlcontent) {
            toast.error("Please enter description", { containerId: "M" })
            return false
        }
        else if (language.length == 0) {
            toast.error("Please select language", { containerId: "M" })
            return false
        }
        else {
            const textContent = htmlcontent;
            const blob = new Blob([textContent], { type: 'text/plain' });
            textFileS3Save(`book_${window.localStorage.getItem("userId")}_${new Date().getTime()}.txt`, blob);
        }
    };




    const savebookDetails = async (textFileData) => {
        try {

            const PostData = {
                "bookId": props.data.bookId,
                "title": title,
                "description": "",
                "isSeriesCompleted": bookDetail[0].is_series_completed,
                "description_without_html": "",
                "price": "",
                "image": imageName !== "" ? imageName : imagePathName,
                "createdOn": new Date().getTime(),
                "userId": props.data.user_id,
                "statusId": bookDetail[0].status_id,
                "languageId": language.value,
                "bookCategories": bookDetail[0].bookCategories.map((obj) => obj.category_id),
                "bookTypes": bookDetail[0].bookTypes.map((obj) => obj.type_id),
                "bookContentFileS3Url": textFileData.Location,
            };
            
            console.log("🚀 ~ savebookDetails ~ PostData:", PostData)
            const response = await callingAPI('book/bookCategorySave', PostData);
            if (response.data.success === '1') {
                if (file.length !== 0) {
                    await imageS3Save(imageName, file);
                }
                toast.success("Books Details Updated Successfully", { containerId: "M" })
                props.backToList()
            }

        } catch (error) {
            console.error("Error:", error);
        }
    }



    const textFileS3Save = (imageName, blob) => {
        return new Promise((resolve, reject) => {
            try {

                var S3BucketMainFolder = environmentType + '/' + categoryImagePath;
                var fullFilePath = S3BucketMainFolder + '/' + imageName;

                s3.upload(
                    {
                        Key: fullFilePath,
                        Body: blob, // Upload the Blob object
                        ACL: 'public-read', // Optional: Set access control (consider security)
                    },
                    (err, data) => {
                        if (err) {
                            alert('There was an error uploading your photo: ' + err.message);
                            reject(err);
                        } else {
                            console.log("File uploaded to S3 successfully!", data);
                            resolve(true);
                            savebookDetails(data);
                        }
                    }
                );
            } catch (error) {
                console.error("Error uploading file to S3:", error);
                reject(error); // Propagate errors for handling in calling code
            }
        });
    };




    const getConfiguration = () => {
        return new Promise((resolve, reject) => {
            try {
                const PostData = {
                };
                callingAPI('configurationData', PostData).then((res) => {
                    setConfigDetails(res.data.result[0]);
                    const getConfigValue = (configKey) => {
                        return res.data.result[0].configDetails
                            ? res.data.result[0].configDetails.find((config) => config.config_key === configKey)?.config_value
                            : null;
                    };
                    setEnvironmentType(getConfigValue("S3BucketMainFolder"));
                    setCategoryImagePath(getConfigValue("bookImagePath"));
                    AWS.config.update({
                        accessKeyId: getConfigValue("AWSAccessKeyId"),
                        secretAccessKey: getConfigValue("AWSSecretKey"),
                        region: getConfigValue("s3Region"),
                    });
                    var bucketParams = {
                        Bucket: getConfigValue("s3bucket"),
                    };
                    s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
                    getLanguageList()
                    resolve(s3)
                })

            }
            catch (e) {
                console.error("Error in getConfiguration", e)
                reject(e)
            }
        })


    }


    const imageS3Save = (imageName, file) => {
        return new Promise((resolve, reject) => {

            var S3BucketMainFolder = environmentType + '/' + categoryImagePath;
            var fullFilePath = S3BucketMainFolder + '/' + imageName;
            s3.upload(
                {
                    Key: fullFilePath,
                    Body: file,
                    ACL: 'public-read',
                },
                (err, data) => {
                    if (err) {
                        alert('There was an error uploading your photo: ' + err.message);
                        reject(err);
                    } else {
                        //  alert("File Uploaded Successfully")
                        resolve(data);
                        document.getElementById('uploadButton').style.display = 'block';

                    }
                }
            );
        })
    };

    const handleImageUploadOnChange = (e) => {

        if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
            toast.error("Please attach jpg,jpeg or png files", { containerId: "M" })
            return false;
        }
        setFile(e.target.files[0]);
        setImageName(e.target.files[0].name);
        const obj = URL.createObjectURL(e.target.files[0])
        setImgObject(obj);
        document.getElementById('uploadButton').style.display = 'block';

    };


    const getLanguageList = () => {
        const PostData = {

        };
        callingAPI('languageListing', PostData).then((res) => {
            setLanguageList(res.data.result);
            booksEdits();

        })
    }


    return (
        <>
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"M"}
                theme="colored" />
            {/* {bookDetail && bookDetail.map((obj, i) => ( */}
            <div className="container booksList-Wrapper text-center booksList-EditWrapper">



                <div className="form-group row mb-2">
                    <label for="title" className="col-sm-2 col-form-label">Title <sup style={{ color: "red" }}>*</sup></label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                </div>
                <div className="form-group row mb-2">
                    <label for="author" className="col-sm-2 col-form-label">Image<sup style={{ color: "red" }}>*</sup></label>
                    <div className="col-sm-10" style={{ textAlign: "left" }}>
                        <span id="uploadButton" className="uploadButton uploadButtonSmall">
                            <input
                                type="file"
                                id="file1"
                                title="Upload"
                                onChange={(e) => handleImageUploadOnChange(e)}
                                hidden
                            />
                            <label for="file1" className="btn-upload padd-upload">
                                upload
                            </label>
                            <img src={imgObject !== "" ? imgObject : imagePath} alt="" width={40} height={40} />

                        </span>
                    </div>
                </div>

                <div className="form-group row mb-2 description-editor">
                    <label for="description" className="col-sm-2 col-form-label">Description<sup style={{ color: "red" }}>*</sup></label>
                    <div className="col-sm-10">
                        <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            editorState={editorState}
                            onEditorStateChange={handleEditorState}
                        />
                    </div>
                </div>
                <div className="form-group row mb-2">
                    <label for="language" className="col-sm-2 col-form-label">Language<sup style={{ color: "red" }}>*</sup></label>
                    <div className="col-sm-4">
                        {/* <input type="text" className="form-control" placeholder="Language" defaultValue={obj.languageName} onChange={(e)=>setLanguage(e.target.value)}/> */}
                        <Select
                            value={language}
                            placeholder="Select" className="select-language"
                            options={languageList.map(item => ({
                                value: item.id,
                                label: item.name,
                            }))}
                            onChange={(e) => {
                                setLanguage(e)
                            }}
                        />
                    </div>
                </div>
                <div className="">
                    <button className="btnNavy btn-saveBook" onClick={() => handleWriteToFile()}>Save</button>
                    <button className="btnNavy btn-saveBook btnCancel" onClick={props.backToList}>Cancel</button>
                </div>
            </div>
            {/* ))} */}

        </>
    )

}
export default EditBook