import axios from "axios";
//  const apiPath = "https://1t1oi98ul2.execute-api.us-west-2.amazonaws.com/api/"  //test
  const apiPath = "https://sdpv2xn9gk.execute-api.us-west-2.amazonaws.com/api/"  //production


export function callingAPI(path, postData) {
  const apiUrl = apiPath + path
  return axios.post(apiUrl, postData)
    .then((data) => data)
    .then((res) => res)
    .catch((err) => console.log(err, 'err'));
}

