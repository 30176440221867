import React, { useEffect } from 'react';
function NoData(props) {

    return (

        <div class="nodata-wrapper card" style={{ margin:"150px 0px"}}>
                <div class="alert alert-danger bg-white w-100 text-center" role="alert">
                    No Data Found
                </div>
        </div>
    );
}
export default NoData;
