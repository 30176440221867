import React, { useState, useEffect, Component } from 'react';
import Header from '../Home/layout/header';
import Footer from '../Home/layout/footer';
import { FaLayerGroup, FaStar, FaUser, FaClock, FaBookReader, FaArrowLeft, FaCalendar, FaRegTimesCircle, FaUserPlus, FaUserMinus, FaTimes } from "react-icons/fa";
import EpisodeListHome from '../Home/episodeList';
import Reviews from './reviews';
import { callingAPI } from '../service';
import { Rating } from 'react-simple-star-rating'
import EpisodeWiseReview from "./episodeWiseReview"
import { useLocation } from 'react-router-dom';
import Write from "../images/write.png"
import { Link } from 'react-router-dom';
import LoginPopup from './loginPopUp';
import ReadMore from "./readMore"
import { useNavigate } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import Select from "react-select";
import $ from "jquery";
import addTolibraryIcon from "../images/addTolibraryIcon.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EpisodeDetail from './episodeDetail'
import styled from 'styled-components';
import Parser from 'html-react-parser';
function BookDetailHome(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const userID = (location.state && location.state.userID) ? location.state.userID : [];
    const pageName = (location.state && location.state.pageName) ? location.state.pageName : [];
    const categoryValue = (location.state && location.state.categoryValue) ? location.state.categoryValue : [];
    const [bookDetail, setBookDetail] = useState([]);
    const [episodeLists, setEpisodeLists] = useState([]);
    const [lastCompletedEpisode, setLastCompletedEpisode] = useState(null);
    const [nextEpisode, setNextEpisode] = useState(null);
    const [lastEpisodeOfList, setLastEpisodeOfList] = useState("");
    const [hasRead, setHasRead] = useState("");
    const [firstEpisodeId, setFirstEpisodeId] = useState(null);
    const [progressPercentages, setProgressPercentages] = useState({})
    // const [singleClick, setSingleClick] = useState(false)
    const [library, setLibrary] = useState([]);
    const [libraryStatus, setLibraryStatus] = useState("Library");
    const [rating, setRating] = useState(0)
    const [episodeClickParent, setepisodeClickParent] = useState(false);
    const [episodeID, setEpisodeID] = useState("");
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [showReadMore, setShowReadMore] = useState(false);
    const [episodeClick, setepisodeClick] = useState(false);
    const [htmlcontent, setHtmlContent] = useState("");
    const [bookId, setBookId] = useState([]);
    const [isReading, setIsReading] = useState(false);
    const [followStatusValue, setFollowStatusValue] = useState("")
    const [currentDate] = useState(new Date().getTime())
    const [followerCount, setFollowerCount] = useState(); // State variable to hold follower count

    const [userDataList, setUserDataList] = useState([])
    const [profileImage, setProfileImage] = useState([])
    const [coverImage, setCoverImage] = useState([])
    const [followersList, setFollowersList] = useState([])
    const [followingList, setFollowingList] = useState([])
    const [activeTab, setActiveTab] = useState(0);
    const [showReviewPopup, setShowReviewPopup] = useState(false)
    const [updated, setupdated] = useState(new Date())
    const [showReadMorePopup, setShowReadMorePopup] = useState(false)

    const [bookStatus, setbookStatus] = useState([
        {
            value: "3",
            label: "Publish",
        },
        {
            value: "1",
            label: "Draft",
        }, {
            value: "4",
            label: "Un-Publish",
        }]);
    const [title, setTitle] = useState("");
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML("")
            )
        ),
    );
    const [content, setContent] = useState("");
    const [status, setStatus] = useState([]);
    // const maxLengthThreshold = 500;
    // const handleRating = (rate) => {
    //     console.log('Rating clicked:', rate);
    //     // Add your logic here
    // };
    const [popupView, setPopupView] = useState(false);
    const maxLengthThreshold = 200;
    const onPointerEnter = () => {
        console.log('Mouse entered');
    };
    const onPointerLeave = () => {
        console.log('Mouse left');
    };
    const onPointerMove = (value, index) => {
        console.log('Mouse moved over', value, 'at index', index);
    };









    const closeReviewPopup = () => {
        setShowReviewPopup(false)
    }
    const handleShowReviewPopup = () => {
        setShowReviewPopup(true)
    }



    useEffect(() => {
        console.log("Props.....................,", userID);
        window.scrollTo(0, 0);
        if (window.localStorage.getItem("userId") == null) {
            showPopup()
        }
        else {
            bookDetailInfo();

        }

        setPopupView(false);
        // setSingleClick(false)
        followStatus();
        // =============================
        var btn = $('#button');

        $(window).scroll(function () {
            if ($(window).scrollTop() > 100) {
                btn.addClass('show');
            } else {
                btn.removeClass('show');
            }
        });

        btn.on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '100');
        });


        // =========== Restrict right menu action ====================
        $(document).bind("contextmenu", function (e) {
            return false;
        });
        // ============ Restrict Print screen ===============================================

        document.addEventListener('keyup', (e) => {
            if (e.key == 'PrintScreen') {
                navigator.clipboard.writeText('');
            }

        });
        document.addEventListener('keydown', (e) => {
            if (e.ctrlKey && e.key == 'p') {
                // alert('This section is not allowed to print or export to PDF');
                e.cancelBubble = true;
                e.preventDefault();
                e.stopImmediatePropagation();
            }

        });
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Meta' && e.code === 'PrintScreen') {
                e.preventDefault();
                e.stopImmediatePropagation();
                navigator.clipboard.writeText('');
            }
        });


        // ===============================

    }, []);




    const bookDetailInfo = () => {
        const PostData = {
            "bookId": userID,
            "userId": window.localStorage.getItem("userId")
        };
        callingAPI('book/getBookDetails', PostData).then((response) => {
            console.log(" === jjjjjjjjjjjjjjjjjjjjjjjj", response)
            if (response.data.success === '1') {
                setBookDetail(response.data.result);
                setEpisodeLists(response.data.result[0].episodeDetails);
                setBookId(response.data.result[0].bookId);
                console.log("==========>> BOOK DETAIL INFO", response.data.result)
                const progressData = {};
                response.data.result.forEach(book => {
                    book.episodeDetails.forEach(episode => {
                        progressData[episode.id] = episode.readingPercentage;
                    });
                });

                const lastEpisode = response.data.result[0].episodeDetails[response.data.result[0].episodeDetails.length - 1];
                setLastEpisodeOfList(lastEpisode)
                console.log("LLLLLLLLLLLLLLLLLLLLL", lastEpisode);
                console.log("🚀 ~ callingAPI ~ progressData:", progressData)
                console.log("🚀 ~ 1111111111111111111111111111111111:", response.data.result)
                setProgressPercentages(progressData);
                setepisodeClick(false)


            }
            console.log("🚀 ~ callingAPI ~ response.data.result[0].episodeDetails:", response.data.result[0].episodeDetails)
        })
    }
    const displayBookDuration = (decimalMinutes) => {
        const totalSeconds = Math.round(decimalMinutes * 60);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        let result = '';
        if (hours > 0) {
            result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
        }
        if (minutes > 0) {
            result += `${result.length > 0 ? ' and ' : ''}${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        }
        if (seconds > 0 && hours <= 0 && minutes <= 0) {
            result += `${result.length > 0 ? ' and ' : ''}${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
        }
        if (result.length === 0) {
            result += 'Less than a minute';
        }
        return result;
    }
    const readMore = (obj) => {

        // setNextEpisode(obj.episodeDetails[0])
        setepisodeClick(true)
        setShowReadMore(true);
        setepisodeClickParent(true)
    };
    const readMoreDescription = (obj) => {
        console.log("0000000000000000000000000", obj.description);
        setShowReadMorePopup(true)
    };

    const closePopupReadMore = () => {
        setShowReadMorePopup(false)
    }

    const showPopup = () => {
        setPopupVisibility(true);
    };
    const hidePopup = () => {
        // setPopupVisibility(false);
    };

    const addChapters = () => {
        setPopupView(true);
    }
    const closePopup = () => {
        setPopupView(false);
        setTitle("");
        setStatus("");
        setEditorState("")
    }
    const handleEditorState = (value) => {
        const rawData = convertToRaw(value.getCurrentContent());
        setEditorState(value);
        const htmlContent = convertToHTML(value.getCurrentContent());
        setHtmlContent(htmlContent);
        setContent(rawData.blocks.map(block => block.text).join("\n"));
    }

    const handleSave = async () => {
        debugger
        try {
            var postData = {

                "episodeId": "",
                "title": title,
                "description": content,
                "description_without_html": content,
                "image": "",
                "createdOn": new Date().getTime(),
                "bookId": bookId,
                "createdBy": window.localStorage.getItem("userId"),
                "statusId": status.value

            }
            const response = await callingAPI('episodeData/episodeSave', postData);

            if (response.data.success == 1) {
                alert("Added Successfully")
                setTitle("");
                setStatus("");
                setEditorState("")
            }
        }
        catch (error) {
            console.error("Error:", error);
        }
    }

    const handleBookList = () => {
        debugger
        if (pageName === "booksList") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate("/books", { state: { categoryValueData: categoryValue } })

        }
        else {
            navigate("/")
        }
    }
    useEffect(() => {
        const PostData = {
            "userId": window.localStorage.getItem("userId")
        };
        callingAPI('library/myLibraryDetails', PostData).then((response) => {
            if (response.data.success === '1') {
                var resultdata = response.data.result.myLibrary;

                const filterData = resultdata.filter((libraryItem) => libraryItem.bookId === bookId)
                setLibraryStatus(filterData.length > 0 ? "Added in Library" : "Library")
                // setLibrary(response.data.result.myLibrary);

            }
        })
    },)



    const addTolibrary = (item, bookId) => {
        const PostData = {
            "book_id": bookId,
            // "episode_id": epiId,
            "episode_id": "",
            "user_id": window.localStorage.getItem("userId"),
            "created_on": item.created_on
        };

        callingAPI('library/addLibraryDetails', PostData).then((response) => {
            console.log("ADD LIBRARY RESPONSE", response)
            if (response.data.success === '1') {
                setLibraryStatus("Added in library")
                toast.success("Added in Library.", { containerId: "C" })
            }
        })
    }
    const options1 = {
        // weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    };




    useEffect(() => {
        findLastCompletedEpisode();
    }, [episodeLists]);

    const findLastCompletedEpisode = () => {
        // debugger
        //alert("findLastCompletedEpisode")
        if (episodeLists.length > 0) {
            setHasRead("0")
        }
        let hasReading = episodeLists.filter((obj) => (obj.readingPercentage > 0 && obj.readingPercentage < 100))
        if (hasReading.length > 0) {
            setHasRead("1")
        }
        else {
            let hasReadingWithNextEpisode = episodeLists.filter((obj) => (obj.readingPercentage === 100))
            if (hasReadingWithNextEpisode.length > 0 && hasReadingWithNextEpisode.length !== episodeLists.length) {
                setHasRead("2")
            }
        }
        setupdated(new Date())

        // setLastCompletedEpisode(null);
        // for (let i = episodeLists.length - 1; i >= 0; i--) {
        //     if (episodeLists[i].readingPercentage === 100) {
        //         setLastCompletedEpisode(episodeLists[i]);
        //         return;
        //     }
        // }

        // let hasReading = episodeLists.filter((obj)=>(obj.readingPercentage > 0 && obj.readingPercentage !== 100))
        // if(hasReading.length > 0){
        //     setHasRead("1")
        // }

        // If no episode found with readingPercentage 100
        // setLastCompletedEpisode(null);
        // for (let i = episodeLists.length - 1; i >= 0; i--) {
        //     if (episodeLists[i].readingPercentage === 100) {
        //         setLastCompletedEpisode(episodeLists[i]);
        //         return;
        //     }
        // }

        // setNextEpisode(null); // Reset next episode
    };

    useEffect(() => {
        if (lastCompletedEpisode !== null) {
            const lastCompletedIndex = episodeLists.indexOf(lastCompletedEpisode);
            if (lastCompletedIndex < episodeLists.length - 1) {
                const nextEpisode = episodeLists[lastCompletedIndex + 1];
                setNextEpisode(nextEpisode);
            } else {
                setNextEpisode(null); // There are no more episodes after the last completed one
            }
        } else {
            setNextEpisode(null); // No completed episode found
        }
    }, [lastCompletedEpisode, episodeLists]);


    const readNextEpisode = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // setbookObj(nextEpisode)
        setepisodeClick(true)
        setepisodeClickParent(true)
        setEpisodeID(nextEpisode.id)

    };
    const startReading = () => {
        setIsReading(true);
        // console.log("000000000000000000000000000",episodeLists.length);
        // if (episodeLists.length > 0) {
        //     setFirstEpisodeId(episodeLists[0].id)
        //     console.log("First Episode ID:", firstEpisodeId);
        // } else {
        //     console.log("No episodes available to start reading.");
        // }
        console.log("000000000000000000000000000", episodeLists[0].id);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // setbookObj(episodeLists[0])
        setepisodeClick(true)
        setepisodeClickParent(true)
        setNextEpisode(episodeLists[0]);
        setEpisodeID("")
    };

    const goToAuthorsPage = (userID) => {
        navigate('/star-writer', { state: { userID: userID } });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // const followStatus = () => {
    //     debugger
    //     const PostData = {
    //         "userId": window.localStorage.getItem("userId"),
    //         "followerId": userID
    //     };
    //     callingAPI('book/getFollowingStatus', PostData).then((response) => {
    //         setFollowStatusValue(response.data.result[0].status)
    //         console.log("Star Writers Follower status book detail page ::", response.data.result[0].status)
    //     })
    // }
    // const handleFollow = () => {
    //     debugger
    //     if (window.localStorage.getItem("userId") == null) {
    //         showPopup()
    //     }
    //     else {
    //     const PostData = {
    //         "followerId": userID,
    //         "userId": window.localStorage.getItem("userId"),
    //         "createdOn": currentDate

    //     };
    //     callingAPI('book/follow', PostData).then((response) => {
    //         setFollowerCount(prevCount => prevCount + 1);
    //         followStatus()
    //         // $("#FollowBKDtl").hide();
    //     })
    //     }
    // }

    // ======================================

    useEffect(() => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        }
        else {
            followStatus()
            followListHandle()

            const PostData = {
                "userId": userID
            };
            callingAPI('userData/userProfileDetails', PostData).then((response) => {
                if (response.data.success === '1') {
                    setUserDataList(response.data.userData)
                    setProfileImage(response.data.userData[0].profilePic)
                    setCoverImage(response.data.userData[0].coverPic)
                    setFollowerCount(response.data.userData[0].followers)
                    console.log("Star Writers FollowerCount", response.data.userData[0].followers)
                }

            })
        }
    }, [])

    const followStatus = () => {
        const PostData = {
            "userId": window.localStorage.getItem("userId"),
            "followerId": userID
        };
        callingAPI('book/getFollowingStatus', PostData).then((response) => {
            setFollowStatusValue(response.data.result[0].status)
            console.log("Star Writers Follower status", response.data.result[0].status)
        })
    }

    const followListHandle = () => {
        const PostData = {
            "userId": window.localStorage.getItem("userId"),
            "authorId": userID
        };
        callingAPI('book/followerList', PostData).then((response) => {
            setFollowersList(response.data.result.followersData)
            setFollowingList(response.data.result.followedData)
        })
    }
    // const showPopup = (id, data) => {
    //     if (window.localStorage.getItem("userId") == null) {
    //         showPopup1()
    //     }
    //     else {
    //         if (data === "followers") {
    //             setActiveTab(0);
    //         } else {
    //             setActiveTab(1);
    //         }
    //         const popup = document.getElementById(id);
    //         if (popup) {
    //             popup.style.display = 'block';
    //         }
    //     }
    // };

    // const hidePopup = (id) => {
    //     setActiveTab(0);
    //     const popup = document.getElementById(id);
    //     if (popup) {
    //         popup.style.display = 'none';
    //     }
    // };
    const handleFollow = () => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        }
        else {
            const PostData = {
                "followerId": userID,
                "userId": window.localStorage.getItem("userId"),
                "createdOn": currentDate

            };
            callingAPI('book/follow', PostData).then((response) => {
                setFollowerCount(prevCount => prevCount + 1);
                followStatus()
            })
        }
    }
    const handleUnFollow = () => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        }
        else {
            const PostData = {
                "followerId": userID,
                "userId": window.localStorage.getItem("userId"),
                "createdOn": currentDate

            };
            callingAPI('book/unfollow', PostData).then((response) => {
                setFollowerCount(prevCount => Math.max(prevCount - 1, 0)); // Ensure follower count doesn't go below 0
                followStatus()
            })
        }
    }

    const handleFollowersFollow = (id) => {


        const PostData = {
            "followerId": id,
            "userId": window.localStorage.getItem("userId"),
            "createdOn": currentDate

        };
        callingAPI('book/follow', PostData).then((response) => {
            followListHandle()
        })

    }
    const handleFollowersUnFollow = (id) => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        } else {
            const PostData = {
                "followerId": id,
                "userId": window.localStorage.getItem("userId"),
                "createdOn": currentDate

            };
            callingAPI('book/unfollow', PostData).then((response) => {
                followListHandle()
            })
        }
    }
    const showPopup1 = () => {
        setPopupVisibility(true);
    };
    const hidePopup1 = () => {
        // setPopupVisibility(false);
    };
    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    return (
        <div className='section-bookdetail position-relative'>
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"C"}
                theme="colored" />
            <Header isPopupVisible={isPopupVisible} showPopup={showPopup} hidePopup={hidePopup} />

            <div className="book-detail-wrapper">
                {(episodeClickParent == true && episodeClick == false) && (
                    <div className='floating '>
                        <div className=" aweReview">
                            <h3 onClick={() => handleShowReviewPopup()}>Reviews</h3>
                        </div>
                    </div>
                )}
                <div className="container 11">
                    {bookDetail && bookDetail.map((obj, i) => (
                        <>
                            {(!episodeClickParent) && (
                                <div className="bookdetail-outer" dataChanged={updated}>
                                    <div className="row">
                                        <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px", padding: "10px 20px" }}>
                                            <div className="text-left backTo-btn 111111" id="button"><span onClick={() => handleBookList()}
                                            // to={pageName === "booksList" ? "/books" : "/"}
                                            ><FaArrowLeft title='Back' /></span></div>
                                            {/* <span title='Edit'
                                            onClick={() => handleEditClick(obj)}
                                        >
                                            <FaEdit className='editiconBlog' />
                                        </span> */}
                                            {window.localStorage.getItem("userId") === obj.authorId ?
                                                <span className='addChapBtn' onClick={() => addChapters()}>
                                                    Add Chapters
                                                </span>
                                                : ""}
                                        </span>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="bookdetailImg">
                                                <img src={obj.bookImageWithPath} alt="" className='img-fluid' />
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8">
                                            <div className="book-detail-content">
                                                <h3>{obj.title}</h3>
                                                <div className="rating-wrpr AweratingWrpr">
                                                    <span>
                                                        <Rating
                                                            initialValue={Math.round(obj.avgBookRating)}
                                                            readonly
                                                            // onClick={handleRating}
                                                            onPointerEnter={onPointerEnter}
                                                            onPointerLeave={onPointerLeave}
                                                            onPointerMove={onPointerMove}
                                                        /* Available Props */
                                                        />
                                                        <span className='rating-text'>{(obj.avgBookRating).toFixed(1)} ({obj.no_of_reviews})</span>
                                                    </span>
                                                    <span className='addTolibraryIcon' onClick={libraryStatus === "Added in Library" ? null : () => addTolibrary(obj, obj.bookId)}>
                                                        <img src={addTolibraryIcon} className='libraryIcon' />
                                                        <span className='libraryTxt' id="libraryTxt">{libraryStatus} </span>
                                                    </span>
                                                </div>
                                                <div className="bookdetail-author d-flex align-items-center">
                                                    {obj.authorImageFullpath ? <img src={obj.authorImageFullpath} className='athrImg' onClick={() => goToAuthorsPage(obj.authorId)} style={{ cursor: "pointer" }} /> : <FaUser onClick={() => goToAuthorsPage(obj.authorId)} style={{ cursor: "pointer" }} />}
                                                    <span onClick={() => goToAuthorsPage(obj.authorId)} style={{ cursor: "pointer" }}>{obj.authorName}</span> <span className='vLine'>|</span>
                                                    <FaCalendar />

                                                    {(new Intl.DateTimeFormat('en-GB', options1).format(obj.lastUpdatedDate))}

                                                    <div className="proFollwBtn" style={{ marginLeft: "15px" }}>

                                                        {followStatusValue != "NotFollowing" ?
                                                            // <div className="follow-btn-div1">

                                                            //     <button onClick={() => handleUnFollow()} className='unfolow-btn' ><FaUserMinus /><span>UnFollow</span></button>
                                                            // </div>
                                                            ""
                                                            :
                                                            <div className="follow-btn-div1">
                                                                <button onClick={() => handleFollow()} className='follow-btn'><FaUserPlus /><span>Follow</span></button>
                                                            </div>}
                                                    </div>


                                                </div>
                                                <div className='AweCategory'>
                                                    <ul>
                                                        {obj.bookCategories.map((item) => (
                                                            <li>{item.categoryName}</li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                {/* <p className="" >{Parser(obj.description_without_html)}</p> */}
                                                <div className="detail-decsrptn" dangerouslySetInnerHTML={{ __html: obj.description }}></div>
                                                {/* {obj.description.length > maxLengthThreshold &&
                                                    <div className="detail-readmore" onClick={() => readMore(obj)}>Read Description</div>} */}
                                                {episodeLists.length <= 0 ?
                                                    <div className="detail-readmore" onClick={() => readMore(obj)}>Read</div> :
                                                    (obj.description.length > maxLengthThreshold ?
                                                        <div className="detail-readmore readEpi" onClick={() => readMoreDescription(obj)}>Read Description</div> :
                                                        ""
                                                    )
                                                }


                                                <div id="demo-modal" class="modal writeHereModal ChangePaswd" style={{ display: showReadMorePopup === true ? "flex" : "none" }} >
                                                    <div class="modal__content">
                                                        <div className="modalFields">
                                                            <div className='write-here-sec ' style={{ background: "transparent" }}>

                                                                <div className="container readMoreDescription">
                                                                    {Parser(obj.description)}
                                                                </div>


                                                            </div>
                                                        </div>

                                                        <a onClick={() => closePopupReadMore()} class="modal__close"> <FaTimes style={{ color: "#de4200", cursor: "pointer" }} /></a>
                                                    </div>
                                                </div>

                                                {console.log("lastCompletedEpisode ", lastCompletedEpisode)}
                                                {console.log("lastCompletedEpisode ", lastCompletedEpisode)}
                                                {console.log("nextEpisode ", nextEpisode)}
                                                {hasRead === "0" && (
                                                    <div className="detail-readmore readEpi readNextEpi" onClick={startReading}>Start Reading</div>
                                                )}
                                                {hasRead === "1" && (
                                                    <div className="detail-readmore readEpi readNextEpi" onClick={startReading}>Continue Reading</div>
                                                )}
                                                {hasRead === "2" && (
                                                    <div className="detail-readmore readEpi readNextEpi" onClick={startReading}>Read Next Episode</div>
                                                )}

                                                {/* {!isReading && !lastCompletedEpisode && episodeLists.length > 0 && (
                                                    <div className="detail-readmore readEpi readNextEpi" onClick={startReading}>Start Reading</div>
                                                )}
                                                {lastCompletedEpisode && lastCompletedEpisode.readingPercentage === 100 && nextEpisode ?
                                                    <div className="detail-readmore readEpi readNextEpi" onClick={() => readNextEpisode()}>Read Next Episode</div>
                                                    : ""}

                                                {lastCompletedEpisode && lastCompletedEpisode.readingPercentage > 0 && lastCompletedEpisode.readingPercentage < 100 && (
                                                    <div className="detail-readmore readEpi readNextEpi" onClick={startReading}>
                                                        Continue Reading
                                                    </div>
                                                )} */}
                                            </div>

                                            <div className="bookdetail-bottom">
                                                {obj.avgBookRating !== 0 &&
                                                    <>
                                                        <div className="d-flex align-items-center">
                                                            <FaStar /> <span>{obj.avgBookRating.toFixed(1)}</span>
                                                            <div className="m-left">Rating</div>
                                                        </div>
                                                        <div className="v-line"></div>
                                                    </>
                                                }
                                                {obj.noOfEpisodes > 0 &&
                                                    <>
                                                        <div className="d-flex align-items-center">
                                                            <FaLayerGroup /> <span>{obj.noOfEpisodes}</span>
                                                            <div className="m-left">Episodes</div>
                                                        </div>
                                                        <div className="v-line"></div>
                                                    </>
                                                }

                                                <div className="d-flex align-items-center">
                                                    <FaClock /> <span>{displayBookDuration(obj.duration)}</span>
                                                    <div className="m-left"></div>
                                                </div>
                                                <div className="v-line"></div>
                                                <div className="d-flex align-items-center">
                                                    <FaBookReader /> <span>{obj.no_of_views}</span>
                                                    <div className="m-left">Read Count</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {(showReadMore && episodeClick == true) && <ReadMore bookObj={obj} setepisodeClickParent={setepisodeClickParent} setEpisodeID={setEpisodeID} setepisodeClick={setepisodeClick} />}
                            {(obj.noOfEpisodes > 0 && episodeClick == false) &&
                                <EpisodeListHome setEpisodeLists={setEpisodeLists} findLastCompletedEpisode={findLastCompletedEpisode} id={obj.bookId} setepisodeClickParent={setepisodeClickParent} setEpisodeID={setEpisodeID} episodeID={episodeID} setepisodeClick={setepisodeClick} />
                            }
                            {(obj.noOfEpisodes > 0 && episodeClick == true) &&
                                <EpisodeDetail findLastCompletedEpisode={findLastCompletedEpisode} episodeListfunction={bookDetailInfo} bookObj={nextEpisode} setepisodeClick={setepisodeClick} setepisodeClickParent={setepisodeClickParent} setProgressPercentages={setProgressPercentages} readNextEpisode={readNextEpisode} episodeLists={episodeLists} setEpisodeID={setEpisodeID} episodeID={episodeID} lastEpisodeOfList={lastEpisodeOfList} setLastEpisodeOfList={setLastEpisodeOfList} />
                            }
                            {/* {(episodeClickParent == true && episodeClick == false) && (
                                <EpisodeWiseReview episodeID={episodeID} id={obj.bookId} />
                            )} */}
                            {!episodeClickParent && (
                                <Reviews id={obj.bookId} />
                            )}



                            {/* Episodewise Review */}

                            {(episodeClickParent == true && episodeClick == false) && (
                                <div id="demo-modal" class="modal writeHereModal ChangePaswd" style={{ display: showReviewPopup === true ? "flex" : "none" }} >
                                    <div class="modal__content aweRevw">
                                        <h3 id="popHeading">Reviews</h3>
                                        <div className="modalFields">
                                            <div className='write-here-sec ' style={{ background: "transparent" }}>
                                                <EpisodeWiseReview episodeID={episodeID} id={obj.bookId} />
                                            </div>
                                        </div>

                                        <a onClick={() => closeReviewPopup()} class="modal__close"> <FaTimes style={{ color: "#de4200", cursor: "pointer" }} /></a>
                                    </div>
                                </div>
                            )}
                            {/* Episodewise Review */}

                        </>
                    ))}
                </div>
            </div>
            {showLoginPopup && <LoginPopup show={showLoginPopup} onClose={hidePopup} />}


            <Footer />
            {/* =============== MODAL BEGINS HERE =============== */}
            <div id="demo-modal" class="modal writeHereModal" style={{ display: popupView === true ? "flex" : "none" }} >
                <div class="modal__content">
                    <h3 id="popHeading">Add Chapters</h3>
                    <div className="modalFields">
                        {/* ----------------------------- */}
                        <div className='write-here-sec ' style={{ background: "transparent" }}>

                            <div className="container write-here-wrapper writeinnerSec">
                                <div className="add-chapters">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Title</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Description</label>
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            editorState={editorState}
                                            onEditorStateChange={handleEditorState}
                                            placeholder='Please enter your text'
                                        />
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <div className="col-md-6">  <div className="form-group">
                                        <label for="exampleInputEmail1">Status</label>
                                        <Select
                                            placeholder="Select" // className="select-language"   
                                            name="colors"
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? 'grey' : '#403c3c',
                                                    backgroundColor: state.isSelected ? 'grey' : 'transparent',
                                                    width: "100%"
                                                }),

                                            }}
                                            className="select-language basic-multi-select"
                                            classNamePrefix="select"
                                            value={status}
                                            options={bookStatus.map(item => ({
                                                value: item.value,
                                                label: item.label,
                                            }))}
                                            onChange={(e) => {
                                                setStatus(e)
                                            }}
                                        />
                                    </div></div>
                                </div>
                                <div className=' actionsBtn'>
                                    <span onClick={() => handleSave()}>Save</span>
                                    <span className='btnCanceled' onClick={() => closePopup()}>Cancel</span>
                                </div>
                            </div>


                        </div>
                        {/* ----------------------------- */}
                    </div>

                    <a onClick={() => closePopup()} class="modal__close"> <FaRegTimesCircle style={{ color: "#fff", cursor: "pointer" }} /></a>
                </div>
            </div>
            {/*  */}
        </div>
    )

}
export default BookDetailHome