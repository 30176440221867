import React, { useState, useEffect, Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FaEllipsisV, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import userImg from "../../images/userImg.jpg"
import BudgetDistribution from "./budgetDistribution";
import LineCharts from "./lineCharts";
import PublishedBooks from "./publishedBooks";
import TaskDistribution from "./taskDistribution";
import UserRegistration from "./userRegistration";
import BooksTableList from "../reports/booksList";
import ApprovedBookList from "../reports/approvedBookList";
import UnapprovedBookList from "../reports/unapprovedBookList";
import { callingAPI } from '../../service';
import LanguageList from "../languages/languageList";
import CategoryList from "../category/categoryList";
import TypeList from "../type/typeList";
import AuthorsList from "../authors/authorsList";
import UsersList from "../authors/usersList.jsx";

import StartWriterList from "../authors/startWriterList";
import BookList from "../books/booksList";
import BookDetail from "../books/bookDetail"
import LatestReleases from "./latestReleases";
import Notifications from "../notification/notification";
import Support from "../support/support.jsx";
export default function DashboardTabs(props) {
    const [count, setCount] = useState([]);
    const [selectedBook, setSelectedBook] = useState(false);
    const [data, setData] = useState([]);
    const [defaultIndex, setDefaultIndex] = useState(0);

    useEffect(() => {

        const json = {

        };
        callingAPI('userData/userAnalytics', json).then((response) => {
            if (response.data.success === '1') {
                setCount(response.data.result)
            }
            else {
                setCount([])
            }
        })
            .catch();
    }, []);
    const handleDetailClick = (book) => {

        setData(book)
        setSelectedBook(true);
    };
    const backToList = () => {
        setSelectedBook(false);
    };
    useEffect(() => {
        if (props.navigateBookList === "bookList") {
            setDefaultIndex(6)
        }
    }, [props.navigateBookList])
    return (
        <>
            <Tabs
                // tabIndex={defaultIndex}
                selectedIndex={defaultIndex}
                onSelect={(index) => {
                    setDefaultIndex(index);
                    props.setNavigateBookList("")
                }}
            >
                <TabList>
                    <Tab>Home</Tab>
                    {/* <Tab>Budget</Tab>
                    <Tab>Team <span className="memberCount">7</span></Tab> */}
                    <Tab>Languages</Tab>
                    <Tab>Category</Tab>
                    <Tab>Type</Tab>
                    <Tab>Authors</Tab>
                    <Tab>Users</Tab>
                    <Tab>Star Writers</Tab>
                    <Tab>Books</Tab>
                    <Tab>Notifications</Tab>
                    <Tab>Support</Tab>
                </TabList>

                <TabPanel>
                    {!selectedBook && (
                        <>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="dashCards">
                                        <h2><span>User Count</span>
                                            {/* <span><FaEllipsisV /> </span> */}
                                        </h2>
                                        <div className="dashCount">
                                            <h1>{count.totalUserCount}</h1>
                                            {/* <p>Due Tasks</p>
                                    <h6>Completed : <span>10</span></h6> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="dashCards">
                                        <h2><span>Total Published Book Count</span>  </h2>
                                        <div className="dashCount redClr">
                                            <h1>{count.totalBookPublished}</h1>
                                            {/* <p>Tasks</p>
                                    <h6>From yesterday : <span>10</span></h6> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="dashCards ">
                                        <h2><span>Writer Count</span>  </h2>
                                        <div className="dashCount yellowClr">
                                            <h1>{count.totalWriterCount}</h1>
                                            {/* <p>Open</p>
                                    <h6>Closed today : <span>10</span></h6> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="dashCards ">
                                        <h2><span>Reader Count</span>  </h2>
                                        <div className="dashCount greenClr">
                                            <h1>{count.totalReaderCount}</h1>
                                            {/* <p>Proposals</p>
                                    <h6>Implemented : <span>10</span></h6> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <LatestReleases handleDetailClick={handleDetailClick} />
                            <PublishedBooks />
                            <div className="row">
                                <div className="col-md-6">
                                    <TaskDistribution />
                                </div>
                                <div className="col-md-6">
                                    <UserRegistration />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tabBooksList">
                                        {/* <Tabs>
                                            <TabList>
                                                <Tab>Approved Book List</Tab>
                                                <Tab>Unapproved Book List</Tab>
                                                <Tab >Draft Book List</Tab>
                                            </TabList>
                                            <TabPanel> */}
                                                <ApprovedBookList handleDetailClick={handleDetailClick}/>
                                            {/* </TabPanel>
                                            <TabPanel>
                                                <UnapprovedBookList handleDetailClick={handleDetailClick} />
                                            </TabPanel>
                                            <TabPanel>
                                                <BooksTableList handleDetailClick={handleDetailClick} />
                                            </TabPanel>
                                        </Tabs> */}
                                    </div>
                                </div>
                            </div>
                        </>

                    )}
                    {selectedBook && (
                        <BookDetail backToList={backToList} key={selectedBook} data={data} />
                    )}
                </TabPanel>
                {/* <TabPanel>
                    <div className="row">
                        <div className="col-md-8">
                            <BudgetDistribution />
                        </div>
                        <div className="col-md-4">
                            <LineCharts />
                        </div>
                    </div>

                    <div className="issueSummWrap heightAuto">
                        <h2>Budget Details</h2>
                        <div className="summaryTabs budgetTable">
                            <table >
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Total Budget </th>
                                        <th>Expenses (USD)</th>
                                        <th>Expenses (%)</th>
                                        <th>Remaining (USD)</th>
                                        <th>Remaining (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td data-column="Type"><span className="dots blueDot"></span>Concept</td>
                                        <td data-column="Total Budget">$14,880.00</td>
                                        <td data-column="Expenses (USD)">$14,880.00</td>
                                        <td data-column="Expenses (%)">94.08%</td>
                                        <td data-column="Remaining (USD)">$880.00</td>
                                        <td data-column="Remaining (%)">5.92%</td>
                                    </tr>
                                    <tr>
                                        <td data-column="Type"><span className="dots RedDot"></span>Design</td>
                                        <td data-column="Total Budget">$14,880.00</td>
                                        <td data-column="Expenses (USD)">$14,880.00</td>
                                        <td data-column="Expenses (%)">94.08%</td>
                                        <td data-column="Remaining (USD)">$880.00</td>
                                        <td data-column="Remaining (%)">5.92%</td>
                                    </tr>
                                    <tr>
                                        <td data-column="Type"><span className="dots GreenDot"></span>Development</td>
                                        <td data-column="Total Budget">$14,880.00</td>
                                        <td data-column="Expenses (USD)">$14,880.00</td>
                                        <td data-column="Expenses (%)">94.08%</td>
                                        <td data-column="Remaining (USD)">$880.00</td>
                                        <td data-column="Remaining (%)">5.92%</td>
                                    </tr>
                                    <tr>
                                        <td data-column="Type"><span className="dots YellowDot"></span>Extras</td>
                                        <td data-column="Total Budget">$14,880.00</td>
                                        <td data-column="Expenses (USD)">$14,880.00</td>
                                        <td data-column="Expenses (%)">94.08%</td>
                                        <td data-column="Remaining (USD)">$880.00</td>
                                        <td data-column="Remaining (%)">5.92%</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </TabPanel>
                <TabPanel>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="dashCards dashTeamMemb ">
                                <div className="dashCount dashTeams ">
                                    <img src={userImg} className="br50" />
                                    <div className="teamsInfo">
                                        <p>John Doe</p>
                                        <h6>Project Director</h6>
                                    </div>
                                    <div className="teamContact">
                                        <div className="teamConn">
                                            <h2><FaEnvelope /> Email</h2>
                                            <h2><FaPhoneAlt /> Call</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashCards dashTeamMemb ">
                                <div className="dashCount dashTeams ">
                                    <img src={userImg} className="br50" />
                                    <div className="teamsInfo">
                                        <p>John Doe</p>
                                        <h6>Project Director</h6>
                                    </div>
                                    <div className="teamContact">
                                        <div className="teamConn">
                                            <h2><FaEnvelope /> Email</h2>
                                            <h2><FaPhoneAlt /> Call</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashCards dashTeamMemb ">
                                <div className="dashCount dashTeams ">
                                    <img src={userImg} className="br50" />
                                    <div className="teamsInfo">
                                        <p>John Doe</p>
                                        <h6>Project Director</h6>
                                    </div>
                                    <div className="teamContact">
                                        <div className="teamConn">
                                            <h2><FaEnvelope /> Email</h2>
                                            <h2><FaPhoneAlt /> Call</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashCards dashTeamMemb ">
                                <div className="dashCount dashTeams ">
                                    <img src={userImg} className="br50" />
                                    <div className="teamsInfo">
                                        <p>John Doe</p>
                                        <h6>Project Director</h6>
                                    </div>
                                    <div className="teamContact">
                                        <div className="teamConn">
                                            <h2><FaEnvelope /> Email</h2>
                                            <h2><FaPhoneAlt /> Call</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashCards dashTeamMemb ">
                                <div className="dashCount dashTeams ">
                                    <img src={userImg} className="br50" />
                                    <div className="teamsInfo">
                                        <p>John Doe</p>
                                        <h6>Project Director</h6>
                                    </div>
                                    <div className="teamContact">
                                        <div className="teamConn">
                                            <h2><FaEnvelope /> Email</h2>
                                            <h2><FaPhoneAlt /> Call</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashCards dashTeamMemb ">
                                <div className="dashCount dashTeams ">
                                    <img src={userImg} className="br50" />
                                    <div className="teamsInfo">
                                        <p>John Doe</p>
                                        <h6>Project Director</h6>
                                    </div>
                                    <div className="teamContact">
                                        <div className="teamConn">
                                            <h2><FaEnvelope /> Email</h2>
                                            <h2><FaPhoneAlt /> Call</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashCards dashTeamMemb ">
                                <div className="dashCount dashTeams ">
                                    <img src={userImg} className="br50" />
                                    <div className="teamsInfo">
                                        <p>John Doe</p>
                                        <h6>Project Director</h6>
                                    </div>
                                    <div className="teamContact">
                                        <div className="teamConn">
                                            <h2><FaEnvelope /> Email</h2>
                                            <h2><FaPhoneAlt /> Call</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </TabPanel> */}
                <TabPanel>
                    <LanguageList />
                </TabPanel>
                <TabPanel>
                    <CategoryList />
                </TabPanel>
                <TabPanel>
                    <TypeList />
                </TabPanel>
                <TabPanel>
                    <AuthorsList />
                </TabPanel>
                <TabPanel>
                    <UsersList />
                </TabPanel>
                <TabPanel>
                    <StartWriterList />
                </TabPanel>
                <TabPanel>
                    <BookList userIdBookList={props.userIdBookList} navigateBookList={props.navigateBookList} />
                </TabPanel>
                <TabPanel>
                    <Notifications />
                </TabPanel>
                <TabPanel>
                    <Support />
                </TabPanel>
            </Tabs>
        </>
    )
}