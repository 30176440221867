import React, { useState, useEffect } from "react";
import { FaEdit, FaSearch, FaPlus, FaTrashAlt, FaCheckCircle, FaRegTimesCircle, FaSave } from "react-icons/fa";
import { callingAPI } from '../../service'
import $ from "jquery"
export default function LanguageList() {
    const [languageList, setLanguageList] = useState([]);
    const [langName, setLangname] = useState('');
    const [langCode, setLangcode] = useState('');
    const [langLocalName, setLocalLangname] = useState('');
    const [popupView, setPopupView] = useState(false);
    const [ids, setIds] = useState('')
    useEffect(() => {
        languageListing();
        setPopupView(false);
    }, [])
    const languageListing = () => {
        const PostData = {

        };
        callingAPI('languageListing', PostData).then((res) => {
            setLanguageList(res.data.result);
        })
    }
    const deleteLang = (delId) => {
        var x = false;
        x = window.confirm('Do you want to Delete this Language?');
        if (x === true) {
            const PostData = {
                language_id: delId,
            };
            callingAPI('deleteLanguage', PostData).then((res) => {
                if (res.data.success === "1") {
                    setPopupView(false)
                    alert("Language Deleted Successfully.");
                    languageListing();
                }
                else {
                    alert("Invalid Data");
                }
            })
        }
    }
    const addNewLang = (type, info) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        setPopupView(true)
        if (type === "Add Language") {
            document.getElementById("popHeading").innerHTML = type;
            setLangname("");
            setLangcode("");
            setLocalLangname("");
            setIds("")
            $("#updateBtn").css("display", "none")
            $("#saveBtn").css("display", "inline-flex")

        }
        else {
            document.getElementById("popHeading").innerHTML = type;
            setLangname(info.name);
            setLangcode(info.code);
            setLocalLangname(info.local_name);
            setIds(info.id)
            $("#updateBtn").css("display", "inline-flex")
            $("#saveBtn").css("display", "none")
        }
    }

    const saveLang = () => {
        if (!langName) {
            alert("Please Enter the Language Name.");
            document.getElementById("langName").focus();
            return
        }
        else if (!langCode) {
            alert("Please Enter the Language Code.");
            document.getElementById("langCode").focus();
            return
        }
        else if (!langLocalName) {
            alert("Please Enter the Language Local Name.");
            document.getElementById("langLocalName").focus();
            return
        }
        else {
            const PostData = {
                name: langName,
                code: langCode,
                local_name: langLocalName,
                id: "",
            };
            callingAPI('saveLanguage', PostData).then((res) => {
                if (res.data.success === "1") {
                    setPopupView(false)
                    alert("Language Added Successfully.");
                    languageListing();

                }
                else {
                    alert("Invalid Data");
                }
            })
        }
    }
    const updateLang = () => {
        if (!langName) {
            alert("Please Enter the Language Name.");
            document.getElementById("langName").focus();
            return
        }
        else if (!langCode) {
            alert("Please Enter the Language Code.");
            document.getElementById("langCode").focus();
            return
        }
        else if (!langLocalName) {
            alert("Please Enter the Language Local Name.");
            document.getElementById("langLocalName").focus();
            return
        }
        else {
            const PostData = {
                name: $("#langName").val(),
                code: $("#langCode").val(),
                local_name: $("#langLocalName").val(),
                id: ids,
            };
            callingAPI('saveLanguage', PostData).then((res) => {
                if (res.data.success === "1") {
                    setPopupView(false)
                    alert("Language Updated Successfully.");
                    languageListing();

                }
                else {
                    alert("Invalid Data");
                }
            })
        }
    }
    const closePopup = () => {
        setPopupView(false);
    }
    return (
        <>

            <div className="dashboardContentInner">
                <div className="mainHead ">
                    <div className="row">
                        <div className="col-md-8">
                            <h2>Language List</h2>
                        </div>
                        <div className="col-md-4 dashClmRight">
                            <div className="dashAction">
                                <a onClick={(e) =>
                                    addNewLang("Add Language", "")
                                }
                                    className="btnNavy"  ><FaPlus /> Add New</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabContentsWrap">
                {/* <div className="issueSummWrap heightAuto"> */}

                <div className="summaryTabs budgetTable">
                    <table >
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Code </th>
                                <th>Local Name</th>
                                <th style={{ width: "100px" }}>Status</th>
                                <th style={{ textAlign: "right", width: "150px" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {languageList.map((obj, i) =>
                                <tr>
                                    <td data-column="Name">{obj.name}</td>
                                    <td data-column="Code">{obj.code}</td>
                                    <td data-column="Local Name">{obj.local_name}</td>
                                    <td data-column="Status">{obj.is_active === true ?
                                        <FaCheckCircle color={"green"} />
                                        : <FaRegTimesCircle color={"red"} />}</td>
                                    <td data-column="Action " style={{ textAlign: "right" }}>
                                        <div className="tableAction">
                                            <span><FaEdit title="Edit" color={"#6a5b5b"} className="cursorPointer"
                                                onClick={(e) => addNewLang("Edit Language", obj)}
                                            />
                                            </span>
                                            <span><FaTrashAlt title="Delete" color={"#db0000"} className="cursorPointer" onClick={() => deleteLang(obj.id)} /> </span>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>


            {/* =============== MODAL BEGINS HERE =============== */}
            <div id="demo-modal" class="modal" style={{ display: popupView === true ? "flex" : "none" }} >
                <div class="modal__content">
                    <h3 id="popHeading"></h3>
                    <div className="modalFields">
                        <div className="form-group">
                            <label htmlFor=""> Name <span>:</span> </label>
                            <input type="text" className="form-control" id='langName'
                                onChange={(e) => setLangname(e.target.value)}
                                value={langName}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor=""> Code <span>:</span></label>
                            <input type="text" className="form-control" id='langCode'
                                onChange={(e) => setLangcode(e.target.value)}
                                value={langCode}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Local Name <span>:</span> </label>
                            <input type="text" className="form-control" id='langLocalName'
                                onChange={(e) => setLocalLangname(e.target.value)}
                                value={langLocalName}
                            />
                        </div>

                    </div>
                    <div class="modal__footer">
                        <div className="dashAction">

                            <a onClick={() => saveLang()} id="saveBtn" className="btnNavy"  >  Save</a>
                            <a onClick={() => updateLang()} id="updateBtn" className="btnNavy"  >  Update</a>
                            <a onClick={() => closePopup()} className="btnCancel"> Cancel</a>
                        </div>
                    </div>
                    <a onClick={() => closePopup()} class="modal__close"> <FaRegTimesCircle /></a>
                </div>
            </div>

        </>
    )
}
