import React, { useState, useEffect, Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./layout/header"
import Footer from "./layout/footer"
import Banner from "./banner"
import Category from "./category"
import Language from "./languages"
import TopBookList from "./topbooklist"
import LatestRelease from "./latest-release"
import Navarasa from "./navarasa"
import StarWriter from "./star-writers"
import { callingAPI } from '../service';
import ActiveAuthors from './activeAuthors'
// import {  } from "react-icons/fa";
import Write from "../images/write.png"
import { useNavigate } from 'react-router-dom';
import LibraryList from './libraryList';
function Home() {
    const navigate = useNavigate();

    const [navarasaData, setNavarasaData] = useState([])
    const [latestReleaseData, setLatestReleaseData] = useState([])
    const [topTenData, setTopTenData] = useState([])
    const [starWritersData, setStarWritersData] = useState([])
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [library, setLibrary] = useState([]);
    useEffect(() => {
        homeData()
        libraryList()
    }, [])


    const homeData = () => {
        const PostData = {
            "userId": ""
        };
        callingAPI('home/getHomeData', PostData).then((response) => {
            if (response.data.success === '1') {
                setNavarasaData(response.data.result[0]['Navarasa Details'])
                setTopTenData(response.data.result[0]['Top 20 This Week'])
                setLatestReleaseData(response.data.result[0]['Latest Releases'])
                setStarWritersData(response.data.result[0]['Star Writers'])
                // setLibrary(response.data.result[0]['My Library'])
                // setkeyFlag(new Date())
            }

        })
    }
    const libraryList = () => {
        // debugger
        const PostData = {
            // "userId": "4"
            "userId": window.localStorage.getItem("userId")
        };
        callingAPI('library/myLibraryDetails', PostData).then((response) => {
            console.log("LIBRARYLIST(H) RESPONSE ===========> ", response)
            if (response.data.success === '1') {
                setLibrary(response.data.result.myLibrary);
                console.log("LIBRARYLIST(H) RESULTS ===========> ", response.data.result.myLibrary)

            }
        })
    }

    const showPopup = () => {
        setPopupVisibility(true);
    };
    const hidePopup = () => {
        setPopupVisibility(false);
    };


    return (
        <>
            <div className="position-relative">
                <Header isPopupVisible={isPopupVisible} showPopup={showPopup} hidePopup={hidePopup} />
                <Banner />
                <Language />
                {window.localStorage.getItem("userId") &&
                    <>
                        {library.length > 0 &&
                            <LibraryList 
                            // key={library} library={library}
                             />
                           }
                    </>
                }

                {topTenData.length > 0 &&
                    <TopBookList key={topTenData} topTenData={topTenData} />}

                <Category />
                {starWritersData.length > 0 &&
                    <ActiveAuthors key={starWritersData} starWritersData={starWritersData} />}
                {latestReleaseData.length > 0 &&
                    <LatestRelease key={latestReleaseData} latestReleaseData={latestReleaseData} />}
                {navarasaData.length > 0 &&
                    <Navarasa key={navarasaData} navarasaData={navarasaData} />}
                {starWritersData.length > 0 &&
                    <StarWriter key={starWritersData} starWritersData={starWritersData} />}
                <Footer />
            </div>


        </>
    )

}
export default Home