import React, { useState, useEffect, Component } from 'react';
import bookImg4 from "../../src/images/category-filter-img-04.jpg"
import bookImg3 from "../../src/images/category-filter-img-03.jpg"
import bookImg2 from "../../src/images/category-filter-img-02.jpg"
import bookImg1 from "../../src/images/category-filter-img-01.jpg"
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function StarWriters(props) {
    const navigate = useNavigate();
    const [list, setList] = useState([])

    useEffect(() => {
        setList(props.starWritersData)
    }, [])

    const [isPaused, setIsPaused] = useState(true);

    const pauseAnimation = () => {
        setIsPaused(true);
        const marquee = document.querySelector('.marquee div');
        marquee.style.animationPlayState = 'paused';
    };

    const resumeAnimation = () => {
        setIsPaused(false);
        const marquee = document.querySelector('.marquee div');
        marquee.style.animationPlayState = 'running';
    };

    const goToComponentB = (userID) => {
        navigate('/star-writer', { state: { userID: userID } });
        window.scrollTo({top:0,behavior:'smooth'});
    };
    return (
        <div className="section-starWriter">
            <div className="container">
                <div className="headdiv-sec">
                    <h3 className="mainHeadText"><span className='color-text'>Star</span> Writers</h3>
                    <span className="underline center"></span>
                </div>

                <div class="marquee" onMouseEnter={pauseAnimation} onMouseLeave={resumeAnimation}>
                    <div class="marquee-content">
                    {list.map((data) =>
                            <span onClick={() => goToComponentB(data.id)} class="marquee-item position-relative zoom-effect-container shine-overlay">
                                <div className="image-card">
                                    <img src={data.profile_pic} alt="" />
                                </div>
                                <div class="shine"></div>
                                <h4 className='texthead-star-writer'>{data.display_name}</h4>
                                <div className="text-center followers-text">
                                    <span className='color-text'>{data.followersCount} </span> Followers
                                </div>
                            </span>

                        
                    )}
                    </div>
                </div>




            </div>
        </div>
        // <div class="photobanner" >
        //     <img class="first" src="https://picsum.photos/350/233/?image=1" alt="" />
        //     <img src="https://picsum.photos/350/233/?image=2" alt="" />
        //     <img src="https://picsum.photos/350/233/?image=3" alt="" />
        //     <img src="https://picsum.photos/350/233/?image=4" alt="" />
        //     <img src="https://picsum.photos/350/233/?image=5" alt="" />
        //     <img src="https://picsum.photos/350/233/?image=6" alt="" />
        //     <img src="https://picsum.photos/350/233/?image=1" alt="" />
        //     <img src="https://picsum.photos/350/233/?image=2" alt="" />
        //     <img src="https://picsum.photos/350/233/?image=3" alt="" />
        //     <img src="https://picsum.photos/350/233/?image=4" alt="" />
        // </div>
    )

}
export default StarWriters