import React, { useState, useEffect, Component } from 'react';
import { callingAPI } from '../../service';
import $ from "jquery"
import { FaUser, FaEye, FaTrashAlt, FaEdit, FaCheckCircle, FaTimesCircle, FaLanguage, FaCalendarAlt, FaUserPlus, FaBookReader, FaBookOpen, FaClock, FaStar } from "react-icons/fa";
import Header from "../../components/layouts/header";
import Chats from "../dashboard/chats";
import Edit from "../../images/edit.png"
import Delete from "../../images/deleteicon.png"
import { Link, redirect, useNavigate } from "react-router-dom";
import EditBook from "../books/books-edit"
import BookDetail from "../books/bookDetail"
import Parser from 'html-react-parser';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import noDataImg from "../../images/empty.png"
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Select from "react-select";
import { convertToHTML } from 'draft-convert';
import { ToastContainer, toast } from 'react-toastify';
import AWS from 'aws-sdk';
// import { Rating } from 'react-simple-star-rating'
var s3
function BookList(props) {
    const navigate = useNavigate()
    const [bookList, setBookList] = useState([]);
    // const [booklistDiv, setBooklistDiv] = useState(new Date());
    const [selectedBook, setSelectedBook] = useState(false);
    const [selectedBookEdit, setSelectedBookEdit] = useState(false);
    const [data, setData] = useState([]);
    const [searchType, setSearchType] = useState("0");
    const [searchInput, setSearchInput] = useState("");
    const [authorDetail, setAuthorDetail] = useState([]);
    const [title, setTitle] = useState("");
    const [file, setFile] = useState('');
    const [imageName, setImageName] = useState('');
    const [imgObject, setImgObject] = useState('');
    const [htmlcontent, setHtmlContent] = useState("");
    const [content, setContent] = useState("");
    const [languageList, setLanguageList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [listFlag, setListFlag] = useState()
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML("")
            )
        ),
    );
    const [statusList, setStatusList] = useState([
        {
            id: "3",
            name: "Publish",
        },
        {
            id: "1",
            name: "Draft",
        }, {
            id: "4",
            name: "Un-Publish",
        }]);
    const [language, setLanguage] = useState([]);
    const [status, setStatus] = useState([]);
    const [types, setTypes] = useState([]);
    const [category, setCategory] = useState([]);
    const [environmentType, setEnvironmentType] = useState('');
    const [cofigDetails, setConfigDetails] = useState('')
    const [categoryImagePath, setCategoryImagePath] = useState('');
    useEffect(() => {
        getLanguageList()
        bookListing();
        authorDetails();
        getConfiguration();
    }, [props.navigateBookList, props.userIdBookList]);

    const bookListing = () => {
        const PostData = {
            "max": "",
            "offset": "0",
            "cmbSearchType": "",
            "searchInput": "",
            "userId": window.localStorage.getItem("role") === "Admin"?"":window.localStorage.getItem("userId")
        };
        callingAPI('book/adminBookList', PostData).then((response) => {
            if (response.data.success !== '1') {
                setBookList([]);
                return;
            }
            if (props.navigateBookList == "bookList") {
                const output = response.data.result[0];
                output.publishedBookLists = output.publishedBookLists.filter(({ user_id }) => user_id === props.userIdBookList);
                setBookList(output);
                if (output.length === 0) {
                    setListFlag(true)
                }
                else {
                    setListFlag(false)
                }
            } else {
                setBookList(response.data.result[0])
                if (response.data.result[0].publishedBookLists.length === 0) {
                    setListFlag(true)
                }
                else {
                    setListFlag(false)
                }
            }
        })
    }

    const authorDetails = () => {
        const PostData = {
            "userId": props.userIdBookList
        }
        callingAPI('userData/userProfileDetails', PostData).then((response) => {
            if (response.data.success === '1') {
                setAuthorDetail(response.data.userData);
            }

        })
    }

    // const deleteBook = (bookId) => {
    //     const PostData = {
    //         "bookId": bookId
    //     };
    //     callingAPI('book/deleteBookDetails', PostData).then((response) => {
    //         if (response.data.success === '1') { 
    //             bookListing()
    //         }
    //         else {
    //             toast.error("Something Went Wrong", { containerId: "K" })

    //         }
    //     })
    // }bookId
    const deleteBook = (bookId) => {
        var x = false;
        x = window.confirm('Do you want to Delete?');
        if (x === true) {
            const PostData = {
                "bookId": bookId
            };
            callingAPI('book/deleteBookDetails', PostData).then((response) => {
                if (response.data.success === "1") { 
                    alert("Book Deleted Successfully.");
                    bookListing()
                }
                else {
                    alert("Invalid Data");
                }
            })
        }
    }

    const handleDetailClick = (book) => {
        setData(book)
        setSelectedBook(true);
    };
    // const handleEditClick = (editData) => {
    //     setData(editData)
    //     setSelectedBookEdit(true);
    // };
    const backToList = () => {
        setSelectedBook(false);
        setSelectedBookEdit(false);
        bookListing();
    };

    const handleSearchInput = () => {
        const PostData = {
            "max": "",
            "offset": "0",
            "cmbSearchType": searchType,
            "searchInput": searchInput,
            "userId": window.localStorage.getItem("role") === "Admin"?"":window.localStorage.getItem("userId")
        };


        callingAPI('book/adminBookList', PostData).then((response) => {
            if (response.data.success === '1') {
                setBookList(response.data.result[0])
                if (response.data.result[0].publishedBookLists.length === 0) {
                    setListFlag(true)
                }
                else {
                    setListFlag(false)
                }
            }
            else {
                setBookList([])
            }
        })
    };

    const handleSearchInputClear = () => {
        setSearchInput("")
        setSearchType("0")
        bookListing();
    }
    const handleButtonAdd = (showFlag) => {
        document.getElementById("exampleModal").style.display = showFlag
    }
    const handleImageUploadOnChange = (e) => {
        if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
            toast.error("Please attach jpg,jpeg or png files", { containerId: "K" })
            return false;
        }
        setFile(e.target.files[0]);
        setImageName(e.target.files[0].name);
        const obj = URL.createObjectURL(e.target.files[0])
        setImgObject(obj);
    };
    const handleEditorState = (value) => {
        const rawData = convertToRaw(value.getCurrentContent());
        setEditorState(value);
        const htmlContent = convertToHTML(value.getCurrentContent());
        setHtmlContent(htmlContent);
        setContent(rawData.blocks.map(block => block.text).join("\n"));
    }
    const getLanguageList = () => {
        const PostData = {

        };
        callingAPI('languageListing', PostData).then((res) => {
            setLanguageList(res.data.result);

        })
        const jsondata = {

        };
        callingAPI('categoryListing', jsondata).then((res) => {
            setCategoryList(res.data.result);

        })
        const postValue = {

        };
        callingAPI('typeListing', postValue).then((res) => {
            setTypesList(res.data.result);
        })
    }



    const handleWriteToFile = async () => {
        if (!title) {
            toast.error("Please enter title", { containerId: "M" })
            return false
        }
        else if (!file) {
            toast.error("Please upload image", { containerId: "M" })
            return false
        }
        else if (!content) {
            toast.error("Please enter description", { containerId: "M" })
            return false
        }
        else if (language.length == 0) {
            toast.error("Please select language", { containerId: "M" })
            return false
        }
        else if (status.length == 0) {
            toast.error("Please select status", { containerId: "M" })
            return false
        }
        else if (types.length == 0) {
            toast.error("Please select type", { containerId: "M" })
            return false
        }
        else if (category.length == 0) {
            toast.error("Please select category", { containerId: "M" })
            return false
        }
        else {
            const textContent = content;
            const blob = new Blob([textContent], { type: 'text/plain' });
            textFileS3Save(`book_${window.localStorage.getItem("userId")}_${new Date().getTime()}.txt`, blob);
        }
    };





    const handleSave = async (textFileData) => {

        try {
            var postData = {
                "bookId": "",
                "title": title,
                "description": "",
                "isSeriesCompleted": false,
                "description_without_html": "",
                "price": "",
                "image": imageName,
                "createdOn": new Date().getTime(),
                "userId": window.localStorage.getItem("userId"),
                "statusId": status.value,
                "languageId": language.value,
                "bookCategories": category.map(category => category.value),
                "bookTypes": types.value,
                "bookContentFileS3Url": textFileData.Location,
            }
            console.log("🚀 ~ handleSave ~ postData:", postData)
            const response = await callingAPI('book/bookCategorySave', postData);

            if (response.data.success == 1) {
                if (file.length !== 0) {
                    imageS3Save(imageName, file);
                }
               
                toast.success("Added Successfully", { containerId: "M" })
                handleButtonAdd("none")
                bookListing();
            }
        }
        catch (error) {
            console.error("Error:", error);
        }
    }

    const textFileS3Save = (imageName, blob) => {
        return new Promise((resolve, reject) => {
            try {

                var S3BucketMainFolder = environmentType + '/' + categoryImagePath;
                var fullFilePath = S3BucketMainFolder + '/' + imageName;

                s3.upload(
                    {
                        Key: fullFilePath,
                        Body: blob, // Upload the Blob object
                        ACL: 'public-read', // Optional: Set access control (consider security)
                    },
                    (err, data) => {
                        if (err) {
                            alert('There was an error uploading your photo: ' + err.message);
                            reject(err);
                        } else {
                            console.log("File uploaded to S3 successfully!", data);
                            resolve(true);
                            handleSave(data);
                        }
                    }
                );
            } catch (error) {
                console.error("Error uploading file to S3:", error);
                reject(error); // Propagate errors for handling in calling code
            }
        });
    };



    const imageS3Save = (imageName, file) => {
        return new Promise((resolve, reject) => {

            var S3BucketMainFolder = environmentType + '/' + categoryImagePath;
            var fullFilePath = S3BucketMainFolder + '/' + imageName;
            s3.upload(
                {
                    Key: fullFilePath,
                    Body: file,
                    ACL: 'public-read',
                },
                (err, data) => {
                    if (err) {
                        alert('There was an error uploading your photo: ' + err.message);
                        reject(err);
                    } else {
                        //  alert("File Uploaded Successfully")
                        resolve(data);
                        document.getElementById('uploadButton').style.display = 'block';

                    }
                }
            );
        })
    };
    const getConfiguration = () => {
        return new Promise((resolve, reject) => {
            try {
                const PostData = {
                };
                callingAPI('configurationData', PostData).then((res) => {
                    setConfigDetails(res.data.result[0]);
                    const getConfigValue = (configKey) => {
                        return res.data.result[0].configDetails
                            ? res.data.result[0].configDetails.find((config) => config.config_key === configKey)?.config_value
                            : null;
                    };
                    setEnvironmentType(getConfigValue("S3BucketMainFolder"));
                    setCategoryImagePath(getConfigValue("bookImagePath"));
                    AWS.config.update({
                        accessKeyId: getConfigValue("AWSAccessKeyId"),
                        secretAccessKey: getConfigValue("AWSSecretKey"),
                        region: getConfigValue("s3Region"),
                    });
                    var bucketParams = {
                        Bucket: getConfigValue("s3bucket"),
                    };
                    s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
                    getLanguageList()
                    resolve(s3)
                })

            }
            catch (e) {
                console.error("Error in getConfiguration", e)
                reject(e)
            }
        })


    }

    return (
        <>
            {/* <div className="WrapLeft">
                <Header /> */}
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"M"}
                theme="colored" />
            {!selectedBook && !selectedBookEdit && (
                <div key={selectedBook}>
                    <div className="dashboardContentInner">
                        <div className="mainHead mainHead-wrapper">

                            {authorDetail && authorDetail.map((obj, i) => (
                                <div className="authordetails-wraper">
                                    <div className="">
                                        <img src={obj.profilePic} alt="Profile" className='img-fluid profile-imgBook' />
                                    </div>
                                    <div className="author-details-wraper">
                                        <h3 className="author-headtext">{obj.displayName}</h3>
                                        <div className="author-subtext">{obj.emailId}</div>
                                        <div className="author-subtext">Phone: {obj.mobileNumber}</div>
                                        <div className="followers-wraper">
                                            <div className="follow-subdiv">
                                                <div className='follow-number'>{obj.followers}</div>
                                                <div className="follow-text">Followers</div>
                                            </div>
                                            <div className="follow-subdiv">
                                                <div className='follow-number'>{obj.following}</div>
                                                <div className="follow-text">Following</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="row">
                                <div className="col-md-3">
                                    <h2>Books List</h2>
                                </div>
                                <div className="col-md-8 bookListFilter">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <select name="" id="" className='search-bookinput' onChange={(e) => setSearchType(e.target.value)} value={searchType}>
                                                <option value="0">Select</option>
                                                <option value="bookName">Book Name</option>
                                                {props.navigateBookList !== "bookList" ? <option value="autherName">Author Name</option>
                                                    : ""}
                                            </select>
                                        </div>
                                        <div className="col-md-4 paddRight">
                                            <input type="text" placeholder='Search' className='search-bookinput' onChange={(e) => setSearchInput(e.target.value)} value={searchInput} />
                                        </div>
                                        <div className="col-md-5 dashAction d-flex">
                                            <button className="btnNavy" onClick={handleSearchInput}>Search</button>
                                            <button className="btnCancel" onClick={handleSearchInputClear}>Clear</button>
                                            <button className="btnAdd" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleButtonAdd("block")}>Add</button>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                   
                    <div className="container-fluid booksList-Wrapper">
                        
                        <div className="row blog-listrow">
                            {bookList && bookList.publishedBookLists && bookList.publishedBookLists.map((obj, i) => (
                                <div className="col-md-6 blogListSec" key={i}
                                    // onClick={() => handleDetailClick(obj)}
                                >
                                    <div className="row blog-content-center" onClick={() => handleDetailClick(obj)}>
                                        <div className="col-md-4">
                                            <span className='bkListSpan' style={{ position: "relative" }}>
                                                <img src={obj.bookImageWithPath} alt="" className='img-fluid' />
                                                {data.rating !== "" || data.rating !== null || data.rating !== "null" &&
                                                    <span className="starRatingCountTbl">
                                                        <FaStar style={{ fontSize: "13px", color: "#fff" }} /> &nbsp;
                                                        {data.rating}
                                                    </span>
                                                }

                                                {obj.noOfEpisodes > 0 &&
                                                    <span className="episodesCount">
                                                        <FaBookOpen style={{ fontSize: "15px", color: "#fff" }} />&nbsp;
                                                        {obj.noOfEpisodes} Episode(s)
                                                    </span>
                                                }
                                            </span>
                                        </div>
                                        <div className="col-md-8 blogP">
                                            <h3 className='blog-head'>{obj.title}</h3>
                                            <p className='blog-author'><FaUser /> <span className='ml-2 '  >
                                                {obj.authorName}</span> </p>
                                            <p className='mb-1s ' style={{ color: "rgb(2 104 135)", margin: "0px" }}><FaLanguage style={{ color: "rgb(2 104 135)" }} />
                                                <span
                                                    style={{ color: "rgb(2 104 135)" }}
                                                >{obj.languageName}</span>
                                            </p>
                                            <p className='mb-1s ' style={{ color: "rgb(2 104 135)", margin: "0px", margin: "0px" }}> <FaClock style={{ color: "rgb(2 104 135)" }} /> {obj.duration}</p>
                                            <p className='mb-2 ' style={{ color: "rgb(2 104 135)" }}> <FaBookReader style={{ color: "rgb(2 104 135)" }} /> {obj.no_of_views} Views</p>
                                            <p className='blog-description' >{Parser(obj.description_without_html)}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <hr className='hrBorder' />
                                        <div className="d-flex justify-content-between" style={{cursor:"default"}}>
                                            <p className=' blog-updatedate' style={{ margin: "0px" }}><FaCalendarAlt style={{ color: "grey", fontSize: "14px" }} /> {new Date(obj.lastUpdatedDate).toLocaleString()}</p>
                                            {/* <Link to={`/books/bookDetail/${obj.bookId}/${obj.user_id}`} className='blog-viewDetail'>View Detail</Link> */}
                                            <div className="d-flex justify-content-between  ">

                                                {obj.status === "Published" ? (
                                                    <p className='mb-0' style={{ color: "#006ebb", fontSize: "14px" }}><FaCheckCircle /><span style={{ marginLeft: "5px" }}>{obj.status}</span></p>
                                                ) : obj.status === "Approved" ? (
                                                    <p className='mb-0' style={{ color: "green", fontSize: "14px" }}><FaCheckCircle /><span style={{ marginLeft: "5px" }}>{obj.status}</span></p>
                                                ) : obj.status === "UnApproved" ? (
                                                    <p className='mb-0' style={{ color: "red", fontSize: "14px" }}><FaTimesCircle /><span style={{ marginLeft: "5px" }}>{obj.status}</span></p>
                                                ) : ""}


                                            </div>
                                            <div className="blog-icons">
                                                {/* <Link to={`/books/bookDetail/${obj.bookId}/${obj.user_id}`} className='blog-viewDetail' title='View Detail'><FaEye className='viewIconBlog' /></Link> */}
                                                {/* <span className='blog-viewDetail' title='View Detail' onClick={() => handleDetailClick(obj)}>
                                                    <FaEye className='viewIconBlog' />
                                                </span> */}
                                                {/* <Link to={`/books/editBooks/${obj.bookId}/${obj.user_id}`} title='Edit'><FaEdit className='editiconBlog' /></Link> */}
                                                {/* <span title='Edit' onClick={() => handleEditClick(obj)}>
                                                    <FaEdit className='editiconBlog' />
                                                </span> */}
                                                <FaTrashAlt onClick={() => deleteBook(obj.bookId)} title='Delete' className='deleteIconBlog' />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                        {console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,11",listFlag)}
                        {listFlag &&
                <div class="color-text-follow noDataTxts">
                    <span><img src={noDataImg} className='noDataImg' /></span>
                    <p>  No Data Found</p>
                </div>
            }
                    </div>
                    
                </div>

            )}
            {selectedBook && (
                <BookDetail backToList={backToList} key={selectedBook} data={data} />
            )}
            {selectedBookEdit && (
                <EditBook key={selectedBookEdit} data={data} backToList={backToList} bookListing={bookListing} />
            )}
            <div class="modal " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "none" }}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Book</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleButtonAdd("none")}></button>
                        </div>
                        <div class="modal-body">
                            <div className="container">
                                <div className="form-group row mb-2">
                                    <label for="title" className="col-sm-2 col-form-label">Title <sup style={{ color: "red" }}>*</sup></label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label for="author" className="col-sm-2 col-form-label">Image<sup style={{ color: "red" }}>*</sup></label>
                                    <div className="col-sm-10" style={{ textAlign: "left" }}>
                                        <span id="uploadButton" className="uploadButton uploadButtonSmall">
                                            <input
                                                type="file"
                                                id="file1"
                                                title="Upload"
                                                onChange={(e) => handleImageUploadOnChange(e)}
                                                hidden
                                            />
                                            <label for="file1" className="btn-upload padd-upload">
                                                upload image
                                            </label>
                                            {imgObject &&
                                                <img src={imgObject} alt="" width={40} height={40} />}

                                        </span>
                                    </div>
                                </div>

                                <div className="form-group row mb-2 description-editor">
                                    <label for="description" className="col-sm-2 col-form-label">Description<sup style={{ color: "red" }}>*</sup></label>
                                    <div className="col-sm-10">
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            placeholder='Please enter your text'
                                            editorState={editorState}
                                            onEditorStateChange={handleEditorState}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label for="language" className="col-sm-2 col-form-label">Language<sup style={{ color: "red" }}>*</sup></label>
                                    <div className="col-sm-4">
                                        {/* <input type="text" className="form-control" placeholder="Language" defaultValue={obj.languageName} onChange={(e)=>setLanguage(e.target.value)}/> */}
                                        <Select
                                            value={language}
                                            placeholder="Select" className="select-language"
                                            options={languageList.map(item => ({
                                                value: item.id,
                                                label: item.name,
                                            }))}
                                            onChange={(selectedOption) => {
                                                setLanguage(selectedOption)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label for="language" className="col-sm-2 col-form-label">Status<sup style={{ color: "red" }}>*</sup></label>
                                    <div className="col-sm-4">
                                        {/* <input type="text" className="form-control" placeholder="Language" defaultValue={obj.languageName} onChange={(e)=>setLanguage(e.target.value)}/> */}
                                        <Select
                                            value={status}
                                            placeholder="Select" className="select-language"
                                            options={statusList.map(item => ({
                                                value: item.id,
                                                label: item.name,
                                            }))}
                                            onChange={(e) => {
                                                setStatus(e)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label for="language" className="col-sm-2 col-form-label">Type<sup style={{ color: "red" }}>*</sup></label>
                                    <div className="col-sm-4">
                                        {/* <input type="text" className="form-control" placeholder="Language" defaultValue={obj.languageName} onChange={(e)=>setLanguage(e.target.value)}/> */}
                                        <Select
                                            value={types}
                                            placeholder="Select" className="select-language"
                                            options={typesList.map(item => ({
                                                value: item.id,
                                                label: item.name,
                                            }))}
                                            onChange={(e) => {
                                                setTypes(e)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row mb-2">
                                    <label for="language" className="col-sm-2 col-form-label">Categories<sup style={{ color: "red" }}>*</sup></label>
                                    <div className="col-sm-4">
                                        {/* <input type="text" className="form-control" placeholder="Language" defaultValue={obj.languageName} onChange={(e)=>setLanguage(e.target.value)}/> */}
                                        <Select
                                            isMulti
                                            value={category}
                                            placeholder="Select" className="select-language"
                                            options={categoryList.map(item => ({
                                                value: item.id,
                                                label: item.name,
                                            }))}
                                            onChange={(e) => {
                                                setCategory(e)
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="add-modal-footer">
                                    <button className="btnNavy btn-saveBook" onClick={() => handleWriteToFile()} >Save</button>
                                    <button className="btnNavy btn-saveBook btnCancel" onClick={() => handleButtonAdd("none")} >Cancel</button>
                                </div>
                            </div>
                        </div>
                        {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        
                      </div> */}
                    </div>
                </div>
            </div>
        </>

    )

}
export default BookList