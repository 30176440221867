import React, { useState, useEffect, Component } from 'react';
import Header from '../Home/layout/header';
import Footer from '../Home/layout/footer';
import Book2 from "../images/304301_1706363750816.jpg"
import BookCover from "../images/ashback.jpg"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Book1 from "../images/860295_1704701727805.jpg"
import { FaLayerGroup, FaStar, FaUser, FaEdit, FaClock,FaTimes, FaRegTimesCircle, FaBookReader,FaUserMinus, FaCommentAlt, FaUserPlus, FaBookOpen, FaCamera, FaPencilAlt,FaLanguage, FaUpload, FaLockOpen } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Content from "../Home/star-writer-content"
import Status from "../Home/star-writer-status"
import { useLocation } from 'react-router-dom';
import Write from "../images/write.png" 
import WriteBlk from "../images/writeBlk.png"
import { callingAPI } from '../service';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import AWS from 'aws-sdk';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import BookDetail from '../pages/books/bookDetail';
import LibraryList from './libraryList';
import LibraryListProfile from './libraryListProfile';
var s3;
function StarWriterDetail(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [list, setList] = useState([])
    const [noList, setNoList] = useState([])
    const [draftList, setDraftList] = useState([])
    const [listFlag, setListFlag] = useState()
    const [profileList, setProfileList] = useState([])
    const [profileListFlag, setprofileListFlag] = useState()
    const [followersList, setFollowersList] = useState([])
    const [followingList, setFollowingList] = useState([])
    const [showPaswdPopup, setShowPaswdPopup] = useState(false)
    const [showProfilePopup, setShowProfilePopup] = useState(false)
    const [environmentType, setEnvironmentType] = useState('');
    const [configDetails, setConfigDetails] = useState({});
    const [userImagePath, setUserImagePath] = useState('');
    const [fnChange, setFNChange] = useState("")
    const [lnChange, setLNChange] = useState("")
    const [pnChange, setPNChange] = useState("")
    const [emailChange, setEmailChange] = useState("")
    const [mobileChange, setMobileChange] = useState("")
    const [dobChange, setDOBChange] = useState("")
    const [bioChange, setBioChange] = useState("")
    const [gender, setGender] = useState("")
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [userDataList, setUserDataList] = useState([])
    const userID = (location.state && location.state.userID) ? location.state.userID : []; 
    const [activeTab, setActiveTab] = useState(0);
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [currentDate] = useState(new Date().getTime())
    const [statusValue, setstatusValue] = useState("")
    const [bookStatus, setbookStatus] = useState([
        {
            value: "3",
            label: "Publish",
        },
        {
            value: "1",
            label: "Draft",
        }, {
            value: "4",
            label: "Un-Publish",
        }]);
        const [selectedBook, setSelectedBook] = useState(false);
        const [data, setData] = useState([]);
    useEffect(() => {
        if (window.localStorage.getItem("userId") == null) {
            window.location.href = "/"
        }
        bookListHandle()
        profileListHandle()
        followListhandle()
        awsConfig()
        setShowPaswdPopup(false)
        setShowProfilePopup(false)
        // 
    }, [])

    const awsConfig = () => {
        const PostData = {

        };
        callingAPI('configurationData', PostData).then((res) => {

            setConfigDetails(res.data.result[0]);
            const getConfigValue = (configKey) => {
                return res.data.result[0].configDetails
                    ? res.data.result[0].configDetails.find((config) => config.config_key === configKey)?.config_value
                    : null;
            };
            setEnvironmentType(getConfigValue("S3BucketMainFolder"));
            setUserImagePath(getConfigValue("userImagePath"));
            AWS.config.update({
                accessKeyId: getConfigValue("AWSAccessKeyId"),
                secretAccessKey: getConfigValue("AWSSecretKey"),
                region: getConfigValue("s3Region"),
            });
            var bucketParams = {
                Bucket: getConfigValue("s3bucket"),
            };
            s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
        })
    }
    const followListhandle = () => {
        const PostData = {
            "userId": window.localStorage.getItem("userId"),
            "authorId": ""
        }
        callingAPI('book/followerList', PostData).then((response) => {
            if (response.data.success == 1) {
                setFollowersList(response.data.result.followersData)
                setFollowingList(response.data.result.followedData)
            }
            else {
                setFollowersList([])
                setFollowingList([])
            }
        })
    }
    const profileListHandle = () => {
        const PostData = {
            "userId": window.localStorage.getItem("userId"),
        }
        callingAPI('userData/userProfileDetails', PostData).then((response) => {
            if (response.data.success == 1) {
                setProfileList(response.data.userData)
                if (response.data.userData === 0) {
                    setprofileListFlag(true)
                }
                else {
                    setprofileListFlag(false)
                }
            }
            else {
                setProfileList([])
            }
        })
    }
    const bookListHandle = () => {
        const PostData = {
            "max": "",
            "offset": "0",
            "cmbSearchType": "",
            "searchInput": "",
            "userId": window.localStorage.getItem("role") === "Admin"?"":window.localStorage.getItem("userId")
        };
        callingAPI('book/adminBookList', PostData).then((response) => {
            if (response.data.success == 1) {
                setDraftList(response.data.result[0])
                setList(response.data.result[0].publishedBookLists)
                setNoList(response.data.result[0].draftBookLists)
                if (response.data.result[0].publishedBookLists.length === 0) {
                    setListFlag(true)
                }
                else {
                    setListFlag(false)
                }
            }
            else {
                setList([])
                setNoList([])
                setDraftList([])
            }
        })
    }

    const displayBookDuration = (decimalMinutes) => {
        const totalSeconds = Math.round(decimalMinutes * 60);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        let result = '';
        if (hours > 0) {
            result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
        }
        if (minutes > 0) {
            result += `${result.length > 0 ? ' and ' : ''}${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        }
        if (seconds > 0 && hours <= 0 && minutes <= 0) {
            result += `${result.length > 0 ? ' and ' : ''}${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
        }
        if (result.length === 0) {
            result += 'Less than a minute';
        }
        return result;
    }
    const showPswdPopup = () => {
        setShowPaswdPopup(true)
        setCurrentPassword("")
        setNewPassword("")
        setConfirmPassword("")
    }
    const closePopup = () => {
        setShowPaswdPopup(false)
        setShowProfilePopup(false)
    }
    const showPopupProfile = (data) => {
        setShowProfilePopup(true)
        setFNChange(data.firstName)
        setLNChange(data.lastName)
        setPNChange(data.penName)
        setEmailChange(data.emailId)
        setMobileChange(data.mobileNumber)
        setBioChange(data.bio)
        setGender(data.gender)
        setDOBChange(data.dateOfBirth)
        if (
            data.gender === "Male" ||
            data.gender === "male"
        ) {
            document.getElementById("radio1").checked = true;
        }
        else {
            document.getElementById("radio2").checked = true;
        }

    }

    const coverImageUploadOnChange = (e) => {
        console.log('File selected:', e.target.files[0]);
        try {
            console.log('File selected:', e.target.files[0]);
            if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
                alert("Please attach jpg, jpeg, or png files");
                return false;
            }
        } catch (error) {
            console.error('Error in coverImageUploadOnChange:', error);
        }
        if (e.target.files[0])
            saveCoverPic(e.target.files[0].name, e.target.files[0]);
    };
    const saveCoverPic = async (image, fileData) => {
        const infoDetails = {
            "userId": window.localStorage.getItem("userId"),
            "cover_pic": image
        };
        const response = await callingAPI('userData/updateUserCoverPic', infoDetails);
        if (response.data.success == 1) {
            if (fileData.length !== 0) {
                imageS3Save(image, fileData);
            }
        }

    }
    const imageS3Save = (image, fileData) => {
        return new Promise((resolve, reject) => {
            var S3BucketMainFolder = environmentType + '/' + userImagePath;
            var fullFilePath = S3BucketMainFolder + '/' + image;

            s3.upload(
                {
                    Key: fullFilePath,
                    Body: fileData,
                    ACL: 'public-read',
                },
                (err, data) => {
                    if (err) {
                        alert('There was an error uploading your photo: ' + err.message);
                        reject(err);
                    } else {
                        // alert("File Uploaded Successfully")
                        resolve(data);
                        profileListHandle()
                        toast.success("Changed Successfully", { containerId: "K" })

                    }
                }
            );
        })
    };
    const profileImageUploadOnChange = (e) => {
        try {
            console.log('File selected:', e.target.files[0]);
            if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
                alert("Please attach jpg, jpeg, or png files");
                return false;
            }
        } catch (error) {
            console.error('Error in coverImageUploadOnChange:', error);
        }
        if (e.target.files[0])
            saveProfilePic(e.target.files[0].name, e.target.files[0]);
    }
    const saveProfilePic = async (image, fileData) => {
        const infoDetails = {
            "userId": window.localStorage.getItem("userId"),
            "bio": "",
            "image": image,
            "modified_on": new Date().getTime()
        };
        const response = await callingAPI('userData/updateUserProfileSummary', infoDetails);
        if (response.data.success == 1) {
            if (fileData.length !== 0) {
                imageS3Save(image, fileData);
            }
        }

    }
    const editProfileSave = async () => {
        let name = emailChange;
        var charactersEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if(!fnChange){
            toast.error("Please enter first name", { containerId: "K" })
            return false
        }else if(!lnChange){
            toast.error("Please enter last name", { containerId: "K" })
            return false
        }else if(!pnChange){
            toast.error("Please enter pen name", { containerId: "K" })
            return false
        }else if(!emailChange){
            toast.error("Please enter email id", { containerId: "K" })
            return false
        }else if (!name || charactersEmail.test(name) === false) {
            toast.error("please enter valid email", { containerId: "K" })
            return false
        }else if(!mobileChange){
            toast.error("Please enter mobile number", { containerId: "K" })
            return false
        }else if(!dobChange){
            toast.error("Please enter date of birth", { containerId: "K" })
            return false
        }else if(!gender){
            toast.error("Please select gender", { containerId: "K" })
            return false
        }
        const infoDetails = {
            "userId": window.localStorage.getItem("userId"),
            "firstName": fnChange,
            "lastName": lnChange,
            "emailId": emailChange,
            "mobileNumber": mobileChange,
            "dateOfBirth": dobChange,
            "gender": gender,
            "penName": pnChange,
            "bio": bioChange,
            "created_on": new Date().getTime()
        };
        const response = await callingAPI('userData/updateUserProfileDetails', infoDetails);
        if (response.data.success == 1) {
            toast.success("Updated Successfully", { containerId: "K" })
            profileListHandle()
            closePopup()
        }
    }
    
    const changePasswordHandle = async () => {
        
        if(!currentPassword){
            toast.error("Please enter current password", { containerId: "K" })
            return false
        }else if(!newPassword){
            toast.error("Please enter new password", { containerId: "K" })
            return false
        }else if(!confirmPassword){
            toast.error("Please enter confirm password", { containerId: "K" })
            return false
        }
        else if(newPassword !== confirmPassword){
            toast.error("Password doesn't match", { containerId: "K" })
            return false
        }
        const infoDetails = {
            "userId":  window.localStorage.getItem("userId"),
            "oldPassword": currentPassword,
            "currentPassword": confirmPassword
        };
        const response = await callingAPI('userData/changePassword', infoDetails);
        if (response.data.successMessage !="Old Passwords does not match" && response.data.success == 1) {
            toast.success("Changed Successfully", { containerId: "K" })
            profileListHandle()
            closePopup()
        }
        else{
            toast.error(response.data.successMessage, { containerId: "K" })
        }
    }
    const goToBookDetails = (userID) => {
        navigate('/book-detail', { state: { userID: userID } });
        // navigate('/profileBookDetail', { state: { userID: userID } });
        
    };
    const showWrite = () => {
        navigate('/write-here');
    }
    const hidePopup = (id) => {
        setActiveTab(0);
        const popup = document.getElementById(id);
        if (popup) {
            popup.style.display = 'none';
        }
    };
    const handleTabChange = (index) => {
        setActiveTab(index);
    };
    const showPopup = (id, data) => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        }
        else {
            if (data === "followers") {
                setActiveTab(0);
            } else {
                setActiveTab(1);
            }
            const popup = document.getElementById(id);
            if (popup) {
                popup.style.display = 'block';
            }
        }
    };
    const showPopup1 = () => {
        setPopupVisibility(true);
    };
    const handleFollowersUnFollow = (id) => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        } else {
            const PostData = {
                "followerId": id,
                "userId": window.localStorage.getItem("userId"),
                "createdOn": currentDate

            };
            callingAPI('book/unfollow', PostData).then((response) => {
                followListhandle()
            })
        }
    }
    const handleFollowersFollow = (id) => {


        const PostData = {
            "followerId": id,
            "userId": window.localStorage.getItem("userId"),
            "createdOn": currentDate

        };
        callingAPI('book/follow', PostData).then((response) => {
            followListhandle()
        })

    }
    const changeStatus = (event, date, episodeId) => {
        debugger
        setstatusValue(event.value);
        const PostData = {
            "episode_id": episodeId,
            "status_id": event.value,// for publish - 3, unpublish - 1
            "createdOn": new Date().getTime(),
            "userId": window.localStorage.getItem("userId")
        };
        callingAPI('episodeData/publishAndUnpublishEpisode', PostData).then((response) => {
            if (response.data.success === '1') {
                toast.success("Status changed successfully", { containerId: "K" })
            } else {
                console.log(response.data.errorMessage)
            }

        })
    }
    const handleDetailClick = (book) => {
        setData(book)
        setSelectedBook(true);
    };
    const backToList = () => {
        setSelectedBook(false);
        // setSelectedBookEdit(false);
        // bookListing();
    };
    return (

        <div className='section-star-writerdetails userProfileInfo'>
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"K"}
                theme="colored" />
            <Header />
           <div className="star-writer-detailWrpr">
           {profileList &&
                <>
                    {profileList.map((dataValue, i) =>
                        <div >
                            <div className="container position-relative">
                                <div className="columns the-header is-marginless">
                                    <div className="column header-text is-6 is-offset-3 is-12-mobile">
                                        <img className="header-background" src={dataValue.coverPic ? dataValue.coverPic : BookCover} id="header-background-id" alt="background-img" />

                                    </div>
                                </div>
                                <div className="column is-12 has-text-centered profilrImg position-relativeeeee">
                                    {/* <img className="profile-picture" src={dataValue.profilePic} alt="profile-picture" />
                                        <div className="profile-camera">
                                            <FaCamera />
                                        </div> */}
                                    <div class="profile-pic">
                                        {/* <label class="-label" >
                                                <span>  Upload</span>
                                            </label> */}
                                        {/* <input id="file" type="file" onChange={(event) => loadFile(event)} /> */}
                                        <img src={dataValue.profilePic} id="output" width="200" />

                                        <label htmlFor="fileInput" className="profile-camera" title='upload profile photo'>
                                            <FaCamera />
                                            <input
                                                type="file"
                                                id="fileInput"
                                                onChange={(e) => profileImageUploadOnChange(e)}
                                                accept="image/*"
                                                style={{ display: "none" }} // Hide the file input visually
                                            />
                                        </label>
                                    </div>

                                </div>
                                {/* <div className="upload-cover"  >
                                        <FaUpload />Upload
                                    </div> */}

                                <label htmlFor="fileInput1" className="edit-cover" title='upload cover photo'>
                                    <FaUpload /> Upload cover photo
                                    <input
                                        type="file"
                                        id="fileInput1"
                                        onChange={(e) => coverImageUploadOnChange(e)}
                                        style={{ display: "none" }} // Hide the file input visually/
                                    />
                                </label>

                            </div>
                            <div className="container">

                                <div className="star-writer-headText">
                                    <div className='profileInfoEdit'>

                                        <h3 className="">{dataValue.displayName}</h3>
                                        <span className='penNameUser'><img src={Write} /> {dataValue.penName}</span>
                                        <div className='bio-para'>{dataValue.bio}</div>
                                    </div>


                                    {/* <div className="author-follow-div">
                                        <div className="cursor-pointer">
                                            <span className='color-text-follow'>{followersList.length}</span> <br /> Followers
                                        </div>
                                        <div className="cursor-pointer">
                                            <span className='color-text-follow'>{followingList.length}</span> <br /> Following
                                        </div>
                                    </div> */}
                                    {/* {userDataList.map((data) => */}
                        <div className="star-writer-headText" style={{marginTop:"30px"}}>
                             <div className="author-follow-div">
                                <div className="cursor-pointer" onClick={() => showPopup('followersId', "followers")}>
                                    <span className='color-text-follow'>{followersList.length}</span> <br /> Followers
                                </div>
                                <div className="cursor-pointer" onClick={() => showPopup('followersId', "following")}>
                                    <span className='color-text-follow'> {followingList.length}</span> <br /> Following
                                </div>
                            </div> 
                        </div>
                        {/* )} */}



                                    <div className="follow-btn-div follow-btn-divProfile">

                                        <button className='changepassword-btn  EditProfile' onClick={() => showPopupProfile(dataValue)} ><span><FaEdit style={{color:"#de4200"}} />&nbsp;<span className='Text'>Edit Profile</span></span></button>
                                        <button className='changepassword-btn' onClick={() => showPswdPopup()} ><FaLockOpen/> &nbsp;<span className='Text'>Change Password</span></button>
                                    </div>
                                </div>


                                <Tabs >
                                    <TabList>
                                       
                                        <Tab>Status</Tab>
                                        <Tab>Library</Tab>
                                        <Tab>Books</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <Status />
                                    </TabPanel>
                                    <TabPanel>
                                       <LibraryListProfile/>
                                    </TabPanel>
 
                                    <TabPanel className="profileBkList">
                                        <div className="userbooklist-section" style={{padding:"0px"}}>
                                        <div className="status-wrapper"  >
                                        <div className="create-status">
                <span className="Addplus-btn addWrite1" 
                onClick={() => showWrite()}
                >
                    
                    <img src={Write} alt="" className='img-fluid' width={25} height={25} />
                </span>
                <div className="create-status-text">
                    <h3 className="">Write</h3>
                    <p>Write stories, series, poem or anything</p>
                </div>
            </div>
            </div>

                                            {(!selectedBook && (list || noList)) &&
                                            <>
                                                <>
                                                    {list.map((data, index) =>
                                                        <div key={index} className="user-booklist">
                                                            <div className="user-booklist-div">
                                                                <div className="user-booklist-img position-relative">
                                                                    <img src={data.bookImageWithPath} alt="" />
                                                                    <span className="user-booklist-episodesCount"> <FaBookOpen style={{ fontSize: "15px", color: "#fff" }} /> {data.noOfEpisodes} episodes</span>
                                                                </div>
                                                                <div className="user-booklist-detail">
                                                                    <p className="user-book-list-headText" title=""  onClick={() => handleDetailClick(data)}>{data.title}</p>
                                                                    <div className="">
                                                                        {/* <p className='detailsubtext'><FaUser /> Anvika</p> */}
                                                                    </div>
                                                                    <div className="detail-subsection">
                                                                        <p className='detailsubtext'><FaClock /> {displayBookDuration(data.duration)}</p>
                                                                        <p className='detailsubtext'><FaBookReader title="Views" /> {data.no_of_views} Views</p>
                                                                        <p className='detailsubtext'><FaCommentAlt title="Reviews" /> {data.no_of_reviews} Reviews</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between">
                                                                        <p className='detailsubtext'><FaLanguage title="Language" /> {data.languageName}</p>
                                                                        <p className='detailsubtext'>{moment(data.lastUpdatedDate).format('DD-MM-YYYY')}</p>
                                                                    </div>
                                                                    
                                                                    {/* <div className="col-md-4">
                                                      
                                                        <div className="">
                                                        <Select
                                                            id="select-status" className="selectControl-status"
                                                            value={statusValue}
                                                            placeholder="Change Status"
                                                            options={bookStatus.filter((status) => status.value !== data.status_id.toString()).map((item) => ({
                                                                value: item.value,
                                                                label: item.label,
                                                            }))}
                                                            onChange={(e) => changeStatus(e, data.created_on, data.id)}
                                                        />

                                                    </div>

                                                    </div> */}


                                                                </div>
                                                            </div>
                                                            {/* <div className="book-episode-sections">
                                                            {draftList.totalDraftBooksCount > 0 ?<div className="user-booklist-status-btn status-draftbtn">Draft Episode : {draftList.totalDraftBooksCount}</div> : ""}
                                                            {draftList.totalPublishedBookCount > 0 ?<div className="user-booklist-status-btn status-approvedbtn">Approved Episode : {draftList.totalPublishedBookCount}</div> : ""} */}
                                                            {/* {data.totalUnApprovedEpisodeCount > 0 ?<div className="user-booklist-status-btn status-unapprovedbtn">Unapproved Episode : {data.totalUnApprovedEpisodeCount}</div> : ""} */}
                                                            {/* </div> */}
                                                            {data.status_id === 3 ? (
                                                                <div className="tag approved-tag">
                                                                    Approved
                                                                </div>
                                                            ) : data.status_id === 1 ? (
                                                                <div className="tag draft-tag">
                                                                    Draft
                                                                </div>
                                                            ) : (
                                                                <div className="tag unapproved-tag">
                                                                    Unapproved
                                                                </div>
                                                            )}
                                                            {/* <div className="tag draft-tag">
                                    Draft
                                </div>
                                <div className="tag unapproved-tag">
                                    Unapproved
                                </div> */}
                                                        </div>
                                                    )}
                                                </>
                                                <>
                                                {noList.map((data, index) =>
                                                        <div key={index} className="user-booklist">
                                                            <div className="user-booklist-div">
                                                                <div className="user-booklist-img position-relative">
                                                                    <img src={data.bookImageWithPath} alt="" />
                                                                    <span className="user-booklist-episodesCount"> <FaBookOpen style={{ fontSize: "15px", color: "#fff" }} /> {data.noOfEpisodes} episodes</span>
                                                                </div>
                                                                <div className="user-booklist-detail">
                                                                    <p className="user-book-list-headText" title=""  onClick={() => handleDetailClick(data)}>{data.title}</p>
                                                                    <div className="">
                                                                        {/* <p className='detailsubtext'><FaUser /> Anvika</p> */}
                                                                    </div>
                                                                    <div className="detail-subsection">
                                                                        <p className='detailsubtext'><FaClock /> {displayBookDuration(data.duration)}</p>
                                                                        <p className='detailsubtext'><FaBookReader title="Views" /> {data.no_of_views} Views</p>
                                                                        <p className='detailsubtext'><FaCommentAlt title="Reviews" /> {data.no_of_reviews} Reviews</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between">
                                                                        <p className='detailsubtext'><FaLanguage title="Language" /> {data.languageName}</p>
                                                                        <p className='detailsubtext'>{moment(data.lastUpdatedDate).format('DD-MM-YYYY')}</p>
                                                                    </div>
                                                                    
                                                                    {/* <div className="col-md-4">
                                                      
                                                        <div className="">
                                                        <Select
                                                            id="select-status" className="selectControl-status"
                                                            value={statusValue}
                                                            placeholder="Change Status"
                                                            options={bookStatus.filter((status) => status.value !== data.status_id.toString()).map((item) => ({
                                                                value: item.value,
                                                                label: item.label,
                                                            }))}
                                                            onChange={(e) => changeStatus(e, data.created_on, data.id)}
                                                        />

                                                    </div>

                                                    </div> */}


                                                                </div>
                                                            </div>
                                                            {/* <div className="book-episode-sections"> */}
                                                            {/* {draftList.totalDraftBooksCount > 0 ?<div className="user-booklist-status-btn status-draftbtn">Draft Episode : {draftList.totalDraftBooksCount}</div> : ""}
                                                            {draftList.totalPublishedBookCount > 0 ?<div className="user-booklist-status-btn status-approvedbtn">Approved Episode : {draftList.totalPublishedBookCount}</div> : ""} */}
                                                            {/* {data.totalUnApprovedEpisodeCount > 0 ?<div className="user-booklist-status-btn status-unapprovedbtn">Unapproved Episode : {data.totalUnApprovedEpisodeCount}</div> : ""} */}
                                                            {/* </div> */}
                                                            {data.status_id === 3 ? (
                                                                <div className="tag approved-tag">
                                                                    Approved
                                                                </div>
                                                            ) : data.status_id === 1 ? (
                                                                <div className="tag draft-tag">
                                                                    Draft
                                                                </div>
                                                            ) : (
                                                                <div className="tag unapproved-tag">
                                                                    Unapproved
                                                                </div>
                                                            )}
                                                            {/* <div className="tag draft-tag">
                                    Draft
                                </div>
                                <div className="tag unapproved-tag">
                                    Unapproved
                                </div> */}
                                                        </div>
                                                    )}
                                                </>
                                                </>
                                            }
                                        </div>
                                        {selectedBook && (
                <BookDetail backToList={backToList} key={selectedBook} data={data} />
            )}
                                    </TabPanel>
                                   
                                </Tabs>
                            </div>
                        </div>
                    )}
                </>
            }
           </div>
            <Footer /> 
          

            {/* Change Password Begins here */}
            

            <div id="demo-modal" class="modal writeHereModal ChangePaswd" style={{ display: showPaswdPopup === true ? "flex" : "none" }} >
                <div class="modal__content">
                    <h3 id="popHeading">Change Password</h3>
                    <div className="modalFields">
                        <div className='write-here-sec ' style={{ background: "transparent" }}>

                            <div className="container write-here-wrapper writeinnerSec">
                                <div className="add-chapters">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Current Password</label>
                                        <input type="password" class="form-control" onChange={(e) => setCurrentPassword(e.target.value)}
                                            value={currentPassword}/>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">New Password</label>
                                        <input type="password" class="form-control" onChange={(e) => setNewPassword(e.target.value)}
                                            value={newPassword}/>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Confirm Password</label>
                                        <input type="password" class="form-control" onChange={(e) => setConfirmPassword(e.target.value)}
                                            value={confirmPassword}/>
                                    </div>
                                </div>
                                <div className=' actionsBtn'>
                                    <span onClick={() => changePasswordHandle()}>Save</span>
                                    <span className='btnCanceled'
                                        onClick={() => closePopup()}
                                    >Cancel</span>
                                </div>
                            </div>


                        </div>
                    </div>

                    <a onClick={() => closePopup()} class="modal__close"> <FaTimes style={{ color: "#de4200", cursor: "pointer" }} /></a>
                </div>
            </div>
            {/*  */}
            {/* profile edit popup begins  */}

            <div id="demo-modal" class="modal writeHereModal ChangePaswd" style={{ display: showProfilePopup === true ? "flex" : "none" }} >
                <div class="modal__content">
                    <h3 id="popHeading">Edit Profile</h3>
                    <div className="modalFields">
                        <div className='write-here-sec ' style={{ background: "transparent" }}>

                            <div className="container write-here-wrapper writeinnerSec">
                                <div className="add-chapters">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">First Name<sup style={{color:"red"}}>*</sup></label>
                                        <input type="text" class="form-control" onChange={(e) => setFNChange(e.target.value)}
                                            value={fnChange} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Last Name<sup style={{color:"red"}}>*</sup></label>
                                        <input type="text" class="form-control" onChange={(e) => setLNChange(e.target.value)}
                                            value={lnChange} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Pen Name<sup style={{color:"red"}}>*</sup></label>
                                        <input type="text" class="form-control" onChange={(e) => setPNChange(e.target.value)}
                                            value={pnChange} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Email Id<sup style={{color:"red"}}>*</sup></label>
                                        <input type="text" class="form-control" placeholder="" onChange={(e) => setEmailChange(e.target.value)}
                                            value={emailChange} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Mobile Number<sup style={{color:"red"}}>*</sup></label>
                                        <input type="text" class="form-control" placeholder="" onChange={(e) => setMobileChange(e.target.value)}
                                            value={mobileChange} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">DOB<sup style={{color:"red"}}>*</sup></label>
                                        <DatePicker
                                            selected={dobChange ? new Date(dobChange) : null}
                                            onChange={(date) => setDOBChange(date)}
                                            dateFormat="dd-MM-yyyy"
                                            className="form-control"
                                            maxDate={new Date()}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            autoComplete="off"
                                            dropdownMode="select"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Gender<sup style={{color:"red"}}>*</sup></label>
                                        <span style={{display:"flex"}}>
                                            <input
                                                type="radio"
                                                onChange={(e) => setGender(e.target.value)}
                                                value="Male"
                                                id="radio1"
                                                name="gender"
                                            />
                                            <label className='genderField' for="exampleInputEmail1" style={{minWidth:"auto", padding:"0 5px"}} >Male</label>
                                            
                                        </span>
                                        <span style={{display:"flex"}}>
                                            <input
                                                onChange={(e) => setGender(e.target.value)}
                                                type="radio"
                                                value="Female"
                                                id="radio2"
                                                name="gender"
                                            />
                                            <label for="exampleInputEmail1" className='genderField' style={{minWidth:"auto", padding:"0 5px"}} >Female</label>
                                            
                                        </span>

                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Bio</label>
                                        <textarea class="form-control" placeholder="" onChange={(e) => setBioChange(e.target.value)}
                                            value={bioChange}
                                        />
                                    </div>
                                </div>
                                <div className=' actionsBtn'>
                                    <span onClick={() => editProfileSave()}>Save</span>
                                    <span className='btnCanceled'
                                        onClick={() => closePopup()}
                                    >Cancel</span>
                                </div>
                            </div>


                        </div>
                    </div>

                    <a onClick={() => closePopup()} class="modal__close"> <FaTimes style={{ color: "#de4200", cursor: "pointer" }} /></a>
                </div>
            </div>
            {/*  profile popup ends here */}
            {/* Followers popup begins here  */}
            <div className="popup followersPopup" id="followersId">
                <section className='followers-wrapper'>
                    <div className="close-popup" onClick={() => hidePopup('followersId')}><FaTimes /></div>
                    <div className="start-writerBookList">
                        <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
                            <TabList>
                                <Tab>Followers</Tab>
                                <Tab>Following</Tab>
                            </TabList>

                            <TabPanel>
                                
                                <ul className='followers-ul'>
                                    {followersList && followersList.map((data) =>
                                        <li>
                                            <div className="">
                                                <div className="followers-name">{data.display_name}</div>
                                                <div className="followers-sub">{data.followerCount} Followers</div>
                                            </div>
                                            {data.isFollowing != 0 ?
                                                <div className="">
                                                    <button className='followers-btn' onClick={() => handleFollowersUnFollow(data.follower_id)}><FaUserMinus /><span>UnFollow</span></button>
                                                </div> :
                                                <div className="">
                                                    <button className='followers-btn' onClick={() => handleFollowersFollow(data.follower_id)}><FaUserPlus /><span>Follow</span></button>
                                                </div>}
                                        </li>)}

                                </ul>
                            </TabPanel>
                            <TabPanel>
                                <ul className='followers-ul'>
                                    {followingList && followingList.map((data) =>
                                        <li>
                                            <div className="">
                                                <div className="followers-name">{data.display_name}</div>
                                                <div className="followers-sub">{data.followerCount} Followers</div>
                                            </div>
                                            {data.isFollowing != 0 ?
                                                <div className="">
                                                    <button className='followers-btn' onClick={() => handleFollowersUnFollow(data.followed_id)}><FaUserMinus /><span>UnFollow</span></button>
                                                </div> :
                                                <div className="">
                                                    <button className='followers-btn' onClick={() => handleFollowersFollow(data.followed_id)}><FaUserPlus /><span>Follow</span></button>
                                                </div>}
                                        </li>)}

                                </ul>
                                
                            </TabPanel>
                        </Tabs>
                    </div>
                </section>
            </div>
            {/*  */}
        </div>
    )

}
export default StarWriterDetail