import React, { useState, useEffect } from "react";
import { FaEdit, FaSearch, FaPlus, FaTrashAlt, FaCheckCircle, FaRegTimesCircle, FaSave, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { callingAPI } from '../../service'
export default function StartWriterList() {
    const [writers, setWriters] = useState([]);
    const [statusChanged, setStatusChanged] = useState([]);
    const [popupView, setPopupView] = useState(false);
    const [authors, setAuthors] = useState([]);
    const [nonstar, setNonstar] = useState([]);
    const [searchAuthor, setSearchAuthor] = useState('');
    const [dataSection, setDataSection] = useState()
    useEffect(() => {
        writersListing();
        setPopupView(false);
        nonStarWriters();

    }, [])
    const writersListing = () => {
        document.getElementById('authorNameId').value = "";
        const PostData = {

        };
        callingAPI('getStarWriters', PostData).then((res) => {
            setWriters(res.data.result.starWriters);
            if (res.data.result.starWriters.length === 0) {
                setDataSection(true)
            }
            else {
                setDataSection(false)
            }
        })
    }
    const statusChangeWriter = (uId, status) => {
        var x = 1;
        if (status === 1) {
            x = window.confirm('Do you want to set as star writer?');
        } else {
            x = window.confirm('Do you want to remove from star writer?');
        }
        if (x === true) {
            const PostData = {
                userId: uId,
                isStarWriter: status === 0 ? 0 : 1,
            };
            setStatusChanged(status)
            callingAPI('userData/setUserAsStarWriter', PostData).then((res) => {
                if (res.data.success === "1") {
                    setPopupView(false);
                    writersListing();
                }
            })
        }
    }

    const addAuthors = () => {
        setPopupView(true);
        nonStarWriters();

    }

    const nonStarWriters = () => {
        const PostData = {

        };
        callingAPI('getNonStarWriters', PostData).then((res) => {
            setNonstar(res.data.result.nonStarWriters);

        })

    }
    const closePopup = () => {
        setPopupView(false);
    }
    const searchValue = () => {
        const PostData = {
            autherName: document.getElementById('authorNameId').value
        };

        callingAPI('getStarWriters', PostData).then((res) => {
            setWriters(res.data.result.starWriters);
            if (res.data.result.starWriters.length === 0) {
                setDataSection(true)
            }
            else {
                setDataSection(false)
            }
        })
    }
    const filterAuthors = () => {
        // Filter the 'nonstar' array based on the entered searchAuthor value
        return nonstar.filter((obj) =>
            obj.display_name.toLowerCase().includes(searchAuthor.toLowerCase())
        );
    };
    return (
        <>

            <div className="dashboardContentInner">
                <div className="mainHead ">
                    <div className="row">
                        <div className="col-md-8">
                            <h2>Star Writers List</h2>
                        </div>
                        <div className="col-md-4 dashClmRight">
                            <div className="dashAction">
                                <a
                                    onClick={() => addAuthors()}
                                    className="btnNavy"><FaPlus /> Add </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <input type="text" placeholder="Enter Author Name." id="authorNameId" className="form-control"

                            />
                        </div>
                        <div className="col-md-3">
                            <div className="dashAction">
                                <a
                                    onClick={() =>
                                        searchValue()
                                    }
                                    className="btnNavy"  > Search</a>
                                <a
                                    onClick={() =>
                                        writersListing()
                                    }
                                    className="btnNavy btnGrey"  > Clear</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="tabContentsWrap">
                <div className="issueSummWrap heightAuto">
                    <div className="summaryTabs budgetTable">
                        <table >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email Id</th>
                                    <th>Mobile Number</th>
                                    <th>Followers Count</th>
                                    <th style={{ width: "100px", textAlign: "center" }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {writers.map((obj, i) =>
                                    <tr>
                                        <td data-column="Authors Name">
                                            <img className="proPic" src={obj.profilePic} alt="" />
                                            {obj.display_name}
                                        </td>
                                        <td data-column="Email Id">{obj.email_id}</td>
                                        <td data-column="Mobile Number">{obj.mobile_number}</td>
                                        <td data-column="Followers Count">{obj.followersCount}</td>
                                        <td data-column="Action" style={{ textAlign: "center" }}>
                                            <span onClick={() => statusChangeWriter(obj.id, 0)} >
                                                <span className="removeBtn" title="title=" Click here to remove from star writer list>Remove</span>
                                            </span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tbody>
                                <tr>
                                    <td colspan="5">
                                    {dataSection &&
                                            <p className="noDataSec">No Data Found</p>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* =============== MODAL BEGINS HERE =============== */}
            <div id="demo-modal" class="modal" style={{ display: popupView === true ? "flex" : "none" }} >
                <div class="modal__content">
                    <h3 > Add Star Writer(s)</h3>
                    <div className="modalFields">
                        <div className="summaryTabs budgetTable starWriterListoverflw">
                            <table >
                                <thead>
                                    <tr>
                                        <th style={{ paddingLeft: "0px" }}>
                                            <input
                                                type="text"
                                                placeholder="Enter Author Name" className="form-control"
                                                value={searchAuthor}
                                                onChange={(e) => setSearchAuthor(e.target.value)}
                                                style={{ paddingLeft: "8px" }}
                                            />
                                        </th>
                                        <th style={{ width: "180px" }}></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {filterAuthors().map((obj, i) => (
                                        <tr key={i}>
                                            <td data-column="Authors Name"><b>{obj.display_name}</b><br />
                                                <span style={{ fontSize: "15px" }}> {obj.email_id} </span><br />
                                                <span style={{ fontSize: "15px" }}> {obj.mobile_number} </span></td>
                                            <td data-column="Set As Star Writer"   >
                                                <>
                                                    <span onClick={() => statusChangeWriter(obj.id, 1)} >
                                                        <span className="startWriterBtn" title="Click here to set as star writer">Set as star writer</span>

                                                    </span>

                                                </>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <a onClick={() => closePopup()} class="modal__close"> <FaRegTimesCircle /></a>
                </div>
            </div>

        </>
    )
}
