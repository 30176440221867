import React, { useState, useEffect, Component } from 'react';
import BannerImg from "../images/header-slide.jpg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Banner() {

    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000
    };
    return (
        <div className="AwBanner">
            <Slider {...settings}>
                <div className='bannerImg'>
                    <img src={BannerImg} alt="Banner" className='img-fluid' />
                    <div className="bannerContent">
                        <h3 className="">Explore genres, share your narratives, and connect with fellow storytellers.</h3>
                        <h2 className="">Magic Awaits at Your Fingertips !</h2>
                        <p className="">Immerse yourself in a universe where stories thrive and imagination knows no bounds. </p>
                        {/* <div className="text-center">
                            <button className='viewmore-btn mt-0 mx-1'>READ MORE</button>
                            <button className='viewmore-btn mt-0 mx-1 purchase-btn'>PURCHASE</button>
                        </div> */}
                    </div>
                </div>
                <div className='bannerImg'>
                    <img src={BannerImg} alt="Banner" className='img-fluid' />
                    <div className="bannerContent">
                        <h3 className="">A realm where every word holds power and every tale sparks imagination.</h3>
                        <h2 className="">Embark on an Adventure !  </h2>
                        <p className="">Dive into a world where your imagination takes flight and every page is a new adventure waiting to unfold. </p>
                    </div>
                </div>
                {/* <div className='bannerImg'>
                    <img src={BannerImg} alt="Banner" className='img-fluid' />
                    <div className="bannerContent">
                        <h3 className="">Online Learning Anytime, Anywhere!</h3>
                        <h2 className="">Discover Your Roots</h2>
                        <p className="">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words.</p>
                    </div>
                </div> */}

            </Slider>
        </div>
    )

}
export default Banner