
import React, { useState, useEffect, Component } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../../service';
import BookReview from './bookReview';
import { FaArrowLeft, FaEdit, FaUser, FaCalendar, FaMicrophone, FaStar, FaBookOpen, FaClock, FaBookReader } from "react-icons/fa";
import EpisodeEdit from './episodeEdit';
import Parser from 'html-react-parser';
import $ from "jquery"
function EpisodeDetails(props) {
    const [selectedEpisode, setSelectedEpisode] = useState([]);
    const [data, setData] = useState([]);
    const [selectedBookEdit, setSelectedBookEdit] = useState(false);
    const [selectedBook, setSelectedBook] = useState(false);
    useEffect(() => {
        console.log("*******************************", props);
        fetchData();
        // =============================

        if (window.localStorage.getItem('role') !== "Admin") {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 300) {
                $('#buttonArrow').addClass('show');
            } else {
                $('#buttonArrow').removeClass('show');
            }
        });

        $('#buttonArrow').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '300');
        });
        $('#buttonArrowAdmin').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '0');
        });
    }
        // buttonArrowAdmin




        // ===============================
    }, []);
    const fetchData = () => {
        const PostData = {
            "bookId": props.bookObj.book_id,
            "currentEpisodeId": props.bookObj.id,
            "userId": window.localStorage.getItem("userId")
            // "userId": props.bookObj.created_by
        };
        callingAPI('reading/episodeRead', PostData).then((response) => {
            if (response.data.success === '1') {
                setSelectedEpisode(response.data.bookReadNextData);

            }
        })

    }


    function backToEpisodeList() {
        props.setepisodeClick(false)
        props.setepisodeClickParent(false);
        window.scrollTo({top:0,behavior:'smooth'});
    }

    const backToList = () => {
        setSelectedBook(false);
        setSelectedBookEdit(false);
        fetchData();
        props.episodeListfunction()
    };
    const handleEditClick = (editData) => {
        setData(editData)
        setSelectedBookEdit(true);
    };
    return (
        <>
            {!selectedBookEdit &&
                <>
                    <div className=" booksList-Wrapper1 bookDetOLDDD ">
                        <>
                            {selectedEpisode.map((obj, i) => (
                                <div className="bkBg">
                                    <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                    {window.localStorage.getItem('role') !== "Admin" ?
                                        <div className="text-left backToLink"><span className='' id="buttonArrow"
                                            onClick={backToEpisodeList}
                                        //  onClick={props.backToList}

                                        ><FaArrowLeft title='Back To List' /></span></div>
                                        :
                                        <div className="text-left backToLink">
                                              <span className='' id="buttonArrowAdmin" style={{top:"38%"}} onClick={backToEpisodeList}>
                                                <FaArrowLeft title='Back To List' /></span>
                                         </div> 
                            }
                                        <div>
                                        {/* <span title='Micro Phone'  >
                                                    <FaMicrophone className='editiconBlog' />
                                                </span> */}
                                        <span title='Edit'
                                            onClick={() => handleEditClick(obj)}
                                        >
                                            <FaEdit className='editiconBlog' />
                                        </span>
                                        </div>
                                    </span>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <h3 className='main-bookHead text-center'>
                                                {obj.title}
                                            </h3>

                                            <div className="book-description text-left">
                                                {Parser(obj.description)}
                                            </div>

                                        </div>
                                    </div>
                                    <p></p>
                                </div>
                            ))}
                            <p></p>
                        </>

                    </div>

                </>
            }
            {selectedBookEdit && (
                <EpisodeEdit key={selectedBookEdit} data={data} book_id={props.bookObj.book_id} userId={props.bookObj.created_by} backToList={backToList} />
            )}
        </>
    )

}
export default EpisodeDetails