import React, { useState, useEffect, Component } from 'react';
import Header from '../Home/layout/header';
import Footer from '../Home/layout/footer';
import Book2 from "../images/304301_1706363750816.jpg"
import BookCover from "../images/cover-reading-books-wallpapers-19431.jpg"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Book1 from "../images/860295_1704701727805.jpg"
import { FaLayerGroup, FaStar, FaUser, FaClock, FaBookReader, FaTimes, FaUserPlus, FaUserMinus } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Content from "../Home/star-writer-content"
import Status from "../Home/star-writer-status"
import { useLocation } from 'react-router-dom';
import Write from "../images/write.png"
import { callingAPI } from '../service';
import { useNavigate } from 'react-router-dom';
function StarWriterDetail(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [userDataList, setUserDataList] = useState([])
    const [profileImage, setProfileImage] = useState([])
    const [coverImage, setCoverImage] = useState([])
    const [followersList, setFollowersList] = useState([])
    const [followingList, setFollowingList] = useState([])
    const [followStatusValue, setFollowStatusValue] = useState("")
    const [currentDate] = useState(new Date().getTime())
    const [activeTab, setActiveTab] = useState(0);
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const userID = (location.state && location.state.userID) ? location.state.userID : [];

     const [followerCount, setFollowerCount] = useState(); // State variable to hold follower count

    useEffect(() => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        }
        else{
        followStatus()
        followListHandle()
        
        const PostData = {
            "userId": userID
        };
        callingAPI('userData/userProfileDetails', PostData).then((response) => {
            if (response.data.success === '1') {
                setUserDataList(response.data.userData)
                setProfileImage(response.data.userData[0].profilePic)
                setCoverImage(response.data.userData[0].coverPic)
                setFollowerCount(response.data.userData[0].followers)
                console.log("Star Writers FollowerCount", response.data.userData[0].followers)
             }

        })
    }
    }, [])

    const followStatus = () => { 
        const PostData = {
            "userId": window.localStorage.getItem("userId"),
            "followerId": userID
        };
        callingAPI('book/getFollowingStatus', PostData).then((response) => {
            setFollowStatusValue(response.data.result[0].status)
            console.log("Star Writers Follower status", response.data.result[0].status)
        })
    }

    const followListHandle = () => {
        const PostData = {
            "userId": window.localStorage.getItem("userId"),
            "authorId": userID
        };
        callingAPI('book/followerList', PostData).then((response) => {
            setFollowersList(response.data.result.followersData)
            setFollowingList(response.data.result.followedData)
        })
    }
    const showPopup = (id, data) => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        }
        else {
            if (data === "followers") {
                setActiveTab(0);
            } else {
                setActiveTab(1);
            }
            const popup = document.getElementById(id);
            if (popup) {
                popup.style.display = 'block';
            }
        }
    };

    const hidePopup = (id) => {
        setActiveTab(0);
        const popup = document.getElementById(id);
        if (popup) {
            popup.style.display = 'none';
        }
    };
    const handleFollow = () => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        }
        else {
            const PostData = {
                "followerId": userID,
                "userId": window.localStorage.getItem("userId"),
                "createdOn": currentDate

            };
            callingAPI('book/follow', PostData).then((response) => {
                setFollowerCount(prevCount => prevCount + 1);
                followStatus()
            })
        }
    }
    const handleUnFollow = () => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        }
        else {
            const PostData = {
                "followerId": userID,
                "userId": window.localStorage.getItem("userId"),
                "createdOn": currentDate

            };
            callingAPI('book/unfollow', PostData).then((response) => {
                setFollowerCount(prevCount => Math.max(prevCount - 1, 0)); // Ensure follower count doesn't go below 0
                followStatus()
            })
        }
    }

    const handleFollowersFollow = (id) => {


        const PostData = {
            "followerId": id,
            "userId": window.localStorage.getItem("userId"),
            "createdOn": currentDate

        };
        callingAPI('book/follow', PostData).then((response) => {
            followListHandle()
        })

    }
    const handleFollowersUnFollow = (id) => {
        if (window.localStorage.getItem("userId") == null) {
            showPopup1()
        } else {
            const PostData = {
                "followerId": id,
                "userId": window.localStorage.getItem("userId"),
                "createdOn": currentDate

            };
            callingAPI('book/unfollow', PostData).then((response) => {
                followListHandle()
            })
        }
    }
    const showPopup1 = () => {
        setPopupVisibility(true);
    };
    const hidePopup1 = () => {
        // setPopupVisibility(false);
    };
    const handleTabChange = (index) => {
        setActiveTab(index);
    };
    


    return (
        <div className='section-star-writerdetails'>
            <Header isPopupVisible={isPopupVisible} showPopup={showPopup1} hidePopup={hidePopup1} />
            <div className="star-writer-detailWrpr">
                <div className="container position-relative">
                    <div className="columns the-header is-marginless">
                        <div className="column header-text is-6 is-offset-3 is-12-mobile">
                            {(coverImage || BookCover) &&
                                <img className="header-background" src={coverImage ? coverImage : BookCover} id="header-background-id" alt="background-img" />}
                        </div>
                    </div>
                    <div className="column is-12 has-text-centered">
                        {profileImage &&
                            <img className="profile-picture" src={profileImage} alt="profile-picture" />}
                    </div>


                </div>
                <div className="container">
                    {userDataList.map((data) =>
                        <div className="star-writer-headText">
                            <h3 className="">{data.displayName}</h3>
                            <div className="author-follow-div">
                                <div className="cursor-pointer" onClick={() => showPopup('followersId', "followers")}>
                                    <span className='color-text-follow'>
                                    {followerCount} 
                                    </span> <br /> Followers

                                    
                                </div>
                                <div className="cursor-pointer" onClick={() => showPopup('followersId', "following")}>
                                    <span className='color-text-follow'> {data.following}</span> <br /> Following
                                </div>
                            </div>
                            {followStatusValue != "NotFollowing" ?
                                <div className="follow-btn-div">
                                    <button onClick={() => handleUnFollow()} className='unfolow-btn' ><FaUserMinus /><span>UnFollow</span></button>
                                </div> :
                                <div className="follow-btn-div">
                                    <button onClick={() => handleFollow()} className='follow-btn'><FaUserPlus /><span>Follow</span></button>
                                </div>}
                        </div>)}
                    <div className="start-writerBookList">
                        <Tabs >
                            <TabList>
                                {/* <Tab>Content</Tab> */}
                                {/* <Tab>Status</Tab> */}
                            </TabList>

                            <TabPanel>
                                <Content userID={userID} />
                            </TabPanel>
                            {/* <TabPanel>
                                <Status userID={userID}/>
                            </TabPanel> */}
                        </Tabs>
                    </div>
                </div>
            </div>
            <Footer />



            <div className="popup followersPopup" id="followersId">
                <section className='followers-wrapper'>
                    <div className="close-popup" onClick={() => hidePopup('followersId')}><FaTimes /></div>
                    <div className="start-writerBookList">
                        <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
                            <TabList>
                                <Tab>Followers</Tab>
                                <Tab>Following</Tab>
                            </TabList>

                            <TabPanel>
                                <ul className='followers-ul'>
                                    {followersList && followersList.map((data) =>
                                        <li>
                                            <div className="">
                                                <div className="followers-name">{data.display_name}</div>
                                                <div className="followers-sub">{data.followerCount} Followers</div>
                                            </div>
                                            {data.isFollowing != 0 ?
                                                <div className="">
                                                    <button className='followers-btn' onClick={() => handleFollowersUnFollow(data.follower_id)}><FaUserMinus /><span>UnFollow</span></button>
                                                </div> :
                                                <div className="">
                                                    <button className='followers-btn' onClick={() => handleFollowersFollow(data.follower_id)}><FaUserPlus /><span>Follow</span></button>
                                                </div>}
                                        </li>)}

                                </ul>
                            </TabPanel>
                            <TabPanel>
                                <ul className='followers-ul'>
                                    {followingList && followingList.map((data) =>
                                        <li>
                                            <div className="">
                                                <div className="followers-name">{data.display_name}</div>
                                                <div className="followers-sub">{data.followerCount} Followers</div>
                                            </div>
                                            {data.isFollowing != 0 ?
                                                <div className="">
                                                    <button className='followers-btn' onClick={() => handleFollowersUnFollow(data.followed_id)}><FaUserMinus /><span>UnFollow</span></button>
                                                </div> :
                                                <div className="">
                                                    <button className='followers-btn' onClick={() => handleFollowersFollow(data.followed_id)}><FaUserPlus /><span>Follow</span></button>
                                                </div>}
                                        </li>)}

                                </ul>
                            </TabPanel>
                        </Tabs>
                    </div>
                </section>
            </div>   


        </div>
    )

}
export default StarWriterDetail