import React, { useState, useEffect } from "react";
import BuyerHeader from '../components/layouts/buyerHeader';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../service';
import Header from "../Home/layout/header";
import ResetHeader from "../Home/layout/resetPasswordHeader"
export default function Login() {
    const params = useParams()
    const [confirmPass, setConfirmPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [userId, setUserId] = useState("")
    const [notValid, setNotValid] = useState(true)

    useEffect(() => {
        if (params.tokenId && params.tokenId != "") {

            const PostData = {
                "encryptedData": params.tokenId,
            };
            callingAPI('authentication/decryptUrl', PostData).then((response) => {
                if (response.data.success === '1') {
                    setUserId(response.data.result.userId);
                    setNotValid(true)
                }
                else {
                    setNotValid(false)
                }
            })
        }
    }, [])

    const forgotPassApi = (e) => {
        e.preventDefault();
        let flag = true;
        if (!newPass) {
            alert("Please Fill New Password")
            document.getElementById("newPass").focus()
            flag = false
        } else if (!confirmPass) {
            alert("Please Fill Confirm Password")
            document.getElementById("cnfrmPass").focus()
            flag = false
        }
        else if (newPass !== confirmPass) {
            alert("Password do not match")
            document.getElementById("cnfrmPass").focus()
            flag = false
        }
        if (flag === true) {
            const PostData = {
                "userId": userId,
                "password": confirmPass
            };


            callingAPI('authentication/resetPassword', PostData).then((response) => {
                if (response.data.success === '1') {
                    alert("Password Updated Successfully")
                    window.location.href = '/';
                    setNewPass("")
                    setConfirmPass("")
                }
                else {
                    console.log("Error",response.data.errorMessage);
                }
            })
        }
    }

    const handleConfrimPassword = (event) => {
        setConfirmPass(event.target.value)
    }
    const handleNewPass = (event) => {
        setNewPass(event.target.value)
    }



    return (
        <>
            <div className="forgotPasswrdWrsp section-star-writerdetails WrapLeft loginWrap">
                 {/* <BuyerHeader />  */}
                 <ResetHeader/> 
                <div className="container">
                    {notValid === true ?
                        <div className="fotgotpass-wrapper">
                            <div class="form-group">
                                <label for="newPass">New Password</label>
                                <input type="password" class="form-control" id="newPass" placeholder="New Password" onChange={handleNewPass} value={newPass} />
                            </div>
                            <div class="form-group">
                                <label for="cnfrmPass">Confirm Password</label>
                                <input type="password" class="form-control" id="cnfrmPass" placeholder="Confirm Password" onChange={handleConfrimPassword} value={confirmPass} />
                            </div>
                            <div class="form-group text-center mb-0">
                                <button className="forgotPass-btn" onClick={(e) => forgotPassApi(e)}>Submit</button>
                            </div>
                        </div>
                        :
                        <div className="not-valid-sec">
                            You are not a valid user
                        </div>
                     } 
                </div>
            </div>
        </>
    )
}
