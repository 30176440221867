import React, { useState, useEffect, Component } from 'react';
import { FaLayerGroup, FaStar, FaUser, FaClock, FaBookReader, FaCommentAlt, FaEye } from "react-icons/fa";
import { callingAPI } from '../service';
import EpisodeDetail from './episodeDetail';
import EpisodewiseReview from './episodeWiseReview'
import { error } from 'jquery';
function EpisodeListHome(props) {
    const [bookDetail, setBookDetail] = useState([]);
    const [noOfEpisodes, setNoOfEpisodes] = useState([]);
    const [episodeClick, setepisodeClick] = useState(false);
    const [isData, setisData] = useState(false);
    const [bookObj, setbookObj] = useState({});
    const [progressPercentages, setProgressPercentages] = useState({}); // Store progress percentage for each episode
    const [largestScrollPercentage, setLargestScrollPercentage] = useState(0);
    const [readinEpisodeId, setReadinEpisodeId] = useState("");
    const [lastEpisodeOfList, setLastEpisodeOfList] = useState("");


    useEffect(() => {
        console.log("............p", props);
        fetchData();
    }, []);

    const fetchData = () => {
        // alert("kkkkkkkkkkkkkkkkkkkkk")
        console.log("@@@@@@@ 444444444");
        const PostData = {
            "bookId": props.id,
            "userId": window.localStorage.getItem("userId")
        };
        callingAPI('book/getBookDetails', PostData).then((response) => {
            if (response.data.success === '1') {
                props.setEpisodeLists(response.data.result[0].episodeDetails);
                setBookDetail(response.data.result);
                setNoOfEpisodes(response.data.result[0].noOfEpisodes)
                setisData(true)
                const progressData = {};
                response.data.result.forEach(book => {
                    book.episodeDetails.forEach(episode => {
                        progressData[episode.id] = episode.readingPercentage;
                    });
                });
                const lastEpisode = response.data.result[0].episodeDetails[response.data.result[0].episodeDetails.length - 1];
                setLastEpisodeOfList(lastEpisode)
                console.log("🚀 ~ callingAPI ~ progressData:", progressData)
                console.log("🚀 ~ 1111111111111111111111111111111111:", response.data.result)
                setProgressPercentages(progressData);
                console.log("@@@@@@@ 5555555555");
                props.findLastCompletedEpisode()
            } else {
                setBookDetail([])
            }
            console.log("🚀 ~ callingAPI ~ response.data.result[0].noOfEpisodes:", response.data.result[0].episodeDetails)

        })
    }

    function handleEpisodeDetailClick(obj) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setbookObj(obj)
        setepisodeClick(true)
        props.setepisodeClickParent(true)
        props.setEpisodeID(obj.id)

    };
    const displayBookDuration = (decimalMinutes) => {
        const totalSeconds = Math.round(decimalMinutes * 60);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        let result = '';
        if (hours > 0) {
            result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
        }
        if (minutes > 0) {
            result += `${result.length > 0 ? ' and ' : ''}${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        }
        if (seconds > 0 && hours <= 0 && minutes <= 0) {
            result += `${result.length > 0 ? ' and ' : ''}${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
        }
        if (result.length === 0) {
            result += 'Less than a minute';
        }
        return result;
    }
    const options1 = {
        // weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    };



    return (
        <>
            {isData === true && (
                (episodeClick === false) ?
                    <div className='episodeList-wrapper 111'>
                        <h3 className="">{noOfEpisodes} Chapters</h3>
                        <div className="row">
                            {bookDetail && bookDetail.map((bookobj, i) => (
                                bookobj && bookobj.episodeDetails.map((obj, i) => (
                                    <div className="col-md-6">
                                        <div className="episode-content-sec" style={{ cursor: "default" }}
                                        //  onClick={() => handleEpisodeDetailClick(obj)}
                                        >
                                            <div className="d-flex justify-content-between pb-3 align-centertext">
                                                <h3 onClick={() => handleEpisodeDetailClick(obj)}>{i + 1}. &nbsp;{obj.title}</h3>
                                                {/* <div className="episode-timetext">{new Date(obj.created_on).toLocaleString()}</div> */}

                                                <div className="episode-timetext">
                                                    <div className="episode-timetext">
                                                        {(new Intl.DateTimeFormat('en-GB', options1).format(obj.created_on))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex align-items-center eposde-list-activites">
                                                    <FaEye /> <span>{obj.no_of_views}</span>
                                                </div>
                                                {obj.rating != "" &&
                                                    <div className="d-flex align-items-center eposde-list-activites">
                                                        <FaStar /> <span>{obj.rating}</span>
                                                    </div>
                                                }
                                                <div className="d-flex align-items-center eposde-list-activites">
                                                    <FaClock /> <span>{displayBookDuration(obj.duration)}</span>
                                                </div>
                                            </div>

                                            {/* {console.log("000000000000000000000000000000", obj)} */}
                                            {/* {obj.readingPercentage > 0 ?
                                                <div class="progress-container" data-percentage={obj.readingPercentage}>
                                                    <div class="progress"></div>
                                                    <div class="percentage">0%</div>
                                                </div>
                                                : ""} */}
                                            {obj.readingPercentage !== 0 ?
                                                <div className="progress-container" data-percentage={progressPercentages[obj.id] || 0}>
                                                    <div className="progress" style={{ width: `${progressPercentages[obj.id] || 0}%` }}></div>
                                                    <div className="percentage">{progressPercentages[obj.id] || 0}%</div>
                                                </div>
                                                : ""}

                                        </div>
                                    </div>
                                ))))}
                        </div>
                    </div>
                    :
                    <>
                        {/* {console.log("bookObj*****....", bookObj)} */}
                        <EpisodeDetail findLastCompletedEpisode={props.findLastCompletedEpisode} episodeListfunction={fetchData} bookObj={bookObj} setepisodeClick={setepisodeClick} setepisodeClickParent={props.setepisodeClickParent} setProgressPercentages={setProgressPercentages} largestScrollPercentage={largestScrollPercentage} setLargestScrollPercentage={setLargestScrollPercentage} episodeID={props.episodeID} lastEpisodeOfList={lastEpisodeOfList} setLastEpisodeOfList={setLastEpisodeOfList} />
                        {/* <EpisodewiseReview obj={bookObj} id={bookObj.id} /> */}
                    </>
            )}

        </>
    )

}
export default EpisodeListHome