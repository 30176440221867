
import React, { useState, useEffect, Component , useContext} from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../service';
import { FaArrowLeft, FaEdit, FaUser, FaCalendar, FaStar, FaBookOpen, FaClock, FaBookReader } from "react-icons/fa";
import Parser from 'html-react-parser';
import FontSizeChanger from 'react-font-size-changer';
import { DarkLightModeContext } from "react-light-dark-mode";
import theme from "../images/theme.png"
function ReadMore(props) {
    const [selectedEpisode, setSelectedEpisode] = useState([]);
    const [data, setData] = useState([]);
    const [selectedBookEdit, setSelectedBookEdit] = useState(false);
    const [selectedBook, setSelectedBook] = useState(false);
    const { lightMode, toggleLightDarkMode } = useContext(DarkLightModeContext);
    const toggleMode = () => {
        toggleLightDarkMode();
      };
    useEffect(() => {
        console.log("*******************************",props.bookObj);
        setSelectedEpisode([props.bookObj]);
        // fetchData();
    }, []);
    const fetchData = () => {
        const PostData = {
            "bookId": props.bookObj.book_id,
            "currentEpisodeId": props.bookObj.id,
            "userId": window.localStorage.getItem("userId")
            // "userId": props.bookObj.created_by
        };
        callingAPI('reading/episodeRead', PostData).then((response) => {
            if (response.data.success === '1') {
                setSelectedEpisode(response.data.bookReadNextData);

            }
        })

    }


    function backToEpisodeList() {
        window.scrollTo({top:0,behavior:'smooth'});
        props.setepisodeClick(false)
        props.setepisodeClickParent(false);
    }

    const backToList = () => {
        setSelectedBook(false);
        setSelectedBookEdit(false);
        fetchData();
        props.episodeListfunction()
    };
    const handleEditClick = (editData) => {
        setData(editData)
        setSelectedBookEdit(true);
    };
    return (
        <>
            {/* {!selectedBookEdit && */}
                <>
                    <div className="epiosde-detail-wrapper 222">
                        <>
                            {selectedEpisode.map((obj, i) => (
                                <div className="bkBg ">
                                    <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                        <div className="text-left"><span className='backToLink' id="button"
                                            onClick={backToEpisodeList}
                                        //  onClick={props.backToList}

                                        ><FaArrowLeft title='Back To List' /></span></div>
                                        {/* <span title='Edit'
                                            onClick={() => handleEditClick(obj)}
                                        >
                                            <FaEdit className='editiconBlog' />
                                        </span> */}
                                        <div className='awe'>
                                   <FontSizeChanger
                                        targets={['#target-one .Awesomecontent']} 
                                        customButtons={{
                                             up: <span className='font' title="Increase Font Size">A+</span>,
                                            down: <span className='font' title="Decrease Font Size">A-</span>,
                                            style: {
                                                 color: 'white',
                                                WebkitBoxSizing: 'border-box',
                                                WebkitBorderRadius: '5px',
                                                width: '60px'
                                            },
                                            buttonsMargin: 10
                                        }}
                                    />
                                     <button onClick={toggleMode} style={{background:"transparent", border:"none", marginLeft:"5px"}}>
                                        <img src={theme} title="Dark Mode/Light Mode" style={{width:"30px"}}/> 
                                    </button>
                                   </div>
                                    </span>

                                    <div className="row" id="target-one">
                                        <div className="col-md-12">
                                            <h3 className='main-bookHead text-center Awesomecontent'>
                                                {obj.title}
                                            </h3>
                                            <span className="underline center"></span>
                                            <div className="book-description text-left Awesomecontent">
                                                {Parser(obj.description)}
                                            </div>

                                        </div>
                                    </div>
                                    <p></p>
                                </div>
                            ))}
                            <p></p>
                        </>

                    </div>

                </>
            {/* } */}
            {/* {selectedBookEdit && (
                <EpisodeEdit key={selectedBookEdit} data={data} book_id={props.bookObj.book_id} userId={props.bookObj.created_by} backToList={backToList} />
            )} */}
        </>
    )

}
export default ReadMore