import React, { useState, useEffect } from "react";
import { FaEdit, FaSearch, FaPlus, FaTrashAlt, FaCheckCircle, FaRegTimesCircle, FaSave } from "react-icons/fa";

export default function Notification() {

    return (
        <>

            <div className="dashboardContentInner">
                <div className="mainHead ">
                    <div className="row">
                        <div className="col-md-8">
                            <h2>Notifications</h2>
                        </div>

                    </div>
                </div>
            </div>
            <div className="tabContentsWrap notificationWrap">
                {/* <div className="issueSummWrap heightAuto"> */}
                <div className="summaryTabs budgetTable1">
                    <div className="form-group">
                        <input type="text" placeholder="Title" className="form-control" />
                    </div>
                    <div className="form-group">
                        <textarea placeholder="Message" className="form-control" />
                    </div>
                    <div className="form-group">
                        <div className="notifiSec">
                            <p>
                                <input type="radio" id="test1" name="radio-group"  />
                                <label for="test1">Every One</label>
                            </p>
                            <p>
                                <input type="radio" id="test2" name="radio-group" />
                                <label for="test2">
                                    <select name="" id="" className="form-control notiSelect">
                                        <option value="">Select Member</option>
                                    </select>
                                </label>
                            </p>
                            <p>
                                <input type="radio" id="test3" name="radio-group" />
                                <label for="test3">
                                <select name="" id="" className="form-control notiSelect">
                                        <option value="">Select Filter</option>
                                    </select>
                                </label>
                            </p>
                        </div>
                    </div>
                    <div className="form-group dashAction notSub">
                      <a className="btnNavy  ">SUBMIT</a>
                    </div>
                </div>
            </div>
        </>
    )
}
