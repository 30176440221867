import React, { useState, useEffect } from "react";
import { FaEdit, FaSearch, FaPlus, FaTrashAlt, FaCheckCircle, FaRegTimesCircle, FaSave, FaCalendar } from "react-icons/fa";
import { callingAPI } from '../../service'
import userImg from "../../images/user-avatar.png"
import AWS from 'aws-sdk';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
var s3;
export default function AuthorsList() {
    const [file, setFile] = useState('');
    const [authors, setAuthors] = useState([]);
    const [statusChanged, setStatusChanged] = useState([]);
    const [content, setContent] = useState([]);
    const [contentChange, setContentChange] = useState('');
    const [FNChange, setFNChange] = useState('');
    const [LNChange, setLNChange] = useState('');
    const [dobChange, setDOBChange] = useState('');
    const [genderChange, setGenderChange] = useState('');
    const [mobileChange, setMobileChange] = useState('');
    const [emailChange, setEmailChange] = useState('');
    const [imageName, setImageName] = useState('');
    const [configDetails, setConfigDetails] = useState({});
    const [authorNamedata, setAuthorNameData] = useState([]);
    const [popupView, setPopupView] = useState(false);
    const [ids, setId] = useState([]);
    const [environmentType, setEnvironmentType] = useState('');
    const [userImagePath, setUserImagePath] = useState('');
    const [categoryImagePath, setCategoryImagePath] = useState('');
    const [imgObject, setImgObject] = useState('');
    const [imgName, setImgName] = useState("")
    const [dataSec, setDataSec] = useState()
    useEffect(() => {
        setPopupView(false);
        authorsListing();
        const PostData = {

        };
        callingAPI('configurationData', PostData).then((res) => {

            setConfigDetails(res.data.result[0]);
            const getConfigValue = (configKey) => {
                return res.data.result[0].configDetails
                    ? res.data.result[0].configDetails.find((config) => config.config_key === configKey)?.config_value
                    : null;
            };
            setEnvironmentType(getConfigValue("S3BucketMainFolder"));
            setUserImagePath(getConfigValue("userImagePath"));
            AWS.config.update({
                accessKeyId: getConfigValue("AWSAccessKeyId"),
                secretAccessKey: getConfigValue("AWSSecretKey"),
                region: getConfigValue("s3Region"),
            });
            var bucketParams = {
                Bucket: getConfigValue("s3bucket"),
            };
            s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
        })
    }, [])
    // Function to get config_value based on config_key
    const getConfigValue = (configKey) => {
        return configDetails.configDetails
            ? configDetails.configDetails.find((config) => config.config_key === configKey)?.config_value
            : null;
    };
    const authorsListing = () => {
        document.getElementById('authorNameId').value = "";
        const PostData = {
            autherName: ""
        };
        callingAPI('userData/authorList', PostData).then((res) => {
            setAuthors(res.data.result);
             if (res.data.result.length === 0) {
                setDataSec(true)
            }
            else {
                setDataSec(false)
            }

        })

    }
    const statusChange = (uId, status) => {

        var x = false;
        if (status === true) {
            x = window.confirm('Do you want to Disable this?');
        } else {
            x = window.confirm('Do you want to Enable this?');
        }
        if (x === true) {
            const PostData = {
                userId: uId,
                status: status === false ? true : false,
            };
            setStatusChanged(status)
            callingAPI('userData/activateAndDeactivateUser', PostData).then((res) => {
                if (res.data.success === "1") {
                    authorsListing();
                }
            })
        }

    }
    const searchValue = () => {
        const PostData = {
            autherName: document.getElementById('authorNameId').value
        };

        callingAPI('userData/authorList', PostData).then((res) => {
            setAuthors(res.data.result); 
            if (res.data.result.length === 0) {
                setDataSec(true)
            }
            else {
                setDataSec(false)
            }
        })
    }
    const closePopup = () => {
        setImageName("");
        setPopupView(false);
        setImgObject("");
        setImgName("");

        setFile("")

    }
    const editContent = (info) => {
        setPopupView(true);
        setId(info.id);
        setContentChange(info.bio);
        setFNChange(info.first_name);
        setLNChange(info.last_name);
        setDOBChange(formatDate(info.dob));
        setGenderChange(info.gender);
        setMobileChange(info.mobile_number);
        setEmailChange(info.email_id);
        setAuthorNameData(info.display_name);
        // setImgObject(info.image_name); 
        setFile(info.image_name);
        setImageName(info.image_name);
        setImgName(info.profile_pic)
    }
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const formattedDate = dateObject.toISOString().split('T')[0];
        return formattedDate;
    };
    const saveContent = (imageName, fulFile = []) => {

        const infoDetails = {
            "userId": ids,
            "firstName": FNChange,
            "lastName": LNChange,
            "emailId": emailChange,
            "mobileNumber": mobileChange,
            "dateOfBirth": dobChange, //"1996-05-11"
            "gender": genderChange,
            "penName": "",
            "bio": contentChange,
            "created_on": new Date().getTime()
        };
        callingAPI('userData/updateUserProfileDetails', infoDetails).then(async (res) => {

            if (fulFile.length !== 0) {
                const PostData = {
                    userId: ids,
                    bio: contentChange,
                    image: imageName,
                    modified_on: new Date().getTime(),
                };

                callingAPI('userData/updateUserProfileSummary', PostData).then(async (res) => {
                    if (res.data.success === "1") {
                        const promises = [];
                        if (fulFile.length !== 0) {
                            await imageS3Save(imageName, fulFile);
                        }
                        authorsListing();
                        setPopupView(false)
                        closePopup()
                        //alert("Data Updated Successfully.");

                    }
                    else {
                        alert("Invalid Data");
                    }
                })
            }
            if (res.data.success === "1") {
                authorsListing();
                setPopupView(false)
                alert("Data Updated Successfully.");
            }
            else {
                alert("Invalid Data");
            }
        })


    }
    const handleImageUploadOnChange = (e) => {

        if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {

            alert("Please attach jpg,jpeg or png files");
            return false;
        }
        setFile(e.target.files[0]);
        setImageName(e.target.files[0].name);
        const obj = URL.createObjectURL(e.target.files[0])
        setImgObject(obj);
        document.getElementById('uploadButton').style.display = 'block';

    };
    const imageS3Save = (imageName, file) => {
        return new Promise((resolve, reject) => {
            var S3BucketMainFolder = environmentType + '/' + userImagePath;
            var fullFilePath = S3BucketMainFolder + '/' + imageName;

            s3.upload(
                {
                    Key: fullFilePath,
                    Body: file,
                    ACL: 'public-read',
                },
                (err, data) => {
                    if (err) {
                        alert('There was an error uploading your photo: ' + err.message);
                        reject(err);
                    } else {
                        // alert("File Uploaded Successfully")
                        resolve(data);
                        document.getElementById('uploadButton').style.display = 'block';
                        authorsListing();
                    }
                }
            );
        })
    };
    return (
        <>

            <div className="dashboardContentInner">
                <div className="mainHead ">
                    <div className="row">
                        <div className="col-md-8">
                            <h2>Authors List</h2>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <input type="text" placeholder="Enter Name" id="authorNameId" className="form-control"

                            />
                        </div>
                        <div className="col-md-3">
                            <div className="dashAction">
                                <a
                                    onClick={() =>
                                        searchValue()
                                    }
                                    className="btnNavy"  > Search</a>
                                <a
                                    onClick={() =>
                                        authorsListing()
                                    }
                                    className="btnNavy btnGrey"  > Clear</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="tabContentsWrap AuthrsListWrap">
                <div className="issueSummWrap heightAuto">

                    <div className="summaryTabs budgetTable">
                        <table >
                            <thead>
                                <tr>
                                    <th style={{ width: "50px" }}>Image</th>
                                    <th style={{ width: "300px" }}>Name</th>
                                    <th style={{ width: "150px" }}>Email Id</th>
                                    <th style={{ width: "150px" }}>Mobile Number</th>
                                    <th style={{ width: "80px" }}>Status</th>
                                    <th style={{ width: "80px" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {authors.map((obj, i) =>
                                    <tr>
                                        <td>
                                            {obj.profile_pic !== null ?
                                                <img className="proPic" src={getConfigValue('s3Url') + getConfigValue('S3BucketMainFolder') + "/" + getConfigValue('userImagePath') + "/" + obj.profile_pic} alt="" style={{ margin: "5px" }} />
                                                :
                                                <>
                                                    <img className="proPic" src={userImg} alt="" style={{ margin: "5px" }} />
                                                    {/* <img className="proPic" src={imgName}/> */}
                                                </>
                                            }

                                        </td>

                                        <td data-column="Authors Name"><b>{obj.display_name}</b> <br />
                                            <span className="alignCenter">
                                                {obj.bio !== "" && <>

                                                    <span className="bio1" id="bioTxt"

                                                        style={{ fontSize: "15px" }}>
                                                        {obj.bio}
                                                    </span>
                                                </>
                                                }

                                            </span>
                                        </td>
                                        <td data-column="Email Id">{obj.email_id}</td>
                                        <td data-column="Mobile Number">{obj.mobile_number}</td>
                                        <td data-column="Status" >
                                            {obj.is_active === 1 &&
                                                <FaCheckCircle color={"green"} title="Click to Deactivate " onClick={() => statusChange(obj.id, true)} />
                                            }
                                            {obj.is_active === 0 &&
                                                <FaRegTimesCircle color={"red"} title="Click to Activate " onClick={() => statusChange(obj.id, false)} />
                                            }</td>
                                        <td> <FaEdit id="authorEdit" className="faEdit"
                                            onClick={() => editContent(obj)}

                                        /></td>

                                    </tr>
                                )}
                            </tbody> 
                            <tbody>
                                <tr>
                                    <td colspan="6">
                                        {dataSec &&
                                            <p className="noDataSec">No Data Found</p>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            {/* =============== MODAL BEGINS HERE =============== */}
            <div id="demo-modal" class="modal" style={{ display: popupView === true ? "flex" : "none" }} >
                <div class="modal__content">
                    <h3 id="popHeading">Authors Details  </h3>
                    <div className="modalFields">
                        <div className="form-group">
                            <label htmlFor=""> First Name <span>:</span> </label>
                            <input type="text" className="form-control"
                                onChange={(e) => setFNChange(e.target.value)}
                                value={FNChange}

                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor=""> Last Name <span>:</span> </label>
                            <input type="text" className="form-control"
                                onChange={(e) => setLNChange(e.target.value)}
                                value={LNChange}

                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor=""> DOB <span>:</span> </label>
                            <DatePicker
                                selected={dobChange ? new Date(dobChange) : null}
                                onChange={(date) => setDOBChange(date)}
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                                maxDate={new Date()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                dropdownMode="select"
                            />
                            {/* <input type="text" className="form-control" 
                                // onChange={(e) => handleDOBChange(e.target.value)}
                                value={dobChange}
                            /> */}

                        </div>
                        <div className="form-group">
                            <label htmlFor=""> Gender <span>:</span> </label>
                            <input type="text" className="form-control"
                                onChange={(e) => setGenderChange(e.target.value)}
                                value={genderChange}

                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor=""> Phone No <span>:</span> </label>
                            <input type="text" className="form-control"
                                onChange={(e) => setMobileChange(e.target.value)}
                                value={mobileChange}

                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor=""> Email Id <span>:</span> </label>
                            <input type="text" className="form-control"
                                onChange={(e) => setEmailChange(e.target.value)}
                                value={emailChange}

                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor=""> Description <span>:</span> </label>
                            <input type="text" className="form-control"
                                onChange={(e) => setContentChange(e.target.value)}
                                value={contentChange}

                            />
                        </div>

                        {/* Image Upload Begins */}
                        <div className="form-group">
                            <label htmlFor=""> Image  <span>:</span> </label>
                            <span id="uploadButton" className="uploadButton uploadButtonSmall">
                                <input
                                    type="file"
                                    id="file1"
                                    title="Upload"
                                    onChange={(e) => handleImageUploadOnChange(e)}
                                    hidden
                                />
                                <label for="file1" className="btn-upload">
                                    upload
                                </label>
                                {/* {imgName ?
                                    <img className="proPic" src={getConfigValue('s3Url') + getConfigValue('S3BucketMainFolder') + "/" + getConfigValue('userImagePath') + "/" + imgName} alt="" />
                                    : */}
                                <img className="proPic" src={imgObject !== "" ? imgObject : getConfigValue('s3Url') + getConfigValue('S3BucketMainFolder') + "/" + getConfigValue('userImagePath') + "/" + imgName} width={40} height={40} style={{ margin: "5px" }} />
                                {/* } */}



                                {/* <img src={imgObject !== "" ? imgObject : imageName} 
                                alt="" width={40} height={40} style={{margin:"5px"}} /> */}
                                {/* <img className="proPic" src={getConfigValue('s3Url') + getConfigValue('S3BucketMainFolder') + "/" + getConfigValue('userImagePath') + "/" + imageName} alt="" /> */}


                            </span>
                        </div>
                    </div>
                    <div class="modal__footer">
                        <div className="dashAction">
                            <a
                                onClick={() => saveContent(imageName, file)}

                                id="saveBtn" className="btnNavy"  >Update</a>
                            <a
                                onClick={() => closePopup()}
                                className="btnCancel"> Cancel</a>
                        </div>
                    </div>
                    <a onClick={() => closePopup()} class="modal__close"> <FaRegTimesCircle /></a>
                </div>
            </div>
        </>
    )
}
