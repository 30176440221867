import React, { useEffect, useState } from 'react';
import {FaEnvelope,FaLock, FaTimes} from "react-icons/fa";
import Google from "../images/google.png";
import { callingAPI } from '../../src/service';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import firebaseApp from './firebase'
const LoginPopup = ({ show, onClose }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
   
    useEffect(() => {
        const loginPopup = document.getElementById("loginPopup");
        if (loginPopup) {
            loginPopup.style.display = show ? 'block' : 'none';
        }
    }, [show]);

    const closePopUp = () => {
        if (onClose) {
            onClose();
            navigate('/')
        }
    };
    // initializeApp(firebaseConfig);
     const auth = getAuth(firebaseApp);
     useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            console.log('User signed in:', user);
          } else {
            console.log('User signed out');
          }
        });
    
        return () => unsubscribe();
      }, [auth]);
      const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
    
        try {
          const result = await signInWithPopup(auth, provider);
          console.log('Google Sign-In success:', result.user);
          const PostData = {
            "firstName": result.user.displayName,
            "lastName": "",
            "emailId": result.user.email,
            "mobileNumber": "",
            "dateOfBirth": "",
            "password": "",
            "created_on": new Date().getTime(),
            "gender": "",
            "penName": result.user.displayName,
            "bio": "",
            "from": "Gmail"
        }
        console.log("postdataaaa", PostData)
        callingAPI('authentication/userRegistration', PostData).then((data) => {
            console.log("fffffffffffffffffffffff", data)
            if (data.data.success === '1') {
                debugger
                toast.success("Successfully Registered", { containerId: "M" })
                window.localStorage.setItem("role", data.data.result[0].role);
                window.localStorage.setItem("userId", data.data.result[0].userId);
                window.localStorage.setItem("emailId", data.data.result[0].emailId);
                window.location.href = '/';
            }else{
                toast.error("Something went wrong.", { containerId: "M" }) 
            }
        })
        } catch (error) {
          console.error('Google Sign-In error:', error);
        }
      };
    const loginClick = () => {
        if (forgotPasswordMode) {
            if (!email) {
                toast.error("Email Id Required", { containerId: "M" })
                document.getElementById("emailId").focus()
                return false
            } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                toast.error("Please Fill Valid Email Id", { containerId: "M" })
                document.getElementById("emailId").focus()
                return false
            } else {
                const PostData = {
                    "emailId": email
                }
                callingAPI('authentication/forgotPassword', PostData).then((data) => {
                    if (data.data.success === '1') {
                        toast.success("Email sent successfully", { containerId: "M" })
                    }
                })
            }
        }
        else {
            if (!email) {
                toast.error("Email Id Required", { containerId: "M" })
                document.getElementById("emailId").focus()
                return false
            } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                toast.error("Please Fill valid Email Id", { containerId: "M" })
                document.getElementById("emailId").focus()
                return false
            }
            else if (!password) {
                toast.error("Password Required", { containerId: "M" })
                document.getElementById("pass").focus()
                return false
            }
            else {
                const PostData = {
                    userName: email,
                    password: password,
                };
                console.log("postdataaaa", PostData)
                callingAPI('authentication/userLogin', PostData).then((data) => {
                    console.log("dataaaaaaa", data)
                    if (data.data.success === '1') {
                        toast.success("Logged In Successfully", { containerId: "M" })
                        if (data.data.userData[0].role === 'Admin') {
                            window.localStorage.setItem("role", data.data.userData[0].role);
                            window.localStorage.setItem("userId", data.data.userData[0].userId);
                            window.location.href = '/dashboard/dashboard';
                        }
                        else {
                            window.location.href = '/';
                            window.localStorage.setItem("role", data.data.userData[0].role);
                            window.localStorage.setItem("userId", data.data.userData[0].userId);
                            window.localStorage.setItem("emailId", data.data.userData[0].emailId);
                        }
                    }
                    else {
                        toast.error(data.data.successMessage, { containerId: "M" })
                    }

                })
            }
        }
    };
    const forgotPasswordClick = (e) => {
        setForgotPasswordMode(!forgotPasswordMode);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loginClick();
        }
    };
    return (
        <>
            <div className="popup" id="loginPopup">
                <ToastContainer
                    enableMultiContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    containerId={"M"}
                    theme="colored" />
                <section className='login-wrapper'>
                    <div>
                        <h1 className='login-headtext'>Welcome to Krithy</h1>
                        <span className="underline-login center"></span>
                        <div style={{ cursor: 'pointer', position: "relative" }} onClick={closePopUp}>
                            <FaTimes className='faTimesRight' />
                        </div>
                        <div className="inputbox faEnvelope">
                            <FaEnvelope />
                            <input type="text" required id="emailId" className='email-input' value={email} autoComplete="off" autoFocus onChange={(e) => setEmail(e.target.value)} onKeyPress={handleKeyPress}/>
                            <label htmlFor="" >Email</label>
                        </div>
                        <div className="inputbox" style={{ display: forgotPasswordMode ? 'none' : 'block' }}>
                            <FaLock />
                            <input type="password" id="pass" required value={password} autoComplete="off" onChange={(e) => setPassword(e.target.value)} onKeyPress={handleKeyPress}/>
                            <label htmlFor="" >Password</label>
                        </div>
                        {!forgotPasswordMode &&
                            <div className="forget " >
                                <a onClick={(e) => forgotPasswordClick(e)}>
                                    Forget Password
                                </a>
                            </div>
                        }
                        <button className='' onClick={() => loginClick()}> {forgotPasswordMode ? 'Send Email' : 'Log in'}</button>
                        <div className="my-1 or-text">Or</div>
                        {forgotPasswordMode && <div className="forget" style={{ justifyContent: "center", margin: "20px 0" }}>
                            <a onClick={(e) => forgotPasswordClick(e)}>
                                Back to Login
                            </a>
                        </div>
                        }
                        <button className='login-google-btn' style={{ display: forgotPasswordMode ? 'none' : 'block' }}  onClick={handleGoogleSignIn} ><img src={Google} alt="Google" className='img-fluid google-img' />Continue with Google</button>
                        <div className="register" style={{ display: forgotPasswordMode ? 'none' : 'block' }}>
                            <p>Not on Krithy yet ? <Link to="/register">Sign up</Link></p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default LoginPopup;
