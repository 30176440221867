import React, { useState, useEffect, Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import category1 from "../images/category-filter-img-01.jpg"
import category2 from "../images/category-filter-img-02.jpg"
import category3 from "../images/category-filter-img-03.jpg"
import category4 from "../images/category-filter-img-04.jpg"
import { FaArrowRight } from "react-icons/fa";
import { callingAPI } from '../service';
import { useNavigate, Link } from "react-router-dom";
function Category() {
    const navigate = useNavigate();
    const [list, setList] = useState([])

    useEffect(() => {
        categorylist()
    }, [])

    const categorylist = () => {
        const PostData = {

        };
        callingAPI('book/booksByCategories', PostData).then((response) => {
            if (response.data.success === '1') {
                console.log("........................category", response.data.bookListCategoryArray);
                setList(response.data.bookListCategoryArray)
            }

        })
    }
    const goToComponentB = (categoryName) => {
        navigate('/books', { state: { categoryName: categoryName } });
        window.scrollTo({top:0,behavior:'smooth'});
    };
    const goToComponentstar = (userID) => {
        navigate('/star-writer', { state: { userID: userID } });
        window.scrollTo({top:0,behavior:'smooth'});
    };
    const goToBookDetails = (userID) => {
        navigate('/book-detail', { state: { userID: userID } });
        window.scrollTo({top:0,behavior:'smooth'});
    };
    return (
        <header className="categorySection">
            <div className="">
                <div className="content-center">
                    <div className="">
                        <h2 className="section-title">Check Out The New Releases</h2>
                        <span className="underline center"></span>
                        <p className="lead">Discover an eclectic mix of captivating stories across different genres in our latest releases, offering something for every reader's taste and imagination.</p>
                    </div>
                    <div className="category-tab">
                        <Tabs>
                            <TabList>
                                {list.slice(0, 6).map((data) =>
                                    <Tab>{data.categoryname
                                    }</Tab>)}
                                {/* <Tab>SHORT STORIES</Tab>
                                <Tab>DARK ROMANCE</Tab>
                                <Tab>ROYAL ROMANCE</Tab>
                                <Tab>AUDIO</Tab>
                                <Tab>BOOKS</Tab>
                                <Tab>MAGAZINES</Tab> */}
                            </TabList>
                            {list.slice(0, 6).map((data) =>
                                <TabPanel>
                                    <div className="categoryTabList">
                                        <ul>
                                            {data.bookData.slice(0, 6).map((dataset) =>
                                                // <li className='position-relative'>
                                                //     <img src={dataset.bookImage} alt="" className='img-fluid' />
                                                //     <div className="bg-orange">
                                                //         <div className="info-block">
                                                //             <h4 className='texthead'>{dataset.title}</h4>
                                                //             <span className="author">
                                                //                 <span className='strong-text'>Author:</span> {dataset.authorName}
                                                //             </span>
                                                //             <span className="author">
                                                //                 {/* <span className='strong-text'>Epsisodes:</span> {dataset.title} */}
                                                //                 <span className='strong-text'>Epsisodes:</span> 10
                                                //             </span>
                                                //             <div className="rating">

                                                //             </div>

                                                //             <a href="" className='readmore'>Read More <FaArrowRight /></a>
                                                //         </div>
                                                //     </div>
                                                // </li>
                                                <>
                                               <li class="content">

                                                    <div class="content-overlay"></div>
                                                    <img src={dataset.bookImage} alt="" className='content-image img-fluid' />
                                                    
                                                    <div className="bg-orange content-details fadeIn-bottom">
                                                        <div className="info-block">
                                                            <h4 className='texthead' >{dataset.title}</h4>
                                                            <span className="author">
                                                                <span className='strong-text' >Author :</span>
                                                                <span className='readmore' style={{ cursor: 'pointer' }} onClick={() => goToComponentstar(dataset.user_id)}> {dataset.authorName}</span>
                                                            </span>
                                                            {dataset.totalEpisodeCount > 0 &&
                                                                <span className="author">
                                                                    {/* <span className='strong-text'>Epsisodes:</span> {dataset.title} */}
                                                                    <span className='strong-text' >Episodes :</span> {dataset.totalEpisodeCount}
                                                                </span>}
                                                            <div className="rating">

                                                            </div>

                                                            <span onClick={() => goToBookDetails(dataset.bookId)} style={{ cursor: 'pointer' }} className='readmore'>Read More <FaArrowRight /></span> 
                                                        </div>
                                                        
                                                    </div>
                                                    <h4 className='texthead categoryTxtTitle' >{dataset.title}</h4>
                                                </li>
                                                 
                                                </>
                                            )}

                                        </ul>
                                        {data.bookData.length > 6 &&
                                            <div className="text-center">
                                                <button className='viewmore-btn' onClick={() => goToComponentB(data.categoryname)}>VIEW MORE</button>
                                            </div>}
                                    </div>
                                </TabPanel>)}
                        </Tabs>
                    </div>
                </div>
            </div>
        </header>
    )

}
export default Category