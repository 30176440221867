import React, { useState ,useEffect} from 'react';
import BannerImg from "../images/header-slide.jpg"
import Header from '../Home/layout/header';
import Footer from '../Home/layout/footer';
import { FaCalendarAlt, FaEnvelope, FaLock, FaPen, FaPhoneAlt, FaTransgenderAlt, FaUser } from "react-icons/fa";
import { callingAPI } from '../../src/service';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Google from "../images/google.png";
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import firebaseApp from './firebase'
function Banner() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [penName, setPenName] = useState('');
    const [gender, setGender] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [signUpEmail, setSignUpEmail] = useState('');
    const [signUpPassword, setSignUpPassword] = useState('');
    const [signUpConfirmPassword, setSignUpConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
    const [checked, setChecked] = useState(false);
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };
    const formatToYYYYMMDD = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are zero-indexed
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const isValidPhoneNumber = (phoneNumber) => {
        return /^\d{10}$/.test(phoneNumber);
    };
    const auth = getAuth(firebaseApp);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            console.log('User signed in:', user);
          } else {
            console.log('User signed out');
          }
        });
    
        return () => unsubscribe();
      }, [auth]);
    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
    
        try {
          const result = await signInWithPopup(auth, provider);
          console.log('Google Sign-In success:', result.user);
          const PostData = {
            "firstName": result.user.displayName,
            "lastName": "",
            "emailId": result.user.email,
            "mobileNumber": "",
            "dateOfBirth": "",
            "password": "",
            "created_on": new Date().getTime(),
            "gender": "",
            "penName": result.user.displayName,
            "bio": "",
            "from": "Gmail"
        }
        console.log("postdataaaa", PostData)
        callingAPI('authentication/userRegistration', PostData).then((data) => {
            console.log("fffffffffffffffffffffff", data)
            if (data.data.success === '1') {
                toast.success("Successfully Registered", { containerId: "N" })
                window.localStorage.setItem("role", data.data.result[0].role);
                window.localStorage.setItem("userId", data.data.result[0].userId);
                window.location.href = '/';
            }else{
                toast.error("Something went wrong.", { containerId: "N" }) 
            }
        })
        } catch (error) {
          console.error('Google Sign-In error:', error);
        }
      };
    const signUpSave = () => {
        if (!firstName) {
            toast.error("First Name Required", { containerId: "N" })
            document.getElementById("firstName").focus()
            return false
        }
        else if (!lastName) {
            toast.error("LastName Required", { containerId: "N" })
            document.getElementById("lastName").focus()
            return false
        }
        else if (!penName) {
            toast.error("PenName Required", { containerId: "N" })
            document.getElementById("penName").focus()
            return false
        }
        else if (!phone) {
            toast.error("Phone Number Required", { containerId: "N" })
            document.getElementById("phone").focus()
            return false
        }
        else if (!isValidPhoneNumber(phone)) {
            toast.error("Invalid Phone Number", { containerId: "N" });
            document.getElementById("phone").focus();
            return false;
        }
        else if (!signUpEmail) {
            toast.error("Email Id Required", { containerId: "N" })
            document.getElementById("signUpEmail").focus()
            return false
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpEmail)) {
            toast.error("Invalid Email Id", { containerId: "N" })
            document.getElementById("signUpEmail").focus()
            return false
        }
        else if (!signUpPassword) {
            toast.error("Password Required", { containerId: "N" })
            document.getElementById("signUpPassword").focus()
            return false
        }
        else if (signUpPassword !== signUpConfirmPassword) {
            toast.error("Passwords do not match", { containerId: "N" })
            document.getElementById("signUpConfirmPassword").focus();
            return false;
        }
        else if (!checked) {
            toast.error("Please Accept the terms and condition", { containerId: "N" })
            return false
        }
        else {
            const formattedDateOfBirth = dateOfBirth !== "" ? formatToYYYYMMDD(dateOfBirth) : "";
            const PostData = {
                "firstName": firstName,
                "lastName": lastName,
                "emailId": signUpEmail,
                "mobileNumber": phone,
                "dateOfBirth": formattedDateOfBirth,
                "password": signUpPassword,
                "created_on": new Date().getTime(),
                "gender": gender,
                "penName": penName,
                "bio": "",
                "from": ""
            }
            console.log("postdataaaa", PostData)
            callingAPI('authentication/userRegistration', PostData).then((data) => {
                console.log("fffffffffffffffffffffff", data)
                if (data.data.success === '1') {
                    toast.success("Successfully Registered", { containerId: "N" })
                    window.localStorage.setItem("role", data.data.result[0].role);
                    window.localStorage.setItem("userId", data.data.result[0].userId);
                    window.location.href = '/';
                } else {
                    toast.error("Something went wrong.", { containerId: "N" })
                }
            })
        }
    }
    const showPopup = () => {
        setPopupVisibility(true);
    };
    const hidePopup = () => {
        setPopupVisibility(false);
    };
    return (
        <div className="reg-section">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"N"}
                theme="colored" />
            <Header isPopupVisible={isPopupVisible} showPopup={showPopup} hidePopup={hidePopup} />
            <div className='bannerImg'>
                <img src={BannerImg} alt="Banner" className='img-fluid' />
                <div className="bannerContent">
                    <h1 className='login-headtext'>Join With Us</h1>
                    <span className="underline center"></span>
                    <div className="" id="">
                        <section className='reg-wrapper login-wrapper' >
                            <div className=' row signupFields   '>
                                <div className="inputbox col-md-6">
                                    <FaUser />
                                    <input type="text" id="firstName" required value={firstName} autoComplete="off" onChange={(e) => setFirstName(e.target.value)} autoFocus />
                                    <label htmlFor="">First Name <sup className='star-red'>*</sup></label>
                                </div>
                                <div className="inputbox col-md-6">
                                    <FaUser />
                                    <input type="text" id="lastName" required value={lastName} autoComplete="off" onChange={(e) => setLastName(e.target.value)} />
                                    <label htmlFor="">Last Name <sup className='star-red'>*</sup></label>
                                </div>
                                <div className="inputbox col-md-6">
                                    <FaPen />
                                    <input type="text" id="penName" required value={penName} autoComplete="off" onChange={(e) => setPenName(e.target.value)} />
                                    <label htmlFor="" >Pen Name <sup className='star-red'>*</sup></label>
                                </div>
                                <div className="inputbox col-md-6">
                                    <FaTransgenderAlt style={{ top: "15px" }} />
                                    <select className='form-control genderSelect'
                                        value={gender}
                                        onChange={handleGenderChange}
                                        required
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                                <div className="inputbox datePickerWrap col-md-6">
                                    <FaCalendarAlt />
                                    <DatePicker
                                        selected={dateOfBirth ? new Date(dateOfBirth) : null}
                                        onChange={(date) => setDateOfBirth(date)}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control dobPicker"
                                        maxDate={new Date()}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        dropdownMode="select"
                                    />
                                    <label htmlFor=""> DOB </label>
                                </div>
                                <div className="inputbox col-md-6">
                                    <FaPhoneAlt />
                                    <input type="text" required id="phone" className='email-input' value={phone} autoComplete="off"
                                        onChange={(e) => setPhone(e.target.value)} />
                                    <label htmlFor="" >Phone <sup className='star-red'>*</sup></label>
                                </div>
                                <div className="inputbox col-md-12" style={{ width: "95%", margin: "15px auto" }}>
                                    <FaEnvelope />
                                    <input type="text" id="signUpEmail" required
                                        className='email-input' value={signUpEmail} autoComplete="off"
                                        onChange={(e) => setSignUpEmail(e.target.value)} />
                                    <label htmlFor="" >Email <sup className='star-red'>*</sup></label>
                                </div>
                                <div className="inputbox col-md-6">
                                    <FaLock />
                                    <input type="password" id="signUpPassword" required value={signUpPassword} autoComplete="off" onChange={(e) => setSignUpPassword(e.target.value)} />
                                    <label htmlFor="" >Password <sup className='star-red'>*</sup></label>
                                </div>
                                <div className="inputbox col-md-6">
                                    <FaLock />
                                    <input type="password" required id="signUpConfirmPassword" value={signUpConfirmPassword} autoComplete="off" onChange={(e) => setSignUpConfirmPassword(e.target.value)} />
                                    <label htmlFor="" > Confirm Password</label>
                                </div>
                            </div>
                            <div className="register">
                                <input type="checkbox" id="checkboxId" value={checked} onChange={(e) => setChecked(e.target.checked)} /> By continuing, you agree to Krithy's <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditon </a>
                                and acknowledge you've read our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy.</a>
                            </div>
                            <button className='mt-3 mb-1' onClick={() => signUpSave()}>Sign Up</button>
                            <div className="my-1 or-text">Or</div>
                            <div className="text-center continue-google-reg">

                                <button className='login-google-btn' style={{ display: forgotPasswordMode ? 'none' : 'block' }}  onClick={handleGoogleSignIn}><img src={Google} alt="Google" className='img-fluid google-img' />Continue with Google</button>
                            </div>
                        </section>
                    </div>
                </div>
            </div >
            <Footer />
        </div >
    )
}
export default Banner