import React, { useState, useEffect, Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { callingAPI } from '../../service';
import $, { data } from "jquery"
import Header from "../../components/layouts/header";
import Chats from "../dashboard/chats";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Select from "react-select";
import { convertToHTML } from 'draft-convert';
import { ToastContainer, toast } from 'react-toastify';
import AWS from 'aws-sdk';
var s3

function EpisodeEdit(props) {
    const [selectedEpisode, setSelectedEpisode] = useState([]);
    const { bookId, user_id } = useParams();
    const navigate = useNavigate()
    const [bookDetail, setBookDetail] = useState([]);
    const [content, setContent] = useState("");
    const [htmlcontent, setHtmlContent] = useState("");
    const [title, setTitle] = useState("");
    const [environmentType, setEnvironmentType] = useState('');
    const [categoryImagePath, setCategoryImagePath] = useState('');
    const [file, setFile] = useState('');
    const [imageName, setImageName] = useState('');
    const [imgObject, setImgObject] = useState('');
    const [cofigDetails, setConfigDetails] = useState('')
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML("")
            )
        ),
    );

    const handleEditorState = (value) => {
        const rawData = convertToRaw(value.getCurrentContent());
        setEditorState(value);
        const htmlContent = convertToHTML(value.getCurrentContent());
        setHtmlContent(htmlContent);
        setContent(rawData.blocks.map(block => block.text).join("\n"));
    }

    useEffect(() => {

        getConfiguration();
        fetchData();
    }, []);

    const fetchData = () => {
        const PostData = {
            "bookId": props.book_id,
            "currentEpisodeId": props.data.episodeId,
            "userId": window.localStorage.getItem("userId"),
            // "userId": props.userId,
        };
        callingAPI('reading/episodeRead', PostData).then((response) => {
            if (response.data.success === '1') {
                setSelectedEpisode(response.data.bookReadNextData);
                setTitle(response.data.bookReadNextData[0].title)
                const [data] = response.data.bookReadNextData
                setHtmlContent(data.description)
                setContent(data.description_without_html)
                setEditorState(EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(data.description)
                    )
                ),)

            } else {
                // setSelectedEpisode([])
            }
        })

    }






    const handleWriteToFile = async () => {
        if (!title) {
            toast.error("Please enter title", { containerId: "F" })
            return false
        }
       
        else if (!htmlcontent) {
            toast.error("Please enter description", { containerId: "F" })
            return false
        }
        else {
            const textContent = htmlcontent;
            const blob = new Blob([textContent], { type: 'text/plain' });
            textFileS3Save(`book_${window.localStorage.getItem("userId")}_${new Date().getTime()}.txt`, blob);
        }
    };





    const saveEpisodeDetails = async (textFileData) => {
   
        try {

            const PostData = {
                
                "episodeId": props.data.episodeId,
                "title": title,
                "description": htmlcontent,
                "description_without_html": content,
                "image": "",
                "createdOn": new Date().getTime(),
                "bookId": props.book_id,
                "createdBy": props.userId,
                "statusId": props.data.status_id,
                "bookContentFileS3Url": textFileData.Location,
            };
            console.log("🚀 ~ saveEpisodeDetails ~ PostData11:", PostData)

            const response = await callingAPI('episodeData/episodeSave', PostData);
            if (response.data.success === '1') {
                toast.success("Episode Details Updated Successfully", { containerId: "F" })

                props.backToList()
            }

        } catch (error) {
            console.error("Error:", error);
        }
    }


    const textFileS3Save = (imageName, blob) => {
        return new Promise((resolve, reject) => {
            try {

                var S3BucketMainFolder = environmentType + '/' + categoryImagePath;
                var fullFilePath = S3BucketMainFolder + '/' + imageName;

                s3.upload(
                    {
                        Key: fullFilePath,
                        Body: blob, // Upload the Blob object
                        ACL: 'public-read', // Optional: Set access control (consider security)
                    },
                    (err, data) => {
                        if (err) {
                            alert('There was an error uploading your photo: ' + err.message);
                            reject(err);
                        } else {
                            console.log("File uploaded to S3 successfully!", data);
                            resolve(true);
                            saveEpisodeDetails(data);
                        }
                    }
                );
            } catch (error) {
                console.error("Error uploading file to S3:", error);
                reject(error); // Propagate errors for handling in calling code
            }
        });
    };


    const getConfiguration = () => {
        return new Promise((resolve, reject) => {
            try {
                const PostData = {
                };
                callingAPI('configurationData', PostData).then((res) => {
                    setConfigDetails(res.data.result[0]);
                    const getConfigValue = (configKey) => {
                        return res.data.result[0].configDetails
                            ? res.data.result[0].configDetails.find((config) => config.config_key === configKey)?.config_value
                            : null;
                    };
                    setEnvironmentType(getConfigValue("S3BucketMainFolder"));
                    setCategoryImagePath(getConfigValue("bookImagePath"));
                    AWS.config.update({
                        accessKeyId: getConfigValue("AWSAccessKeyId"),
                        secretAccessKey: getConfigValue("AWSSecretKey"),
                        region: getConfigValue("s3Region"),
                    });
                    var bucketParams = {
                        Bucket: getConfigValue("s3bucket"),
                    };
                    s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
                    // getLanguageList()
                    resolve(s3)
                })

            }
            catch (e) {
                console.error("Error in getConfiguration", e)
                reject(e)
            }
        })

    }


    const CancelbookDetails = () => {
        navigate('/books/booksList')
    }

    return (
        <>
         <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"F"}
                theme="colored" />
            <div className="container booksList-Wrapper text-center booksList-EditWrapper">
                <div className="form-group row mb-2">
                    <label for="title" className="col-sm-2 col-form-label">Title<sup style={{ color: "red" }}>*</sup></label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" placeholder="Title"
                            defaultValue={title} onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>

                <div className="form-group row mb-2 description-editor">
                    <label for="description" className="col-sm-2 col-form-label">Description<sup style={{ color: "red" }}>*</sup></label>
                    <div className="col-sm-10">
                        <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            placeholder='Please enter your text'
                            editorState={editorState}
                            onEditorStateChange={handleEditorState}
                        />
                    </div>
                </div>

                <div className="">
                    <button className="btnNavy btn-saveBook"
                        onClick={() => handleWriteToFile()}
                    >Save</button>
                    <button className="btnNavy btn-saveBook btnCancel"
                        onClick={props.backToList}
                    >Cancel</button>
                </div>
            </div>
        </>
    )

}
export default EpisodeEdit