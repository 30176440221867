import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const VisitorStatics = (props) => {
  const [series] = useState([
    {
      name: 'Men Count',
      type: 'column',
      data: props.yearMonthResult.map((obj) => obj.mencount)
    },
    {
      name: 'Women Count',
      type: 'column',
      data: props.yearMonthResult.map((obj) => obj.womencount)
    },
    {
      name: 'Others Count',
      type: 'column',
      data: props.yearMonthResult.map((obj) => obj.othersCount)
    },
    // 
  ]);

  const [options] = useState({
    chart: {
      height: 350,
      type: 'line',
      colors: ['#a4b1c3'],
      stroke: {
        width: [0, 2, 1],
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#a4b1c3', 'grey', '#79d1cd'],
    stroke: {
      width: [0, 2, 1],
    },
    xaxis: {
      categories: props.yearMonthResult.map((obj) => obj.YearAndMonth),
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      // style: {
      //   fontSize: '12px',
      //   colors: ["#304758"]
      // }
    },

    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     gradientToColors: ['#FDD835'],
    //     shadeIntensity: 1,
    //     type: 'horizontal',
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 100, 100, 100]
    //   },
    // },
    yaxis: {
      min: 0,
      max: Math.max(...props.yearMonthResult.map((obj) => obj.mencount)) + 5,
    }
  });

  return (
    <div id="chart">
      <ReactApexChart key={props} options={options} series={series}
        //  type="bar"
        type="line"
        height={350} />
    </div>
  );
};

export default VisitorStatics;