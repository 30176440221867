import React, { useState, useEffect, Component } from 'react';
import { callingAPI } from '../../service';
import PublishedBooksAnalytics from './publishedBooks-analytics';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
function PublishedBooks() {
    const [yearAndMonth, setYearMonth] = useState([]);
    const [menCount, setMenCount] = useState([]);
    const [totalAppointmentResult, setTotalAppointmentResult] = useState([]);
    const [category, setCategory] = useState([]);
    useEffect(() => {
        const PostData = {
            "year": ""
        };
        callingAPI('book/publishedBookCountDetails', PostData).then((response) => {
            if (response.data.success === '1') {
                setTotalAppointmentResult(response.data.result.monthWiseData)
            }
            else {
                setTotalAppointmentResult([])
            }
        })
            .catch();

        const json = {

        };
        callingAPI('userData/categoryCountWiseAnalytics', json).then((response) => {
            if (response.data.success === '1') {
                setCategory(response.data.result)
            }
            else {
                setCategory([])
            }
        })
            .catch();
    }, []);
    return (
        <>
            <div className="issueSummWrap">
                <h2>Published Books</h2>
                <div className="summaryTabs">
                    <Tabs>
                        <TabList>
                        {/*<Tab>Last Week</Tab>*/}
                            {/* <Tab>This Week</Tab> */}
                        </TabList>

                        <TabPanel>
                            <div className="row">
                                <div className="col-md-6">
                                    {/* <h2>News vs Closed</h2> */}
                                    {/* <ReactApexChart
                                        options={chartData.options}
                                        series={chartData.series}
                                        type="line"
                                        height={350}
                                    /> */}
                                    {totalAppointmentResult !== "" ?
                                        <>
                                            <PublishedBooksAnalytics key={totalAppointmentResult} yearMonthResult={totalAppointmentResult} />
                                        </>
                                        :
                                        <div className='todayAppScroll nodataFnd nodata-found_wrpr graphDataNotfound' >
                                            <p>No Data Found </p>
                                        </div>
                                    }
                                </div>
                                <div className="col-md-6" key={category} >
                                    <h2>Overview</h2>
                                    <div className="row issueTopTxt">
                                        {/* {category.slice(0, 5).map((data, index) => ( */}
                                        {category.slice(0, 6).map((data, index) => (
                                            <div className={index > 1 ? "col-md-6 " : "col-md-6"}>
                                                <div className="dashCards smallGrids"
                                                    // style={{ background: index == 0 ? "#eef2ff" : (index == 1 ? "#f0fdf4" : "#f1f5f9") }}>

                                                    style={{
                                                        background: index == 0 ? "rgb(200 208 235)" : index == 1 ? "rgb(207 255 222)" : index == 2 ? "#e5ffb4" : index == 3 ? "rgb(180 227 255)" : index == 4 ? "rgb(180 255 218)" : index == 5 ? "rgb(213 180 255)" : "#000"
                                                    }}>
                                                    {/* darkGrn */}

                                                    <div className={index == 0 ? "dashCount darkBlue " : index == 1 ? "dashCount darkGreen" : index == 2 ? "dashCount darkGrn" : index == 3 ? "dashCount darkSkybl" : index == 4 ? "dashCount darkligG" : index == 4 ? "dashCount darkpur" : "dashCount"} >
                                                        <h1>{data.bookCount}</h1>
                                                        <p>{data.categoryName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/* <div className="col-md-6">
                                            <div className="dashCards " style={{ background: "#f0fdf4" }}>
                                                <div className="dashCount darkGreen">
                                                    <h1>38</h1>
                                                    <p>Closed</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row issueBtmTxt">
                                        <div className="col-md-3">
                                            <div className="dashCards" style={{ background: "#f1f5f9" }}>
                                                <div className="dashCount">
                                                    <h1>6</h1>
                                                    <p>Fixed</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dashCards" style={{ background: "#f1f5f9" }}>
                                                <div className="dashCount">
                                                    <h1>11</h1>
                                                    <p>Won't Fix</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dashCards" style={{ background: "#f1f5f9" }}>
                                                <div className="dashCount">
                                                    <h1>6</h1>
                                                    <p>Re-opened</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-3">
                                            <div className="dashCards" style={{ background: "#f1f5f9" }}>
                                                <div className="dashCount">
                                                    <h1>6</h1>
                                                    <p>Needs Triage</p>
                                                </div>
                                            </div>
                                        </div>  */}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        {/* <TabPanel>
                            <h2>Any content 2</h2>
                        </TabPanel> */}
                    </Tabs>
                </div>

            </div>
        </>
    )

}
export default PublishedBooks