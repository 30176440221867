import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrashAlt, FaUserCircle } from "react-icons/fa";
import { callingAPI } from '../service';
import { Rating } from 'react-simple-star-rating'
function Reviews({ id }) {
    const [replyVisible, setReplyVisible] = useState(false);
    const [replySubVisible, setReplySubVisible] = useState(false);
    const [editInputVisible, setEditInputVisible] = useState(false);
    const [editSubInputVisible, setSubEditInputVisible] = useState(false);
    const [editSubLastInputVisible, setSubEditLastInputVisible] = useState(false);
    const [listingData, setListingData] = useState([])
    const [fullListingData, setFullListingData] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [replyInputValue, setReplyInputValue] = useState('')
    const [subinputValue, setSubInputValue] = useState('')
    const [subinputLastValue, setSubInputLastValue] = useState('')
    const [subReplyValue, setSubReplyValue] = useState('')
    const [feedbackType, setFeedbackType] = useState('true');
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editingReplyCommenttId, setEditingReplyCommentId] = useState(null);
    const [editingSubCommentId, setEditingSubCommentId] = useState(null);
    const [editingSubReplyCommentId, setEditingSubReplyCommentId] = useState(null);
    const [editingSubLastCommentId, setEditingSubLastCommentId] = useState(null);
    const [bookreviewId1, setBookReviewId] = useState("")
    const [starRating, setStarRating] = useState("")

    const handleRating = (source, clickedRating) => {
        document.getElementById("popup").style.display = "block"
        const postData = {
            "bookId": id,
            "userId": window.localStorage.getItem("userId"),
            "episodeId": "",
            "currentDateTime": new Date().getTime(),
            "rating": clickedRating
        }
        callingAPI('reading/saveRatings', postData).then((res) => {
            console.log("res", res)
            reviewListing()
        });
    };
    const onPointerEnter = () => { };
    const onPointerLeave = () => { };
    const onPointerMove = (value, index) => {
        console.log('Mouse moved over', value, 'at index', index);
    };
    useEffect(() => {
        reviewListing()
    }, []);
    const reviewListing = () => {
        const postData = {
            "bookId": id,
            "userId": window.localStorage.getItem("userId")
        }
        callingAPI('book/bookReviewDetails', postData).then((res) => {
            console.log("res", res)
            if (res.data.myReviewDetails.length > 0) {
                setInputValue(res.data.myReviewDetails[0].bookReview)
                setBookReviewId(res.data.myReviewDetails[0].bookReviewId)
                setFeedbackType(res.data.myReviewDetails[0].is_positive === 0 ? 'false' : 'true');
                setStarRating(res.data.myReviewDetails[0].rating)
            }
            setListingData(res.data.result)
            setFullListingData(res.data)
        });
    };
    const handleReplyShow = (revId) => {
        setReplyVisible(true);
        setEditingReplyCommentId(revId)
    };
    const handleReplyHide = (bookReviewId, type) => {
        if (type === "submit") {
            const postData = {
                "bookReviewsCommentId": "",
                "userId": window.localStorage.getItem("userId"),
                "currentDateTime": new Date().getTime(),
                "bookReviewId": bookReviewId,
                "comments": replyInputValue,
                "parentCommentId": ""
            }
            callingAPI('reading/saveBookReviewCommentDetails', postData).then((res) => {
                if (res.data.success === "1") {
                    reviewListing()
                    setReplyInputValue("")
                    setReplyVisible(false);
                    setEditingReplyCommentId(null)
                }
            });
        } else {
            setReplyVisible(false);
            setEditingReplyCommentId(null)
        }
    };
    const handleEditShow = (reviewId, bookReview) => {
        setInputValue(bookReview)
        setEditInputVisible(true);
        setEditingCommentId(reviewId);
    };
    const handleCheckboxChange = () => {
        setFeedbackType((prevType) => (prevType === 'true' ? 'false' : 'true'));
    };
    const handleEditHide = (bookreviewId, type) => {
        if (type === "submit") {
            const postData = {
                "bookId": id,
                "bookReviewsId": bookreviewId1 ? bookreviewId1 : bookreviewId,
                "userId": window.localStorage.getItem("userId"),
                "currentDateTime": new Date().getTime(),
                "review": inputValue,
                "is_positive": feedbackType
            }
            callingAPI('reading/saveBookReviews', postData).then((res) => {
                if (res.data.success === "1") {
                    if (bookreviewId === "") {
                        document.getElementById("popup").style.display = "none"
                    }
                    reviewListing()
                    setInputValue("")
                    setEditInputVisible(false);
                    setEditingCommentId(null);
                }
            });
        } else {
            document.getElementById("popup").style.display = "none"
            setEditInputVisible(false);
            setEditingCommentId(null);
        }
    };
    const handleSubEditShow = (comment, commentId) => {
        setSubInputValue(comment)
        setSubEditInputVisible(true);
        setEditingSubCommentId(commentId)
    };
    const handleSubEditHide = (reviewId, reviewcommentId, type) => {
        if (type === "submit") {
            const postData = {
                "bookReviewsCommentId": reviewcommentId,
                "userId": window.localStorage.getItem("userId"),
                "currentDateTime": new Date().getTime(),
                "bookReviewId": reviewId,
                "comments": subinputValue,
                "parentCommentId": ""
            }
            callingAPI('reading/saveBookReviewCommentDetails', postData).then((res) => {
                if (res.data.success === "1") {
                    reviewListing()
                    setSubInputValue("")
                    setSubEditInputVisible(false);
                    setEditingSubCommentId(null)
                }
            });
        } else {
            setSubEditInputVisible(false);
            setEditingSubCommentId(null)
        }
    };
    const handleSubReplyShow = (commentId) => {
        setReplySubVisible(true);
        setEditingSubReplyCommentId(commentId)
    };
    const handleSubReplyHide = (bookReviewId, bookreviewCommentId, type) => {
        if (type === "submit") {
            const postData = {
                "bookReviewsCommentId": "",
                "userId": window.localStorage.getItem("userId"),
                "currentDateTime": new Date().getTime(),
                "bookReviewId": bookReviewId,
                "comments": subReplyValue,
                "parentCommentId": bookreviewCommentId
            }
            callingAPI('reading/saveBookReviewCommentDetails', postData).then((res) => {
                if (res.data.success === "1") {
                    reviewListing()
                    setSubReplyValue("")
                    setReplySubVisible(false);
                    setEditingSubReplyCommentId(null)
                }
            });
        } else {
            setReplySubVisible(false);
            setEditingSubReplyCommentId(null)
        }
    };
    const handleSubLastEditShow = (comment, commentId) => {
        setSubInputLastValue(comment)
        setSubEditLastInputVisible(true);
        setEditingSubLastCommentId(commentId)
    };
    const handleSubLastEditHide = (reviewId, reviewcommentId, parentId, type) => {
        if (type === "submit") {
            const postData = {
                "bookReviewsCommentId": reviewcommentId,
                "userId": localStorage.getItem('userId'),
                "currentDateTime": new Date().getTime(),
                "bookReviewId": reviewId,
                "comments": subinputLastValue,
                "parentCommentId": parentId
            }
            callingAPI('reading/saveBookReviewCommentDetails', postData).then((res) => {
                if (res.data.success === "1") {
                    reviewListing()
                    setSubInputLastValue("")
                    setSubEditLastInputVisible(false);
                    setEditingSubLastCommentId(null)
                }
            });
        } else {
            setSubEditLastInputVisible(false);
            setEditingSubLastCommentId(null)
        }
    };
    const handleDelete = (reviewcommentId, type) => {
        var x = false;
        x = window.confirm('Do you want to Delete this Review?');
        if (x === true) {
            let postData = {}
            if (type === "subReview") {
                postData = {
                    "bookOrEpisodeReviewCommentsId": reviewcommentId,
                    "deleteType": "Book"
                }
                callingAPI('reading/deleteBookEpisodeReviewCommentDetails', postData).then((res) => {
                    if (res.data.success === "1") {
                        reviewListing()
                    }
                });
            }
            else {
                postData = {
                    "bookOrEpisodeReviewsId": reviewcommentId,
                    "deleteType": "Book"
                }
                callingAPI('reading/deleteBookEpisodeReviewDetails', postData).then((res) => {
                    if (res.data.success === "1") {
                        reviewListing()
                    }
                });
            }
        }
    };
    const formatTimestampToDate = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        }).format(date);
        return formattedDate;
    }
    return (
        <>
            <div className="review-wrapper reviewsWrapper position-relative">
                <div className="review-popup-wrapper" id="popup" style={{ display: "none",cursor: 'pointer' }}>
                    <textarea name="" id="" className='review-textarea' placeholder='Write your review here' value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}> </textarea>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex mt-2 comment-toggle commntRply">
                            <span className='comment-toggle-text' style={{ marginRight: "8px" }}>comments</span>
                            <input type="checkbox" id="switch" checked={feedbackType === 'false'}
                                onChange={handleCheckboxChange} /><label for="switch"></label>
                            <span className='comment-toggle-text' style={{ marginLeft: "8px" }}>criticism</span>
                        </div>
                        <div className="replySubmtBtn">
                            <button className="reply-btn" onClick={() => handleEditHide("", "submit")}
                            >
                                Save
                            </button>
                            <button className="reply-btn cancel-btn-reply" onClick={() => handleEditHide("", "cancel")}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <h1>Reviews </h1>
                            <div className="toprating-wrpr">
                                <Rating
                                    ratingValue={fullListingData.AvgEpisodeRating}
                                    initialValue={Math.round(fullListingData.AvgEpisodeRating)}
                                    fillColor='orange'
                                    emptyColor='gray'
                                    readonly
                                />
                                <span className='rating-text'>{fullListingData.AvgEpisodeRating ? (fullListingData.AvgEpisodeRating).toFixed(1) : ""} {fullListingData.EpisodeRatingCount && `(${fullListingData.EpisodeRatingCount})`}</span>
                            </div>
                            <div className="rating-topText">{fullListingData.AvgEpisodeRating ? (fullListingData.AvgEpisodeRating).toFixed(1) : ""} Rating & {fullListingData.EpisodeRatingCount} Reviews</div>
                        </div>
                        <div className="">
                            <div className="rating-topText mb-0">Your Rating</div>
                            <div className="toprating-wrpr">
                                <Rating
                                    onClick={(rating) => handleRating("our rating", rating)}
                                    initialValue={Math.round(starRating)}
                                    onPointerEnter={onPointerEnter}
                                    onPointerLeave={onPointerLeave}
                                    onPointerMove={onPointerMove}
                                />
                            </div>
                        </div>
                    </div>
                    {listingData.length > 0 ?
                        <div className="bkBg">
                            {listingData.map((data, index) => (
                                <div class="comment">
                                    <div class="comment_header">
                                        <div class="comment-headerIcon"><FaUserCircle /></div>
                                        <div className="">
                                            <h2>{data.userDisplayName}</h2>
                                            <div className="reviewrating-wrpr">
                                                <Rating
                                                    initialValue={Math.round(data.rating)}
                                                    readonly
                                                    onPointerEnter={onPointerEnter}
                                                    onPointerLeave={onPointerLeave}
                                                    onPointerMove={onPointerMove}
                                                />
                                            </div>
                                        </div>
                                        <div className="comment-tags">
                                            <span class="tag-comment">Comment</span>
                                        </div>
                                    </div>
                                    <div class="comment_content">
                                        <p className="d-flex edit-revewwrap">
                                            <div className="edit-revewwrap-div">
                                                {data.bookReview}
                                            </div>
                                            <div className='d-flex edit-revewwrapIcon'>
                                                {window.localStorage.getItem("userId") == data.userId &&
                                                    <>
                                                        <div>
                                                            <FaEdit className="edit-svg"
                                                                onClick={() => handleEditShow(data.bookReviewId, data.bookReview)}
                                                                title='Edit' /></div>
                                                        <div><FaTrashAlt className='delete-svg' title='Delete'
                                                            onClick={() => handleDelete(data.bookReviewId, "mainReview")}
                                                        />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </p>
                                        {editInputVisible && editingCommentId === data.bookReviewId && (
                                            <div className="reply-inputSec" id="replyDiv">
                                                <input type="text" placeholder="Reply" className="form-control"
                                                    value={inputValue}
                                                    onChange={(e) => setInputValue(e.target.value)}
                                                ></input>
                                                <div className="revwsubmt-bottm-sec">
                                                    <div className="d-flex mt-2 comment-toggle commntRply">
                                                        <span className='comment-toggle-text' style={{ marginRight: "16px" }}>comments</span>
                                                        <input type="checkbox" id="switch"
                                                            checked={feedbackType === 'false'}
                                                            onChange={handleCheckboxChange}
                                                        /><label for="switch"></label>
                                                        <span className='comment-toggle-text' style={{ marginLeft: "16px" }}>criticism</span>
                                                    </div>
                                                    <div className="replySubmtBtn" style={{ cursor: 'pointer' }}>
                                                        <button className="reply-btn"
                                                            onClick={() => handleEditHide(data.bookReviewId, "submit")}
                                                        >
                                                            Submit
                                                        </button>
                                                        <button className="reply-btn cancel-btn-reply"
                                                            onClick={() => handleEditHide(data.bookReviewId, "cancel")}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div class="comment_footer">
                                        <span>
                                            {new Date(data.modifiedDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
                                        </span>
                                        <span className="replybtn"
                                            onClick={() => handleReplyShow(data.bookReviewId)}
                                        >Reply</span>
                                    </div>
                                    {replyVisible && editingReplyCommenttId === data.bookReviewId && (
                                        <div className="reply-inputSec" id="replyDiv">
                                            <input type="text" placeholder="Reply" className="form-control"
                                                value={replyInputValue}
                                                onChange={(e) => setReplyInputValue(e.target.value)}
                                            />
                                            <div className="revwsubmt-bottm-sec justify-content-end">
                                                <div className="d-flex mt-2 comment-toggle commntRply">
                                                    <span className='comment-toggle-text' style={{ marginRight: "16px" }}>comments</span>
                                                    <input type="checkbox" id="switch" /><label for="switch"></label>
                                                    <span className='comment-toggle-text' style={{ marginLeft: "16px" }}>criticism</span>
                                                </div>
                                                <div className="replySubmtBtn">
                                                    <button className="reply-btn"
                                                        onClick={() => handleReplyHide(data.bookReviewId, "submit")}
                                                    >
                                                        Submit
                                                    </button>
                                                    <button className="reply-btn cancel-btn-reply"
                                                        onClick={() => handleReplyHide(data.bookReviewId, "cancel")}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {data.bookReviewComments.map((obj, index) => (
                                        <div className="sub-commentSection">
                                            <div class="comment">
                                                <div class="comment_header">
                                                    <div class="comment-headerIcon"><FaUserCircle /></div>
                                                    <div className="">
                                                        <h2>{obj.userDisplayName}</h2>
                                                        <div className="reviewrating-wrpr">
                                                            {/* <Rating
                                                    onClick={handleRating}
                                                    onPointerEnter={onPointerEnter}
                                                    onPointerLeave={onPointerLeave}
                                                    onPointerMove={onPointerMove}
                                                /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="comment_content">
                                                    <p className="d-flex">
                                                        <div className="">{obj.bookReviewsComment}</div>
                                                        <div className='d-flex edit-revewwrapIcon'>
                                                            {window.localStorage.getItem("userId") == obj.userId &&
                                                                <>
                                                                    <div>
                                                                        <FaEdit className="edit-svg" title='Edit'
                                                                            onClick={() => handleSubEditShow(obj.bookReviewsComment, obj.bookReviewsCommentsId)}
                                                                        /></div>
                                                                    <div><FaTrashAlt className='delete-svg' title='Delete'
                                                                        onClick={() => handleDelete(obj.bookReviewsCommentsId, "subReview")}
                                                                    /></div>
                                                                </>}
                                                        </div>
                                                    </p>
                                                    {(editSubInputVisible && editingSubCommentId == obj.bookReviewsCommentsId) &&
                                                        <div className="reply-inputSec" id="replyDiv">
                                                            <input type="text" placeholder="Reply" className="form-control"
                                                                value={subinputValue}
                                                                onChange={(e) => setSubInputValue(e.target.value)}
                                                            />
                                                            <div className="revwsubmt-bottm-sec justify-content-end">
                                                                <div className="d-flex mt-2 comment-toggle commntRply">
                                                                    <span className='comment-toggle-text' style={{ marginRight: "16px" }}>comments</span>
                                                                    <input type="checkbox" id="switch" /><label for="switch"></label>
                                                                    <span className='comment-toggle-text' style={{ marginLeft: "16px" }}>criticism</span>
                                                                </div>
                                                                <div className="replySubmtBtn">
                                                                    <button className="reply-btn"
                                                                        onClick={() => handleSubEditHide(data.bookReviewId, obj.bookReviewsCommentsId, "submit")}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    <button className="reply-btn cancel-btn-reply"
                                                                        onClick={() => handleSubEditHide(data.bookReviewId, obj.bookReviewsCommentsId, "cancel")}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div class="comment_footer">
                                                    <span>{formatTimestampToDate(obj.modifiedDate)}</span>
                                                    <span className="replybtn"
                                                        onClick={() => handleSubReplyShow(obj.bookReviewsCommentsId)}
                                                    >Reply</span>
                                                </div>
                                                {(replySubVisible && editingSubReplyCommentId == obj.bookReviewsCommentsId) && (
                                                    <div className="reply-inputSec" id="replyDiv">
                                                        <input type="text" placeholder="Reply" className="form-control"
                                                            value={subReplyValue}
                                                            onChange={(e) => setSubReplyValue(e.target.value)}
                                                        />
                                                        <div className="revwsubmt-bottm-sec justify-content-end">
                                                            <div className="d-flex mt-2 comment-toggle commntRply">
                                                                <span className='comment-toggle-text' style={{ marginRight: "16px" }}>comments</span>
                                                                <input type="checkbox" id="switch" /><label for="switch"></label>
                                                                <span className='comment-toggle-text' style={{ marginLeft: "16px" }}>criticism</span>
                                                            </div>
                                                            <div className="replySubmtBtn">
                                                                <button className="reply-btn"
                                                                    onClick={() => handleSubReplyHide(data.bookReviewId, obj.bookReviewsCommentsId, "submit")}
                                                                >
                                                                    Submit
                                                                </button>
                                                                <button className="reply-btn cancel-btn-reply"
                                                                    onClick={() => handleSubReplyHide(data.bookReviewId, obj.bookReviewsCommentsId, "cancel")}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {obj.bookReviewCommentsReplays.map((obj1, index) => (
                                                <div className="sub-commentSection pl-5">
                                                    <div class="comment">
                                                        <div class="comment_header">
                                                            <div class="comment-headerIcon"><FaUserCircle /></div>
                                                            <div className="">
                                                                <h2>{obj1.userDisplayName}</h2>
                                                                <div className="reviewrating-wrpr">
                                                                    {/* <Rating
                                                        onClick={handleRating}
                                                        onPointerEnter={onPointerEnter}
                                                        onPointerLeave={onPointerLeave}
                                                        onPointerMove={onPointerMove}
                                                    /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="comment_content">
                                                            <p className="d-flex">
                                                                <div className="">{obj1.bookReviewsComment}</div>
                                                                <div className='d-flex edit-revewwrapIcon'>
                                                                    {window.localStorage.getItem("userId") == obj1.userId &&
                                                                        <>
                                                                            <div><FaEdit className="edit-svg" title='Edit'
                                                                                onClick={() => handleSubLastEditShow(obj1.bookReviewsComment, obj1.bookReviewsCommentsId)}
                                                                            /></div>
                                                                            <div><FaTrashAlt className='delete-svg' title='Delete'
                                                                                onClick={() => handleDelete(obj1.bookReviewsCommentsId, "subReview")}
                                                                            /></div>
                                                                        </>}
                                                                </div>
                                                            </p>
                                                            {(editSubLastInputVisible && editingSubLastCommentId == obj1.bookReviewsCommentsId) &&
                                                                <div className="reply-inputSec" id="replyDiv">
                                                                    <input type="text" placeholder="Reply" className="form-control"
                                                                        value={subinputLastValue}
                                                                        onChange={(e) => setSubInputLastValue(e.target.value)}
                                                                    />
                                                                    <div className="revwsubmt-bottm-sec justify-content-end">
                                                                        <div className="d-flex mt-2 comment-toggle commntRply">
                                                                            <span className='comment-toggle-text' style={{ marginRight: "16px" }}>comments</span>
                                                                            <input type="checkbox" id="switch" /><label for="switch"></label>
                                                                            <span className='comment-toggle-text' style={{ marginLeft: "16px" }}>criticism</span>
                                                                        </div>
                                                                        <div className="replySubmtBtn">
                                                                            <button className="reply-btn"
                                                                                onClick={() => handleSubLastEditHide(data.bookReviewId, obj1.bookReviewsCommentsId, obj1.parentCommentId, "submit")}
                                                                            >
                                                                                Submit
                                                                            </button>
                                                                            <button className="reply-btn cancel-btn-reply"
                                                                                onClick={() => handleSubLastEditHide(data.bookReviewId, obj1.bookReviewsCommentsId, obj1.bookReviewsCommentsId, "cancel")}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div class="comment_footer">
                                                            <span>{formatTimestampToDate(obj1.modifiedDate)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        : ""}
                </div>
            </div>
        </>
    )
}
export default Reviews