import React, { useState, useEffect, Component } from 'react';
import { callingAPI } from '../../service';
import { FaEye, FaStar, FaClock } from "react-icons/fa";
import EpisodeDetails from './episodeDetails';
import EpisodeReview from './episodeReview';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
function BookEpisodes(props) {
    const [bookDetail, setBookDetail] = useState([]);
    const [episodeClick, setepisodeClick] = useState(false);
    const [bookObj, setbookObj] = useState({});
    const [isData, setisData] = useState(false);
    const [statusValue, setstatusValue] = useState("")
    const [bookStatus, setbookStatus] = useState([
        {
            value: "3",
            label: "Publish",
        },
        {
            value: "1",
            label: "Draft",
        }, {
            value: "4",
            label: "Un-Publish",
        }]);
    useEffect(() => {

        if (props.statusId) {
            episodeListStatusWise();
        }
        else {
            fetchData();
        }
    }, []);
    const fetchData = () => {
        const PostData = {
            "bookId": props.id,
            "userId": window.localStorage.getItem("userId")
        };
        callingAPI('book/getBookDetails', PostData).then((response) => {
            if (response.data.success === '1') {
                setBookDetail(response.data.result);
                setisData(true)
            } else {
                setBookDetail([])
            }

        })

    }
    const episodeListStatusWise = () => {
        const PostData = {
            "bookId": props.id,
            "statusId": props.statusId,//1-Draft,3-Approved,4-UnApproved
            "offset": "0",
            "max": ""
        };
        callingAPI('book/booksEpisodesListStatusWise', PostData).then((response) => {
            if (response.data.success === '1') {
                let outputObject = {
                    "success": response.data.success,
                    "result": [
                        {
                            "episodeDetails": response.data.episodeData
                        }
                    ],
                    "successMessage": response.data.successMessage,
                    "errorMessage": response.data.errorMessage
                };
                setBookDetail(outputObject.result);
                setisData(true)
            } else {
                setBookDetail([])
            }

        })
    }

    const changeStatus = (event, date, episodeId) => {
        setstatusValue(event.value);
        const PostData = {
            "episode_id": episodeId,
            "status_id": event.value,// for publish - 3, unpublish - 1
            "createdOn": new Date().getTime(),
            "userId": window.localStorage.getItem("userId")
        };
        callingAPI('episodeData/publishAndUnpublishEpisode', PostData).then((response) => {
            if (response.data.success === '1') {
                // alert(response.data.result.notificationJson.notificationType)
                toast.success("Status changed successfully", { containerId: "F" })
                // props.backToList()
                episodeListStatusWise()
                console.log(response.data.result)
            } else {
                console.log(response.data.errorMessage)
            }

        })
    }

    function handleEpisodeDetailClick(obj) {
        setbookObj(obj)
        setepisodeClick(true)
        props.setepisodeClickParent(true)
        props.setEpisodeID(obj.id)
    };
    const displayBookDuration = (decimalMinutes) => {
        const totalSeconds = Math.round(decimalMinutes * 60);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        let result = '';
        if (hours > 0) {
            result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
        }
        if (minutes > 0) {
            result += `${result.length > 0 ? ' and ' : ''}${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        }
        if (seconds > 0 && hours <= 0 && minutes <= 0) {
            result += `${result.length > 0 ? ' and ' : ''}${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
        }
        if (result.length === 0) {
            result += 'Less than a minute';
        }
        return result;
    }
    return (
        <>
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"F"}
                theme="colored" />
            {isData === true && (
                (episodeClick === false) ?
                    <div >
                        {console.log("bookDetail *****...", bookDetail)}
                        {console.log(bookDetail)}
                        {bookDetail.length > 0 &&
                            <div className="card episodeInfo ">
                                <h1 >Chapters </h1>
                                <div className="row ">
                                    {bookDetail && bookDetail.map((bookobj, i) => (
                                        bookobj && bookobj.episodeDetails.map((obj, i) => (
                                            <div className="col-md-6 position-relative chapter-outer" 
                                            // onClick={() => handleEpisodeDetailClick(obj)}
                                             >
                                                {obj.status_id === 3 ? (
                                                    <div className='tag approved-tag'>
                                                        Approved
                                                    </div>
                                                ) : obj.status_id === 1 ? (
                                                    <div className='tag draft-tag'>
                                                        Draft
                                                    </div>
                                                ) : (
                                                    <div className='tag unapproved-tag'>
                                                        Unapproved
                                                    </div>
                                                )}
                                                <div className="row episodeBg">
                                                    {/* <div className='episodeListSectn'> */}


                                                    <div className="col-md-6" style={{ padding: "0px" }}>

                                                        <h2 onClick={() => handleEpisodeDetailClick(obj)}><b>{i + 1}. &nbsp;{obj.title}</b></h2>
                                                        <p className='episodeViews'>
                                                            <span><FaEye style={{ color: "#498507" }} /> {obj.no_of_views}</span>
                                                            {obj.rating !== "" && <span><FaStar style={{ color: "#498507" }} /> {obj.rating}</span>}
                                                            <span><FaClock style={{ color: "#04ad68" }} /> {displayBookDuration(obj.duration)}</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <h2 className='episode-date'>{new Date(obj.created_on).toLocaleString()}</h2>
                                                        <div className="">
                                                            <Select
                                                                id="select-status" className="selectControl-status"
                                                                value={statusValue}
                                                                placeholder="Change Status"
                                                                options={bookStatus.filter((status) => status.value !== obj.status_id.toString()).map((item) => ({
                                                                    value: item.value,
                                                                    label: item.label,
                                                                }))}
                                                                onChange={(e) => changeStatus(e, obj.created_on, obj.id)}
                                                            />

                                                        </div>

                                                    </div>

                                                    {/* <div className="col-md-4 dEnd">
                                                        <Select
                                                            id="select-status" className="selectControl-status"
                                                            value={statusValue}
                                                            placeholder="Change Status"
                                                            options={bookStatus.filter((status) => status.value !== obj.status_id.toString()).map((item) => ({
                                                                value: item.value,
                                                                label: item.label,
                                                            }))}
                                                            onChange={(e) => changeStatus(e, obj.created_on, obj.id)}
                                                        />

                                                    </div> */}
                                                </div>
                                            </div>
                                            // </div>
                                        ))
                                    ))}

                                </div>
                            </div>
                        }
                    </div>
                    :
                    <>
                        {/* {console.log("bookObj*****....", bookObj)} */}
                        <EpisodeDetails episodeListfunction={fetchData} bookObj={bookObj} setepisodeClick={setepisodeClick} setepisodeClickParent={props.setepisodeClickParent} />
                        <EpisodeReview obj={bookObj} id={bookObj.id} />
                    </>
            )}

        </>
    )
}
export default BookEpisodes